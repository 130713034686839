import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import * as printJS from 'print-js'
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import { CurrencyPipe } from '@angular/common';
import * as JsBarcode from 'jsbarcode';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { files } from 'src/app/view-hierarchy/example-data';

export interface SalesOrder {
  menu: number;
  company: string;
  orderDate: number;
  orderNumber: string;
  customerName: string;
  contactName: string;
  status: string;
  carrier: string;
  shipAs: string;
}

export interface SearchResponse {
  menu: any;
  item: any;
  number: string;
  description: string;
  action: any,
  uom: string,
  order: number,
  ship: number,
  package: number,
  weight: number,
  size: number,
  partTracking: string
}
export interface MultiPackageEntity {
  uom: String;
  ready: Number;
  shipped: Number;
  package: Number;
  weight: string;
  size: string;
  tracking: string;
}
declare var $: any
@Component({
  selector: 'app-shipment1',
  templateUrl: './shipment1.component.html',
  styleUrls: ['./shipment1.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class Shipment1Component implements OnInit {
  displayedColumn: string[] = ['menu', 'company', 'orderDate', 'orderNumber', 'customerName', 'contactName', 'status', 'carrier', 'shipAs'];
  displayedColumns: string[] = ['menu', 'item', 'number', 'description', 'action', 'uom', 'order', 'ship', 'package', 'weight', 'size', 'partTracking'];
  dataList: MatTableDataSource<SalesOrder>;
  salesOrderDetail: any = [];
  selection = new SelectionModel<SalesOrder>(true, []);
  [x: string]: any;
  companyId: any;
  form: FormGroup;
  loader: boolean = false;
  statuslist: any;
  boxSizing: any[] = [];
  _sideNavList: any;
  SerialLotForm: FormGroup;
  ChildReadyForLot: number = 0;
  IsLotBtnShow: boolean = false;
  FlayOutId: number = 0;
  FlayOut: any = 0;
  multiPackageData: any = [];
  qrResultString: string = '';
  IsScan: boolean = false;
  IsBarCodeScan: boolean = false;
  ScanIndex: number = 0;
  IsPrint: boolean = false;
  IsErrorValue: any;
  IsShowVariance: boolean = false;
  IsFlyoutErro: boolean = false;
  EqualShippedSerialLotList: any = [];
  IsDuplicateCheckTrackingList: any = [];
  PrinterList: any = [];
  PackingSlipPrinter: string;
  defaultPackingSlipPrinter: string;
  defaultShippingLabelsPrinter: string;
  ShippingLabelsPrinter: string;
  availableDevices: MediaDeviceInfo[];
  _flyOutPIndex: number = 0;
  _flyOutCIndex: number = 0;
  _IsParent: boolean = false;;
  _IsBtnFlyOutSave: boolean = false;;
  hasDevices: boolean;
  customerDetails: any = [];
  SObarcodeImg: any;
  PObarcodeImg: any;
  _masterShipping: any;
  actualShippingWeight: number = 0;
  radioTarget: string;
  @ViewChild('barcode') barcode: ElementRef;
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @ViewChild('barCodescanner', { static: false }) barCodescanner: BarcodeScannerLivestreamComponent;

  // Navneet start
  start: number = 500;
  IsShippingList: boolean = false;
  IsFlyOut: boolean = false;
  ShippingDataById: any;
  StateList: any[] = [];
  CountryList: any[] = [];
  specialPartList = [];
  shiprateListnew = [];
  ship_to: any;
  CompanyDetail: any;
  RateDetails: any;
  ShipMinDate: any = new Date();
  // Navneet End
  constructor(
    public sharedService: SharedService,
    public vendorService: VendorService,
    public customerService: CustomerService,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public datepipe: DatePipe,
    public confirmation: ConfirmationDialogService,
    public dateFormatPipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    public CurrencyPipe: CurrencyPipe,
    private companyService: CompanyService,
    private partService: PartService,
    public salesOrderService: SalesOrderService,
  ) { }
  ngOnInit(): void {
    this.loader = false;
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
    this.CreateForm();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.Status();
        this.getAllState();
        this.getAllShippingTypeById();
        this.GetShippingList('');
        this.GetCompanyById(Number(this.companyId));
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      location.reload();
    })
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
  }
  //Form Creation
  CreateForm() {
    this.SerialLotForm = new FormGroup({
      SerialNumber: this.fb.array([]),
    });
    this.form = new FormGroup({
      'companyId': new FormControl(this.companyId),
      'ShippingPackage': new FormArray([], Validators.minLength(1)),
      'rowItems': new FormArray([]),
      'shippedDate': new FormControl(new Date()),
      'totalShippingPartWeight': new FormControl(0),
      'totalShippingVariance': new FormControl(0),
      'actualShippingWeight': new FormControl(0),
      'masterTrackingNo': new FormControl(''),
      'orderId': new FormControl(0),
      'actualShippingType': new FormControl(''),
      'actualShippingAmount': new FormControl(0),
      'actualPackageType': new FormControl(''),
      'Actualrate_id': new FormControl(''),
      'actualShippingOption': new FormControl(0),
      'LabelId': new FormControl(''),
      'userId': new FormControl(localStorage.getItem('userId')),
    });
  }
  async canDeactivate(): Promise<Promise<boolean> | boolean> {
    if (!this.form.pristine && !this.isSaved) {
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }
  Search(event: any) {
    this.GetShippingList(event);
  }
  Back() {
    this.SerialNumber.clear();
    this.rowItems.clear();
    this.IsShippingList = false;
    this.specialPartList = [];
    this.GetShippingList('');
  }
  GetAllCustomer(companyId) {
    this.loader = true;
    this.customerService.GetAllCustomer(companyId, '').subscribe(
      (data: any) => {
        this.customerList = data.dataList;
        this.loader = false;
      }
    ), (err) => {
      this.loader = false;
    };
    this.GetAllStatus();
  }
  GetAllStatus() {
    this.loader = true;
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.statuslist = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  getAllShippingTypeById() {
    this.sharedService.GetShippingServiceMaster().subscribe(
      (data: any) => {
        this.carrierData = data;
      }), (err) => {
        this.loader = false;
      };
  }
  getMultiplePackageFromOrderList(orderId) {
    this.multiPackageData = [];
    this.sharedService.GetMultiPackageShipping(orderId).subscribe((data: any) => {
      this.rowItems.clear();
      if (data.length > 0) {
        this.multiPackageData = data;
        this.specialPartList.forEach((sp: any) => {
          const _list = this.multiPackageData.filter(x => x.opId == sp.id && x.parentId == null);
          _list.forEach((element: any) => {
            this.rowItems.push(new FormGroup({
              Id: new FormControl(element.id),
              OpId: new FormControl(element.opId),
              ready: new FormControl(element.ready),
              shipped: new FormControl(element.shipped),
              OrderId: new FormControl(element.orderId),
              package: new FormControl(element.package),
              weight: new FormControl(element.weight),
              PartId: new FormControl(element.partId),
              SubItemList: new FormArray([]),
              tracking: new FormControl(element.tracking),
              parentId: new FormControl(element.parentId),
              PartSerialId: new FormControl(element.partSerialId),
              cost: new FormControl(element.cost),
            }))
          })
        });
      } else {
        this.specialPartList.forEach((element: any) => {
          this.rowItems.push(new FormGroup({
            Id: new FormControl(0),
            OpId: new FormControl(element.id),
            ready: new FormControl(element.quantity),
            shipped: new FormControl(0),
            OrderId: new FormControl(element.salesOrderId),
            package: new FormControl(this.form.value.ShippingPackage[0].id),
            weight: new FormControl(element.weight),
            PartId: new FormControl(element.partId),
            SubItemList: new FormArray([]),
            tracking: new FormControl(),
            parentId: new FormControl(),
            PartSerialId: new FormControl(element.partTracking),
            cost: new FormControl(element.cost),
          }))
        });
      }
      this.AddSubListItemMultipackage();
    })
  }
  GetShippingList(searchTerm) {
    this.loader = (this.start == 500 && searchTerm == '') ? true : false;
    let Req = {
      companyId: Number(localStorage.getItem('GlobalSelectedCompanyId')),
      searchTerm: searchTerm != '' ? searchTerm.trim() : '',
      statusIds: this.radioTarget == 'Shipped' ? [7] : [14, 18],
      offsetNo: 0,
      pageSize: this.start,
    }
    let IsShipped = this.radioTarget == 'Shipped' ? true : false;
    this.sharedService.GetShippingList(Req,IsShipped).subscribe(
      (data: any) => {
        this.loader = false;
        this.salesOrderDetail = new MatTableDataSource(data.dataList);
      }
    )
  }
  @HostListener('window:scroll', ['$event'])
  onscroll(event) {
    const elem = event.currentTarget;
    if ((elem.innerHeight + elem.pageYOffset + 200) >= document.body.offsetHeight && this.pageLength <= this.dataLength) {
      this.pageLength += 30;
      this.paginator._changePageSize(this.pageLength);
    }
  }
  Status() {
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.StatusList = data.dataList
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  GetStateCode(stateId: number) {
    return (this.StateList.length > 0 && stateId != null && this.StateList.filter(x => x.id == stateId).length > 0) ? this.StateList.filter(x => x.id == stateId)[0].abbreviation : ''
  }
  GetShippingDetailsByOrderId(Id: number) {
    this.loader = true;
    this.sharedService.GetShippingDetailsByOrderId(Id)
      .subscribe({
        next: (data: any) => {
          this.IsShippingList = true;
          this.ShippingDataById = data.data;
          this.ship_to = {
            "addressLineOne": this.ShippingDataById.shipAddress.addressLine1,
            "addressLineTwo": this.ShippingDataById.shipAddress.addressLine2,
            "addressType": 0,
            "cityName": this.ShippingDataById.shipAddress.city,
            "countryCode": this.ShippingDataById.shipAddress.countryCode,
            "stateCode": this.ShippingDataById.shipAddress.stateCode,
            "postalCode": this.ShippingDataById.shipAddress.zip
          }
          this.BindData();
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  BindData() {
    this.form.patchValue({
      actualPackageType: 'YOUR_PACKAGING',
      actualShippingType: this.ShippingDataById.salesOrder.actualShippingType,
      Actualrate_id: this.ShippingDataById.salesOrder.actualrate_id,
      actualShippingOption: this.ShippingDataById.salesOrder.actualShippingOption,
      actualShippingAmount: this.ShippingDataById.salesOrder.actualShippingAmt,
      totalShippingPartWeight: this.ShippingDataById.salesOrder.totalShippingPartWeight == null ? 0 : this.ShippingDataById.salesOrder.totalShippingPartWeight,
      totalShippingVariance: this.ShippingDataById.salesOrder.totalShippingVariance == null ? 0 : this.ShippingDataById.salesOrder.totalShippingVariance,
      actualShippingWeight: this.ShippingDataById.salesOrder.actualShippingWeight == null ? 0 : this.ShippingDataById.salesOrder.actualShippingWeight,
      masterTrackingNo: this.ShippingDataById.salesOrder.masterTrackingNo,
      orderId: this.ShippingDataById.salesOrder.id,
      companyId: this.ShippingDataById.salesOrder.companyId,
    })
    this.specialPartList = this.ShippingDataById.salesOrder.orderPart;
    this.GetShippedCartonByOrderId();
    this.getShippingBoxSizes();
    this.BarcodeGenerate(false);
    if (this.ShippingDataById.salesOrder.purchaseOrder != '') {
      this.BarcodeGenerate(true);
    }
  }
  AddSubListItemMultipackage() {
    this.SerialNumber.clear();
    this.rowItems.value.forEach((pelement: any, index) => {
      if (this.multiPackageData.length > 0) {
        const _list = this.multiPackageData.filter(x => x.parentId === pelement.Id && x.parentId != null);
        _list.forEach((element: any) => {
          this.getRowItemSubList(index).push(new FormGroup({
            Id: new FormControl(element.id),
            ready: new FormControl(element.ready),
            shipped: new FormControl(element.shipped),
            package: new FormControl(element.package),
            weight: new FormControl(element.weight),
            tracking: new FormControl(element.tracking),
            PartSerialId: new FormControl(pelement.PartSerialId == undefined ? 3 : pelement.PartSerialId),
            cost: new FormControl(element.cost),
          }))
        })
      } else {
        this.getRowItemSubList(index).push(new FormGroup({
          Id: new FormControl(0),
          ready: new FormControl(pelement.ready),
          shipped: new FormControl(pelement.shipped),
          package: new FormControl(),
          weight: new FormControl(pelement.weight),
          tracking: new FormControl(pelement.tracking == null ? '' : pelement.tracking),
          PartSerialId: new FormControl(pelement.PartSerialId == undefined ? 3 : pelement.PartSerialId),
          cost: new FormControl(pelement.cost),
        }))
      }
    });
    this.loader = false;
  }
  GetTracking(Id: number): string {
    return this.constants.SerialTracking.filter(t => t.id == Id).length > 0 ? this.constants.SerialTracking.filter(t => t.id == Id)[0].value : 'None';
  }
  GetMesurmentSymbols(Id: any): string {
    let Symbol = '';
    if (Id == 1) {
      Symbol = 'cm'
    } else if (Id == 2) {
      Symbol = '"'
    } else if (Id == 3) {
      Symbol = "'"
    }
    return Symbol;
  }
  GetShippedCartonByOrderId() {
    this.sharedService.GetShippedCartonByOrderId(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId)
      .subscribe({
        next: (data: any) => {
          if (data.dataList.length > 0) {
            this.ShippingPackage.clear();
            data.dataList.forEach((x: any) => {
              this.ShippingPackage.push(new FormGroup({
                id: new FormControl(x.id),
                boxSizes: new FormControl(x.boxSizes == null || x.boxSizes == undefined || x.boxSizes == '' ? 0 : x.boxSizes),
                pLength: new FormControl(x.pLength == null || x.pLength == undefined ? 0 : Number(x.pLength)),
                pLengthD: new FormControl(x.pLengthD == null || x.pLengthD == undefined ? 0 : Number(x.pLengthD)),
                pWidth: new FormControl(x.pWidth == null || x.pWidth == undefined ? 0 : Number(x.pWidth)),
                pWidthD: new FormControl(x.pWidthD == null || x.pWidthD == undefined ? 0 : Number(x.pWidthD)),
                pHeight: new FormControl(x.pHeight == null || x.pHeight == undefined ? 0 : Number(x.pHeight)),
                pHeightD: new FormControl(x.pHeightD == null || x.pHeightD == undefined ? 0 : Number(x.pHeightD)),
                pWeight: new FormControl(x.pWeight == null || x.pWeight == undefined ? 0 : Number(x.pWeight)),
                pWeightD: new FormControl(x.pWeightD == null || x.pWeightD == undefined ? 0 : Number(x.pWeightD)),
                qtyInPackage: new FormControl(x.qtyInPackage == null || x.qtyInPackage == undefined || x.qtyInPackage == '' ? 0 : x.qtyInPackage),
                tracking: new FormControl(x.tracking),
                partWeight: new FormControl(x.partWeight == null || x.partWeight == undefined ? 0 : x.partWeight),
                totalweight: new FormControl(x.totalweight == null || x.totalweight == undefined ? 0 : x.totalweight),
                variance: new FormControl(x.variance == null || x.variance == undefined ? 0 : x.variance),
                isEditable: new FormControl(x.isEditable == null ? false : x.isEditable),
                label_downloadURL: new FormControl(x.label_downloadURL),
                cost: new FormControl(x.cost),
                packageNo: new FormControl(x.packageNo)
              }))
            })
            this.getMultiplePackageFromOrderList(this.ShippingDataById.salesOrder.id);
          }
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  EditCarton(index: number, rowText) {
    this.form.value.ShippingPackage[index].isEditable = false;
    this.form.patchValue({ ShippingPackage: this.form.value.ShippingPackage });
  }
  GetEqualShippedSerialLot(printerName: any, PrintAll: boolean) {
    if (this.form.value.masterTrackingNo != null && this.form.value.masterTrackingNo != undefined && this.form.value.masterTrackingNo != '') {
      this.RePrintLabel();
    } else {
      if (this.PackageFillValidation() == -1) {
        this.loader = true;
        this.IsPrint = false;
        this.sharedService.GetEqualShippedSerialLot(this.form.value.orderId).subscribe((res: any) => {
          this.EqualShippedSerialLotList = res.dataList;
          this.IsPrint = res.dataList.filter(x => x.isCountSame == 0 || x.tableCount != this.EqualShippedSerialLotList.length).length > 0 ? true : false;
          this.IsPrint == true ? this.PrintSlipMissingInfo() : this.ShippingCreateLabelAPI(printerName, PrintAll);    // need to false
        }), (err) => {
          this.loader = false;
        };
      } else {
        this.PackageFillValidationError();
      }
    }
  }
  TotalShippedForParts(OpId: number) {
    let totalShipped = 0;
    this.form.value.rowItems.filter(x => x.OpId == OpId).forEach(element => {
      totalShipped = Number(element.shipped);
      element.SubItemList.forEach(Ielement => {
        totalShipped = totalShipped + Number(Ielement.shipped);
      });
    });
    return totalShipped;
  }
  preferredCurrency() {
    return { "amount": this.form.value.actualShippingAmount, "type": 67 }
  }
  PackageRequest(): any[] {
    let packages = [];
    this.form.value.ShippingPackage.forEach(element => {
      packages.push(
        {
          "packageId": element.packageNo,
          "groupPackageCount": 1,
          "packageType": this.form.value.actualPackageType,
          "packageSubType": 0,
          "signatureOptionType": 0,
          "declaredValue": {
            "amount": element.cost,
            "type": 67
          },
          "dimensions": {
            "height": element.pHeight == null ? 0 : Number(element.pHeight),
            "length": element.pLength == null ? 0 : Number(element.pLength),
            "width": element.pWidth == null ? 0 : Number(element.pWidth),
            "unitOfMeasurement": element.pLengthD
          },
          "weight": { "unit": 5, "value": element.totalweight } //element.pWeight }
        }
      )
    });
    return packages;
  }
  RateAPI() {
    this.IsFlyoutErro = false;
    // this.loader = true;
    let estimateRequestModels = {
      "documentIndicator": false,
      "packageType": this.form.value.actualPackageType,
      "pickupType": 4,
      "preferredCurrency": 67,
      "providers": [this.form.value.actualShippingOption],
      "rateTypes": [1],
      "shipDate": this.form.value.shippedDate,
      "totalPackageCount": this.form.value.ShippingPackage.length,
      "origin": this.constants.originAddress,
      "recipient": { "address": this.ship_to },
      "totalWeight": { "unit": 5, "value": this.form.value.actualShippingWeight },
      "packages": this.PackageRequest()
    }
    this.loader = true;
    this.sharedService.RateAPI(Number(localStorage.getItem('GlobalSelectedCompanyId')), estimateRequestModels)
      .subscribe({
        next: (data: any) => {
          //Approved variance
          this.loader = false;
          this.shiprateListnew = [];
          data.providerRates[0].rates.forEach(element => {
            var obj = {};
            obj["rate_id"] = element.serviceType;
            obj["package_type"] = element.packageType;
            obj["service_type"] = element.serviceName;
            obj["carrier_delivery_days"] = element.operationalDetail.deliveryDay;
            obj["amount"] = element.rateDetails[0].totalNetCharge;
            this.shiprateListnew.push(obj);
          });
          $("#shiprateVM").show();
        },
        error: (err) => {
          this.loader = false
          //this.PrintSlipException(err)
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  ShippingCreateLabelAPI(printerName: any, PrintAll: boolean) {
    let ShippingLabelRequestModel = {
      "blockInsightVisibilityIndicator": true,
      "individualLabelIndicator": true,
      "labelResponseIndicator": true,
      "packageType": this.form.value.actualPackageType,
      "pickupType": 3,
      "rateRequestTypes": [1],
      "salesOrderId": this.form.value.orderId,
      "serviceType": this.form.value.Actualrate_id,
      "shipmentSubmissionIndicator": true,
      "shipDate": this.form.value.shippedDate,
      "totalWeight": this.form.value.actualShippingWeight,
      "labelSpecification": {
        "labelDataOnlyIndicaAtor": true,
        "labelImageType": 2,
        "labelOptionType": 1,
        "labelStockType": 12
      },
      "preferredCurrency": this.preferredCurrency(),
      "shipper": this.constants.originAddress,
      "totalDeclaredValue": this.preferredCurrency(),
      "packages": this.PackageRequest(),
      "recipients": [
        {
          "address": this.ship_to,
          "contact": {
            "companyName": "Modern Precision",
            "name": "Mike Jablonski",
            "phoneNumber": "8888888888"
          }
        }
      ]
    }
    this.sharedService.LabelAPI(Number(localStorage.getItem('GlobalSelectedCompanyId')), this.form.value.actualShippingOption, ShippingLabelRequestModel).subscribe((res: any) => {
      this.loader = true;
      this.printPackageSlip(res.shipments[0].shipmentPieces[0].packageDocuments);
    }), (err) => {
      this.SwalModal(err, 'Error')
      this.loader = false;
    };
  }
  ShippingPayment(printerName: any, PrintAll: boolean) {
    this.loader = true;
    this.sharedService.ShippingPayment_new(this.form.value.orderId, this.form.value.rowItems)
      .subscribe({
        next: (res: any) => {
          this.loader = true;
          res.payment.status == 'Success' ? this.ShippingCreateLabelAPI(printerName, PrintAll) : this.Paymentfails();
        },
        error: (err) => {
          this.loader = false;
          this.Paymentfails();
        },
      });
  }
  ShippingVarianceLog(value: any, printerName: any, PrintAll: boolean) {
    this.loader = true;
    //const service_Code = this.carrierData.filter(y => y.name == this.form.value.actualShippingType).length > 0 ? this.carrierData.filter(y => y.name == this.form.value.actualShippingType)[0].serviceCode : this.form.value.actualShippingType;
    let shippingVarianceVM = {
      RateTesponse: value,
      OrderId: this.form.value.orderId,
      ShipFrom: JSON.stringify(this.constants.ship_from),
      ShipTo: JSON.stringify(this.ship_to),
      LogType: 'Shipping',
      userId: this.form.value.userId,
    }
    this.sharedService.ShippingVarianceLog(shippingVarianceVM)
      .subscribe({
        next: (data: any) => {
          this.loader = true;
          this.ShippingPayment(printerName, PrintAll);
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  Paymentfails() {
    this.loader = false;
    Swal.fire({
      title: 'Payment Error ',
      text: 'The Shipping Labels were not able to be printed because payment. was not able to be completed. Please consult with Sales to get this resolved.',
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  PrintSlipMissingInfo() {
    this.loader = false;
    Swal.fire({
      title: 'Missing Information',
      text: 'All parts with Lot Numbers or Serial Numbers must be entered before the Shipping Labels and Packing Slip can be printed. Please scan or enter the missing information.',
    }).then((result) => {
      this.IsFlyoutErro = true;
    });
  }
  printPackageSlip(document: any) {
    // this.finalSave();  // Need to uncomment
    this.loader = false;
    let focuser = setInterval(() => window.dispatchEvent(new Event('focus')), 500);
    let doc = []
    document.forEach((d, index) => {
      doc.push(document.packages.length == 1 ? d.url.png : d.url.png);
      this.form.value.ShippingPackage[index].label_downloadURL = document.length == 1 ? document.url.png : d.url.png;
      this.form.value.ShippingPackage[index].tracking = d.trackingNumber;
    });
    this.form.patchValue({ ShippingPackage: this.form.value.ShippingPackage, masterTrackingNo: document.tracking_number, LabelId: document.label_id });
    this.loader = false;
    printJS({
      printable: doc,
      type: 'image',
      showModal: true,
      imageStyle: 'width:50%;margin-bottom:20px;',
      onPrintDialogClose: () => {
        //this.getCompanyDetailsByCompanyId();
      }
    });
  }
  Reprint(document: any) {
    printJS({
      printable: document,
      type: 'image',
      showModal: true,
      imageStyle: 'width:50%;margin-bottom:20px;',
    });
  }
  ReprintPackageSlip(document: any, index: number) {
    this.sharedService.SetDefaultPrinter(this.defaultShippingLabelsPrinter).subscribe((res: any) => {
      this.PrinterList = res;
      this.loader = false;
      Swal.fire({
        title: 'Printing Shipping Lable',
        text: 'Please wait while the Package ' + (index + 1) + ' shipping label is printed.Click reprint to try again.',
        showCancelButton: true,
        cancelButtonText: '<div>Close</div>',
        confirmButtonText: 'Reprint',
      }).then((result) => {
        if (result.value) {
          this.Reprint(document);
        }
      });
    }), (err) => {
      this.loader = false;
    };
  }
  removePackage(index) {
    if (this.form.value.ShippingPackage[index].id < 0) {
      this.ShippingPackage.removeAt(index);
    } else {
      this.loader = true;
      this.sharedService.DeleteCartonFromList(this.form.value.ShippingPackage[index].id)
        .subscribe({
          next: (data: any) => {
            this.ShippingPackage.removeAt(index);
            this.loader = false;
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
          },
        });
    }
  }
  Save(IsModelShow: boolean) {
    if (this.PackageFillValidation() == -1) {
      if (!this.form.invalid) {
        this.loader = true;
        // this.MasterShipping();  need to be correct
        this.sharedService.AddMultiPackageShipping(this.form.value)
          .subscribe({
            next: (data: any) => {
              this.GetShippingDetailsByOrderId(this.ShippingDataById.salesOrder.id);
              if (IsModelShow) {
                Swal.fire('Success', 'Record Saved Successfully.')
              }
            },
            error: (err) => {
              this.loader = false
              this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
            },
          });
      }
    } else {
      this.PackageFillValidationError();
    }
  }
  ChangeStatus(value: any) {
    this.radioTarget = value.target.id;
    this.GetShippingList('');

  }
  createPackage(id: number): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      boxSizes: new FormControl(0),
      pLength: new FormControl(0),
      pLengthD: new FormControl(),
      pWidth: new FormControl(0),
      pWidthD: new FormControl(),
      pHeight: new FormControl(0),
      pHeightD: new FormControl(),
      pWeight: new FormControl(0, Validators.required),
      pWeightD: new FormControl(),
      qtyInPackage: new FormControl(0),
      tracking: new FormControl(),
      partWeight: new FormControl(),
      variance: new FormControl(0.00),
      isEditable: new FormControl(),
      totalweight: new FormControl(0),
      cost: new FormControl(0),
      packageNo: new FormControl(0),
    })
  }

  AddSinglePackage() {
    this.loader = true;
    this.sharedService.AddSinglePackage(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId)
      .subscribe(
        data => {
          this.ShippingPackage.push(this.createPackage(data.data.id));
          this.loader = false;
        }
      ), (err) => {
        this.loader = false;
      };
  }
  get ShippingPackage(): FormArray {
    return this.form.get('ShippingPackage') as FormArray
  }
  get rowItems(): FormArray {
    return this.form.get('rowItems') as FormArray
  }
  getRowItemSubList(subItemListIndex: number): FormArray {
    return this.rowItems.at(subItemListIndex).get('SubItemList') as FormArray
  }
  PackageFillValidation() {
    return this.form.value.ShippingPackage.findIndex(x => x.boxSizes == null || x.boxSizes == 0 || x.boxSizes == undefined);
  }
  PackageFillValidationError() {
    this.form.value.ShippingPackage.forEach((sp, index) => {
      if (sp.boxSizes == 0 || sp.boxSizes == null || sp.boxSizes == undefined) {
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').markAsTouched();
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').setErrors({ 'BoxErrors': true });
      } else {
        this.form.get('ShippingPackage')['controls'][index].get('boxSizes').setErrors(null);
      }
    });
  }
  createItem(row: any, ParentId: number): FormGroup {
    return new FormGroup({
      Id: new FormControl(0),
      ready: new FormControl(),
      shipped: new FormControl(0, Validators.required),
      package: new FormControl(''),
      weight: new FormControl(row.weight * row.shipQty),
      tracking: new FormControl(''),
      PartSerialId: new FormControl(row.partSerialId),
      cost: new FormControl(row.cost * row.shipQty),
      PartId: new FormControl(row.partId),
      OpId: new FormControl(row.id),
      OrderId: new FormControl(this.OrderId),
      ParentId: new FormControl(ParentId),
    })
  }
  createSubItem(row: any, OrderId: number): FormGroup {
    return new FormGroup({
      Id: new FormControl(row.id),
      ready: new FormControl(row.shipQty),
      weight: new FormControl(row.weight * row.shipQty),
      shipped: new FormControl(),
      package: new FormControl(),
      OpId: new FormControl(row.id),
      OrderId: new FormControl(OrderId),
      PartId: new FormControl(row.partId),
      PartSerialId: new FormControl(row.partSerialId),
      cost: new FormControl(row.cost * row.shipQty),
      SubItemList: new FormArray([])
    })
  }
  CalculateWeight(value: any, PI, SI, row: any) {
    this.form.value.rowItems[PI].SubItemList[SI].weight = value.value * row.weight;
    this.form.value.rowItems[PI].SubItemList[SI].cost = value.value * row.cost;
    if (SI == 0) {
      this.form.value.rowItems[PI].SubItemList[SI].ready = row.quantity - this.form.value.rowItems[PI].shipped - value.value;
    } else {
      this.form.value.rowItems[PI].SubItemList[SI].ready = this.form.value.rowItems[PI].SubItemList[SI - 1].ready - value.value;
    }
    for (let i = 0; i < this.form.value.rowItems[PI].SubItemList.length; i++) {
      if (i == 0) {
        this.form.value.rowItems[PI].SubItemList[i].ready = this.form.value.rowItems[PI].ready - (this.form.value.rowItems[PI].shipped + this.form.value.rowItems[PI].SubItemList[i].shipped);
      } else {
        this.form.value.rowItems[PI].SubItemList[i].ready = this.form.value.rowItems[PI].SubItemList[i - 1].ready - this.form.value.rowItems[PI].SubItemList[i].shipped;
      }
    }
    this.form.patchValue({
      rowItems: this.form.value.rowItems
    })
    if (this.form.value.rowItems[PI].SubItemList[SI].ready < 0) {
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').markAsTouched();
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').setErrors({ 'ReadyExceed': true });
    } else {
      this.form.get('rowItems')['controls'][PI].get('SubItemList')['controls'][SI].get('shipped').setErrors(null);
    }
    this.CalculatePartWeight();
  }
  addItem(index: number, row: any): void {
    if (this.form.value.ShippingPackage.length > this.getRowItemSubList(index).length) {
      this.getRowItemSubList(index).push(this.createItem(row, this.form.value.rowItems[index].Id));
    } else {
      this.PackageNeeded();
    }
  }
  getShippingBoxSizes() {
    this.partService.GetAllPartWithoutPagging(this.ShippingDataById.salesOrder.companyId)
      .subscribe({
        next: (data: any) => {
          this.boxSizing = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, 'Something Went Wrong.');
        },
      });
  }
  onValueChange(event: any, index: number) {
    const boxes = this.boxSizing;
    for (let i = 0; i < boxes.length; i++) {
      if (boxes[i].id == event.value) {
        this.form.value.ShippingPackage[index].boxSizes = boxes[i].id;
        this.form.value.ShippingPackage[index].pLength = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].length);
        this.form.value.ShippingPackage[index].pLengthD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pWidth = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].width);
        this.form.value.ShippingPackage[index].pWidthD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pHeight = (this.IsCustomPackage(boxes[i].name)) ? 0 : Number(boxes[i].height);
        this.form.value.ShippingPackage[index].pHeightD = Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].pWeightD = 3 //Number(boxes[i].dimentionType);
        this.form.value.ShippingPackage[index].partWeight = Number(boxes[i].weight);
        this.form.value.ShippingPackage[index].totalweight = Number(boxes[i].weight);
        this.form.value.ShippingPackage[index].cost = Number(boxes[i].cost);
        this.form.value.ShippingPackage[index].isEditable = (this.IsCustomPackage(boxes[i].name)) ? false : true;
        this.form.value.ShippingPackage[index].packageNo = index + 1;
        this.CalculatePartWeight();
      }
    }
  }
  IsCustomPackage(PartName: string) {
    let IsCustom = false;
    if (PartName != '' && PartName.trim().toUpperCase() == 'CUSTOM SIZE' || PartName.trim().toUpperCase() == 'CUSTOM PACKAGE') {
      IsCustom = true;
    }
    return IsCustom;
  }
  CalculateVariance(event: any, i: any) {
    const cost = this.form.value.ShippingPackage[i].pWeight;
    this.form.value.ShippingPackage.at(0).pWeight = (this.isValidCost(cost) ? Number(cost) : 0);
    this.CalculatePartWeight();
  }
  isValidCost(input: any) {
    const isDecimal = /^\d+(\.\d+)?$/.test(input);
    if (isDecimal) {
      return true;
    }
    return false;
  }
  TotalLBS() {
    let totalShippingPartWeight = 0;
    this.form.value.ShippingPackage.forEach(element => {
      totalShippingPartWeight = totalShippingPartWeight + element.totalweight;
    });
    this.form.patchValue({ totalShippingPartWeight: totalShippingPartWeight })
  }
  TotalVariance() {
    let totalShippingVariance = 0;
    let actualShippingWeight = 0;
    this.form.value.ShippingPackage.forEach(element => {
      actualShippingWeight = actualShippingWeight + Number(element.pWeight);
      element.variance = ((isNaN(element.variance) == true) ? 0 : element.variance);
      totalShippingVariance = totalShippingVariance + element.variance;
    });
    this.form.patchValue({ totalShippingVariance: this.validateDecimalTwoPlace(totalShippingVariance), actualShippingWeight: actualShippingWeight })
  }
  PackageNeeded() {
    Swal.fire({
      title: 'Package Needed',
      text: 'The rows exceed the number of packagees setup for this shipment. Click Add package to add a new package and select the box size or Cancel.',
      showCancelButton: true,
      cancelButtonText: '<div style="color:red;">Cancel </div>',
      confirmButtonText: '+ Add Package',
    }).then((result) => {
      if (result.value) {
        this.AddPackageBySubListItem();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  AddPackageBySubListItem() {
    var model = [];
    let _list = [];
    _list = this.boxSizing;
    this.boxSizing.forEach(element => {
      model.push(element.name);
    });
    Swal.fire({
      position: 'top',
      title: "Add Package",
      confirmButtonText: 'Continue',
      input: "select",
      html: `
    <div class="row">
  <label style="margin:auto;">Please select the Box Size from the menu below.</label> 
    </div>
   `,
      inputOptions: model,
      inputPlaceholder: "Box Size",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            this.loader = true;
            this.sharedService.AddSinglePackage(this.ShippingDataById.salesOrder.id, this.ShippingDataById.salesOrder.companyId)
              .subscribe(
                data => {
                  this.ShippingPackage.push(this.createPackage(data.data.id));
                  this.loader = false;
                  let obj = { "value": _list[value].id }
                  this.onValueChange(obj, this.form.value.ShippingPackage.length - 1)
                  Swal.close()
                }
              ), (err) => {
                this.loader = false;
              };
          } else {
            resolve('You need to select')
          }
        })
      },
      showCancelButton: true,
      showConfirmButton: true
    })
  }
  createLotItem(): FormGroup {
    const index = this.SerialNumber ? this.SerialNumber.length : 0;
    return new FormGroup({
      tracking: new FormControl('')
    })
  }
  OpenFlyout(rowIndex: number, chieldIndex: number, isParent: boolean, value: any) {
    this.FlayOut = undefined;
    this.PartsData(rowIndex);
    if (value.partTracking == 2) {
      this.IsLotBtnShow = true;
      if (isParent == true) {
        this._IsParent = true;
        this._flyOutPIndex = rowIndex;
        this.FlayOut = this.form.value.rowItems[rowIndex];
      } else {
        this._flyOutPIndex = rowIndex;
        this._flyOutCIndex = chieldIndex;
        this._IsParent = false;
        this.FlayOut = this.getRowItemSubList(rowIndex).value[chieldIndex];
      }
      this.getFlayOutValue(this.FlayOut, value.partTracking)
    } else if (value.partTracking == 1) {
      if (isParent == true) {
        this._flyOutPIndex = rowIndex;
        this._IsParent = true;
        this.FlayOut = this.form.value.rowItems[rowIndex];
      } else {
        this._flyOutPIndex = rowIndex;
        this._flyOutCIndex = chieldIndex;
        this._IsParent = false;
        this.FlayOut = this.getRowItemSubList(rowIndex).value[chieldIndex];
      }
      this.getFlayOutValue(this.FlayOut, value.partTracking)
    }
    this.IsFlyOut = true;
    this._sideNavList = value;
  }
  AddTracking() {
    if (this.SerialNumber.length < this.ChildReadyForLot) {
      this.SerialNumber.push(this.createLotItem());
      this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
    }
  }
  get SerialNumber(): FormArray {
    return this.SerialLotForm.get('SerialNumber') as FormArray
  }
  closeFlyOut() {
    this.loader = false;
    this.ChildReadyForLot = 0;
    this.SerialNumber.clear();
    this.IsFlyOut = false;
    this.IsLotBtnShow = false;
    this.FlayOutPackageNo = 0;
    this.FlayOut = undefined;
    this.IsScan = false;
    this.ScanIndex = 0;
    this._IsParent = false;
    this._flyOutCIndex = 0;
    this._flyOutCIndex = 0;
    this._IsBtnFlyOutSave = false;
    this.IsBarCodeScan = false;
    this.clearResult();
  }
  saveSerialLot() {
    if (!this.SerialLotForm.invalid) {
      this.FlayOut.tracking = JSON.stringify(this.SerialLotForm.value.SerialNumber);
      this.loader = true;
      this.sharedService.SaveFlyOut(this.FlayOut)
        .subscribe(
          data => {
            this.loader = false;
            data.data != null ? this.BindFlyOutResponse(data.data) : null
            this.sharedService.GetEqualShippedSerialLot(this.form.value.orderId).subscribe((res: any) => {
              this.EqualShippedSerialLotList = res.dataList;
            }), (err) => {
              this.loader = false;
            };
            this.closeFlyOut();
          }
        ), (err) => {
          this.loader = false;
        };
    }
  }
  BindFlyOutResponse(data: any) {
    this.FlayOut.Id = data.id;
    this.FlayOut.OpId = data.opId;
    this.FlayOut.ParentId = data.parentId;
    this.FlayOut.PartId = data.partId;
    this.FlayOut.PartSerialId = data.partSerialId;
    this.FlayOut.cost = data.cost;
    this.FlayOut.package = data.package;
    this.FlayOut.ready = data.ready;
    this.FlayOut.shipped = data.shipped;
    this.FlayOut.tracking = data.tracking;
    this.FlayOut.weight = data.weight;
    if (this._IsParent == true) {
      this.form.value.rowItems[this._flyOutPIndex] = this.FlayOut;
    } else {
      this.form.value.rowItems[this._flyOutPIndex].SubItemList[this._flyOutCIndex] = this.FlayOut;
    }
    this.form.patchValue({ rowItems: this.form.value.rowItems });
  }
  async getFlayOutValue(value: any, Type: number) {
    this.FlayOut.shipped == 0 ? (this._IsBtnFlyOutSave = true) : (this._IsBtnFlyOutSave = false);
    if (value.Id != 0 && value.Id != null) {
      this.loader = true
      this.sharedService.GetFlyOutById(value.Id)
        .subscribe(
          (data: any) => {
            this.loader = false;
            this.SerialNumber.clear();
            if (data.data.tracking != "" && data.data.tracking != null) {
              let result = JSON.parse(data.data.tracking);
              result.forEach((x: any, i: number) => {
                if (x.tracking != '') {
                  this.SerialNumber.push(new FormGroup({
                    tracking: new FormControl(x.tracking)
                  }))
                }
              })
            }
            this.FlayOutId = this.FlayOut.Id;
            this.FlayOutPackageNo = this.form.value.ShippingPackage.findIndex(x => x.id == this.FlayOut.package) + 1;
            if (Type == 1) {
              this.IsLotBtnShow = false;
              for (var i = this.SerialNumber.length; i < this.FlayOut.shipped; i++) {
                this.SerialNumber.push(this.createLotItem());
              }
            } else if (Type == 2) {
              this.ChildReadyForLot = this.FlayOut.shipped;
              this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
              if (this.SerialNumber.length <= this.FlayOut.shipped && this.SerialNumber.length == 0) {
                this.SerialNumber.push(this.createLotItem());
              }
            }
          }
        ), (err) => {
          this.loader = false;
        };
    } else {
      this.FlayOutId = this.FlayOut.Id;
      this.FlayOutPackageNo = this.form.value.ShippingPackage.findIndex(x => x.id == this.FlayOut.package) + 1;
      if (Type == 1) {
        this.IsLotBtnShow = false;
        for (var i = this.SerialNumber.length; i < this.FlayOut.shipped; i++) {
          this.SerialNumber.push(this.createLotItem());
        }
      } else if (Type == 2) {
        this.ChildReadyForLot = this.FlayOut.shipped;
        this.IsLotBtnShow = this.SerialNumber.length == this.ChildReadyForLot ? false : true;
        if (this.SerialNumber.length <= this.FlayOut.shipped && this.SerialNumber.length == 0 && this.FlayOut.shipped != 0) {
          this.SerialNumber.push(this.createLotItem());
        }
      }
      this.loader = false;
    }
  }
  changePackage(event: any, index: number) {
    this.form.value.rowItems[index].package = event.value;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    this.CalculatePartWeight();
  }

  changeChildPackage(event: any, PIndex: number, CIndex: number) {
    this.form.value.rowItems[PIndex].SubItemList[CIndex].package = event.value;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    this.CalculatePartWeight();
  }

  ChangeShip(event: any, index: number, row: any) {
    this.IsErrorValue = Number(event.value);
    this.form.value.rowItems[index].shipped = Number(event.value);
    this.BindWeightForParent(index, row);
    this.form.value.rowItems[index].SubItemList[0].ready = row.quantity - this.form.value.rowItems[index].shipped;
    this.form.patchValue({ rowItems: this.form.value.rowItems });
    if (this.form.value.rowItems[index].shipped > this.form.value.rowItems[index].ready) {
      this.form.get('rowItems')['controls'][index].get('shipped').setErrors({ 'ReadyExceed': true });
    } else {
      this.form.get('rowItems')['controls'][index].get('shipped').setErrors(null);
    }
    this.CalculatePartWeight();
  }
  BindWeightForParent(rowItemsIndex: number, row: any): string {
    this.form.value.rowItems[rowItemsIndex].cost = this.form.value.rowItems[rowItemsIndex].shipped * row.cost;
    return (this.form.value.rowItems[rowItemsIndex].weight = this.form.value.rowItems[rowItemsIndex].shipped * row.weight).toString();
  }
  BindWeightForChild(rowItemsIndex: number, SubIndex: number, row: any): string {
    this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].cost = this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].shipped * row.cost;
    return (this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].weight = this.form.value.rowItems[rowItemsIndex].SubItemList[SubIndex].shipped * row.weight).toString();
  }
  GetUOM(Id: number): string {
    return this.constants.UnitLength.filter(u => u.id == Id).length > 0 ? this.constants.UnitLength.filter(u => u.id == Id)[0].name : 'in';
  }
  CalculatePartWeight() {
    let temp = [];
    this.form.value.rowItems.forEach(element => {
      temp.push(element)
      element.SubItemList.forEach(s => {
        temp.push(s)
      });
    });
    this.form.value.ShippingPackage.forEach((sp, index) => {
      sp.totalweight = 0;
      sp.totalweight = Number(sp.partWeight);
      sp.qtyInPackage = 0;
      temp.filter(x => x.package == sp.id).forEach(w => {
        sp.totalweight = sp.totalweight + w.weight;
        sp.qtyInPackage = sp.qtyInPackage + w.shipped;
        sp.variance = this.validateDecimalTwoPlace((Number(sp.pWeight) / Number(sp.totalweight) - 1) * 100)
      });
      this.form.get('ShippingPackage')['controls'][index].patchValue(sp)
    });
    this.TotalLBS();
    this.TotalVariance();
  }
  BindTracking(rowItemsIndex: number, subIndex: number): string {
    let tracking = [];
    let result = '';
    if (this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking != '' && this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking != null) {
      tracking = JSON.parse(this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].tracking);
      tracking.forEach(element => {
        if (element.tracking != '') {
          result = result.concat(element.tracking + ',');
        }
      });
    }
    return result = result.replace(/,\s*$/, "");
  }
  CheckEqualTracking(value: any): number {
    return this.EqualShippedSerialLotList.filter(x => x.id == value).length > 0 ? this.EqualShippedSerialLotList.filter(x => x.id == value)[0].isCountSame : 0;
  }
  BindTrackingForParent(rowItemsIndex: number): string {
    let ParentTracking = [];
    let ParentResult = '';
    if (this.form.value.rowItems[rowItemsIndex].tracking != '' && this.form.value.rowItems[rowItemsIndex].tracking != null) {
      ParentTracking = JSON.parse(this.form.value.rowItems[rowItemsIndex].tracking);
      ParentTracking.forEach(element => {
        if (element.tracking != '') {
          ParentResult = ParentResult.concat(element.tracking + ',');
        }
      });
    }
    return ParentResult = ParentResult.replace(/,\s*$/, "");
  }
  removeChildPart(rowItemsIndex: number, subIndex: number) {
    if (this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id == undefined || this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id == 0) {
      this.getRowItemSubList(rowItemsIndex).removeAt(subIndex);
    } else {
      this.loader = true;
      this.sharedService.DeleteShipmentMultiPackageById(this.form.value.rowItems[rowItemsIndex].SubItemList[subIndex].Id).subscribe((res: any) => {
        this.getRowItemSubList(rowItemsIndex).removeAt(subIndex);
        this.loader = false;
      }), (err) => {
        this.loader = false;
      };
    }
  }
  removeFlyOut(rowItemsIndex: number) {
    this.SerialNumber.removeAt(rowItemsIndex);
    for (var i = this.SerialNumber.length; i < this.FlayOut.shipped; i++) {
      this.SerialNumber.push(this.createLotItem());
    }
  }
  clearResult(): void {
    this.qrResultString = '';
    this.IsScan = false;
    this.ScanIndex = 0;
  }
  AvailableDevice(index: number) {
    this.qrResultString = '';
    this.IsScan = true;
    this.ScanIndex = index;
    this.showNavbar = false;
  }
  camerasNotFound(e: Event) {
    Swal.fire({
      text: 'Device not found.',
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false
    }).then((result) => {
      this.IsScan = false;
      this.showNavbar = true;
    });
  }
  onCodeResult(resultString: string) {
    this.SerialLotForm.value.SerialNumber[this.ScanIndex].tracking = resultString;
    this.SerialLotForm.patchValue({ SerialNumber: this.SerialLotForm.value.SerialNumber })
    this.qrResultString = resultString;
    this.IsScan = false;
    this.showNavbar = true;
  }
  onBarCodeValueChange(result: any) {
    this.IsBarCodeScan = true;
    this.barCodescanner.start();
    this.SerialLotForm.value.SerialNumber[this.ScanIndex].tracking = result.codeResult.code;
    this.SerialLotForm.patchValue({ SerialNumber: this.SerialLotForm.value.SerialNumber })
    this.barCodescanner.stop();
  }
  validateDecimal(event: any): any {
    var arr = event.value.split('.');
    event.value =
      event.value.indexOf('.') >= 0
        ? arr[0] + '.' + arr[1].substr(0, 1) : event.value;
    return event.value;
  }
  validateDecimalTwoPlace(event: any): any {
    if (event != null && event != undefined && event != '') {
      var arr = event.toString().split('.');
      event =
        event.toString().indexOf('.') >= 0
          ? arr[0] + '.' + arr[1].substr(0, 2) : event;
    }
    return Number(event);
  }
  checkTrackingDuplicacy(index, value: string) {
    this.PartsData(this._flyOutPIndex);
    if (value != '') {
      if (this.IsDuplicateCheckTrackingList.length > 0) {
        if (this.IsDuplicateCheckTrackingList.filter(i => i.tracking.toUpperCase() === value.toUpperCase()).length > 0) {
          this.SerialNumber.controls[index].get('tracking').setErrors({ 'duplicateName': true })
        } else {
          this.SerialNumber.controls[index].get('tracking').setErrors(null)
        }
      }
      if (this.SerialNumber.value.length > 0) {
        if (this.SerialNumber.value.filter(i => i.tracking.toUpperCase() == value.toUpperCase()).length > 1) {
          this.SerialNumber.controls[index].get('tracking').setErrors({ 'duplicateName': true })
        } else {
          this.SerialNumber.controls[index].get('tracking').setErrors(null)
        }
      }
    }
  }
  getTracking(index: number) {
    return this.SerialNumber
      ? (this.SerialNumber.at(index) as FormGroup).get("tracking")
      : null;
  }
  PartsData(index: number) {
    this.IsDuplicateCheckTrackingList = [];
    let temp = this.form.value.rowItems[index]
    if (temp.tracking != '' && temp.tracking != null) {
      this.IsDuplicateCheckTrackingList.push(JSON.parse(temp.tracking));
    }
    temp.SubItemList.forEach(c => {
      if (c.tracking != '') {
        this.IsDuplicateCheckTrackingList.push(JSON.parse(c.tracking));
      }
    });
    this.IsDuplicateCheckTrackingList = this.IsDuplicateCheckTrackingList.flat();
  }
  SelectPrinter() {
    this.loader = true;
    this.PrinterList = [];
    this.sharedService.GetPrinterList().subscribe((res: any) => {
      this.PrinterList = [];
      this.PrinterList = res;
      this.loader = false;
    }), (err) => {
      this.loader = false;
    };
    $("#SelectPrinter").show();
  }
  CloseSelectPrinter() {
    $("#SelectPrinter").hide();
  }
  CloseshiprateVM() {
    $("#shiprateVM").hide();
  }
  ShowshiprateVM() {
    if (this.form.value.actualShippingOption != 0) {
      this.RateAPI();
    }
  }
  changeShippingLabelsPrinter(event: any) {
    this.ShippingLabelsPrinter = event.value;
  }
  changePackingSlipPrinter(event: any) {
    this.PackingSlipPrinter = event.value;
  }
  SaveSelectPrinter() {
    this.defaultShippingLabelsPrinter = this.ShippingLabelsPrinter;
    this.defaultPackingSlipPrinter = this.PackingSlipPrinter;
    this.CloseSelectPrinter();
  }

  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  ExpandMore(index: number, row: any) {
    if (this.form.value.rowItems[index].SubItemList.length == 0) {
      this.addItem(index, row);
    }
    this.specialPartList[index].isShow = !this.specialPartList[index].isShow;
  }
  SetDefaultPrinter(PrinterName: any) {
    if (this.defaultPackingSlipPrinter != undefined) {
      this.sharedService.SetDefaultPrinter(PrinterName).subscribe();
    }
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value != undefined && value != null && value != '') {
      result = true;
    }
    return result;
  }
  PrintPackingSlip() {
    var ShippingDataById = this.ShippingDataById;
    let PackageInfo = [];
    PackageInfo = this.form.value.ShippingPackage;
    var actualShippingType = this.form.value.actualShippingType;
    var PObarcodeImg;
    var SObarcodeImg;
    var PurchaseOrderNumber;
    if (this.PObarcodeImg != undefined && this.PObarcodeImg != null && this.PObarcodeImg != '') {
      PObarcodeImg = this.PObarcodeImg;
      PurchaseOrderNumber = 'Purchase Order Number';
    } else {
      PObarcodeImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
      PurchaseOrderNumber = '';
    }
    if (this.SObarcodeImg != undefined && this.SObarcodeImg != null && this.SObarcodeImg != '') {
      SObarcodeImg = this.SObarcodeImg;
    } else {
      SObarcodeImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
    }
    this.CompanyDetail.cLogo = !this.ISNullOrEmpty(this.CompanyDetail.base64Logo) ? this.constants.dummyCompanyLogo : this.CompanyDetail.base64Logo;
    var pdFgeneratedate = this.datepipe.transform(new Date(), 'MMMM dd, yyyy, hh:mm:ss a');
    let docDefinition = {
      pageMargins: [15, 30, 15, 50],
      content: [
        {
          columns: [
            [
              {
                image: this.CompanyDetail.cLogo,
                bold: true,
                width: 180,
                height: 35,
                margin: [0, 0, 0, 0]
              },
              { text: '', bold: true, fontSize: 15, margin: [0, 10, 0, 0] },
              {

                table: {
                  heights: [80],
                  margin: [0, 0, 0, 0],
                  body: [
                    [
                      {
                        text: (this.CompanyDetail.companyName) + '\n' +
                          (this.CompanyDetail.addressLine1) + '\n' +
                          ((this.GetStateCode(this.CompanyDetail.stateId) + ',') + ' ' + this.CompanyDetail.zipCode) + '\n' +
                          ('Phone: ' + this.CompanyDetail.phoneNumber) + '\n' +
                          ('Sales Rep Name: ' + (this.loginFirstName == undefined ? '' : this.loginFirstName)) + '\n' +
                          ('Email: ' + (this.loginUserName == undefined ? '' : this.loginUserName))
                        , fontSize: 10, border: [false, false, false, false]
                      }
                    ]
                  ]
                }
              },
              {
                table: {
                  widths: [260],
                  heights: [10, 100],
                  body: [
                    [{ text: 'Bill To:', bold: true }],
                    [
                      {
                        text: (ShippingDataById.billAddress.name) + '\n' +
                          (ShippingDataById.billAddress.addressLine1 == null ? '' : ShippingDataById.billAddress.addressLine1 + '\n') +
                          (ShippingDataById.billAddress.addressLine2 == null || ShippingDataById.billAddress.addressLine2 == '' ? '' : (ShippingDataById.billAddress.addressLine2 + '\n')) +
                          (ShippingDataById.billAddress.city == null ? '' : (ShippingDataById.billAddress.city + ', ')) +
                          ((this.ISNullOrEmpty(ShippingDataById.billAddress.stateId) ? (this.GetStateCode(ShippingDataById.billAddress.stateId)) : '') + ', ') +
                          (ShippingDataById.billAddress.zip == null ? '' : (ShippingDataById.billAddress.zip) + '\n\n') +
                          ('Contact Name: ' + ShippingDataById.billAddress.cName + '\n') +
                          ('Email Address: ' + ShippingDataById.billAddress.email + '\n') +
                          ('Phone #: ' + ShippingDataById.billAddress.phone)
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              },
            ],
            [
              {
                text: 'Packing Slip',
                alignment: 'right',
                bold: true,
                fontSize: 19
              }, { text: ' ' },
              {
                alignment: 'right',
                table: {
                  widths: [83, 83, 83],
                  heights: [10, 10, 10],
                  margin: [90, 70, 70, 0],
                  bold: true,
                  body: [
                    [{ text: 'Purchase Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Sales Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Date', bold: true, alignment: 'center', fontSize: 10 }],
                    [
                      { text: ShippingDataById.salesOrder.purchaseOrder == null ? '' : ShippingDataById.salesOrder.purchaseOrder, alignment: 'center', bold: true, fontSize: 10 },
                      { text: ShippingDataById.salesOrder.orderNumber, alignment: 'center', bold: true, fontSize: 10 },
                      { text: this.datepipe.transform(new Date(), 'MM/dd/yyyy'), alignment: 'center', fontSize: 10 }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, { text: '    ' + PurchaseOrderNumber + '                     Sales Order Number', fontSize: 10, alignment: 'right', margin: [0, 2, 20, 0] },
              {
                image: SObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'right',
                margin: [0, 0, 0, 0],
              },
              {
                image: PObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'left',
                margin: [0, -40, 10, 0]
              },
              {
                margin: [0, 7, 0, 0],
                table: {
                  heights: [12, 100],
                  widths: [270],
                  alignment: 'right',
                  body: [
                    [{ text: 'Ship To:', bold: true }],
                    [
                      {
                        text: (ShippingDataById.shipAddress.name) + '\n' +
                          (ShippingDataById.shipAddress.attention == null || ShippingDataById.shipAddress.attention == '' ? '' : ('Attn:' + ShippingDataById.shipAddress.attention + '\n')) +
                          (ShippingDataById.shipAddress.addressLine1 == null ? '' : (ShippingDataById.shipAddress.addressLine1 + '\n')) +
                          (ShippingDataById.shipAddress.addressLine2 == null || ShippingDataById.shipAddress.addressLine2 == '' ? '' : (ShippingDataById.shipAddress.addressLine2 + '\n')) +
                          (ShippingDataById.shipAddress.city == null ? '' : (ShippingDataById.shipAddress.city + ', ')) +
                          (ShippingDataById.shipAddress.stateId == null ? '' : (ShippingDataById.shipAddress.stateId + ', ')) +
                          (ShippingDataById.shipAddress.zip == null ? '' : (ShippingDataById.shipAddress.zip) + '\n')
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }
            ]
          ]
        },
        {
          style: 'contactTable1',
          table: {
            widths: [181, 181, 173],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Ship Via', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Collect Account Info', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Payment Terms', fontSize: 10, bold: true, alignment: 'center' },
              ]
              ,
              [
                { text: this.form.value.actualShippingType, alignment: 'center', fontSize: 10 },
                { text: !this.ISNullOrEmpty(ShippingDataById.salesOrder.collectAccount) ? '' : ShippingDataById.salesOrder.collectAccount, alignment: 'center', fontSize: 10 },
                { text: ShippingDataById.salesOrder.paymentType, alignment: 'center', fontSize: 10 },
              ],
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#edeaea' : null;
            }
          }
        },
        {
          margin: [0, -1, 0, 0],
          table: {
            widths: [36, 100, 217, 52, 52, 51],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Item', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Part Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Ordered', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Shipped', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'B/O', fontSize: 10, bold: true, alignment: 'center' }
              ]
              ,
              ...this.specialPartList.map((p1, i) => ([
                { text: i + 1, fontSize: 10, alignment: 'center' },
                { text: p1.partNumber, fontSize: 10, alignment: 'left' },
                { text: p1.partName, fontSize: 10, alignment: 'left' },
                { text: p1.quantity, fontSize: 10, alignment: 'center' },
                { text: p1.shipQty == null ? 0 : p1.shipQty, fontSize: 10, alignment: 'center' },
                { text: p1.boQty, fontSize: 10, alignment: 'center' }])),
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#edeaea' : null;
            }
          }
        },
      ],
      footer: function (currentPage, pageCount) {
        return [
          {
            margin: [10, -170, 0, 0],
            table: {
              heights: [12, "*"],
              widths: [270],
              alignment: 'left',
              style: 'footersTableStyle',
              body: [
                [
                  { text: (actualShippingType != null ? actualShippingType : ' N/A') + ' tracking numbers for this shipment. \nTrack  your shipment at: www.ups.com/tracking/tracking.html', fontSize: 8 },
                ],
                ...PackageInfo.map((element, i) => ([
                  { text: 'Package     ' + (i + 1) + ' :  ' + (element.tracking != null ? element.tracking : 'N/A') + '  -      ' + element.pWeight + '  LBS', fontSize: 8 }])),
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 2 : 1;
              },
              vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
              },
              hLineColor: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
              },
              vLineColor: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
              },
            },
          },
          {
            margin: [300, -65, 0, 0],
            table: {
              heights: [12, 70],
              widths: [270],
              alignment: 'right',
              body: [
                [{ text: 'Notes', bold: true, alignment: 'center' }],
                [
                  {
                    text: ShippingDataById.salesOrder.customerNotes, fontSize: 10
                  }
                ]
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#edeaea' : null;
              }
            }
          },
          { text: pdFgeneratedate, fontSize: 8, bold: true, margin: [30, 90, 0, 0] },
          { text: 'Thank you for your Business! ', fontSize: 8, alignment: 'center', bold: true, margin: [20, -10, 0, 0] },
          { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, fontSize: 8, bold: true, margin: [520, -10, 0, 0] }
        ]
      },
      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        tableExample: {
          margin: [90, 0, 0, 0]
        },
        contactTable: {
          margin: [0, 5, 0, 0]
        },
        contactTable1: {
          margin: [0, 10, 0, 0]
        }
      }
    };
    this.loader = false;
    return docDefinition;
    //pdfMake.createPdf(docDefinition).print();
  }
  BarcodeGenerate(IsPOBarcode: boolean): any {
    this.SObarcodeImg = undefined;
    JsBarcode(this.barcode.nativeElement, this.ShippingDataById.salesOrder.orderNumber, {
      height: 90,
      width: 2.5,
      displayValue: false
    });
    var s = new XMLSerializer().serializeToString(document.getElementById("barcode"));
    this.ImgConvertor(s, IsPOBarcode);
  }
  ImgConvertor(s, IsPOBarcode: boolean): any {
    this.SVGToImage({
      svg: s,
      mimetype: "image/png",
      width: 500,
      quality: 1
    }).then((base64image: any) => {
      let img = document.createElement('img');
      img.src = base64image;
      if (IsPOBarcode) {
        this.PObarcodeImg = img.src;
      } else {
        this.SObarcodeImg = img.src;
      }
    });
  }

  SVGToImage(settings) {
    let _settings = {
      svg: null,
      // Usually all SVG have transparency, so PNG is the way to go by default
      mimetype: "image/png",
      quality: 0.92,
      width: "auto",
      height: "auto",
      outputFormat: "base64"
    };

    // Override default settings
    for (let key in settings) { _settings[key] = settings[key]; }

    return new Promise(function (resolve, reject) {
      let svgNode;

      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) == "string") {
        // Create a non-visible node to render the SVG string
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let svgXml = new XMLSerializer().serializeToString(svgNode);
      let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

      const image = new Image();

      image.onload = function () {
        // Define the canvas intrinsic size
        canvas.width = 800;
        canvas.height = 400;

        // Render image in the canvas
        context.drawImage(image, 0, 0, 800, 400);

        if (_settings.outputFormat == "blob") {
          // Fullfil and Return the Blob image
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, _settings.mimetype, _settings.quality);
        } else {
          // Fullfil and Return the Base64 image
          resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        }
      };
      // Load the SVG in Base64 to the image
      image.src = svgBase64;
    });
  }
  CancelShipment() {
    this.loader = true;
    this.sharedService.CancelShippingLabel(this.form.value.LabelId, this.form.value.orderId).subscribe((res: any) => {
      this.loader = false;
      res.cancelShippingLabelResponseVMSuccess == null ? this.ShipmentCancelErrMsg(res) : this.ShipmentCancelMsg();
    }), (err) => {
      this.loader = false;
    };
  }
  ShipmentCancelMsg() {
    this.form.value.ShippingPackage.forEach((element, index) => {
      element.label_downloadURL = null;
      element.tracking = null;
    });
    this.form.patchValue({ ShippingPackage: this.form.value.ShippingPackage, masterTrackingNo: undefined });
    this.loader = false;
    Swal.fire({
      title: 'Shipment Canceled',
      text: 'The shipment has been canceled and the tracking information will be removed from the order.',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'OK',
    })
  }
  ShipmentCancelErrMsg(res: any) {
    this.loader = false;
    Swal.fire({
      title: 'Denied',
      text: res.cancelShippingLabelResponseVMError.errors[0].message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'OK',
    })
  }
  PrintAll() {
    if (this.masterTrackingNo != null && this.masterTrackingNo != undefined && this.masterTrackingNo != '') {
      this.loader = true;
      this.sharedService.SetDefaultPrinter(this.defaultShippingLabelsPrinter).subscribe((res: any) => {
        let doc = []
        this.form.value.ShippingPackage.forEach((element) => {
          doc.push(element.label_downloadURL);
        });
        this.loader = true;
        let focuser = setInterval(() => window.dispatchEvent(new Event('focus')), 500);
        printJS({
          printable: doc,
          type: 'image',
          showModal: true,
          imageStyle: 'width:50%;margin-bottom:20px;',
          onPrintDialogClose: () => {

          }
        });
      }), (err) => {
      };
    } else {
      this.GetEqualShippedSerialLot(this.defaultShippingLabelsPrinter, true);
    }
  }
  RePrintLabel() {
    this.SetDefaultPrinter(this.defaultShippingLabelsPrinter);
    let doc = []
    this.form.value.ShippingPackage.forEach((element) => {
      doc.push(element.label_downloadURL);
    });
    this.Reprint(doc);
  }
  Cancel() {
    this.BindData();
  }
  masterToggle() {
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected() ?
        this.selection.clear() : this.salesOrderDetail.data.forEach(element => this.selection.select(element));
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.salesOrderDetail.data.length;
    return numSelected === numRows;
  }
  isSomeSelected() {
    return this.selection.selected.length > 0;
  }
  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  MarkCompleted() {
    if (this.selection.selected.length > 0) {
      this.loader = true;
      this.sharedService.MarkCompleted(this.selection.selected).subscribe((data: any) => {
        if (data != null || data != undefined) {
          this.GetShippingList('');
          this.selection.clear()
          this.loader = false;
        }
      })
    }
  }
  SaveRate() {
    if (this.RateDetails != undefined) {
      this.form.patchValue({ actualShippingAmount: this.RateDetails.amount, Actualrate_id: this.RateDetails.rate_id, actualShippingType: this.RateDetails.service_type, actualPackageType: this.RateDetails.package_type })
      this.RateDetails = undefined;
      this.CloseshiprateVM();
    }
  }
  getRateValue(rate) {
    this.RateDetails = rate;
  }
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End
  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }

  printZPL() {
    const obj = {
      ZplUrl: 'https://api.shipengine.com/v1/downloads/10/Av1_R2NK3EWitC2_uwfnsQ/labelpackage-539581254.zpl',
      CompanyId: Number(localStorage.getItem('GlobalSelectedCompanyId')),
    }
    this.sharedService.AddPrintZPL(obj).subscribe({
      next: (data: any) => {
        console.log('success', data);
      },
      error: (data: any) => { console.log('error', data); },
      complete: () => {
        console.log('completed');
      }
    });
  }
  printSlip() {
    let x = this.PrintPackingSlip();
    let file = new FormData();
    file = pdfMake.createPdf(x)

    const pdfDocGenerator = pdfMake.createPdf(x);
    pdfDocGenerator.getBase64((data) => {
      const file = {
        file: data
      }
      // this.base64 = 'data:application/pdf;base64,' + data;
      this.sharedService.PrintPdf(file)
        .subscribe({
          next: (data: any) => {
            this.SwalModal('Success', 'Print');
          },
          error: (err) => {
            this.SwalModal('Exception', err);
          },
        });
    });
  }


  getBase64ofPdf(docDefinition: any): any {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      this.base64 = 'data:application/pdf;base64,' + data;
    });
  }
}