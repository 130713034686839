import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import { FulfillmentService } from '../services/Fulfillment/fulfillment.service';
import JsBarcode from 'jsbarcode';
import pdfMake from "pdfmake/build/pdfmake";

@Component({
  selector: 'app-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.css']
})
export class FulfillmentComponent implements OnInit {
  start: number = 500;
  loader: boolean = false;
  FulfillmentDSColumn: string[] = ['menu', 'Order', 'OrderDate', 'OrderNumber', 'CustomerName', 'NewPOs', 'IssuedPOs', 'LatestArrivalDate', 'FulfillmentType', 'ShipAs', 'FulfillmentStatus'];
  FulfillmentDS: any;
  FulfillmentList: any;
  FulfillmentStatusCount: any;
  FulfillmentById: any;
  IsFulfillmentList: boolean = false;
  IsHistoryFlyOut: boolean = false;
  companyId: any;
  rolePermissionsList: any;
  permissionMessage = '';
  userId: any;
  StatusList: any = [];
  OrderHistoryList: any = [];
  FulfillmentForm: FormGroup;
  NetTermsList: any = [];
  StateList: any[] = [];
  CountryList: any[] = [];
  allSelected = 1;
  CompanyDetail: any;
  SObarcodeImg: any;
  PObarcodeImg: any;
  ShipAs: string = '0,1';
  fulfillStatus: string = '0,5,15,30';
  FulfillmentType: string = 'Manual,Auto';
  @ViewChild('barcode') barcode: ElementRef;
  constructor(
    public sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public companyService: CompanyService,
    public salesOrderService: SalesOrderService,
    public fulfillmentService: FulfillmentService,
    public customerService: CustomerService,
    private globalChangeService: GlobalChangeService,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
  ) {
  }
  ngOnInit(): void {
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
    this.Constant();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.Status();
        this.getAllState();
        this.GetCountry();
        this.GetFulfillmentList('', true, false);
        this.GetFulfillmentStatusCount();
        this.GetCompanyById(Number(this.companyId));
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.userId = localStorage.getItem(this.constants.userId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      location.reload();
    })
    this.createForm();
  }
  // All form Creation
  createForm() {
    this.CreateOrderPartForm();
  }
  Status() {
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.StatusList = data.dataList
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  selectedRow(Id: number) {
    this.loader = true;
    this.IsFulfillmentList = true;
    this.GetFulfillPartsByOrderId(Id);
  }
  AddHistoryFlyOut() {
    this.loader = true;
    this.OrderHistoryList = [];
    this.salesOrderService.GetSalesOrderLog(this.FulfillmentById.salesOrder.id)
      .subscribe({
        next: (data: any) => {
          this.OrderHistoryList = data;
          this.IsHistoryFlyOut = true;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  CloseHistoryFlyOut() {
    this.IsHistoryFlyOut = false;
  }
  GetFulfillmentStatusCount() {
    this.fulfillmentService.GetFulfillmentStatusCount(Number(this.companyId))
      .subscribe({
        next: (data: any) => {
          this.FulfillmentStatusCount = data.data;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  Filter() {
    this.GetFulfillmentList('', true, false)
  }
  GetFulfillmentList(searchTerm: string, IsLoader: boolean, isUpdate: boolean) {
    this.loader = IsLoader;
    let fullfillRequest = {
      companyId: Number(this.companyId),
      isActive: 1,
      offsetNo: 0,
      pageSize: this.start,
      searchTerm: searchTerm != '' ? searchTerm.trim() : '',
      ShipAs: this.ShipAs,
      FulfillmentType: this.FulfillmentType,
      FulfillStatus: this.fulfillStatus
    }
    this.FulfillmentList = [];
    this.fulfillmentService.GetFulfillmentList(fullfillRequest)
      .subscribe({
        next: (data: any) => {
          this.FulfillmentList = data.dataList;
          this.FulfillmentDS = new MatTableDataSource(this.FulfillmentList);
          this.loader = false;
          if (isUpdate) {
            this.updateIndex();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  // for lazyload first table
  SalesOrderListScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.GetFulfillmentList('', false, true);
    }
  }
  updateIndex() {
    this.start = this.FulfillmentList.length + 500;
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  GetFulfillPartsByOrderId(Id) {
    this.loader = true;
    this.fulfillmentService.GetFulfillPartsByOrderId(Id).subscribe(
      (data: any) => {
        this.FulfillmentById = data;
        this.PObarcodeImg = this.FulfillmentById.salesOrder.purchaseOrder != null && this.FulfillmentById.salesOrder.purchaseOrder != '' ? this.BarcodeGenerate('', '', '', true) : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="';
        this.BindFulfillPart();
      }
    ), (err) => {
      this.loader = false;
      this.SwalModal(this.constants.APIError, err);
    };
  }
  //Constant value Get
  Constant() {
    this.NetTermsList = this.constants.NetTermsListVal.sort((low, high) => high.id - low.id);
  }
  // Constant Value End
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End

  // Swal Msg Start
  CancelStatusModal() {
    Swal.fire({
      titleText: 'Confirm Canceled Status!',
      text: 'Click the Cancel button to update the status of this order to Canceled or Click the Close button to keep the current status.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'close',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  OrderShippedMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Shipped.</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  OrderInvoicedMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Invoiced or <br/> Exported to Accounting</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  OrderFulfilledMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is being <br/> (Compltely) fulfilled</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  PartiallyShippeddMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Partially <br/> Shipped</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }

  // Swal Msg End
  // Form defination start
  get FulfillPart() {
    return (<FormArray>this.FulfillmentForm.get('FulfillPart'));
  }
  AddCurrency(value): string {
    return value != '' ? this.currencyPipe.transform(value, '$', 'symbol', '1.0-2') : '';
  }
  CreateOrderPartForm() {
    this.FulfillmentForm = this.fb.group({
      'FulfillPart': new FormArray([]),
    });
  }
  // Form defination End

  DisableNetTerms(): Number {
    let val = 0;
    if (this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms).length) {
      this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms)[0].IsDisable = false;
      val = this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms)[0].id;
      this.NetTermsList.filter(x => x.name > this.FulfillmentById.netTerms).forEach(element => {
        element.IsDisable = true;
      });
    } else {
      val = 0;
    }
    return val;
  }
  BindFulfillPart() {
    this.FulfillPart.clear();
    this.FulfillmentById.fulfillPartsVM.forEach((element, index) => {
      this.FulfillPart.push(new FormGroup({
        id: new FormControl(element.id),
        vendorId: new FormControl(element.vendorId),
        opId: new FormControl(element.id),
        partId: new FormControl(element.partId),
        partNumber: new FormControl(element.partNumber),
        partName: new FormControl(element.partName),
        quantity: new FormControl(element.quantity),
        needed: new FormControl(element.needed),
        shipped: new FormControl(element.shipped),
        available: new FormControl(element.available),
        bo: new FormControl(element.bo),
        ead: new FormControl(element.ead),
        invQty: new FormControl({ value: element.invQty == 0 ? undefined : element.invQty, disabled: element.invQty == 0 ? true : false }),
        boQty: new FormControl({ value: element.boQty == 0 ? undefined : element.boQty, disabled: element.boQty == 0 ? true : false }),
        npoQty: new FormControl({ value: element.npoQty == 0 ? undefined : element.npoQty, disabled: (element.needed == 0 || element.statusId == 5) ? true : false }),
        shipAll: new FormControl({ value: element.invQty == 0 ? false : true, disabled: element.invQty == 0 ? true : false }),
        statusId: new FormControl(element.statusId),
        childFulfillParts: new FormArray([]),
      }))
      if (element.childFulfillPartsVM != null) {
        this.BindChildFulfillParts(element.childFulfillPartsVM, index);
      }
    });
    this.loader = false;
  }
  InvQty(Qty: number, type: string, i: number) {

    if (type == 'invQty') {
      this.FulfillPart.value[i].invQty = (Qty >= this.FulfillPart.value[i].needed ? this.FulfillPart.value[i].needed : Number(Qty));
      if (this.FulfillPart.value[i].available > this.FulfillPart.value[i].needed && this.FulfillPart.value[i].invQty != this.FulfillPart.value[i].needed) {
        this.FulfillPart.value[i].shipAll = false;
      } else if (this.FulfillPart.value[i].available > this.FulfillPart.value[i].needed && this.FulfillPart.value[i].invQty == this.FulfillPart.value[i].needed) {
        this.FulfillPart.value[i].shipAll = true;
      }
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('invQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('invQty').setErrors(null)
    } else if (type == 'boQty') {
      this.FulfillPart.value[i].boQty = (Qty >= this.FulfillPart.value[i].bo ? this.FulfillPart.value[i].bo : Number(Qty));
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('boQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('boQty').setErrors(null)
    } else if (type == 'npoQty') {
      this.FulfillPart.value[i].npoQty = (Qty >= this.FulfillPart.value[i].needed ? this.FulfillPart.value[i].needed : Number(Qty));
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('npoQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('npoQty').setErrors(null)
    }
  }
  ShipAll(IsShipAll: boolean, i: number, IsChecked: boolean) {

    if (!IsShipAll && this.FulfillPart.value[i].available > this.FulfillPart.value[2].needed) {
      this.FulfillPart.value[i].invQty = this.FulfillPart.value[i].needed;
      this.FulfillPart.value[i].shipAll = IsChecked;
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
    } else if (IsShipAll) {
      this.FulfillPart.value.forEach(q => {
        if (q.available > q.needed) {
          q.invQty = q.needed
          q.shipAll = true
        }
      });
      this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value });
    }
  }
  IsNum(value: any): number {
    return value === null || value === undefined ? 0 : value;
  }
  IsexceedsQty(i: number): boolean {
    return this.FulfillPart.value[i].needed >= (this.IsNum(this.FulfillPart.value[i].invQty) + this.IsNum(this.FulfillPart.value[i].boQty) + this.IsNum(this.FulfillPart.value[i].npoQty)) ? false : true
  }
  Cancel() {
    this.BindFulfillPart();
  }
  Save() {
    if (this.FulfillmentForm.valid) {
      this.fulfillmentService.UpdFulfillment(this.FulfillPart.value)
        .subscribe({
          next: (data: any) => {
            this.loader = false;
            this.selectedRow(this.FulfillmentById.salesOrder.id)
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, err);
          },
        });
    }
  }
  FulFillOrder() {
    if (this.FulfillmentForm.valid) {
      this.fulfillmentService.FulFillOrder(this.FulfillPart.value, this.FulfillmentById.salesOrder.id)
        .subscribe({
          next: (data: any) => {

            this.loader = false;
            this.SwalModal('Record', 'record successfully Fulfill');
            this.selectedRow(this.FulfillmentById.salesOrder.id)
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, err);
          },
        });
    }
  }
  childFulfillParts(index: number): FormArray {
    return this.FulfillPart.at(index).get('childFulfillParts') as FormArray
  }
  BindChildFulfillParts(childFulfillParts: any, i: number) {
    childFulfillParts.forEach((q) => {
      this.childFulfillParts(i).push(new FormGroup({
        id: new FormControl(q.id),
        opId: new FormControl(q.opId),
        partId: new FormControl(q.partId),
        orderTypeId: new FormControl(q.orderTypeId),
        shipped: new FormControl(q.shipped),
        shippedDate: new FormControl(q.shippedDate),
        tracking: new FormControl(q.tracking),
        shipAll: new FormControl({ value: q.shipAll, disabled: q.statusId == 5 ? true : false }),
        statusId: new FormControl(q.statusId),
        IsShow: new FormControl(false)
      }))
    });
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  //Get All Country
  GetCountry() {
    this.sharedService.GetCountry()
      .subscribe({
        next: (data: any) => {
          this.CountryList = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  BackToList() {
    this.FulfillPart.clear();
    this.FulfillmentById = undefined;
    this.IsFulfillmentList = false;
    this.GetFulfillmentList('', true, false);
  }
  ShowRow(i: number, value: boolean) {
    this.childFulfillParts(i).value.forEach(IsShow => {
      IsShow.IsShow = value;
    });
    this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value })
  }
  GetCountryCode(countryId: number) {
    return this.CountryList.length > 0 && countryId != null ? this.CountryList.filter(x => x.id == countryId)[0].countryCode : ''
  }
  GetStateCode(stateId: number) {
    return this.StateList.length > 0 && stateId != null ? this.StateList.filter(x => x.id == stateId)[0].abbreviation : ''
  }
  GetStatusName(Id: number) {
    return this.constants.FulfillmentStatus.length > 0 && Id != 0 && this.constants.FulfillmentStatus.filter(x => x.id == Id).length > 0 ? this.constants.FulfillmentStatus.filter(x => x.id == Id)[0].name : ''
  }
  IsCollapseAll(value: boolean) {
    this.FulfillPart.value.forEach(ql => {
      ql.childFulfillParts.forEach(IsShow => {
        IsShow.IsShow = value;
      });
    });
    this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value });
  }
  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value != undefined && value != null && value != '') {
      result = true;
    }
    return result;
  }
  // Pdf Generated for LOG Start
  CreatePdf(value: any, orderNumber: string) {
    let pdfValue = JSON.parse(value)
    let SObarcodeImg = this.SObarcodeImg
    let PObarcodeImg = this.PObarcodeImg
    var email = localStorage.getItem("useremail");
    var loggedName = localStorage.getItem("username");
    var pdFgeneratedate = this.datepipe.transform(new Date(), 'MMMM dd, yyyy, hh:mm:ss a');
    this.CompanyDetail.cLogo = (this.CompanyDetail.base64Logo == null || this.CompanyDetail.base64Logo == '' || this.CompanyDetail.base64Logo == undefined) ? this.constants.dummyCompanyLogo : this.CompanyDetail.base64Logo;
    let docDefinition = {
      pageMargins: [15, 30, 15, 50],
      content: [
        // Previous configuration  
        {
          columns: [
            [
              {
                image: this.CompanyDetail.cLogo,
                bold: true,
                width: 180,
                height: 35,
                margin: [0, 0, 0, 0]
              },
              { text: '', bold: true, fontSize: 15, margin: [0, 10, 0, 0] },
              {

                table: {
                  heights: [80],
                  margin: [0, 0, 0, 0],
                  body: [
                    [
                      {
                        text:
                          (this.CompanyDetail.addressLine1) + '\n' +
                          ((this.CompanyDetail.city == null ? '' : this.CompanyDetail.city + ',') + ' ' + (this.CompanyDetail.stateId == null ? '' : this.GetStateCode(this.CompanyDetail.stateId) + ',') + ' ' + this.CompanyDetail.zipCode) + '\n' +
                          ('Phone: ' + (this.ISNullOrEmpty(this.CompanyDetail.compContactNumber)?this.CompanyDetail.compContactNumber:'')) + '\n' +
                          ('Sales Rep Name: ' + loggedName) + '\n' +
                          ('Email: ' + this.CompanyDetail.contactEmail)
                        , fontSize: 10, border: [false, false, false, false]
                      }
                    ]
                  ]
                }
              },
              {
                table: {
                  widths: [260],
                  heights: [10, "*"],
                  style: 'vendorTableStyle',
                  body: [
                    [{ text: 'Bill To:', bold: true }],
                    [
                      {
                        text: (pdfValue.BillAddress.Name) + '\n' +
                          (pdfValue.BillAddress.AddressLine1 == null ? '' : pdfValue.BillAddress.AddressLine1 + '\n') +
                          (pdfValue.BillAddress.City == null ? '' : (pdfValue.BillAddress.City) + ', ') +
                          (pdfValue.BillAddress.StateId == null ? '' : (this.GetStateCode(pdfValue.BillAddress.StateId)) + ', ') +
                          (pdfValue.BillAddress.Zip == null ? '' : (pdfValue.BillAddress.Zip) + '\n') +
                          ('Email Address: ' + pdfValue.BillAddress.Email + '\n') +
                          ('Phone #: ' + this.ISNullOrEmpty(pdfValue.BillAddress.Phone)?pdfValue.BillAddress.Phone:'')
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, ,
              {
                text: 'Notes: ' + '  ' + (!this.ISNullOrEmpty(pdfValue.salesOrder.CustomerNotes)?'':pdfValue.salesOrder.CustomerNotes),
                bold: false,
                fontSize: 11,
                margin: [0, 20, 0, 10]
              }
            ],
            [
              {
                text: (pdfValue.salesOrder.StatusId == 11) ? 'Sales Quote' : 'Sales Order',
                alignment: 'right',
                bold: true,
                fontSize: 19
              }, { text: ' ' },
              {
                alignment: 'right',
                table: {
                  widths: [85, 85, 85],
                  heights: [10, 10, 10],
                  margin: [90, 70, 0, 0],
                  bold: true,
                  body: [

                    [{ text: 'Purchase Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Date', bold: true, alignment: 'center', fontSize: 10 }],
                    [
                      { text: this.FulfillmentById.salesOrder.purchaseOrder, alignment: 'center', bold: true, fontSize: 10 },
                      { text: (orderNumber.substring(2)), alignment: 'center', bold: true, fontSize: 10 },
                      { text: this.datepipe.transform(new Date(), 'MM/dd/yyyy'), alignment: 'center', fontSize: 10 }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, { text: '    ' + ((this.FulfillmentById.salesOrder.purchaseOrder != null) ? 'Purchase Order Number' : '') + '                     Sales Order Number', fontSize: 10, alignment: 'right', margin: [0, 2, 20, 0] },
              {
                image: SObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'right',
                margin: [0, 0, 0, 0],
              },
              {
                image: PObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'left',
                margin: [0, -40, 10, 0]
              },
              {
                margin: [0, 7, 0, 0],
                table: {
                  heights: [10, "*"],
                  widths: [270],
                  alignment: 'right',
                  body: [
                    [{ text: 'Ship To:', bold: true }],
                    [
                      {
                        text: (this.ISNullOrEmpty(pdfValue.ShipAddress.CustomerName)? '\n' + pdfValue.ShipAddress.CustomerName : '')  +
                        (this.ISNullOrEmpty(pdfValue.ShipAddress.CName)? + '\n' + pdfValue.ShipAddress.CName : '') +
                          ((pdfValue.ShipAddress.AddressLine1 + '\n')) +
                          ((pdfValue.ShipAddress.AddressLine2 != '' && pdfValue.ShipAddress.AddressLine2 != null) ? '\n' + pdfValue.ShipAddress.AddressLine2 : '') +
                          (pdfValue.ShipAddress.City + ', ') +
                          (this.GetStateCode(pdfValue.ShipAddress.StateId) + ', ') +
                          (pdfValue.ShipAddress.Zip + '\n') +
                          (this.GetCountryCode(pdfValue.ShipAddress.CountryId))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }
            ]
          ]
        },
        {
          table: {
            widths: [100, 110, 70, 80, 170],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            body: [
              [{ text: 'Buyer', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Payment Terms', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'FOB Point', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Shipping Term', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'Ship VIa', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 }],
              [
                { text: loggedName, alignment: 'center', fontSize: 10 },
                { text: (pdfValue.salesOrder.PaymentType), alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.CompanyDetail.fobPointName) ? this.CompanyDetail.fobPointName : '', alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.CompanyDetail.shippingTermName) ? this.CompanyDetail.shippingTermName : '', alignment: 'center', fontSize: 10 },
                { text: (pdfValue.salesOrder.ShippingType), alignment: 'center', fontSize: 10 },
              ]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#dadada' : null;
            }
          }
        }, { text: ' ' },
        {
          style: 'contactTable1',
          table: {
            widths: [40, 100, 208, 40, 40, 80],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Item', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Part Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Ordered', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Shipped', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Extension Total', fontSize: 10, bold: true, alignment: 'right' }
              ]
              ,
              ...pdfValue.salesOrder.OrderPart.map((p1, i) => ([
                { text: i + 1, fontSize: 10, alignment: 'center' },
                { text: p1.PartNumber, fontSize: 10, alignment: 'left' },
                { text: p1.PartName, fontSize: 10, alignment: 'left' },
                { text: p1.Quantity, fontSize: 10, alignment: 'center' },
                { text: p1.shipedQty == null ? 0 : p1.shipedQty, fontSize: 10, alignment: 'center' },
                { text: this.currencyPipe.transform(p1.SExtension), fontSize: 10, alignment: 'right' }])),

            ]
          },
        },
        {
          table: {
            widths: [330, 0, 0, 0, 0, 100, 67],
            margin: [0, 0, 0, 0],
            body: [
              [
                {}, {}, {}, {}, {},
                { text: 'Shipping', margin: [0, 0, 0, 0], alignment: 'left', fontSize: 10, bold: true },

                { text: this.currencyPipe.transform(pdfValue.salesOrder.ShippingAmount), alignment: 'right', bold: true, fontSize: 10 }

              ],
              [
                {}, {}, {}, {}, {},
                { text: 'Sales Tax ( ' + (pdfValue.salesOrder.PartTaxRate) + '% )', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(pdfValue.salesOrder.PartTaxRateAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Total', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(pdfValue.salesOrder.SubTotalSummaryExt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Payment Received', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(pdfValue.salesOrder.PaymentAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Balance Due', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(pdfValue.salesOrder.BalanceDue), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }]
            ]
          },
          layout: {
            fillColor: '#dadada',
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#dadada' : '#dadada';
            },
          }
        },
      ],
      footer: function (currentPage, pageCount) {
        if (currentPage === pageCount) {
          return [
            {
              margin: [20, 0, 0, 0],
              table: {
                widths: [480, 100],

                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '**** PLEASE CONFIRM AND ADVISE OF ETA BY EMAIL AT ' + email + '\n**** THANK YOU, ' + loggedName + '\n\n' + pdFgeneratedate, fontSize: 8, bold: true },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }

          ]
        }
        else {
          return [
            {
              table: {
                widths: [480, 100],
                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '' },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }
          ];
        }

      },

      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        tableExample: {
          margin: [90, 0, 0, 0]
        },
        contactTable: {
          margin: [0, 5, 0, 0]
        },
        contactTable1: {
          margin: [0, 10, 0, 0]
        }
      }
    };
    pdfMake.createPdf(docDefinition).open();
  }
  //Generate BarCode Start
  BarcodeGenerate(value: any, orderNumber: string, Status: string, IsPOBarcode: boolean) {
    this.SObarcodeImg = undefined;
    JsBarcode(this.barcode.nativeElement, IsPOBarcode ? this.FulfillmentById.salesOrder.purchaseOrder : orderNumber.substring(2), {
      height: 90,
      width: 2.5,
      displayValue: false
    });
    var s = new XMLSerializer().serializeToString(document.getElementById("barcode"));
    this.ImgConvertor(s, value, orderNumber, Status, IsPOBarcode);
  }
  ImgConvertor(s, value: any, orderNumber: string, Status: string, IsPOBarcode: boolean): any {
    this.SVGToImage({
      svg: s,
      mimetype: "image/png",
      width: 500,
      quality: 1
    }).then((base64image: any) => {
      let img = document.createElement('img');
      img.src = base64image;
      if (IsPOBarcode) {
        this.PObarcodeImg = img.src;
      } else {
        this.SObarcodeImg = img.src;
        this.CreatePdf(value, orderNumber)
      }
    });
  }
  SVGToImage(settings) {
    let _settings = {
      svg: null,
      mimetype: "image/png",
      quality: 0.92,
      width: "auto",
      height: "auto",
      outputFormat: "base64"
    };
    for (let key in settings) { _settings[key] = settings[key]; }
    return new Promise(function (resolve, reject) {
      let svgNode;

      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) == "string") {
        // Create a non-visible node to render the SVG string
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let svgXml = new XMLSerializer().serializeToString(svgNode);
      let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

      const image = new Image();

      image.onload = function () {
        // Define the canvas intrinsic size
        canvas.width = 800;
        canvas.height = 400;

        // Render image in the canvas
        context.drawImage(image, 0, 0, 800, 400);

        if (_settings.outputFormat == "blob") {
          // Fullfil and Return the Blob image
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, _settings.mimetype, _settings.quality);
        } else {
          // Fullfil and Return the Base64 image
          resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        }
      };
      // Load the SVG in Base64 to the image
      image.src = svgBase64;
    });
  }
  //Generate BarCode End
}