import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DiscountVM } from 'src/app/CommonInterface';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { SharedService } from 'src/app/services/shared.service';
import pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import JsBarcode from 'jsbarcode';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateComponent } from '../email-template/email-template.component';
import { distinctUntilChanged } from 'rxjs';
import { BlobOptions } from 'buffer';
let card: any;

@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css'],
})
export class SalesOrderComponent implements OnInit {
  DiscountIndex: number = -1;
  start: number = 500;
  loader: boolean = false;
  SalesOrderListDSColumn: string[] = ['menu', 'orderDate', 'customerponumber', 'customername', 'companyName', 'contactName', 'status', 'source', 'shipAs'];
  customerList: any = [];
  SalesOrderListDS: any;
  SalesOrderList: any;
  SalesOrderById: any;
  IsSalesOrderList: boolean = false;
  shipToVal: number = 0;
  IsAddPartFlyOut: boolean = false;
  IsHistoryFlyOut: boolean = false;
  IsPaymentLogFlyOut: boolean = false;
  companyId: any;
  rolePermissionsList: any;
  permissionMessage = '';
  userId: any;
  StatusList: any = [];
  selectedSearchText: string = 'Customer or Sales Order Number'
  selectedFilter: any = [1, 8, 10, 11];
  selectedSearch: any = ['Company', 'Order Number'];
  BillToDetails: any;
  CompanyWareHouseList: any = [];
  CustomerWithChildList: any = [];
  OrderHistoryList: any = [];
  OrderPartForm: FormGroup;
  BillToForm: FormGroup;
  CustomerForm: FormGroup;
  PaymentForm: FormGroup;
  CCardPaymentForm: FormGroup;
  ShipToForm: FormGroup;
  NetTermsList: any = [];
  PaymentLogList: any = [];
  ShippingTypeList: any = [];
  targetVal: string = 'Summary';
  submitted: boolean = false;
  StateList: any[] = [];
  StateListByCountryId: any[] = [];
  CountryList: any[] = [];
  partList = [];
  _PShipping: boolean = false;
  _PSales: boolean = false;
  _PPayment: boolean = false;
  shiprateListnew: any = [];
  isPay: boolean = false;
  IsAddressValidate: boolean = true;
  ApprovedamountMoney: any = 0;
  @ViewChild('select') select: MatSelect;
  allSelected = false;
  AddressValidatedAddress: any;
  CompanyDetail: any;
  SObarcodeImg: any;
  PObarcodeImg: any;
  _IsShippingUpdate: boolean = false;
  @ViewChild('barcode') barcode: ElementRef;
  base64: string;
  constructor(
    public sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public companyService: CompanyService,
    public salesOrderService: SalesOrderService,
    public customerService: CustomerService,
    private globalChangeService: GlobalChangeService,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    public dialog: MatDialog,
  ) {
  }
  async ngOnInit(): Promise<void> {
    this.IsSalesOrderList = localStorage.getItem('IsSalesOrderList') == 'true' ? true : false;
    localStorage.removeItem('IsSalesOrderList');
    this.companyId = localStorage.getItem('GlobalSelectedCompanyId');
    this.Constant();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.Status();
        this.getAllState();
        this.GetCountry();
        this.GetWareHouseWithChild();
        this.GetOrderList('', true, false);
        this.GetCompanyById(Number(this.companyId));
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      location.reload();
    })
    this.createForm();
    //Card Details
    const payments = window.Square.payments(this.constants.sqAppId, this.constants.sqLocationId);
    card = await this.initializeCard(payments);
    card.attach('#card-container');
    //Card Details End
  }
  async initializeCard(p1: any) {
    return p1.card();
  }
  // All form Creation
  createForm() {
    this.CreateBillToForm();
    this.CreateCustomerForm();
    this.CreateShipToForm();
    this.CreateOrderPartForm();
    this.CreateOrderPaymentForm();
    this.CreditCardPaymentForm();
  }
  ChangePartsStatusList(value: any) {
    this.targetVal = value.target.id;
    this.SubTotal();
  }
  ChangeStatu(value: any) {
    this.GetOrderList('', false, false);
  }
  ChangeOrderInfoStatus(status: any) {
    if (status == 8) {
      this.OrderPartForm.patchValue({ holdReason: '', holdUntilDate: new Date(), defQuoteDays: undefined })
    } else if (status == 11) {
      let days = 0;
      if (this.CompanyDetail != undefined && this.CompanyDetail.defQuoteDays != null) {
        days = this.CompanyDetail.defQuoteDays;
      }
      this.OrderPartForm.patchValue({ holdReason: undefined, holdUntilDate: undefined, defQuoteDays: new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000) })
    } else {
      this.OrderPartForm.patchValue({ holdReason: undefined, holdUntilDate: undefined, defQuoteDays: undefined })
    }
  }
  SearchCustomer(value: any) {
    this.loader = false
    this.customerService.GetAllCustomer(this.companyId, value.target.value)
      .subscribe({
        next: (data: any) => {
          this.customerList = data.dataList;
        },
        error: (err) => {
          this.customerList = [];
          this.loader = false
        },
      });
  }
  ChangeSearch() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  ChangeCustomer(value: any) {
    const BillToDetails = this.customerList.length > 0 ? this.customerList.filter(y => y.customerName.trim().toUpperCase() == value.option.value.toUpperCase())[0] : undefined;
    if (BillToDetails != undefined) {
      this.BillToForm.patchValue({
        id: 0,
        name: BillToDetails.billName,
        nttention: BillToDetails.billAtten,
        addressLine1: BillToDetails.billAddressLine1,
        addressLine2: BillToDetails.billAddressLine2,
        city: BillToDetails.billCity,
        stateId: BillToDetails.billState,
        countryId: BillToDetails.billCountry,
        zip: BillToDetails.billZipCode,
        phone: BillToDetails.cPhone,
        cName: BillToDetails.cName,
        customerName: BillToDetails.customerName,
        email: BillToDetails.cEmail,
        parentCustomerId: Number(BillToDetails.id),
        customerId: Number(BillToDetails.parentCustomerId),
        companyId: BillToDetails.companyId,
      })
      if (this.SalesOrderById == undefined) {
        this.OrderPartForm.patchValue({
          netTerms: BillToDetails.netTerms == null || BillToDetails.netTerms == '0' ? this.constants.NetTermsList[0].id : this.constants.NetTermsList.filter(x => x.name == BillToDetails.netTerms)[0].id,
          availableCredit: this.AddCurrency(BillToDetails.creditCardLimit),
          taxable: BillToDetails.taxable == null ? 'Taxable' : BillToDetails.taxable,
          collectAccount: BillToDetails.collectAmount,
          collectAmount: BillToDetails.isCollectAmount ? (BillToDetails.collectAmount == 'Fedex' ? BillToDetails.fedexCollect : BillToDetails.upsCollect) : '',
          isCollectAmount: BillToDetails.isCollectAmount,
          businessChannel: BillToDetails.businessChannels,
          customerRole: BillToDetails.customerRoles,
          customerType: BillToDetails.customerType != null ? Number(BillToDetails.customerType) : 0,
          taxType: BillToDetails.taxable == null ? 1 : 3,
          customerId: Number(BillToDetails.id),
          paymentType: 'NetTerms',
        })
        this.ChangeCollectAccount(this.OrderPartForm.value.collectAccount)
      }
      this.GetCustomerWithChild(Number(BillToDetails.id), true);
    }
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  Status() {
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.StatusList = data.dataList
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  GetCustomerWithChild(CustId: number, IsReset: boolean) {
    this.salesOrderService.GetCustomerWithChild(CustId)
      .subscribe({
        next: (data: any) => {
          this.CustomerWithChildList = data.data;
          if (IsReset) {
            this.ReSetShipToAddress();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  GetWareHouseWithChild() {
    this.companyService.GetWareHouseWithChild(this.companyId)
      .subscribe({
        next: (data: any) => {
          this.CompanyWareHouseList = data.data;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  ChangeShipTo(value: any) {
    this.shipToVal = value;
    this.ReSetShipToAddress();
    if (this.shipToVal == 1) {
      this.ShipToForm.patchValue({
        id: 0,
        parentCustomerId: this.SalesOrderById != undefined ? this.SalesOrderById.salesOrder.customerId : 0,
        countryId: 231,
        companyId: Number(this.companyId),
        shipVal: 1,
      })
      this.GetStateByCountryId(231)
    }
    this.IsAddressValidate = false;
  }
  ShipToAtten(Atten: string) {
    this.ShipToForm.patchValue({ attention: Atten })
  }
  ReSetShipToAddress() {
    this.ShipToForm.reset();
    this.ShipToForm.patchValue({
      id: 0,
      companyId: Number(this.companyId),
      addressType: 'Ship',
      shipVal: 3,
      parentCustomerId: this.SalesOrderById != undefined ? this.SalesOrderById.salesOrder.customerId : 0,
    })
  }
  ChangeWareHouse(row) {
    this.ShipToForm.patchValue({
      name: row.name,
      addressLine1: row.address1,
      addressLine2: row.address2,
      city: row.city,
      stateId: Number(row.stateId),
      countryId: row.countryId,
      zip: row.zipCode,
      companyId: row.companyId,
      shipVal: 2,
      customerName: row.name,
    })
    if (this.SalesOrderById != undefined) {
      this._IsShippingUpdate = true;
    }
  }
  ChangeCustomerDetails(row) {
    this.ShipToForm.patchValue({
      name: row.shipName,
      attention: row.shipAtten,
      addressLine1: row.shipAddressLine1,
      addressLine2: row.shipAddressLine2,
      city: row.shipCity,
      stateId: Number(row.shipState),
      countryId: Number(row.shipCountry),
      companyId: Number(row.companyId),
      zip: row.shipZipCode,
      email: row.shipEmail,
      phone: row.shipPhone,
      parentCustomerId: row.parentCustomerId,
      customerId: row.id,
      shipVal: 3,
      customerName: row.customerName,
      cName: row.cname,
    })
    if (this.SalesOrderById != undefined) {
      this._IsShippingUpdate = true;
    }
  }
  selectedRow(Id: number, CustId: number) {
    this.loader = true;
    this.IsSalesOrderList = true;
    this.GetCustomerWithChild(CustId, false);
    this.GetSalesOrderById(Id);
  }
  AddPartsFlyOut() {
    this.IsAddPartFlyOut = true;
  }
  PartFlyOutClose(value) {
    this.IsAddPartFlyOut = value.IsAddPartFlyOut;
    let partCount = this.partList.length;
    this.partList = this.partList.concat(value.PartsList);
    this._IsShippingUpdate = partCount != this.partList.length ? true : false;;
    this.BindOrderPart();
  }
  AddHistoryFlyOut() {
    this.loader = true;
    this.OrderHistoryList = [];
    this.salesOrderService.GetSalesOrderLog(this.SalesOrderById.salesOrder.id)
      .subscribe({
        next: (data: any) => {
          this.OrderHistoryList = data;
          this.IsHistoryFlyOut = true;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  CloseHistoryFlyOut() {
    this.IsHistoryFlyOut = false;
  }
  ClosePaymentLogFlyOut() {
    this.IsPaymentLogFlyOut = false;
  }
  GetOrderList(searchTerm: string, IsLoader: boolean, isUpdate: boolean) {
    this.loader = IsLoader;
    let orderRequest = {
      companyId: Number(this.companyId),
      isActive: 1,
      statusIds: this.selectedFilter,
      offsetNo: 0,
      pageSize: this.start,
      searchTerm: searchTerm != '' ? searchTerm.trim() : '',
      Columns: this.select != undefined ? [] : []
    }
    this.SalesOrderList = [];
    this.salesOrderService.GetSalesOrderList(orderRequest)
      .subscribe({
        next: (data: any) => {
          this.SalesOrderList = data.dataList;
          this.SalesOrderListDS = new MatTableDataSource(this.SalesOrderList);
          this.loader = false;
          if (isUpdate) {
            this.updateIndex();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  // for lazyload first table
  SalesOrderListScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.GetOrderList('', false, true);
    }
  }
  updateIndex() {
    this.start = this.SalesOrderList.length + 500;
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  GetCustomerById(custId) {
    this.loader = true;
    this.SalesOrderList = [];
    this.customerService.GetAllCustomerByCustomerId(custId)
      .subscribe({
        next: (data: any) => {
          this.BillToDetails = data.data;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  GetSalesOrderById(Id) {
    this.loader = true;
    this.salesOrderService.GetSalesOrderById(Id)
      .subscribe({
        next: (data: any) => {
          this._IsShippingUpdate = false;
          this.SalesOrderById = data;
          this.BindGetDate();
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  BindGetDate() {
    if (this.SalesOrderById != undefined) {
      this.OrderPartForm.patchValue(this.SalesOrderById.salesOrder)
      this.PObarcodeImg = (this.OrderPartForm.value.purchaseOrder != null && this.OrderPartForm.value.purchaseOrder != '') ? this.BarcodeGenerate('', '', true, false, '', true, 0) : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="';
      this.BindOrderById();
      this.ShipToForm.patchValue(this.SalesOrderById.shipAddress)
      this.BillToForm.patchValue(this.SalesOrderById.billAddress)
      this.shipToVal = this.ShipToForm.value.shipVal;
      this.PaymentLogList = this.SalesOrderById.orderPaymentLog.length > 0 ? this.SalesOrderById.orderPaymentLog.sort((low, high) => low.id - high.id) : [];
      this.partList = this.SalesOrderById.salesOrder.orderPart
      this.BindOrderPart();
    }
  }
  AddCustomer(type: string) {
    this.submitted = true;
    this.CustomerForm.patchValue({ id: 0, parentCustomerId: type == 'Ship' ? this.SalesOrderById.salesOrder.customerId : 0 })
    if (!this.CustomerForm.invalid) {
      this.loader = true;
      this.salesOrderService.AddBillToCustomer(this.CustomerForm.value, type)
        .subscribe({
          next: (data: any) => {
            if (type == 'Ship') {
              this.GetCustomerWithChild(this.SalesOrderById.salesOrder.customerId, true);
              this.CloseAddShipToAddres();
            } else {
              this.CloseBillToAddres();
            }
            this.submitted = false;
            this.loader = false;
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, err);
          },
        });
    }
  }
  PaymentLog() {
    this.loader = true;
    this.PaymentLogList = [];
    this.salesOrderService.PaymentLog(this.OrderPartForm.value.id)
      .subscribe({
        next: (data: any) => {
          this.PaymentLogList = data.length > 0 ? data.sort((low, high) => low.id - high.id) : [];
          this.loader = false;
          this.IsPaymentLogFlyOut = true;
        },
        error: (err) => {
          this.IsPaymentLogFlyOut = false;
          this.loader = false;
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  //Constant value Get
  Constant() {
    this.NetTermsList = this.constants.NetTermsListVal.sort((low, high) => high.id - low.id);
  }
  // Constant Value End
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End
  DefaultValForCustomerForm() {
    this.GetStateByCountryId(231);
    this.CustomerForm.patchValue({ id: 0, companyId: Number(this.companyId), countryId: 231, stateId: 3923 })
  }
  // Modal Show/Hide Start
  AddShippingServicesModal() {
    $("#updateshipping").show();
  }
  CloseShippingServicesModal() {
    $("#updateshipping").hide();
  }
  AddBillToAddres() {
    this.CustomerForm.reset();
    this.DefaultValForCustomerForm();
    $("#BillToAddressModal").show();
  }
  CloseBillToAddres() {
    $("#BillToAddressModal").hide();
  }
  AddShipToAddres() {
    this.CustomerForm.reset();
    this.DefaultValForCustomerForm();
    $("#ShipToAddressModal").show();
  }
  CloseAddShipToAddres() {
    $("#ShipToAddressModal").hide();
  }
  PaymentInfoModal() {
    this.CCardPaymentForm.patchValue({
      CCAmount: this.AddCurrency(this.GetBalanceDue()), name: this.BillToForm.value.name,
      addressLine1: this.BillToForm.value.addressLine1, zip: this.BillToForm.value.zip
    })
    $("#PaymentInfoModal").show();
  }
  ClosePaymentInfoModal() {
    $("#PaymentInfoModal").hide();
  }
  PaymentModal() {
    if (this.OrderPartForm.value.paymentStatus == '') {
      this.PaymentInfoModal();
    } else {
      this.PaymentForm.patchValue({ PaymentAmount: this.AddCurrency(this.GetBalanceDue()) })
      $("#PaymentModal").show();
    }
  }
  ClosePaymentModal() {
    this.PaymentForm.get('PaymentAmount').clearValidators();
    this.PaymentForm.get('PaymentAmount').updateValueAndValidity();
    this.PaymentForm.patchValue({ PaymentAmount: '' })
    $("#PaymentModal").hide();
  }
  SalesDiscountModal(i: number) {
    this.DiscountIndex = i;
    $("#SalesDiscountModal").show();
  }
  CloseSalesDiscountModal() {
    this.DiscountIndex = -1;
    $("#SalesDiscountModal").hide();
  }
  closevalidaddress() {
    $("#validAddress").hide();
  }
  // Modal Show/Hide End
  // Swal Msg Start
  EmailSuccess() {
    Swal.fire({
      titleText: 'Success!',
      text: 'Your email has been sent.',
      confirmButtonText: 'Ok',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  CancelStatusModal() {
    Swal.fire({
      titleText: 'Confirm Canceled Status!',
      text: 'Click the Cancel button to update the status of this order to Canceled or Click the Close button to keep the current status.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'close',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  MissingAnswerMsg() {
    Swal.fire({
      titleText: 'Missing Answer',
      text: 'Please answer all required Sales Questions before updating the Sales Order Status from Quote.',
      confirmButtonText: 'Close',
      showCancelButton: false,
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          this.OrderPart.value.forEach(ql => {
            ql.Question.filter(x => x.IsRequired).forEach(IsShow => {
              IsShow.IsShow = true
            });
          });
          this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value });
        }
      }
    });
  }
  // Swal Msg End
  // Form defination start
  get CustomerFormError() {
    return this.CustomerForm.controls;
  }
  get ShipToFormError() {
    return this.ShipToForm.controls;
  }
  get OrderPart() {
    return (<FormArray>this.OrderPartForm.get('OrderPart'));
  }
  RemoveCurrency(value): number {
    return typeof (value) == 'string' ? value.replaceAll('$', '').replaceAll(',', '') : value;
  }
  AddCurrency(value): string {
    return value != '' ? this.currencyPipe.transform(value, '$', 'symbol', '1.0-2') : '';
  }
  AcceptNumberOnly(event, type: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      if (type == 'CCAmount') {
        this.CCardPaymentForm.patchValue({ CCAmount: event.target.value.indexOf("$") == -1 ? this.AddCurrency(event.target.value) : event.target.value })
        if (this.RemoveCurrency(this.CCardPaymentForm.value.CCAmount) <= this.GetBalanceDue()) {
          this.CCardPaymentForm.get('CCAmount').setErrors(null);
        } else {
          this.CCardPaymentForm.get('CCAmount').setErrors({ 'Exceed': true });
        }
      } else if (type == 'PaymentAmount') {
        this.PaymentForm.patchValue({ PaymentAmount: event.target.value.indexOf("$") == -1 ? this.AddCurrency(event.target.value) : event.target.value })
        if (this.RemoveCurrency(this.PaymentForm.value.PaymentAmount) <= this.GetBalanceDue()) {
          this.PaymentForm.get('PaymentAmount').setErrors(null);
        } else {
          this.PaymentForm.get('PaymentAmount').setErrors({ 'Exceed': true });
        }
      }
      return true;
    }
  }
  GetBalanceDue(): number {
    let amt = 0;
    amt = ((this.OrderPartForm.value.subTotalSummaryExt +
      (this.OrderPartForm.value.partTaxRateAmt + this.OrderPartForm.value.laborTaxRateAmt + this.OrderPartForm.value.shippingTaxRateAmt)
      + this.OrderPartForm.value.shippingAmount) - this.OrderPartForm.value.paymentAmt)
    return amt;
  }
  IsPayNow(): boolean {
    let disbl = false;
    if (this.OrderPartForm.value.paymentType == 'NetTerms') {
      disbl = true;
    } else if (this.GetBalanceDue() == 0) {
      disbl = true;
    }
    return disbl;
  }
  CreateOrderPaymentForm() {
    this.PaymentForm = this.fb.group({
      'id': new FormControl(0),
      'CompanyId': new FormControl(Number(this.companyId)),
      'PaymentAmount': new FormControl(''),
    });
  }
  CreditCardPaymentForm() {
    this.CCardPaymentForm = this.fb.group({
      'id': new FormControl(0),
      'CompanyId': new FormControl(Number(this.companyId)),
      'CCAmount': new FormControl(''),
      'BillName': new FormControl(''),
      'BillAddressLine1': new FormControl('', Validators.required),
      'BillZipCode': new FormControl('', [Validators.minLength(9)]),
    },);
  }
  CreateCustomerForm() {
    this.CustomerForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'parentCustomerId': new FormControl(0),
      'attention': new FormControl(''),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl('', Validators.required),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.minLength(9), Validators.required]),
      'city': new FormControl('', Validators.required),
      'stateId': new FormControl(3923, Validators.required),
      'phone': new FormControl('', [Validators.minLength(11)]),
      'countryId': new FormControl(231, Validators.required),
    });
  }
  CreateBillToForm() {
    this.BillToForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'customerId': new FormControl(0),
      'parentCustomerId': new FormControl(0),
      'attention': new FormControl(''),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl(''),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.minLength(9)]),
      'city': new FormControl(),
      'stateId': new FormControl(3923, Validators.required),
      'phone': new FormControl('', [Validators.minLength(11)]),
      'countryId': new FormControl(231, Validators.required),
      'shipVal': new FormControl(0),
      'addressType': new FormControl('Bill'),
      'cName': new FormControl(''),
      'customerName': new FormControl(''),
    });
  }
  CreateShipToForm() {
    this.ShipToForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'parentCustomerId': new FormControl(this.SalesOrderById != undefined ? this.SalesOrderById.salesOrder.customerId : 0),
      'name': new FormControl('', Validators.compose([Validators.minLength(1)])),
      'attention': new FormControl(''),
      'addressLine1': new FormControl('', Validators.required),
      'addressLine2': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'zip': new FormControl('', [Validators.required]),
      'countryId': new FormControl(231, Validators.required),
      'city': new FormControl('', Validators.required),
      'stateId': new FormControl(3923, Validators.required),
      'shipVal': new FormControl(3),
      'phone': new FormControl('', [Validators.minLength(11)]),
      'addressType': new FormControl('Ship'),
      'cName': new FormControl(''),
      'customerName': new FormControl(''),
    });
  }
  CreateOrderPartForm() {
    this.OrderPartForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(Number(this.companyId)),
      'customerId': new FormControl(0),

      //OrderInformation section start
      'statusId': new FormControl(10),
      'fulfilmentType': new FormControl('Auto'),
      'orderNumber': new FormControl(''),
      'holdReason': new FormControl(''),
      'holdUntilDate': new FormControl(),
      'defQuoteDays': new FormControl(),
      //OrderInformation Section End

      //Middle Section Start
      'businessChannel': new FormControl(''),
      'customerRole': new FormControl(''),
      'netTerms': new FormControl(0),
      'availableCredit': new FormControl({ value: '', disabled: true }),
      'taxable': new FormControl(''),
      'purchaseOrder': new FormControl(''),
      'customerPONumber': new FormControl(''),
      'isPartialShipment': new FormControl(false),
      'collectAccount': new FormControl(''),
      'collectAmount': new FormControl(''),
      'isCollectAmount': new FormControl(false),
      'customerNotes': new FormControl(''),
      'wareHouseNotes': new FormControl(''),
      'eOrderNo': new FormControl(''),
      //Middle Section End

      // OrderPart Start
      'OrderPart': new FormArray([]),
      // Order Part End
      //Shipping Rate Start
      "shippingOption": new FormControl(0),
      "shippingAmount": new FormControl(0.0),
      "partTaxRateAmt": new FormControl(0.0),
      "partTaxRate": new FormControl({ value: 0, disabled: true }),
      "laborTaxRate": new FormControl({ value: 0, disabled: true }),
      "shippingTaxRate": new FormControl({ value: 0, disabled: true }),
      "laborTaxRateAmt": new FormControl(0.0),
      "shippingTaxRateAmt": new FormControl(0.0),
      "shippingType": new FormControl('Select One'),
      "rate_id": new FormControl(''),
      "shippingServices": new FormControl(''),
      //Shipping Rate End
      //Sales Tax Start
      "taxType": new FormControl(0),
      "isTaxPart": new FormControl(false),
      "isTaxLabor": new FormControl(false),
      "isTaxShipping": new FormControl(false),
      //Sales Tax End
      //Payment Start
      "paymentStatus": new FormControl(''),
      "paymentType": new FormControl(''),
      "paymentAmt": new FormControl(0.0),
      "BalanceDue": new FormControl(0.0),
      //Payment End
      'createdBy': new FormControl(localStorage.getItem('userId')),
      'orderDate': new FormControl(new Date()),
      'customerType': new FormControl(),
      //SubTotal Start
      'subTotalSummaryExt': new FormControl(0.0),
      'subTotalDetExt': new FormControl(0.0),
      'subTotalPartsMargin': new FormControl(0.0),
      'subTotalLaborMargin': new FormControl(0.0),
      'subTotalPartsExt': new FormControl(0.0),
      //SubTotal End
      //Shipping Start
      'quotedShippingWeight': new FormControl(0.0),
      'quotedShippingPackage': new FormControl(0),
      //Shipping End
    });
  }
  // Form defination End
  //Bind Order Start
  BindOrderById() {
    this.shiprateListnew = [];
    if (this.SalesOrderById != undefined) {
      this.OrderPartForm.patchValue({
        createdBy: localStorage.getItem('userId'),
        collectAccount: !this.OrderPartForm.value.isCollectAmount ? '' : this.OrderPartForm.value.collectAccount,
        availableCredit: this.AddCurrency(this.SalesOrderById.salesOrder.availableCredit),
        paymentAmt: this.SalesOrderById.salesOrder.paymentStatus != 'COMPLETED' ? 0.0 : this.SalesOrderById.salesOrder.paymentAmt,
        paymentType: (this.SalesOrderById.salesOrder.paymentType != '' && this.SalesOrderById.salesOrder.paymentType != null) ? this.SalesOrderById.salesOrder.paymentType : 'NetTerms',
      })
      //this.ChangeCollectAccount(this.OrderPartForm.value.collectAccount)
      //this.ChangeOrderInfoStatus(this.OrderPartForm.value.statusId);
      this.IsTaxRateChecked('Parts');
      this.IsTaxRateChecked('Labor');
      this.IsTaxRateChecked('Shipping');
      this.DisableNetTerms();
      this.shiprateListnew = (this.OrderPartForm.value.shippingServices != null && this.OrderPartForm.value.shippingServices != undefined && this.OrderPartForm.value.shippingServices != '') ? JSON.parse(this.OrderPartForm.value.shippingServices) : [];
      // OrderPart Start
      //OrderPart End
    }
  }
  // Bind Order End

  DisableNetTerms(): Number {
    let val = 0;
    if (this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms).length) {
      this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms)[0].IsDisable = false;
      val = this.NetTermsList.filter(x => x.name == this.SalesOrderById.salesOrder.netTerms)[0].id;
      this.NetTermsList.filter(x => x.name > this.SalesOrderById.salesOrder.netTerms).forEach(element => {
        element.IsDisable = true;
      });
    } else {
      val = 0;
    }
    return val;
  }
  GetNetTermsVal(): string {
    let val = '';
    if (this.OrderPartForm.value.netTerms != undefined && this.NetTermsList.filter(x => x.id == this.OrderPartForm.value.netTerms).length > 0) {
      let result = this.NetTermsList.filter(x => x.id == this.OrderPartForm.value.netTerms)[0];
      val = (result.id == 0) ? result.name : 'Net ' + result.name;
    }
    return val;
  }

  GetCustomerTypeForRolePricing(Id: any): string {
    let val = '';
    if (this.constants.CustomerType != undefined && this.constants.CustomerType.length > 0 && Id != undefined) {
      let result = this.constants.CustomerType.filter(x => x.id == Id)[0].name;
      val = result;
    }
    return val;
  }
  ChangeTaxable(value: any) {
    let TaxType = 0;
    if (value == 'Taxable') {
      TaxType = 1;
      this.OrderPart.value.forEach((v, index) => {
        this.OrderPart.controls[index].get('IsTaxable').disable({ onlySelf: true, emitEvent: false })
        this.OrderPart.value[index].IsTaxable = true;
      });
      this.loader = true;
      this.addedTaxesAPI();
    } else {
      TaxType = value == 'NonTaxable' ? 3 : 2;
      this.OrderPart.value.forEach((v, index) => {
        this.OrderPart.controls[index].get('IsTaxable').enable();
        this.OrderPart.value[index].IsTaxable = false;
      });
    }
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value, taxType: TaxType, laborTaxRate: 0, laborTaxRateAmt: 0, partTaxRate: 0, partTaxRateAmt: 0, shippingTaxRate: 0, shippingTaxRateAmt: 0 })
    //this.ChangeTaxType(TaxType)
  }
  ChangeCollectAccount(value: any) {
    if (value == '') {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingOption: 0, collectAccount: '', CollectAmount: '', shippingType: 'Select One', shippingServices: '' })
      //this.GetShippingCharge(this.OrderPartForm.value.shippingOption)
    } else {
      this.OrderPartForm.patchValue({ isCollectAmount: true, shippingAmount: 0, shippingOption: 5, shippingType: 'Collect Account', shippingServices: '' })
    }
    this.shiprateListnew = [];
  }
  BindOrderPart() {
    this.OrderPart.clear();
    this.partList.forEach((element, index) => {
      this.OrderPart.push(new FormGroup({
        Id: new FormControl(element.id),
        PartId: new FormControl(element.partId),
        PartNumber: new FormControl(element.partNumber),
        PartName: new FormControl(element.partName),
        Quantity: new FormControl(element.quantity),
        // Discount Section Start
        CustDiscountType: new FormControl(''),
        CustDiscountCost: new FormControl(0),
        _CustomerDiscount: new FormControl(element._CustomerDiscount),
        RoleBasePricingDiscount: new FormControl(element.roleBasePricingDiscount),
        DimentionType: new FormControl(element.dimentionType),
        // Discount Section End
        // Summary Section Start
        IsTaxable: new FormControl({ value: true, disabled: this.OrderPartForm.value.taxable == 'Taxable' ? true : false }),
        Cost: new FormControl(this.RemoveDecimal(element.cost, 2)),
        SExtension: new FormControl(this.RemoveDecimal(element.sExtension, 2)),
        // Summary Section Start
        // Detail Section Start
        Hours: new FormControl(element.hours),
        LaborCost: new FormControl(element.laborCost),
        TotalLobor: new FormControl(this.RemoveDecimal(element.hours * element.laborCost, 2)),
        DExtension: new FormControl(element.DExtension),
        // Detail Section End
        // Profitability Section Start
        UnitCost: new FormControl(0),
        PartsMargin: new FormControl(0),
        LaborLoadedCost: new FormControl(element.laborLoadedCost),
        LaborMargin: new FormControl(0),
        PExtension: new FormControl(0),
        Notes: new FormControl(element.notes),
        IsNotes: new FormControl((element.notes != null && element.notes != '') ? true : false),
        Question: new FormArray([]),
        // Profitability Section End
        //Dimention Start
        width: new FormControl(element.width.toString()),
        height: new FormControl(element.height.toString()),
        length: new FormControl(element.length.toString()),
        weightType: new FormControl(element.weightType),
        weight: new FormControl(element.weight),
        //Dimention End
        //Vendor Start
        customPartId: new FormControl(element.customPartId),
        vendorId: new FormControl(element.vendorId),
        vendorPartCost: new FormControl(element.vendorPartCost),
        //Vendor End
        //Shipment
        shipedQty: new FormControl(element.shipedQty)
      }))
      this.BindQuestions(element.question, index);
    });
    // this.CalculateDimension();
    this.SubTotal();
    //this.OrderPartForm.value.isCollectAmount == false ? this.GetShippingCharge(Number(this.OrderPartForm.value.shippingOption)) : true;
    this.loader = false;
  }
  Question(index: number): FormArray {
    return this.OrderPart.at(index).get('Question') as FormArray
  }
  IsMissingAnswer(): boolean {
    let IsMissingAns = false;
    this.OrderPart.value.forEach(ql => {
      if (ql.Question.filter(x => x.IsRequired && (x.Answer == '' || x.Answer == null)).length > 0) {
        this.MissingAnswerMsg()
        IsMissingAns = true;
      }
    });
    return IsMissingAns;
  }
  Cancel() {
    this.BindGetDate();
    this._IsShippingUpdate = false;
    this.IsAddressValidate = true;
    this._PShipping = false;
    this._PSales = false;
    this._PPayment = false;
    this.base64 = undefined;
  }
  IsUpdatedShipping(IsSkipShipping: boolean) {
    let result = false;
    if (this.SalesOrderById != undefined && !IsSkipShipping && this._IsShippingUpdate) {
      result = true
    } else {
      result = false
    }
    return result;
  }
  Save(IsApprove, IsComplete, IsSkipShipping) {
    this.CloseShippingServicesModal();
    if (!this.IsUpdatedShipping(IsSkipShipping)) {
      if (!this.IsMissingAnswer()) {
        this.loader = true;
        this.OrderPartForm.patchValue({ BalanceDue: this.GetBalanceDue(), availableCredit: Number(this.RemoveCurrency(this.OrderPartForm.getRawValue().availableCredit)) })
        let salesOrderVm = {
          salesOrder: this.OrderPartForm.getRawValue(),
          ShipAddress: this.ShipToForm.value,
          BillAddress: this.BillToForm.value
        }
        salesOrderVm.salesOrder.customerType = 0;
        this.salesOrderService.AddUpdSalesOrder(salesOrderVm)
          .subscribe({
            next: (data: any) => {
              if (!IsApprove && IsComplete) {
                this.CompletePayment(this.PaymentForm.value.PaymentAmount);
              } else if (IsApprove && IsComplete) {
                this.Pay(IsComplete);
              }
              if (this.OrderPartForm.value.statusId != 10 && this.OrderPartForm.value.statusId != 11 && this.OrderPartForm.value.fulfilmentType == 'Auto') {
                this.loader = false;
                this.FulfillmentAutomatic(data);
              }
              this.loader = false;
              this.GetSalesOrderById(data);
            },
            error: (err) => {
              this.loader = false
              this.SwalModal(this.constants.APIError, err);
            },
          });
      }
    } else {
      this.AddShippingServicesModal();
    }
  }
  FulfillmentAutomatic(Id: number) {
    if (Id > 0) {
      this.salesOrderService.FulfillmentAutomatic(Id)
        .subscribe({
          next: (data: any) => {
          },
          error: (err) => {
            // this.SwalModal('Fulfillment Exception', err);
          },
        });
    }
  }
  BindQuestions(Question: any, i: number) {
    Question.forEach((q) => {
      this.Question(i).push(new FormGroup({
        Id: new FormControl(q.id),
        PartId: new FormControl(q.partId),
        QuestionId: new FormControl(q.questionId),
        QuestionTitle: new FormControl(q.questionTitle),
        Answer: new FormControl(q.answer, q.isRequired ? Validators.required : []),
        AnswerTypeId: new FormControl(q.answerTypeId),
        IsRequired: new FormControl(q.isRequired),
        IsShow: new FormControl(false)
      }))
    });
  }
  CalculateListDiscount(value: any, i: number): DiscountVM {
    const disCost = new DiscountVM();
    if (value._CustomerDiscount != null && value._CustomerDiscount.length > 0 && value._CustomerDiscount.filter(x => x.minQty <= value.Quantity && x.qty >= (x.qty != 0 ? value.Quantity : 0)).length > 0) {
      disCost.CustDiscountCost = Math.min(...value._CustomerDiscount.filter(x => x.minQty <= value.Quantity && x.qty >= (x.qty != 0 ? value.Quantity : 0)).map(item => item.customerCost))
      disCost.CustomerType = 'vol';
    }
    if (value.roleBasePricingDiscount != null && value.roleBasePricingDiscount.length > 0 && value.roleBasePricingDiscount.filter(x => x.customerValue == this.OrderPartForm.value.customerType).length > 0) {
      let cost = 0;
      cost = Math.min(...value.roleBasePricingDiscount.filter(x => x.customerValue == this.OrderPartForm.value.customerType).map(item => item.cost))
      disCost.CustomerType = disCost.CustDiscountCost > cost ? 'role' : 'vol';
      disCost.CustDiscountCost = disCost.CustDiscountCost > cost ? cost : disCost.CustDiscountCost;
    }
    return disCost;
  }

  CalculateProfatibility() {
    this.OrderPartForm.value.subTotalPartsMargin = 0
    this.OrderPartForm.value.subTotalLaborMargin = 0
    this.OrderPartForm.value.subTotalPartsExt = 0
    this.OrderPart.value.forEach(v => {
      v.UnitCost = this.RemoveDecimal(v.CustDiscountType == '' ? v.vendorPartCost : v.CustDiscountCost, 2)
      v.SExtension = this.RemoveDecimal(v.CustDiscountType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2);
      v.PartsMargin = this.RemoveDecimal(1 - ((v.vendorPartCost / v.Cost) == Infinity ? 0 : (v.vendorPartCost / v.Cost)), 2);
      v.LaborMargin = this.RemoveDecimal(1 - ((v.LaborCost / v.LaborLoadedCost) == Infinity ? 0 : (v.LaborCost / v.LaborLoadedCost)), 2);
      v.PExtension = this.RemoveDecimal(((v.PartsMargin + v.LaborMargin) / 2), 2);
      this.OrderPartForm.value.subTotalPartsMargin = this.OrderPartForm.value.subTotalPartsMargin + v.PartsMargin;
      this.OrderPartForm.value.subTotalLaborMargin = this.OrderPartForm.value.subTotalLaborMargin + v.LaborMargin;
      this.OrderPartForm.value.subTotalPartsExt = this.OrderPartForm.value.subTotalPartsExt + v.PExtension;
    });

    this.OrderPartForm.value.subTotalPartsMargin = this.RemoveDecimal(this.OrderPartForm.value.subTotalPartsMargin / this.OrderPart.value.length, 2)
    this.OrderPartForm.value.subTotalLaborMargin = this.RemoveDecimal(this.OrderPartForm.value.subTotalLaborMargin / this.OrderPart.value.length, 2)
    this.OrderPartForm.value.subTotalPartsExt = this.RemoveDecimal((this.OrderPartForm.value.subTotalPartsExt) / this.OrderPart.value.length, 2)
    this.OrderPartForm.patchValue({
      OrderPart: this.OrderPart.value, subTotalPartsMargin: this.OrderPartForm.value.subTotalPartsMargin,
      subTotalLaborMargin: this.OrderPartForm.value.subTotalLaborMargin, subTotalPartsExt: this.OrderPartForm.value.subTotalPartsExt
    })
  }
  SubTotal() {
    this.OrderPartForm.value.subTotalDetExt = 0;
    this.OrderPartForm.value.subTotalSummaryExt = 0;
    this.OrderPart.value.forEach((v, index) => {
      let dis = this.CalculateListDiscount(v, index);
      //Summary Section
      v.CustDiscountType = dis.CustomerType;
      v.CustDiscountCost = dis.CustDiscountCost;
      v.SExtension = this.RemoveDecimal(dis.CustomerType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2)
      this.OrderPartForm.value.subTotalSummaryExt = this.OrderPartForm.value.subTotalSummaryExt + v.SExtension;
      //Detail Section
      v.SExtension = this.RemoveDecimal(v.CustDiscountType == '' ? (v.Cost * v.Quantity) : (v.CustDiscountCost * v.Quantity), 2)
      v.TotalLobor = this.RemoveDecimal(v.Hours * v.LaborCost, 2);
      v.DExtension = v.SExtension + v.TotalLobor;
      this.OrderPartForm.value.subTotalDetExt = this.OrderPartForm.value.subTotalDetExt + v.SExtension + v.TotalLobor;
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value, subTotalSummaryExt: this.OrderPartForm.value.subTotalSummaryExt, subTotalDetExt: this.OrderPartForm.value.subTotalDetExt })
    this.CalculateTaxRate('Parts')
    this.CalculateProfatibility();
    this.CalculateDimension();
    this.OrderPartForm.get('OrderPart')['controls'].forEach((control, index) => {
      control.get('Quantity').valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
        if (value !== this.OrderPart.value[index].Quantity) {
          this._IsShippingUpdate = true;
        }
      });
    });
  }
  changeTax(value: boolean) {
    if (this.OrderPart.value.filter(x => x.IsTaxable).length > 0) {
      this.OrderPartForm.patchValue({ TaxType: 1 })
    } else {
      this.OrderPartForm.patchValue({ TaxType: 2 })
    }
    this.addedTaxesAPI()
  }
  IsTaxable(taxType: string): number {
    let taxAmt = 0;
    if (this.OrderPartForm.value.taxable == 'Taxable') {
      this.OrderPart.value.forEach(t => {
        if (taxType == 'Parts') {
          taxAmt = taxAmt + t.SExtension;
        } else if (taxType == 'Labor') {
          taxAmt = taxAmt + t.LaborCost * t.Hours;
        }
      });
    } else {
      this.OrderPart.value.filter(x => x.IsTaxable).forEach(t => {
        if (taxType == 'Parts') {
          taxAmt = taxAmt + t.SExtension;
        } else if (taxType == 'Labor') {
          taxAmt = taxAmt + t.LaborCost * t.Hours;
        }
      });
    }
    return this.RemoveDecimal(taxAmt, 2);
  }
  CalculateTaxRate(type: string) {
    if (type == 'Labor' && this.OrderPartForm.value.laborTaxRate != undefined) {
      this.OrderPartForm.patchValue({ laborTaxRateAmt: this.RemoveDecimal(((this.IsTaxable('Labor') * this.OrderPartForm.value.laborTaxRate) / 100), 2) })
    } else if (type == 'Parts' && this.OrderPartForm.value.partTaxRate != undefined) {
      this.OrderPartForm.patchValue({ partTaxRateAmt: this.RemoveDecimal(((this.IsTaxable('Parts') * this.OrderPartForm.value.partTaxRate) / 100), 2) })
    } else if (type == 'Shipping' && this.OrderPartForm.value.shippingTaxRate != undefined) {
      this.OrderPartForm.patchValue({ shippingTaxRateAmt: this.RemoveDecimal(((this.OrderPartForm.value.shippingAmount * this.OrderPartForm.value.shippingTaxRate) / 100), 2) })
    }
  }
  IsTaxRateChecked(type: string) {
    if (type == 'Labor' && !this.OrderPartForm.value.isTaxLabor) {
      this.OrderPartForm.get('laborTaxRate').disable();
      this.OrderPartForm.patchValue({ laborTaxRate: 0, laborTaxRateAmt: 0 })
    } else if (type == 'Labor' && this.OrderPartForm.value.isTaxLabor) {
      this.OrderPartForm.get('laborTaxRate').enable();
    } else if (type == 'Parts' && !this.OrderPartForm.value.isTaxPart) {
      this.OrderPartForm.get('partTaxRate').disable();
      this.OrderPartForm.patchValue({ partTaxRate: 0, partTaxRateAmt: 0 })
    } else if (type == 'Parts' && this.OrderPartForm.value.isTaxPart) {
      this.OrderPartForm.get('partTaxRate').enable();
    } else if (type == 'Shipping' && !this.OrderPartForm.value.isTaxShipping) {
      this.OrderPartForm.get('shippingTaxRate').disable();
      this.OrderPartForm.patchValue({ shippingTaxRate: 0, shippingTaxRateAmt: 0 })
    } else if (type == 'Shipping' && this.OrderPartForm.value.isTaxShipping) {
      this.OrderPartForm.get('shippingTaxRate').enable();
    }
  }
  ChangeTaxType(value: any) {
    if (value == '1') {
      this.OrderPartForm.patchValue({
        laborTaxRate: 0.0,
        laborTaxRateAmt: 0.0,
        shippingTaxRate: 0.0,
        shippingTaxRateAmt: 0.0,
        taxable: 'Taxable'
      })
      this.addedTaxesAPI();
    } else {
      this.OrderPartForm.value.taxable = value == 3 ? 'NonTaxable' : 'Exempt';
      this.OrderPartForm.patchValue({ laborTaxRate: 0, laborTaxRateAmt: 0.0, partTaxRate: 0, partTaxRateAmt: 0, shippingTaxRate: 0, shippingTaxRateAmt: 0, taxable: this.OrderPartForm.value.taxable })
    }
    //this.ChangeTaxable(this.OrderPartForm.value.Taxable);
  }
  RemoveDecimal(str, val) {
    if (!isNaN(str)) {
      str = String(str)
      if (str.indexOf(".") === -1) {
        str = String(str)
      }
      else {
        str = str.slice(0, (str.indexOf(".")) + val + 1);
      }
    } else {
      str = '';
    }
    return Number(str);
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  GetStateByCountryId(Id) {
    this.sharedService.getAllState(Id).subscribe((data: any) => {
      this.StateListByCountryId = data.dataList;
    })
  }
  //Get All Country
  GetCountry() {
    this.sharedService.GetCountry()
      .subscribe({
        next: (data: any) => {
          this.CountryList = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  BackToList() {
    this.OrderPart.clear();
    this.SalesOrderById = undefined;
    this.IsSalesOrderList = false;
    this.targetVal = 'Summary';
    this._PShipping = false;
    this._PSales = false;
    this._PPayment = false;
    this.base64 = undefined;
    this.GetOrderList('', true, false);
  }
  setPShipping(value: BlobOptions) {
    this._PShipping = !value;
  }
  setPSales(value: BlobOptions) {
    this._PSales = !value;
  }
  setPPayment(value: BlobOptions) {
    this._PPayment = !value;
  }
  AddNotesRow(i: number) {
    this.OrderPart.value[i].IsNotes = true;
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  DelNotesRow(i: number) {
    this.OrderPart.value[i].IsNotes = false;
    this.OrderPart.value[i].Notes = '';
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  DeleteOrderPart(partId: number) {
    this.partList = this.partList.filter(p => p.partId != partId);
    this.BindOrderPart();
  }
  AddQuestionAnsRow(i: number, value: boolean) {
    this.Question(i).value.forEach(IsShow => {
      IsShow.IsShow = value;
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value })
  }
  //Shipping Rate Start
  getshipValue(rate) {
    this.OrderPartForm.patchValue({ shippingAmount: rate.amount, shippingType: rate.service_type, rate_id: rate.rate_id });
    this.CalculateTaxRate('Shipping');
  }
  changeShippingServices(value: any) {
    if (value != 5 && value != 6) {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingOption: value, collectAccount: '', shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name })
      if (this.OrderPart.value.length > 0) {
        this.shiprateListnew = [];
        this.GetShippingCharge(value);
      }
    } else if (value == 6) {
      this.OrderPartForm.patchValue({ isCollectAmount: false, shippingAmount: 0, collectAccount: '', shippingOption: value, shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name })
      this.shiprateListnew = [];
    } else if (value == 5) {
      this.OrderPartForm.patchValue({ isCollectAmount: true, shippingAmount: 0, collectAccount: 'UPS', shippingOption: value, shippingType: this.constants.ShippingType.filter(x => x.id == value)[0].name })
      this.shiprateListnew = [];
    }
  }
  GetShippingCharge(ShippingType: any) {
    if (this.OrderPartForm.value.quotedShippingWeight > 0) {
      this.loader = true;
      this.CloseShippingServicesModal();
      switch (ShippingType) {
        case 1:
          this.FedexRateCalc();
          break;
        case 7:
          this.ShipEngine(this.constants.UPSKey)
          break;
        case 8:
          this.ShipEngine(this.constants.USPSKey)
          break;
        default:
          this.ShipEngine(this.constants.ShipengineKey)
          break;
      }
    }
  }
  GetPackageType(rateId: string) {
    let pacInfo = undefined;
    if (this.shiprateListnew.length && this.shiprateListnew.filter(r => r.rate_id == rateId).length > 0) {
      pacInfo = this.shiprateListnew.filter(r => r.rate_id == this.OrderPartForm.value.rate_id)[0]
    }
    return pacInfo;
  }
  ShipEngine(item3: any) {
    const today: Date = new Date();
    const year: string = today.getFullYear().toString();
    const month: string = (today.getMonth() + 1).toString().padStart(2, '0');
    const day: string = today.getDate().toString().padStart(2, '0');
    const dateInFormat: string = `${year}-${month}-${day}`;
    const packages = [];
    const customs_items = [];
    for (let i = 0; i < this.OrderPart.value.length; i++) {
      let valueobj = {};
      let itemobj = {};
      itemobj["description"] = this.OrderPart.value[i].PartName;
      itemobj["quantity"] = Number(this.OrderPart.value[i].Quantity);
      itemobj["country_of_origin"] = "US";
      itemobj["harmonized_tariff_code"] = "";
      itemobj["value"] = valueobj;
      valueobj["amount"] = Number(this.OrderPart.value[i].CustDiscountType == '' ? this.OrderPart.value[i].Cost : this.OrderPart.value[i].CustDiscountCost);
      valueobj["currency"] = "usd";
      customs_items.push(itemobj);
    }
    let totalbudget = 0;
    const value = Math.floor(this.OrderPartForm.value.quotedShippingWeight / 2400);
    for (let i = 0; i < value; i++) {
      let objweight = {};
      let objdimension = {};
      let obj = {};
      obj["package_code"] = "package";
      obj["weight"] = objweight;
      obj["dimensions"] = objdimension;
      objweight["value"] = 2400;
      objweight["unit"] = "ounce";
      objdimension["length"] = 0;
      objdimension["width"] = 0;
      objdimension["height"] = 0;
      objdimension["unit"] = "inch";
      packages.push(obj);
      totalbudget += 2400;
    }
    let count = this.OrderPartForm.value.quotedShippingWeight - totalbudget;
    const value1 = 1;
    if (count != 0) {
      for (let i = 0; i < value1; i++) {
        let objweight = {};
        let objdimension = {};
        let obj = {};
        obj["package_code"] = "package";
        obj["weight"] = objweight;
        objweight["value"] = count;
        objweight["unit"] = "ounce";
        objdimension["length"] = 0;
        objdimension["width"] = 0;
        objdimension["height"] = 0;
        objdimension["unit"] = "inch";
        packages.push(obj);
      }
    }
    this.OrderPartForm.value.quotedShippingPackage = value + value1;
    this.OrderPartForm.patchValue({ quotedShippingPackage: this.OrderPartForm.value.quotedShippingPackage })
    let shippingratemul = {
      "rate_options": {
        "carrier_ids": item3,
        "preferred_currency": "usd"
      },
      "shipment": {
        "validate_address": "no_validation",
        "ship_date": dateInFormat,
        "ship_to": {
          "name": "",
          "phone": "",
          "company_name": "",
          "address_line1": this.ShipToForm.value.addressLine1,
          "city_locality": this.ShipToForm.value.city,
          "state_province": this.GetStateCode(this.ShipToForm.value.stateId),
          "postal_code": this.ShipToForm.value.zip,
          "country_code": this.GetCountryCode(this.ShipToForm.value.countryId),
          "address_residential_indicator": "yes",
        },
        "ship_from": this.constants.ship_from,
        "confirmation": "none",
        "customs": {
          "non_delivery": "return_to_sender",
          "contents": "Merchandise",
          "customs_items": customs_items
        },
        "packages": packages
      }
    }
    this.loader = true;
    this.sharedService.GetMulShippingDetails(shippingratemul)
      .subscribe({
        next: (result: any) => {
          this.loader = false;
          this._IsShippingUpdate = false;
          if (result.shippingRateResponseModelError == null) {
            this.shiprateListnew = [];
            result.shippingRateResponseModelSuccess.rate_response.rates.forEach(element => {
              if (element.error_messages.length <= 0) {
                var obj = {};
                obj["rate_id"] = element.rate_id;
                if (element.package_type == null) {
                  obj["package_type"] = element.package_type;
                } else {
                  obj["package_type"] = element.package_type.replace(/_/g, ' ').toUpperCase();
                }
                obj["service_type"] = element.service_type;
                obj["carrier_delivery_days"] = element.carrier_delivery_days;
                obj["amount"] = element.shipping_amount.amount;
                this.shiprateListnew.push(obj);
              }
            });
            this.shiprateListnew = this.shiprateListnew.sort((a, b) => (a.amount - b.amount));
            if (this.shiprateListnew.length > 0) {
              this.OrderPartForm.patchValue({ shippingAmount: this.shiprateListnew[0].amount, shippingType: this.shiprateListnew[0].service_type, rate_id: this.shiprateListnew[0].rate_id, shippingServices: JSON.stringify(this.shiprateListnew) });
              if (this.OrderPartForm.value.taxable == 'Taxable') {
                this.addedTaxesAPI();
              }
            }
          } else {
            this.loader = false
          }
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  // FedEX Start
  FedexRateCalc() {
    this.loader = true;
    const packages = [];
    let fedexRateRequest = null;
    const weightinlbs = this.OrderPartForm.value.quotedShippingWeight / 16;
    let totalbudget = 0;
    const value = Math.floor(weightinlbs / 150);
    for (let i = 0; i < value; i++) {
      let obj = {};
      let weight = {};
      weight["units"] = "LB",
        weight["value"] = 150,
        obj["weight"] = weight;
      packages.push(obj);
      totalbudget += 150;
    }
    let count = weightinlbs - totalbudget;
    const value1 = 1;
    if (count != 0) {
      for (let i = 0; i < value1; i++) {
        let obj = {};
        let weight = {};
        weight["units"] = "LB",
          weight["value"] = count,
          obj["weight"] = weight;
        packages.push(obj);
      }
    }
    this.OrderPartForm.value.quotedShippingPackage = value + value1;
    this.OrderPartForm.patchValue({ quotedShippingPackage: this.OrderPartForm.value.quotedShippingPackage })
    fedexRateRequest = {
      "accountNumber": {
        "value": this.constants.accountNumber
      },
      "requestedShipment": {
        "shipper": {
          "address": {
            "postalCode": "92887",
            "countryCode": "US"
          }
        },
        "recipient": {
          "address": {
            "postalCode": this.ShipToForm.value.zip,
            "countryCode": this.GetCountryCode(this.ShipToForm.value.countryId)
          }
        },
        "pickupType": "USE_SCHEDULED_PICKUP",

        "rateRequestType": [
          "ACCOUNT",
          "LIST"
        ],
        "requestedPackageLineItems": packages
      }
    }
    this.sharedService.FedexIntegration(fedexRateRequest)
      .subscribe({
        next: (result: any) => {
          this.loader = false;
          this._IsShippingUpdate = false;
          if (result.output != null) {
            if (result.output.rateReplyDetails.length > 0) {
              this.shiprateListnew = [];
            }
            result.output.rateReplyDetails.forEach(element => {
              var obj = {};
              obj["rate_id"] = element.serviceType;
              obj["package_type"] = element.packagingType;
              obj["service_type"] = element.serviceName;
              obj["carrier_delivery_days"] = element.serviceDescription.description;
              obj["amount"] = element.ratedShipmentDetails[0].totalNetFedExCharge;
              this.shiprateListnew.push(obj);
            });
            this.shiprateListnew = this.shiprateListnew.sort((a, b) => (a.amount - b.amount))
            if (this.shiprateListnew.length > 0) {
              this.OrderPartForm.patchValue({ shippingAmount: this.shiprateListnew[0].amount, shippingType: this.shiprateListnew[0].service_type, rate_id: this.shiprateListnew[0].rate_id, shippingServices: JSON.stringify(this.shiprateListnew) })
              if (this.OrderPartForm.value.taxable == 'Taxable') {
                this.addedTaxesAPI();
              }
            }
          }
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }
  // FedEx End
  //Shipping Rate End
  //Calculate Dimension Start
  CalculateDimension() {
    if (this.OrderPart.value != null && this.OrderPart.value.length > 0) {
      this.OrderPartForm.value.quotedShippingWeight = 0;
      this.OrderPart.value.forEach(op => {
        if (op.DimentionType == "1") {
          op.width = parseFloat(op.width) * 0.393701;
          op.height = parseFloat(op.height) * 0.393701;
          op.length = parseFloat(op.length) * 0.393701;
        }
        else if (op.DimentionType == "3") {
          op.width = parseFloat(op.width) * 12;
          op.height = parseFloat(op.height) * 12;
          op.length = parseFloat(op.length) * 12;
        }
        else {
          op.width = parseFloat(op.width)
          op.height = parseFloat(op.height)
          op.length = parseFloat(op.length)
        }
        if ((op.weightType == "3" || op.weightType == "5") && op.PartId != 0) {
          this.OrderPartForm.value.quotedShippingWeight += Number((op.weight * 16 * parseInt(op.Quantity)));

        }
        else if (op.weightType == "1" && op.PartId != 0) {
          this.OrderPartForm.value.quotedShippingWeight += Number((op.weight * 35.274 * parseInt(op.Quantity)));
        }
        else if (op.PartId != 0) {
          this.OrderPartForm.value.quotedShippingWeight += Number((op.weight * parseInt(op.Quantity)));
        }
      });
      this.OrderPartForm.patchValue({ quotedShippingWeight: this.OrderPartForm.value.quotedShippingWeight })
    }
  }
  //Calculate Dimension End
  //Create Object  for Taxes
  addedTaxesAPI() {
    let objTaxes = {
      "to_country": this.GetCountryCode(this.ShipToForm.value.countryId),
      "to_zip": this.ShipToForm.value.zip,
      "to_state": this.GetStateCode(this.ShipToForm.value.stateId),
      "to_city": this.ShipToForm.value.city,
      "amount": this.IsTaxable('Parts'),
      "shipping": this.OrderPartForm.value.shippingAmount
    }
    if (this.GetStateCode(this.ShipToForm.value.stateId) == 'CA') {
      this.sharedService.TaxesAPIPost(objTaxes)
        .subscribe({
          next: (data) => {
            if (data != null) {
              this.OrderPartForm.patchValue({ partTaxRateAmt: Number(data.tax.amount_to_collect), partTaxRate: this.RemoveDecimal(Number(data.tax.rate * 100), 2), isTaxPart: true });
            }
            this.loader = false;
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, err);
          },
        });
    } else {
      this.OrderPartForm.patchValue({ partTaxRateAmt: 0, partTaxRate: 0 });
      this.loader = false;
    }
    this.CalculateTaxRate('Parts');
    this.CalculateTaxRate('Labor');
    this.CalculateTaxRate('Shipping');
  }
  RemoveMaskValidation() {
    if (this.ShipToForm.value.zip.length > 4) {
      this.ShipToFormError.zip.setValidators(null);
      this.ShipToFormError.zip.updateValueAndValidity();
    } else {
      this.ShipToFormError.zip.setErrors({ requiredMask: "00000-0000" });
    }
  }
  // Address Validation Start
  ShippingAddressValidationApi() {
    this.submitted = true;
    if (!this.ShipToForm.invalid) {
      this.loader = true;
      let objShipping = [{
        "address_line1": this.ShipToForm.value.addressLine1,
        "city_locality": this.ShipToForm.value.city,
        "state_province": this.GetStateCode(this.ShipToForm.value.stateId),
        "postal_code": this.ShipToForm.value.zip,
        "country_code": this.GetCountryCode(this.ShipToForm.value.countryId)
      }]
      this.sharedService.ValidateAddress(objShipping)
        .subscribe({
          next: (data) => {
            this.loader = false;
            this.submitted = true;
            if (data.length > 0 && data[0].matched_address != null) {
              this.AddressValidatedAddress = data[0].matched_address
              this.IsAddressValidate = data[0].isAddressSame;
              this.IsAddressValidate == true ? $("#validAddress").hide() : $("#validAddress").show();
            } else {
              this.IsAddressValidate = false;
              this.SwalModal(data[0].messages[0].message, data[0].status);
            }
          },
          error: (err) => {
            this.loader = false
            this.SwalModal('Address', err);
          },
        });
    }
  }
  // Address Validation Start
  SuggestedShipToAddress() {
    if (this.IsAddressValidate) {
      this.ShipToForm.patchValue({
        addressLine1: this.AddressValidatedAddress.address_line1,
        addressLine2: this.AddressValidatedAddress.address_line2,
        city: this.AddressValidatedAddress.city_locality,
        stateId: this.StateList.filter(s => s.abbreviation == this.AddressValidatedAddress.state_province)[0].id,
        zip: this.AddressValidatedAddress.postal_code,
      })
      this.GetShippingCharge(this.OrderPartForm.value.shippingOption);
    } else {
      this.IsAddressValidate = true;
    }
    $("#validAddress").hide();
  }
  DefaultAddress(value) {
    this.IsAddressValidate = value;
  }
  // Payment Section Start
  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }
  async Pay(IsComplete) {
    this.loader = true;
    //const balance = this.CCardPaymentForm.value.CCAmount //this.GetBalanceDue();  // Approve Amount
    const token = await this.tokenize(card);
    this.ApprovePayment(token, Number(this.RemoveCurrency(this.CCardPaymentForm.value.CCAmount)), IsComplete);
  }
  async ApprovePayment(token, BalanceDue, IsComplete) {
    const random_uuid = uuidv4();
    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
          const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }
    let obj = {
      idempotency_key: random_uuid, source_id: token, amount_money: { amount: parseFloat(((BalanceDue) * 100).toFixed(2)), currency: "USD" },
      "autocomplete": false, "paymentType": this.OrderPartForm.value.paymentType
    };
    this.loader = true;
    this.salesOrderService.ApprovePayment(obj, this.OrderPartForm.value.id)
      .subscribe({
        next: (data: any) => {
          if (data.errors == null) {
            if (data.payment.status === 'APPROVED' || data.payment.status === 'COMPLETED') {
              this.OrderPartForm.patchValue({ paymentStatus: 'APPROVED' })
              this.PaymentLogList = data.salesOrderPaymentLog.length > 0 ? data.salesOrderPaymentLog.sort((low, high) => low.id - high.id) : [];
            }
            if (IsComplete) {
              this.CompletePayment(this.PaymentForm.value.PaymentAmount);
            }
            this.ClosePaymentInfoModal();
          } else {
            this.ClosePaymentInfoModal();
            this.SwalModal(data.errors[0].code, data.errors[0].detail);
          }
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.SwalModal(this.constants.APIError, err);
        },
      });
  }

  //Payment Complete
  CompletePayment(Amount) {
    this.loader = true;
    const Amt = Math.trunc(this.RemoveCurrency(Amount));
    if (this.GetBalanceDue() > Amt) {
      const payment = {
        Type: this.OrderPartForm.value.paymentType,
        orderid: this.OrderPartForm.value.id,
        customerid: this.OrderPartForm.value.customerId,
        paymentType: this.OrderPartForm.value.paymentType,
        amount: Amt * 100
      }
      this.salesOrderService.CompletePayment(payment)
        .subscribe({
          next: (data: any) => {
            this.loader = false;
            if (data.data.errors == null) {
              if (data.data.payment.status === 'COMPLETED') {
                this.PaymentLogList = data.data.salesOrderPaymentLog.length > 0 ? data.data.salesOrderPaymentLog.sort((low, high) => low.id - high.id) : [];
                this.OrderPartForm.patchValue({ paymentAmt: this.TotalCompletedAmount(this.PaymentLogList) })
              }
              if (data.data.payment.status === 'FAILED' || data.data.payment.status === 'CANCELED') {

              }
            }
            this.ClosePaymentModal()
            this.SwalModal('Success', 'Payment Completed Successfully!');
            this.selectedRow(this.OrderPartForm.value.id, this.OrderPartForm.value.customerId);
          },
          error: (err) => {
            this.loader = false
            this.SwalModal(this.constants.APIError, err);
          },
        });
    }
  }
  TotalCompletedAmount(logList): number {
    let amt = 0;
    if (logList.filter(x => x.status == 'COMPLETED').length > 0) {
      amt = logList.filter(x => x.status == 'COMPLETED').reduce((acc, val) => acc += val.amountMoney, 0) / 100;
    }
    return amt;
  }
  GetCountryCode(countryId: number) {
    return (this.CountryList.length > 0 && countryId != null && this.CountryList.filter(x => x.id == countryId).length > 0) ? this.CountryList.filter(x => x.id == countryId)[0].countryCode.trim() : ''
  }
  GetStateCode(stateId: number) {
    return (this.StateList.length > 0 && stateId != null && this.StateList.filter(x => x.id == stateId).length > 0) ? this.StateList.filter(x => x.id == stateId)[0].abbreviation.trim() : ''
  }
  //Payment Section End
  IsCollapseAll(value: boolean) {
    this.OrderPart.value.forEach(ql => {
      ql.Question.forEach(IsShow => {
        IsShow.IsShow = value;
      });
    });
    this.OrderPartForm.patchValue({ OrderPart: this.OrderPart.value });
  }
  GetUOM(Id: number) {
    return this.constants.UnitLength.filter(x => x.id == Id)[0].name;
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value != undefined && value != null && value != '') {
      result = true;
    }
    return result;
  }
  // Pdf Generated for LOG Start
  DownloadPDF(IsPrint: boolean, IsEmail: number) {
    let salesOrderVm = {
      salesOrder: this.OrderPartForm.getRawValue(),
      ShipAddress: this.ShipToForm.value,
      BillAddress: this.BillToForm.value
    }
    this.BarcodeGenerate(JSON.stringify(salesOrderVm), this.OrderPartForm.getRawValue().orderNumber, false, IsPrint, '', false, IsEmail)
  }
  CreatePdf(value: any, orderNumber: string, IsHis: boolean) {
    let pdfValue = JSON.parse(value)
    let SObarcodeImg = this.SObarcodeImg
    let PObarcodeImg = this.PObarcodeImg
    var email = localStorage.getItem("useremail");
    var loggedName = localStorage.getItem("username");
    var pdFgeneratedate = this.datepipe.transform(new Date(), 'MMMM dd, yyyy, hh:mm:ss a');
    this.CompanyDetail.cLogo = !this.ISNullOrEmpty(this.CompanyDetail.base64Logo) ? this.constants.dummyCompanyLogo : this.CompanyDetail.base64Logo;
    let docDefinition = {
      pageMargins: [15, 30, 15, 50],
      content: [
        // Previous configuration
        {
          columns: [
            [
              {
                image: this.CompanyDetail.cLogo,
                bold: true,
                width: '*', // Set width to auto
                height: 35, // Set height to a fixed value
                margin: [0, 0, 0, 0],
                fit: [90, 90] // Wrap the image in a container with a fixed width
              },
              { text: '', bold: true, fontSize: 15, margin: [0, 10, 0, 0] },
              {

                table: {
                  heights: [80],
                  margin: [0, 0, 0, 0],
                  body: [
                    [
                      {
                        text:
                          (this.CompanyDetail.addressLine1) + '\n' +
                          ((this.ISNullOrEmpty(this.CompanyDetail.city) ? (this.CompanyDetail.city + ',') : '') + ' ' + (!this.ISNullOrEmpty(this.CompanyDetail.stateId) ? '' : this.GetStateCode(this.CompanyDetail.stateId) + ',') + ' ' + this.CompanyDetail.zipCode) + '\n' +
                          ('Phone: ' + this.ISNullOrEmpty(this.CompanyDetail.compContactNumber) ? this.CompanyDetail.compContactNumber : '') + '\n' +
                          ('Sales Rep Name: ' + loggedName) + '\n' +
                          ('Email: ' + this.CompanyDetail.contactEmail)
                        , fontSize: 10, border: [false, false, false, false]
                      }
                    ]
                  ]
                }
              },
              {
                table: {
                  widths: [260],
                  heights: [10, 30],
                  body: [
                    [{ text: 'Bill To:', bold: true }],
                    [
                      {
                        text: (IsHis ? (!this.ISNullOrEmpty(pdfValue.BillAddress.Name) ? '' : pdfValue.BillAddress.Name) : (!this.ISNullOrEmpty(pdfValue.BillAddress.name) ? '' : pdfValue.BillAddress.name)) + '\n' +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.AddressLine1) ? '\n' + pdfValue.BillAddress.AddressLine1 : '') : (this.ISNullOrEmpty(pdfValue.BillAddress.city) ? (pdfValue.BillAddress.addressLine1 + ', ') : '')) + '\n' +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.City) ? (pdfValue.BillAddress.City + ', ') : '') : (this.ISNullOrEmpty(pdfValue.BillAddress.city) ? (pdfValue.BillAddress.city + ', ') : '')) +
                          (IsHis ? (!this.ISNullOrEmpty(pdfValue.BillAddress.StateId) ? '' : (this.GetStateCode(pdfValue.BillAddress.StateId)) + ', ') : (!this.ISNullOrEmpty(pdfValue.BillAddress.stateId) ? '' : (this.GetStateCode(pdfValue.BillAddress.stateId)) + ', ')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Zip) ? +'\n' + pdfValue.BillAddress.Zip : '') : (this.ISNullOrEmpty(pdfValue.BillAddress.zip) ? +'\n' + pdfValue.BillAddress.zip : '')) + '\n' +
                          ('Email Address: ' + (IsHis ? (this.ISNullOrEmpty(pdfValue.BillAddress.Email) ? +'\n' + pdfValue.BillAddress.Email : '') : (this.ISNullOrEmpty(pdfValue.BillAddress.email) ? +'\n' + pdfValue.BillAddress.email : ''))) + '\n' +
                          ('Phone #: ' + (IsHis ? (!this.ISNullOrEmpty(pdfValue.BillAddress.Phone) ? '' : pdfValue.BillAddress.Phone) : (!this.ISNullOrEmpty(pdfValue.BillAddress.phone) ? '' : pdfValue.BillAddress.phone)))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              },
              {
                text: 'Notes: ' + '  ' + (IsHis ? (!this.ISNullOrEmpty(pdfValue.salesOrder.CustomerNotes) ? '' : pdfValue.salesOrder.CustomerNotes) : (!this.ISNullOrEmpty(pdfValue.salesOrder.customerNotes) ? '' : pdfValue.salesOrder.customerNotes)),
                bold: false,
                fontSize: 11,
                margin: [0, 20, 0, 10]
              }
            ],
            [
              {
                text: (IsHis && pdfValue.salesOrder.StatusId == 11) ? 'Sales Quote' : 'Sales Order',
                alignment: 'right',
                bold: true,
                fontSize: 19
              }, { text: ' ' },
              {
                alignment: 'right',
                table: {
                  widths: [85, 85, 85],
                  heights: [10, 10, 10],
                  margin: [90, 70, 0, 0],
                  bold: true,
                  body: [
                    [{ text: 'Purchase Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Order #', bold: true, alignment: 'center', fontSize: 10 }, { text: 'Date', bold: true, alignment: 'center', fontSize: 10 }],
                    [
                      { text: this.OrderPartForm.value.purchaseOrder, alignment: 'center', bold: true, fontSize: 10 },
                      { text: (IsHis ? orderNumber.substring(2) : orderNumber), alignment: 'center', bold: true, fontSize: 10 },
                      { text: this.datepipe.transform(new Date(), 'MM/dd/yyyy'), alignment: 'center', fontSize: 10 }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }, { text: '    ' + (this.ISNullOrEmpty(this.OrderPartForm.value.purchaseOrder) ? 'Purchase Order Number' : '') + '                     Sales Order Number', fontSize: 10, alignment: 'right', margin: [0, 2, 20, 0] },
              {
                image: SObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'right',
                margin: [0, 0, 0, 0],
              },
              {
                image: PObarcodeImg,
                bold: true,
                width: 133,
                height: 40,
                alignment: 'left',
                margin: [0, -40, 10, 0]
              },
              {
                margin: [0, 7, 0, 0],
                table: {
                  heights: [10, 30],
                  widths: [270],
                  alignment: 'right',
                  body: [
                    [{ text: 'Ship To:', bold: true }],
                    [
                      {
                        text: (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.CustomerName) ? '\n' + pdfValue.ShipAddress.CustomerName : '') : (this.ISNullOrEmpty(pdfValue.ShipAddress.customerName) ? '\n' + pdfValue.ShipAddress.customerName : '')) +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.CName) ? + '\n' + pdfValue.ShipAddress.CName : '') : (this.ISNullOrEmpty(pdfValue.ShipAddress.cName) ? + '\n' + pdfValue.ShipAddress.cName : '')) +
                          (IsHis ? (pdfValue.ShipAddress.AddressLine1 + '\n') : (pdfValue.ShipAddress.addressLine1)) +
                          (IsHis ? (!this.ISNullOrEmpty(pdfValue.ShipAddress.AddressLine2) ? '' : pdfValue.ShipAddress.AddressLine2) : (!this.ISNullOrEmpty(pdfValue.ShipAddress.addressLine2) ? '' : pdfValue.ShipAddress.addressLine2)) + '\n' +
                          (IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.City) ? (pdfValue.ShipAddress.City + ', ') : '') : (this.ISNullOrEmpty(pdfValue.ShipAddress.city) ? (pdfValue.ShipAddress.city + ', ') : '')) +
                          (IsHis ? (!this.ISNullOrEmpty(pdfValue.ShipAddress.StateId) ? '' : (this.GetStateCode(pdfValue.ShipAddress.StateId)) + ', ') : (!this.ISNullOrEmpty(pdfValue.ShipAddress.stateId) ? '' : (this.GetStateCode(pdfValue.ShipAddress.stateId)) + ', ')) +
                          ((IsHis ? (this.ISNullOrEmpty(pdfValue.ShipAddress.Zip) ? pdfValue.ShipAddress.Zip : '') : (this.ISNullOrEmpty(pdfValue.ShipAddress.zip) ? pdfValue.ShipAddress.zip : '')) + '\n') +
                          (IsHis ? (!this.ISNullOrEmpty(pdfValue.ShipAddress.CountryId) ? '' : (this.GetCountryCode(pdfValue.ShipAddress.CountryId))) : (!this.ISNullOrEmpty(pdfValue.ShipAddress.countryId) ? '' : (this.GetCountryCode(pdfValue.ShipAddress.countryId))))
                        , fontSize: 10
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#edeaea' : null;
                  }
                }
              }
            ]
          ]
        },
        {
          table: {
            widths: [100, 110, 70, 80, 170],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            body: [
              [{ text: 'Buyer', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Payment Terms', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'FOB Point', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 },
              { text: 'Shipping Term', border: [false, false, false, false], alignment: 'center', margin: [0, 5, 0, 0], bold: true, fontSize: 10 }
                , { text: 'Ship VIa', border: [false, false, false, false], margin: [0, 5, 0, 0], alignment: 'center', bold: true, fontSize: 10 }],
              [
                { text: loggedName, alignment: 'center', fontSize: 10 },
                { text: (IsHis ? pdfValue.salesOrder.PaymentType : pdfValue.salesOrder.paymentType), alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.CompanyDetail.fobPointName) ? this.CompanyDetail.fobPointName : '', alignment: 'center', fontSize: 10 },
                { text: this.ISNullOrEmpty(this.CompanyDetail.shippingTermName) ? this.CompanyDetail.shippingTermName : '', alignment: 'center', fontSize: 10 },
                { text: (IsHis ? pdfValue.salesOrder.ShippingType : pdfValue.salesOrder.shippingType), alignment: 'center', fontSize: 10 },

              ]
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? '#dadada' : null;
            }
          }
        }, { text: ' ' },
        {
          style: 'contactTable1',
          table: {
            widths: [40, 100, 208, 40, 40, 80],
            heights: [20, 15],
            alignment: 'center',
            border: 1,
            fontSize: 8,
            body: [
              [
                { text: 'Item', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Part Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Ordered', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Shipped', fontSize: 10, bold: true, alignment: 'center' },
                { text: 'Extension Total', fontSize: 10, bold: true, alignment: 'right' }
              ]
              ,
              ...pdfValue.salesOrder.OrderPart.map((p1, i) => ([
                { text: i + 1, fontSize: 10, alignment: 'center' },
                { text: p1.PartNumber, fontSize: 10, alignment: 'left' },
                { text: p1.PartName, fontSize: 10, alignment: 'left' },
                { text: p1.Quantity, fontSize: 10, alignment: 'center' },
                { text: p1.shipedQty == null ? 0 : p1.shipedQty, fontSize: 10, alignment: 'center' },
                { text: this.currencyPipe.transform(p1.SExtension), fontSize: 10, alignment: 'right' }])),

            ]
          },
        },
        {
          table: {
            widths: [330, 0, 0, 0, 0, 100, 67],
            margin: [0, 0, 0, 0],
            body: [
              [
                {}, {}, {}, {}, {},
                { text: 'Shipping', margin: [0, 0, 0, 0], alignment: 'left', fontSize: 10, bold: true },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.ShippingAmount : pdfValue.salesOrder.shippingAmount), alignment: 'right', bold: true, fontSize: 10 }

              ],
              [
                {}, {}, {}, {}, {},
                { text: 'Sales Tax ( ' + (IsHis ? pdfValue.salesOrder.PartTaxRate : pdfValue.salesOrder.partTaxRate) + '% )', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.PartTaxRateAmt : pdfValue.salesOrder.partTaxRateAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Total', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.SubTotalSummaryExt : pdfValue.salesOrder.subTotalSummaryExt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Payment Received', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.PaymentAmt : pdfValue.salesOrder.paymentAmt), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }],
              [
                {}, {}, {}, {}, {},
                { text: 'Balance Due', alignment: 'left', margin: [0, 0, 0, 0], bold: true, fontSize: 10 },

                { text: this.currencyPipe.transform(IsHis ? pdfValue.salesOrder.BalanceDue : this.GetBalanceDue()), margin: [0, 0, 0, 0], alignment: 'right', bold: true, fontSize: 10 }]
            ]
          },
          layout: {
            fillColor: '#dadada',
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#dadada' : '#dadada';
            },
          }
        },
      ],
      footer: function (currentPage, pageCount) {
        if (currentPage === pageCount) {
          return [
            {
              margin: [20, 0, 0, 0],
              table: {
                widths: [480, 100],

                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '**** PLEASE CONFIRM AND ADVISE OF ETA BY EMAIL AT ' + email + '\n**** THANK YOU, ' + loggedName + '\n\n' + pdFgeneratedate, fontSize: 8, bold: true },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }

          ]
        }
        else {
          return [
            {
              table: {
                widths: [480, 100],
                alignment: 'left',
                style: 'footersTableStyle',
                body: [
                  [
                    { text: '' },
                    { text: 'Page: ' + currentPage.toString() + ' of ' + pageCount, margin: [0, 10, 50, 80], fontSize: 8, alignment: 'right', bold: true }
                  ],
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                },
              }
            }
          ];
        }

      },

      styles: {
        sectionHeader: {
          bold: true,
          fontSize: 14,
          margin: [0, 15, 0, 15]
        },
        tableExample: {
          margin: [90, 0, 0, 0]
        },
        contactTable: {
          margin: [0, 5, 0, 0]
        },
        contactTable1: {
          margin: [0, 10, 0, 0]
        }
      }
    };
    return docDefinition;
  }
  //Pdf End
  //Generate BarCode Start
  BarcodeGenerate(value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string, IsPOBarcode: boolean, IsEmail: number) {
    this.SObarcodeImg = undefined;
    JsBarcode(this.barcode.nativeElement, IsPOBarcode ? this.OrderPartForm.value.purchaseOrder : (IsHis ? orderNumber.substring(2) : orderNumber), {
      height: 90,
      width: 2.5,
      displayValue: false
    });
    var s = new XMLSerializer().serializeToString(document.getElementById("barcode"));
    this.ImgConvertor(s, value, orderNumber, IsHis, IsPrint, Status, IsPOBarcode, IsEmail);
  }
  ImgConvertor(s, value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string, IsPOBarcode: boolean, IsEmail: number): any {
    this.SVGToImage({
      svg: s,
      mimetype: "image/png",
      width: 500,
      quality: 1
    }).then((base64image: any) => {
      let img = document.createElement('img');
      img.src = base64image;
      if (IsPOBarcode) {
        this.PObarcodeImg = img.src;
      } else {
        this.SObarcodeImg = img.src;
        if (IsEmail == 1) {
          this.getBase64(value, orderNumber)
        } else if (IsEmail == 2) {
          this.Share(value, orderNumber, IsHis, IsPrint, Status);
        }
      }
    });
  }
  SVGToImage(settings) {
    let _settings = {
      svg: null,
      mimetype: "image/png",
      quality: 0.92,
      width: "auto",
      height: "auto",
      outputFormat: "base64"
    };
    for (let key in settings) { _settings[key] = settings[key]; }
    return new Promise(function (resolve, reject) {
      let svgNode;

      // Create SVG Node if a plain string has been provided
      if (typeof (_settings.svg) == "string") {
        // Create a non-visible node to render the SVG string
        let SVGContainer = document.createElement("div");
        SVGContainer.style.display = "none";
        SVGContainer.innerHTML = _settings.svg;
        svgNode = SVGContainer.firstElementChild;
      } else {
        svgNode = _settings.svg;
      }

      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      let svgXml = new XMLSerializer().serializeToString(svgNode);
      let svgBase64 = "data:image/svg+xml;base64," + btoa(svgXml);

      const image = new Image();

      image.onload = function () {
        // Define the canvas intrinsic size
        canvas.width = 800;
        canvas.height = 400;

        // Render image in the canvas
        context.drawImage(image, 0, 0, 800, 400);

        if (_settings.outputFormat == "blob") {
          // Fullfil and Return the Blob image
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, _settings.mimetype, _settings.quality);
        } else {
          // Fullfil and Return the Base64 image
          resolve(canvas.toDataURL(_settings.mimetype, _settings.quality));
        }
      };
      // Load the SVG in Base64 to the image
      image.src = svgBase64;
    });
  }
  //Generate BarCode End
  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  //Email Section Start
  Email() {
    this.loader = false;
    const obj = {
      type: 2,
      docId: this.OrderPartForm.value.id,
      docNo: this.OrderPartForm.value.orderNumber,
      comId: Number(this.companyId),
      files: [{
        name: (this.OrderPartForm.value.statusId == 11 ? 'Quote-' : 'SO-') + this.OrderPartForm.value.orderNumber + '-' + this.OrderPartForm.value.customerPONumber.split('.')[2],
        base64: this.base64,
        type: 'pdf'
      }],
      from: localStorage.getItem("useremail"),
      to: this.BillToForm.value.email,
    }
    this.dialog.open(EmailTemplateComponent, {
      height: '712px',
      width: '750px',
      data: obj
    })
  }
  //Email Section End
  getBase64(value: any, orderNumber: string): any {
    this.loader = true;
    const docDefinition = this.CreatePdf(value, orderNumber, false);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data) => {
      this.base64 = 'data:application/pdf;base64,' + data;
    });
    setTimeout(() => { this.Email() }, 1000);
  }
  Share(value: any, orderNumber: string, IsHis: boolean, IsPrint: boolean, Status: string) {
    if (IsPrint) {
      let x = this.CreatePdf(value, orderNumber, IsHis);
      pdfMake.createPdf(x).print();
    } else {
      let x = this.CreatePdf(value, orderNumber, IsHis);
      pdfMake.createPdf(x).open();
    }
  }
}
