<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-4">
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Sales Order</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1 no_bg1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-2 d-flex align-items-center">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-md-2 trp" *ngIf="TCreateUpdate==1">
                                        <label class="customLabel">Change Order</label>
                                        <input class="p_relative custom_checkbox ml-2" [(ngModel)]="isSaleOrder"
                                            formControlName='isSaleOrder' type="checkbox"
                                            (change)="ChangeSaleOrder($event)">
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''"
                                            style="padding-right: 21px important;">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config_search"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-3 col-md-6 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Order
                                                No.</label>
                                            <input type="text" placeholder="Search Order No." class="customInput"
                                                [(ngModel)]="ProductSearch" formControlName="ProductSearch"
                                                autocomplete="off" required
                                                [ngClass]="((!ProductSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                (keyup.enter)="searchorder()">
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchorder()"></i>

                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-6 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center utility-page">

                                            <!-- mat multiple select -->
                                            <mat-form-field class="full-width ml-2" appearance="outline">
                                                <mat-label>Status</mat-label>
                                                <mat-select placeholder="Status" formControlName="IsActive" (selectionChange)="GetIsActiveStatus($event)">
                                                    <mat-option value="1">Active</mat-option>
                                                    <mat-option value="0">Inactive</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>Order Status</mat-label>
                                                <mat-select placeholder="Order Status" formControlName="StatusValue" (selectionChange)="GetStatus($event)" multiple>
                                                    <mat-option value="0">All</mat-option>
                                                    <ng-container *ngIf="IsActive=='1'">
                                                        <mat-option *ngFor="let item of activestatuslist" [value]="item.id">{{ item.name }}</mat-option>
                                                    </ng-container>
                                                    <ng-container *ngIf="IsActive=='0'">
                                                        <mat-option *ngFor="let item of inactivestatuslist" [value]="item.id">{{ item.name }}</mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                            <!--  -->

                                            <!-- <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Status</label>
                                            <select class="customInput" (change)="GetStatus($event)" name='statusId'
                                                id="statusvalues" [(ngModel)]="StatusValue"
                                                formControlName="StatusValue">
                                                <option value="0">All</option>
                                                <option *ngFor="let item of statuslist" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer" (click)="AllStatus()"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1 no_bg1" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3 mb-1">
                                Order List
                                <span id="expand-collapse">
                                    <i class="fa fa-angle-down cpointer" aria-hidden="true"
                                        (click)="ListDivShowHide()"></i>
                                </span>

                            </div>
                        </div>

                        <div class="col-md-7"></div>

                    </div>
                </div>

                <div class="card-desc table-responsive" [style.display]="ListDiv==true ? 'block' : 'none'">
                    
                    <table id="datatable" class="display table table-responsive clearfix" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th style="display: contents !important;"></th>
                                <th (click)="SortTableHeader('companyName')">Company<span id="companyName"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('customerPONumber')">Order No.<span id="customerPONumber"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('isEcommOrder')">eComm<span id="isEcommOrder"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('statusId')">Status<span id="statusId"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('customerFirstName')">Contact Name<span
                                        id="customerFirstName" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('customerName')">Customer Name<span id="customerName"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('partProductName')">Product Name<span id="partProductName"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('orderDate')">Order Date<span id="orderDate"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('orderType')">Part/Product<span id="orderType"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('partialship')">Partial Shipment<span id="partialship"
                                        class="sorting1"></span></th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="orderList?.length != 0">
                            <tr *ngFor="let data of orderList">
                                <td>{{data.companyName}}</td>
                                <td>{{data.customerPONumber==null?'-------':data.customerPONumber}}</td>
                                <td><span *ngIf="data.isEcommOrder==true">
                                    {{data.eOrderNo==null?'':data.eOrderNo}}
                                    </span></td>

                                <!-- <td><span *ngIf="data.isEcommOrder==true"><i class="fa fa-check text-success" aria-hidden="true"></i></span></td> -->
                                <td>{{data.statusId==1?'Open':data.statusId==2?'Sale Quote
                                    ':data.statusId==3?'Canceled':data.statusId==17?'Deleteled':data.statusId==5?'Completed':data.statusId==6 ?'Processing':data.statusId==7 ?'Shipped':data.statusId==8 ?'On Hold':data.statusId==9 ?'In Production':'' }}</td>
                                <td>{{data.customerFirstName}} {{data.customerLastName}}</td>
                                <td>{{data.customerName}}</td>
                                <td>{{data.partProductName}}</td>
                                <td>{{data.orderDate | date: "MMM-dd-yyyy"}}</td>
                                <td>{{data.orderType}}</td>
                                <td><span *ngIf="data.partialShipment==true" class="ml-2"><i
                                            class="fa fa-check text-success" aria-hidden="true"></i></span></td>
                                <td>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                        (click)="PreviewOrder(data.id,data.orderTypeId,'')" class="green-text"><i
                                            class="fas fa-edit"></i></a>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isCopy"
                                        (click)="CopyOrder(data.id, data.companyId)" class="green-text"><i
                                            class="fas fa-copy"></i></a>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                        (click)="deletePopup(data,content)" class="red-text"><i
                                            class="fas fa-trash-alt"></i></a>

                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="orderList==null">
                            <tr class="c1">
                                <td colspan="10" class="no-data-available">No data available in table</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Place Order
                </div>
                <div class="card-desc1 sales1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-3 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==undefined || value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select [config]="config"
                                            class="dropdown-company customInput-dropdown" [items]="userGroupObjects"
                                            [(value)]="value" (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Customer</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput custName" name='customerId'
                                            [(ngModel)]="selectedCustomerValue" formControlName='customerId'
                                            (change)="changeDDLCustomer($event)" required
                                            [ngClass]="((!form.controls.customerId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Customer</option>
                                            <option *ngFor="let item of customerList" [value]="item.id">
                                                {{item.customerName}}

                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12" *ngIf="isSaleOrder==true">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Sales Orders</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <select class="prodName customInput" name='GetSaleOrderId'
                                            [ngClass]="((GetSaleOrderId==null||GetSaleOrderId==undefined||GetSaleOrderId=='') && (validation)) ? 'validation_border': ''"
                                            [(ngModel)]="GetSaleOrderId" formControlName='GetSaleOrderId'
                                            (change)="ChangeorderData($event);">
                                            <option value=''>Select Sales Orders</option>
                                            <option *ngFor="let item of getSoList" [value]="item.customerPONumber">
                                                {{item.customerPONumber}}
                                            </option>
                                        </select>


                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="isSaleOrder==false">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Order Type</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <select class="customInput ordertype" id="ordertype"
                                            (change)="changetype1($event)">
                                            <option value="1" selected>Product</option>
                                            <option value="2">Part</option>
                                        </select>
                                    </div>

                                </div>

                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1" *ngIf="isSaleOrder==false">
                                <div class="row">

                                    <div class="col-lg-8 col-md-10">
                                        <label class="customLabel" for="PartialShipment">Partial Shipments</label>
                                        <input type="checkbox" id="PartialShipment" name="PartialShipment"
                                            [checked]="PartialShipment" formControlName="PartialShipment"
                                            [attr.disabled]="isDisabled" *ngIf="isDisabled==true"
                                            [(ngModel)]="PartialShipment" style="top:2px">

                                        <input type="checkbox" id="PartialShipment" name="PartialShipment"
                                            [checked]="PartialShipment" formControlName="PartialShipment"
                                            *ngIf="isDisabled==false" [(ngModel)]="PartialShipment" style="top:2px">
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row my-1">

                            <div class="col-lg-3 col-md-12" *ngIf="productDisabled==true">
                                <div class="row" id="productdropdown" *ngIf="isSaleOrder==false">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Product</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 mt-1">
                                        <select class="prodName customInput" name='productId'
                                            [(ngModel)]="selectedProductValue" formControlName='productId'
                                            (ngModelChange)="changeDDLProduct($event)"
                                            [ngClass]="((!form.controls.productId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select order-product</option>
                                            <option *ngFor="let item of productList"
                                                [value]="item.id + '-' + item.isVehicleRequired">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <!-- //Salman khan -->

                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1"
                                *ngIf="productDisabled==false && isSaleOrder==false">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Sales Quote Date</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="date" class="customInput" required [(ngModel)]="SalesQuoteDate"
                                            formControlName='SalesQuoteDate' min="{{PopupinDate}}"
                                            [ngClass]="((!form.controls.SalesQuoteDate.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"
                                *ngIf="productDisabled==true && isSaleOrder==false">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Sales Quote Date</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <input type="date" class="customInput" required [(ngModel)]="SalesQuoteDate"
                                            formControlName='SalesQuoteDate' min="{{PopupinDate}}"
                                            [ngClass]="((!form.controls.SalesQuoteDate.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1" *ngIf="productDisabled==true && isSaleOrder==false">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Sales Order Date</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="date" class="customInput"  [(ngModel)]="SalesOrderDate" 
                                            formControlName='SalesOrderDate' disabled  [ngModel] ="SalesOrderDate" min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1" *ngIf="productDisabled==false && isSaleOrder==false">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Sales Order Date</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="SalesOrderDate" 
                                            formControlName='SalesOrderDate' disabled  [ngModel] ="SalesOrderDate"  min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row my-1">
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Manufacturer</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" required name='manufacturerId'
                                            id="manufacturerId" [(ngModel)]="selectedManufacturerValue"
                                            formControlName='manufacturerId' (change)="onManufacturerChange($event)"
                                            [ngClass]="((!form.controls.manufacturerId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Manufacturer</option>
                                            <option *ngFor="let item of manufacturerList" [value]="item.id">
                                                {{item.vehicleManufacturer}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Model</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <select class="customInput pl-1px" required name='manufacturerModelId'
                                            id="manufacturerModelId" [(ngModel)]="selectedManufacturerModelValue"
                                            formControlName='manufacturerModelId'
                                            [ngClass]="((!form.controls.manufacturerModelId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Model</option>
                                            <option *ngFor="let item of manufacturerModelList" [value]="item.id">
                                                {{item.modelName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Year</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" [(ngModel)]="Year" class="customInput Cost"
                                            placeholder="Enter Year (YYYY)" required maxlength="4" minlength="4"
                                            autocomplete="off" formControlName="Year"
                                            [ngClass]="((!form.controls.Year.valid) && (validation)) ? 'validation_border': ''">


                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1" *ngIf="isVehicleRequired == true">
                                <div class="row">

                                    <div class="col-lg-8 col-md-10">
                                        <label class="customLabel" for="CustomerSupplied">Customer Supplied</label>
                                        <input type="checkbox" id="CustomerSupplied" name="CustomerSupplied"
                                            [checked]="CustomerSupplied" formControlName="CustomerSupplied"
                                            [(ngModel)]="CustomerSupplied" style="top:2px">
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="submit-from text-right">

                            <button type="button" class="btn blue-bg round-btn" (click)="PreviewDetails()"
                                id="reviewbtn" *ngIf="isSaleOrder==false"><i class="fas fa-eye" aria-hidden="true"></i>
                                Review
                                Order</button>


                            <button type="button" class="btn blue-bg round-btn" (click)="PreviewPartDetails()"
                                id="orderpart" style="display: none;" *ngIf="isSaleOrder==false"><i class="fas fa-plus"
                                    aria-hidden="true"></i>Order Part</button>

                            <button type="button" class="btn blue-bg round-btn" (click)="GetSaleItemId()" id="orderpart"
                                *ngIf="isSaleOrder==true"><i class="fas fa-plus" aria-hidden="true"></i>Change
                                Order</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

</section>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="DeleteOrder(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="salesorder">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Copy Sales Order</h4>
                <button type="button" class="close btn alert_modal" (click)="closeSalesorder()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row">
                        <div class="col-md-12">
                            <p>{{salesProp}}</p>
                        </div>
                      
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <input type="text" class="customInput cs" name="CreateOrder" [(ngModel)]="CreateOrder"
                                [ngClass]="(CreateOrder == '' && (copyValidation)) ? 'validation_border': ''"
                                autocomplete="off"  />

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <p style="color:red; font-size: 14px;">{{prop}}</p>
                        </div>
                    </div>


                </div>

            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="CopyCreateOrder(copyOrderId, copyCompanyId)">Create</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeSalesorder()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="confirmation">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeConfirmation()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>You would like to create {{CreateOrder}} new Sales Order(s)?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="Create(copyOrderId, copyCompanyId)">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeConfirmation()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="copy_success">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="closeCopySuccess()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Your sale order number has been copied</p>
                    <h3 class="cupon-pop" style="font-size:12px;">Your sale order number:
                        <span class="customLabel" *ngFor="let data of copySerialArr; let i = index; let isLast=last;">
                            <ng-container>
                                {{ data.customerPonumber}}{{data.customerPonumber != "" ? isLast
                                ? '' : ', ' : ''}}
                            </ng-container>
                        </span>
                       
                    </h3>
                    <!-- <p class="mb-0">
                        <button class="btn blue-bg round-btn">Export PDF&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                        <button class="btn blue-bg round-btn" >Send Email&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>

                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>
