<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>


    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Part Category</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="mr-1 customLabel" for="Company">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Part Category">Part Category
                                                Name</label>
                                            <input type="text" placeholder="Search Part Category Name"
                                                class="customInput" [(ngModel)]="userSearch"
                                                formControlName="userSearch" autocomplete="off" required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!userSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize header_error"
                                                style="">
                                                Enter User Name.</div> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchUser()"></i>
                                            <button class="btn blue-bg round-btn m-0" (click)="AllUser()">All
                                                Part Category</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3 mb-2">
                        Part Category List
                        <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Part Catagory Name</th>
                                <th>Description</th>
                                <th>Markup Margin</th>
                                <th>Ecommerce Website Name</th>
                                <th>eCommerce IsActive</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of catagoryDataList">
                                <td>{{data.companyName}}</td>
                                <td>{{data.name}}</td>
                                <td>{{data.description}}</td>
                                <td>{{data.markupMargin}}</td>
                                <td>{{data.websiteName}}</td>  
                    <td><span *ngIf="data.isActiveCat==true"><i class="fa fa-check text-success"  style="margin-left: 48px;" aria-hidden="true"></i></span></td>

                                <!-- <td>{{data.isActiveCat}}</td> -->
                                <td>
                                    <a class="green-text" *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                        (click)="toggleEdit(data)"><i class="fas fa-edit"></i></a>
                                    <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                            class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1">
                <div class="customHeading mt-3">
                    Add Part Category
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-4 col-md-12">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                            (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Part Category Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="category"
                                            placeholder="Enter Part Category" name="category" formControlName="category"
                                            autocomplete="off" required maxlength="50"
                                            [ngClass]="((!form.controls.category.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.category.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Category.</div> -->
                                        <div *ngIf="((form.controls.category.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            Part Category Already Exist.</div>
                                            <div *ngIf="((form.controls.category.valid) && (recordAlreadywordpresssiteExist))"
                                            class="text-danger input-error" style="font-size: 11px;">
                                            Record Already Exist on Wordpress Site.</div>
                                    </div>
                                </div>
                            </div>
                            <!-- ankit implemented -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Parent Categories</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone">
                                        <!-- <ngx-dropdown-treeview-select [config]="config1"
                                                placeholder="Select Parent"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="configCategoriesObj" [(value)]="isParent"
                                                (valueChange)="OnChangeParentCategory($event)">
                                        </ngx-dropdown-treeview-select> -->
                                        <!-- <ngx-dropdown-treeview [items]="configCategoriesObj" [config]="config1"
                                        class="dropdown-company customInput-dropdown"
                                        (selectedChange)="onParenntCatChange($event)" 
                                        (filterChange)="onParenntCatFilterChange($event)">
                                        </ngx-dropdown-treeview> -->

                                        <mat-form-field>
                                            <mat-select [(value)]="isParent" (valueChange)="OnChangeParentCategory($event)" disableOptionCentering>
                                              <ng-container
                                                *ngTemplateOutlet="
                                                  recursiveOptions;
                                                  context: { $implicit: programs1, depth: 1 }
                                                "
                                              >
                                              </ng-container>
                                    
                                              <ng-template #recursiveOptions let-list let-depth="depth">
                                                <ng-container *ngFor="let program of list">
                                                  <mat-option
                                                    [value]="program.value"
                                                    [ngStyle]="{
                                                      'padding-left.px': depth <= 1 ? null : 16 * depth
                                                    }"
                                                  >
                                                    {{ program.text }}
                                                  </mat-option>
                                    
                                                  <ng-container
                                                    *ngIf="!!program.children && program.children.length > 0"
                                                  >
                                                    <ng-container
                                                      *ngTemplateOutlet="
                                                        recursiveOptions;
                                                        context: { $implicit: program.children, depth: depth + 1 }
                                                      "
                                                    >
                                                    </ng-container>
                                                  </ng-container>
                                                </ng-container>
                                              </ng-template>
                                            </mat-select>
                                          </mat-form-field>
    
                                    </div>
                                </div>
                            </div>

                            

                        </div>
                        <div class="row  mt-md-1">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-2 col-md-4 trp">
                                        <label class="customLabel padding-r">Description</label>
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <textarea rows="3" cols="5" type="text"
                                            class="customInput customerTextarea customerTextarea2" placeholder="Enter Description"
                                            [(ngModel)]="description" name="description" formControlName='description'
                                            autocomplete="off"></textarea>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            
                            <div class="col-lg-4 col-md-4">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Not for Resale</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 borderNone">
                                        <input type="checkbox" class="customInput" formControlName="notResale"
                                            [(ngModel)]="notResale">
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-3">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-5 trp required">
                                        <label class="customLabel">Category Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-7">
                                        <select class="customInput pl-1px" name='categoryType'
                                            formControlName='categoryType' [(ngModel)]="categoryType"  >
                                            <option value=''>Select Category Type</option>
                                            <option *ngFor="let item of CategortTypeList" [value]="item.categoryType">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Markup Margin</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="MarkupMargin" (keypress)="keyPressNumbers($event)"
                                            placeholder="Enter Markup Margin" name="MarkupMargin" formControlName="MarkupMargin"
                                            autocomplete="off" required
                                            [ngClass]="((!form.controls.MarkupMargin.valid) && (validation)) ? 'validation_border': ''">
                                       
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            
                            <div class="col-lg-4 col-md-4">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">eCommerce IsActive</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 borderNone">
                                        <input type="checkbox" class="customInput" formControlName="isActiveCat"
                                            [(ngModel)]="isActiveCat" (change)="checkValue1($event)">
                                    </div>
                                    <div class="col-lg-6 col-md-2" *ngIf="IsEcommerceShow">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-5 trp">
                                                <label class="customLabel">eCommerce</label>
                                            </div>
                                            <div class="col-lg-7 col-md-5 borderNone">
                                                <input type="checkbox" class="customInput" formControlName="eCommerce"
                                                    [(ngModel)]="eCommerce" (change)="checkValue($event)" [attr.disabled]="IsDisable">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                       

                            <div class="col-lg-4 col-md-3" *ngIf="IsEcommerce">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-5 trp required">
                                        <label class="customLabel">eCommerce Website</label>
                                    </div>
                                    <div class="col-lg-8 col-md-7">
                                        <select class="customInput pl-1px" name='websiteName'
                                            formControlName='websiteName' [(ngModel)]="websiteName"  
                                         [ngClass]="((!form.controls.websiteName.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Website Name</option>
                                            <option *ngFor="let item of ecommerceObjects" [value]="item.websiteName">
                                                {{item.websiteName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-3" *ngIf="IsEcommerce">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">eCommerce Menu</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" name='isParent'
                                            formControlName='isParent' [(ngModel)]="isParent"
                                            [ngClass]="((!form.controls.isParent.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Parent</option>
                                            <option *ngFor="let item of ecommerceList" [value]="item.wordpressId">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-2" *ngIf="IsEcommerce">
                            <div class="row">
                                <div class="col-lg-2 col-md-2 trp">
                                    <label class="customLabel">Upload</label>
                                </div>
                                <div class="col-lg-4 col-md-10 setImage">
                                    <input type="file" class=" customInput" #Image accept="
                                        image/*"  (change)="ChngSettingImage($event.target.files)"
                                        id="file"
                                        [ngClass]="(imageValidation) ? 'validation_border': ''">
                                </div>
                                <div class="col-lg-1 col-md-12">
                                    <button type="button" class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0"
                                        (click)="AddMultiplePartImage();">+ Add image</button>
                                </div>
                                
                              
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-2" *ngIf="IsEcommerce">
                            <div class="row">
                                <div class="col-lg-2 col-md-2 trp">
                              
                                </div>
                                <div class="col-md-2 mt-2" *ngFor="let image of MultipleImage;let i = index;">
                                    <div class="particular_img" *ngIf="showImage">
                                        <img
                                            [src]="image.imageUrl">
                                        <div class="view_img" (click)="viewImage(image.imageUrl)">
                                            <i class="fa fa-eye" aria-hidden="true" data-toggle="modal"></i>
                                        </div>
                                        <button type="button" class="close" (click)="DeleteImageModalpopup()"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                     

                      
                        <div class="submit-from text-right mt-1 p-0">
                            <button class="btn blue-bg round-btn"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                (click)="addNewCategory()"><i class="fas fa-save"></i> {{buttonValue}}
                            </button>
                            <button class="btn darkred-bg round-btn" (click)="resetCategory()"
                                *ngIf="clearbutton==true"><i class="fas fa-redo"></i> Clear </button>
                            <button class="btn darkred-bg round-btn" (click)="CategoryBack()"
                                *ngIf="clearbutton==false"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1 modal-para">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="deleteUserCategory(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="wrong_msg1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem1()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Wrong Ecommerce API Credentials</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="wrong_msg2">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem2()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Invalid Website Url</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem2()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_model">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteImageModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>Do you want to delete?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                (click)="deleteImage();">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="deleteImageModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="viewImage">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header info-popup-header view-img-modal">
                <button type="button" class="close btn alert_modal" data-dismiss="modal"
                    (click)="HideImage();">&times;</button>
            </div>
            <div class="modal-body">
                <img [src]="viewimagesshow" class="full-doc">
            </div>
        </div>
    </div>
</div>