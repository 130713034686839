import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import Swal from 'sweetalert2';
import { GlobalChangeService } from '../services/global-change.service';
import { CompanyService } from '../services/company/company.service';



@Component({
  selector: 'app-customercontact-type',
  templateUrl: './customercontact-type.component.html',
  styleUrls: ['./customercontact-type.component.css']
})
export class CustomercontactTypeComponent implements OnInit {

  contactTypeDataList: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  contactType: string;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  companyList: any;
  value = 0;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;
  items_search: any;


  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering:true
      };

      
    }
    this.form = new FormGroup({
      'contactType': new FormControl()

    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));

    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1042);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })
  }

  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }

  getAllContactType(companyId, SearchItem) {
    
    this.sharedService.GetCustomerContactTypeList(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.contactTypeDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;

      });
  }

  addNewCustomerContactType() {
    
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.contactType.status === this.constants.Invalid || this.value == 0
    ) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['ContactTypeName'] = this.contactType;
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.updateCustomerContactType(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                this.contactType = '';
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.resetContactType();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;

              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      this.sharedService.addCustomerContactType(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                this.validation = false;
                this.resetContactType();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                this.modalError(this.constants.RecordAlreadyExist);
                break;
              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }


  deleteCustomerContactType(item: any) {
    this.sharedService.deleteCustomerContactType(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getAllContactType(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 400:
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  toggleEdit(item: any) {

    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.contactType = item.name;
    this.id = item.id;
    this.clearbutton = false;
    this.value = item.companyId;
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  ContactTypeBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetContactType();
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
      });
  }

  onValueChange(value: any): void {
    this.value = value;

  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetContactType();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchAlertType() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllContactType(this.value_search, this.userSearch);
  }

  AllContactType() {
 
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllContactType(this.value_search, '');
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
     
      data => {
        
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetContactType() {
    this.contactType = '';
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}



