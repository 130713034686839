<mat-card class="mat-card-custom p-0 h-100" [formGroup]="formGrp">
    <mat-card-header class="align-items-center gap-3 change-mat-header">
        <div class="main-heading">Vendor</div>
        <div class="form-fields-custom">
            <mat-form-field appearance="outline" class="height-change">
                <input matInput placeholder="Search Vendors" [matAutocomplete]="auto"
                    (input)="onVendorInput($event.target.value)"
                    [readonly]="formGrp.value.id>0 && (formGrp.value.statusOld !=10 && formGrp.value.statusOld !=19 && formGrp.value.statusOld !=20)">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of filteredVendors"
                        [value]="item.vendorName" (click)="getVendor(item.id)">
                        {{item.vendorName}}
                    </mat-option>
                </mat-autocomplete>
                <span class="material-icons">
                    search
                </span>
            </mat-form-field>
        </div>
        <div class="new-btn">
            <button mat-stroked-button (click)="addVendor()" [disabled]="formGrp.value.id>0 && (formGrp.value.statusOld !=10 && formGrp.value.statusOld !=19 && formGrp.value.statusOld !=20)">
                <mat-icon class="add-btn">add</mat-icon>
                New</button>
        </div>                                          
    </mat-card-header>
    <mat-card-content class="mat-card-pd mt-2">
        <div class="product-name mb-2">
            <span> {{formGrp.value.vendor.vendorName}}</span>
        </div>
        <div class="address">
            <span>{{formGrp.value.vendor.add1}} {{formGrp.value.vendor.add2}}</span>
            <span>{{formGrp.value.vendor.city}} {{GetStateName(formGrp.value.vendor.state)}} {{GetCountryName(formGrp.value.vendor.country)}} {{formGrp.value.vendor.zip}}</span>
            <span>{{formGrp.value.vendor.contactName}} {{formGrp.value.vendor.phone}}</span>
            <span *ngIf="formGrp.value.vendor.email != undefined && formGrp.value.vendor.email.length>0">Sales Desk: {{formGrp.value.vendor.email}}</span>
        </div>
    </mat-card-content>
</mat-card>
