<section class="content-section pt-2 ws-nobreak1">
    <div class="loading" *ngIf="loader" style="z-index:1082">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Individual Parts</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">

                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row my-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput pl-1px"
                                                (change)="ChangeTCreateUpdate($event.target.value)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <select class="customInput pl-1px"
                                                (change)="ChangefieldToSearch($event.target.value)"
                                                [(ngModel)]="fieldToSearch" id="fieldToSearch"
                                                formControlName="fieldToSearch">
                                                <option value='1'>Search Keywords</option>
                                                <option value='2'>List or Vendor Part Name</option>
                                                <option value='3'>List or Vendor Part #</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="customInput" [(ngModel)]="userSearch"
                                                placeholder="{{fieldToSearchPlaceHolder}}" formControlName="userSearch"
                                                autocomplete="off" required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                (keyup.enter)="searchUser()">
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchUser()"></i>
                                        </div>



                                    </div>
                                    <div class="col-lg-1 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <button type="button" class="btn blue-bg round-btn m-0"
                                            (click)="AllUser('ok')">All
                                            Parts</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading">
                        Part List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>

                <!------------------------------------- company list table ------------------------------>
                <div class="card-desc1 mt-1 divScroll" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="display nowrap table clearfix partlisttable" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th class="coloumn-hide"></th>
                                <th (click)="SortTableHeader('companyName')">Company<span id="companyName"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('name')">List Part Name<span id="name"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('partNo')">List Part#<span id="partNo"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorName')">Vendor Name<span id="vendorName"
                                        class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorPartName')">Vendor Part Name<span
                                        id="vendorPartName" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorPartNumber')">Vendor Part#<span
                                        id="vendorPartNumber" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('cost')">Part Price<span id="cost" class="sorting1"></span>
                                </th>
                                <th class="width-75" (click)="SortTableHeader('hours')">Labor Hrs<span id="hours"
                                        class="sorting1"></span></th>
                                <th>Is Active</th>
                                <th>Non-Inventory</th>

                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="datalist?.length != 0">
                            <tr *ngFor="let data of datalist">
                                <td class="coloumn-hide">{{data.id}}</td>
                                <td>{{data.companyName}}</td>
                                <td title={{data.name}}>{{data.name.length <= 25 ? data.name : SortString(data.name)}}
                                        </td>
                                <td title={{data.partNo}}>
                                    {{data.partNo.length <= 25 ? data.partNo : SortString(data.partNo)}} </td>
                                <td title={{data.vendorName}}>
                                    {{data.vendorName == null ? '' : (data.vendorName.length <= 25 ? data.vendorName :
                                        SortString(data.vendorName))}} <span class="tooltipWrap tooltip-icon"
                                        style="display:inline;">
                                        <img src="/assets/image/AdVendor.png" class="picimage red-tooltip"
                                            *ngIf="data.vendorCount > 0" data-bs-toggle="tooltip"
                                            title="This part has Additional Vendors associated with it">
                                        </span>
                                </td>
                                <td title={{data.vendorPartName}}>
                                    {{data.vendorPartName == null ? '' : (data.vendorPartName.length <= 25 ?
                                        data.vendorPartName : SortString(data.vendorPartName))}} </td>
                                <td title={{data.vendorPartNumber}}>
                                    {{data.vendorPartNumber == null ? '' :(data.vendorPartNumber.length <= 25 ?
                                        data.vendorPartNumber : SortString(data.vendorPartNumber))}} </td>
                                <td>{{data.cost|currency}}</td>
                                <td>{{data.hours|number: '1.2-2'}}</td>
                                <td>{{data.isNotActive == 1 ? 'Not Active' : data.isNotActive == 0 ? 'Active' : ''}}
                                </td>
                                <td>
                                    <span *ngIf="data.nonInventory==true"><i class="fa fa-check text-success"
                                            aria-hidden="true" style="margin-left: 30px;"></i></span>
                                </td>
                                <td>
                                    <a class="green-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                            class="fas fa-edit" (click)="getPartbyId(data)"></i></a>
                                    <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                            class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="datalist==null">
                            <tr class="c1">
                                <td colspan="9" class="no-data-available">No data available in table</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <div class="white-box1">
                <form [formGroup]="form">
                    <div class="customHeading py-1 flex">
                        Individual Parts Details
                        <span class="mds">
                            <input type="checkbox" id="vehicle1" name="vehicle1" [checked]="NonInventory"
                                (change)="changeNonInventory($event)" formControlName="NonInventory"
                                [(ngModel)]="NonInventory" style="top:2px">
                            <label for="vehicle1" class="customLabel flabel">Non-Inventory</label>

                        </span>
                        <span class="mds">
                            <input type="checkbox" (change)="changeActive($event)" formControlName="NotActive"
                                style="top:2px" [(ngModel)]="NotActive" id="notActive">
                            <label for="notActive" class="customLabel flabel">Not Active</label>

                        </span>

                    </div>
                    <div class="card-desc1">

                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Primary Vendor </label>
                                        <div class="popover__wrapper1" *ngIf="AdditionalDiscountLabel else others">
                                            <div class="popover__title1">
                                                <img src="/assets/image/discount.png" class="image-size">
                                            </div>
                                            <div class="popover__content1">

                                                <p class="popover__message1">
                                                    {{AVendor}} has an additional discount of {{ADiscount}}%. This
                                                    will be reflected on Unit Cost when creating POs
                                                </p>

                                            </div>
                                        </div>


                                    </div>

                                    <ng-template #others>
                                        <div class="col-lg-1 col-md-1"></div>

                                    </ng-template>



                                    <div class="col-lg-8 col-md-9" style="display: flex; align-items: center;">


                                        <select class="customInput pl-1px" name='vendorId' id="VendorC"
                                            [(ngModel)]="selectedVendorValue" formControlName='vendorId'
                                            (change)="onValueChange($event)"
                                            [ngClass]="((!form.controls.vendorId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of vendorlist"
                                                [value]="item.id + '-' +item.discount"
                                                [ngStyle]="{'color': item.color}">
                                                {{item.vendorName + ""}}

                                            </option>

                                        </select>

                                        <div class="popover__wrapper">

                                            <button type="button"
                                                class="btn round-btn upload_btn add_img mt-lg-0 popover__title"
                                                (click)="openVendorModal();">+ Add Vendors

                                            </button>

                                            <div class="popover__content">
                                                <div *ngIf="AddvendorList.length != 0">
                                                    <div *ngIf="ActiveCouting == true">
                                                        <ng-container *ngFor="let data of AddvendorList">
                                                            <p class="popover__message" *ngIf="data.IsActive == true">

                                                                {{data.vendorName}}</p>

                                                        </ng-container>


                                                    </div>
                                                </div>

                                                <div *ngIf="ActiveCouting == false">
                                                    <p class="popover__message">No Additional Vendors found</p>
                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel"> Part Category</label>
                                    </div>
                                    <div class="col-lg-6 utility-page">
                                        <!-- <ng-multiselect-dropdown-angular7 [data]="catdropdownList"
                                            placeholder="Select Part Category" [(ngModel)]="selectedItems"
                                            formControlName="selectedItems" [settings]="dropdownSettings"
                                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelectAll)="onDeSelectAll($event)"
                                            [ngClass]="((!form.controls.selectedItems.valid) && (validation)) ? 'validation_border': ''"
                                            (onDeSelect)="onItemDeSelect($event)">
                                        </ng-multiselect-dropdown-angular7> -->



                                        <mat-form-field class="form-field w-100" appearance="outline">
                                            <mat-label>Select Part Category</mat-label>
                                            <mat-select [(value)]="selectedMulti"
                                                (selectionChange)="onParentCatChange($event)" multiple
                                                disableOptionCentering>
                                                <ng-container *ngTemplateOutlet="
                                                  recursiveOptionsMulti;
                                                  context: { $implicit: programs, depth: 1 } ">
                                                </ng-container>

                                                <ng-template #recursiveOptionsMulti let-list let-depth="depth">
                                                    <ng-container *ngFor="let program of list">
                                                        <mat-option [value]="program.value" [ngStyle]="{
                                                      'padding-left.px': depth <= 1 ? null : 16 * depth
                                                    }">
                                                            {{ program.text }}
                                                        </mat-option>

                                                        <ng-container
                                                            *ngIf="!!program.children && program.children.length > 0">
                                                            <ng-container *ngTemplateOutlet="
                                                        recursiveOptionsMulti;
                                                        context: { $implicit: program.children, depth: depth + 1 }
                                                      ">
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-template>
                                            </mat-select>
                                        </mat-form-field>



                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Wordpress ID</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput text-success" [(ngModel)]="Pwordpressid"
                                            formControlName='Pwordpressid' readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">eCommerce Status<span
                                                class="tooltipWrap tooltip-icon"
                                                style="display:inline; padding-left: 7px !important"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltips">
                                                <div>
                                                    <h3 class="text-light text-center">What do these status mean?</h3>
                                                    <ul class="text-light">
                                                        <li class="text-light">Not Exported - The Part exists only in
                                                            the ERP system and has not been exported to WordPress.</li>
                                                        <li class="text-light">Draft - The page is viewable only as a
                                                            Preview to WordPress Users with the Administrator or Editor
                                                            role.</li>
                                                        <li class="text-light">Pending Review - The page is viewable
                                                            only to WordPress Users with the Administrator or Editor
                                                            role and is ready to be reviewed and published.</li>
                                                        <li class="text-light">Privately Published - The page is
                                                            viewable only to WordPress Users with the Administrator or
                                                            Editor role and the URL permalink is assigned.</li>
                                                        <li class="text-light">Published - The page is viewable to all
                                                            users.</li>
                                                    </ul>
                                                </div>
                                            </span></label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px"
                                            (change)="onChgeCommerceStatus($event.target.value)"
                                            [(ngModel)]="ECommerceStatus" id="ECommerceStatus"
                                            formControlName="ECommerceStatus">
                                            <option value=''>Not Exported</option>
                                            <option value='draft'>Draft</option>
                                            <option value='pending'>Pending Review</option>
                                            <option value='private'>Privately Published</option>
                                            <option value='publish'>Published</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="row mt-md-1">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Vendor Part Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="VendorPartNumber"
                                            placeholder="Enter Vendor Part Number" formControlName='VendorPartNumber'
                                            autocomplete="off" required maxlength="100"
                                            (input)="onEnterVendorPartNumber($event)">
                                        <!-- <div *ngIf="((!form.controls.partNo.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter  Customer Part Number.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel">Vendor Part Name</label>
                                    </div>
                                    <div class="col-lg-10 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="VendorPartName"
                                            placeholder="Enter Vendor Part Name" formControlName='VendorPartName'
                                            autocomplete="off" (input)="onEnterVendorPartName($event)">
                                        <div *ngIf="((!form.controls.name.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Part Name.</div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">List Part Name</label><sup
                                            style="font-size: small">§</sup>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="name"
                                            placeholder="Enter List Part Name" formControlName='name' autocomplete="off"
                                            required
                                            [ngClass]="((!form.controls.name.valid) && (validation)) ? 'validation_border': ''">
                                        <div *ngIf="((!form.controls.name.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Part Name.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp required">
                                        <label class="customLabel">List Part Number</label>
                                    </div>
                                    <div class="col-lg-10 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="partNo"
                                            placeholder="Enter List Part Number" formControlName='partNo'
                                            autocomplete="off" required
                                            [ngClass]="((!form.controls.partNo.valid) && (validation)) ? 'validation_border': ''">

                                        <div *ngIf="((form.controls.partNo.valid) && (recordPartNoExist))"
                                            class="text-danger input-error">
                                            List Part Number Already Exist.</div>
                                        <div *ngIf="((!form.controls.partNo.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Customer Part Number.</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3">
                                        <label class="customLabel">Sync with WP</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput" (change)="getWordPress($event)">
                                            <option value="1" *ngIf="!onlyOption" [selected]="controlWPData1 == true">
                                                Export §
                                                fields to WordPress
                                            </option>
                                            <option value="1" *ngIf="onlyOption" [selected]="controlWPData1 == true">
                                                Export § fields
                                                to WordPress
                                            </option>
                                            <option value="2" [selected]="controlWPData == true">Do not Sync § fields
                                            </option>
                                            <option value="3" [disabled]="!isTrue">Import § fields from WordPress
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Inventory Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput"
                                            [ngClass]="((!form.controls.InvType.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            formControlName='InvType' [(ngModel)]="InvType">
                                            <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Inventory Tracking</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" formControlName="partTracking"
                                            [(ngModel)]="partTracking" (change)="changeTracking($event)"
                                            [ngClass]="((!form.controls.partTracking.valid) && (InvValidation)) ? 'validation_border': ''">
                                            <option *ngFor="let item of PartTrackingList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-5 mt-3">
                                <label class="customLabel">Short
                                    Description</label><sup>§</sup>
                                <editor apiKey="{{GetAPIKey()}}" id="shortDescription" [(ngModel)]="ShortDescription"
                                    name="ShortDescription" (onChange)="getStrDesc($event)"
                                    formControlName='ShortDescription' [init]="{   
                                      height: 350,
                                      width: 580,
                                      menubar: false,
                                      plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                      toolbar:
                                        'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                    }"></editor>
                            </div>
                            <div class="col-lg-6 mt-3">
                                <label class="customLabel">Detail
                                    Description</label><sup>§</sup>
                                <editor apiKey="{{GetAPIKey()}}" [(ngModel)]="description" name="description"
                                    formControlName='description' (onChange)="getDtlDesc($event)" id="detailDescription"
                                    [init]="{
                                  height: 350,
                                  width: 780,
                                  menubar: false,
                                  plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                      toolbar:
                                        'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                }"></editor>
                            </div>
                        </div>

                        <div class="row mt-md-1">
                            <div class="col-lg-2 col-md-4 trp">
                                <label class="customLabel padding-r">Installation Notes</label>
                            </div>
                            <div class="col-lg-10 col-md-8">
                                <input type="text" class="customInput customDescription-parts"
                                    [(ngModel)]="installationNotes" placeholder="Enter Installation Notes"
                                    formControlName="installationNotes" autocomplete="off" maxlength="250">
                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-2 col-md-4 trp">
                                <label class="customLabel padding-r"> Search Keywords</label>
                            </div>
                            <div class="col-lg-10 col-md-8">
                                <input type="text" class="customInput customDescription-parts" [(ngModel)]="search"
                                    placeholder="Enter Search Keywords" formControlName="search" autocomplete="off"
                                    maxlength="250">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Cost and Pricing
                                </div>
                            </div>
                        </div>


                        <div class="row mt-md-1">
                            <div class="col-lg-6 col-md-12 mt-lg-0 mt-md-1">
                                <!-- new start -->
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel">Quantity Discounts</label>
                                    </div>

                                    <div class="col-lg-10 col-md-9">
                                        <div class="col-lg-12 col-md-9">

                                            <table class="table-bordered" style="margin-top: 0;">
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="trp required">
                                                            <label class="customLabel"> Vendor Part Cost</label>
                                                        </div>
                                                    </td>
                                                    <td colspan="2">
                                                        <input type="text" class="customInput Cost"
                                                            [(ngModel)]="VendorPartCost"
                                                            placeholder="$ Enter Vendor Part Cost"
                                                            formControlName='VendorPartCost' (blur)="VendorCurrency()"
                                                            autocomplete="off" maxlength="10"
                                                            style="padding-left: 6px;">
                                                    </td>
                                                    <td colspan="4">&nbsp;</td>
                                                </tr>
                                                <tr class="text-start tbl-head">
                                                    <td colspan="7">
                                                        <label class="customLabel">Vendor Discount</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div (click)="addvRow1()">
                                                            <i class="fas fa-plus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td><label class="customLabel">MinQty</label></td>
                                                    <td><label class="customLabel">MaxQty</label></td>
                                                    <td><label class="customLabel">Discount Type</label></td>
                                                    <td><label class="customLabel">Discount Value</label></td>
                                                    <td><label class="customLabel">Price($)</label></td>
                                                    <td><label class="customLabel">Description</label></td>
                                                </tr>

                                                <ng-container *ngFor="let dynamic of dynamicArray; let i = index;">
                                                    <tr *ngIf="dynamic.isActive == true">
                                                        <td>
                                                            <div (click)="deleteRow1(i)">
                                                                <i class="fas fa-minus-circle green-text"></i>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.minQty}}" autocomplete="off"
                                                                value="{{dynamic.minQty}}"
                                                                (change)="VendorCalc3(i, $event.target.value)"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.Qty}}" autocomplete="off"
                                                                value="{{dynamic.Qty}}"
                                                                (change)="VendorCalc1(i, $event.target.value)"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <select name="{{dynamic.DiscountType}}"
                                                                value="{{dynamic.DiscountType}}" autocomplete="off"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput"
                                                                (change)='onVDiscountTypeChange(i, $event.target.value)'>
                                                                <option value="">Select</option>
                                                                <option value="percentage">Percent Discount</option>
                                                                <option value="flat">Fixed Amount Discount</option>
                                                                <option value="fixed_price">Fixed Price for Item
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.DiscountPrice}}"
                                                                value="{{dynamic.DiscountPrice}}"
                                                                (change)="VendorCalc(i, $event.target.value)"
                                                                autocomplete="off"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td class="d-flex">
                                                            <input name="{{dynamic.cost}}" class="customInput Costs"
                                                                autocomplete="off"
                                                                (change)="vpriceChangedCust(i, $event.target.value)"
                                                                type="text" [disabled]="dynamic.isReadOnly == false"
                                                                value="{{dynamic.cost}}" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.description}}" class="customInput"
                                                                autocomplete="off"
                                                                (change)="VendorDescription(i, $event.target.value)"
                                                                type="text" value="{{dynamic.description}}" />
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                                <!-- new end -->

                                <!-- old start -->
                                <!-- <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel">Quantity Discounts</label>
                                    </div>

                                    <div class="col-lg-10 col-md-9">
                                        <div class="col-lg-12 col-md-9">
                                            <table class="table-bordered" style="margin-top: 0;">
                                                <tr>
                                                    <td colspan="2"><label class="customLabel">Vendor Part Cost</label>
                                                    </td>
                                                    <td colspan="2">
                                                        <input type="text" class="customInput Cost"
                                                            [(ngModel)]="VendorPartCost"
                                                            placeholder="$ Enter Vendor Part Cost"
                                                            formControlName='VendorPartCost' (blur)="VendorCurrency()"
                                                            autocomplete="off" maxlength="10"
                                                            style="padding-left: 6px;">
                                                    </td>
                                                </tr>
                                                <tr class="text-start tbl-head">
                                                    <td colspan="4">
                                                        <label class="customLabel">Vendor Discount</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div (click)="addRow()">
                                                            <i class="fas fa-plus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td><label class="customLabel">Quantity</label></td>
                                                    <td><label class="customLabel">Discount</label></td>
                                                    <td><label class="customLabel">Price($)</label></td>
                                                </tr>

                                                <ng-container *ngFor="let dynamic of dynamicArray; let i = index;">
                                                    <tr *ngIf="dynamic.isActive == true">
                                                        <td>
                                                            <div (click)="deleteRow(i)">
                                                                <i class="fas fa-minus-circle green-text"></i>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.Qty}}" value="{{dynamic.Qty}}"
                                                                autocomplete="off" class="customInput Cost"
                                                                (change)="costcalc1(i, $event.target.value)"
                                                                type="text" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.DiscountPrice}}" autocomplete="off"
                                                                value="{{dynamic.DiscountPrice}}"
                                                                (change)="costcalc(i, $event.target.value)"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.cost}}" style="width: 100px;"
                                                                value="{{dynamic.cost}}" autocomplete="off"
                                                                class="customInput Costs"
                                                                (change)="priceChanged(i, $event.target.value)"
                                                                [disabled]="dynamic.isReadOnly == true" type="text" />
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                    </div>

                                </div> -->
                                <!-- old end -->
                            </div>
                            <div class="col-lg-6 col-md-12 mt-lg-0 mt-md-1">
                                <div class="col-lg-2 col-md-3 trp">
                                    <label class="customLabel"></label>
                                </div>

                                <div class="col-lg-6 col-md-8 p-0">
                                    <div class="col-lg-12 col-md-9">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-2 p-0 required" style="display: flex;">
                                                <label class="customLabel"> Auto Markup<span
                                                        class="tooltipWrap tooltip-icon"
                                                        style="display:inline; padding-left: 7px !important"><i
                                                            class="fa fa-info-circle"></i></span>
                                                    <span class="tooltips">
                                                        When selected, the Part Price will automatically be
                                                        calculated
                                                        based on the Vendor Cost and the Markup Margin
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="col-lg-8 col-md-7">
                                                <div style="display: flex">
                                                    <input type="checkbox" class="customInput"
                                                        formControlName="MarkupCheck" [(ngModel)]="MarkupCheck"
                                                        (change)="changeMargin($event)">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-2 p-0 required" style="display: flex;">
                                                <label class="customLabel"> Markup
                                                    Margin
                                                </label>
                                            </div>
                                            <div class="col-lg-8 col-md-7">
                                                <div style="display: flex">
                                                    <input type="text" class="customInput" [(ngModel)]="MarkupMargin"
                                                        placeholder="Enter Markup Margin" name="MarkupMargin"
                                                        formControlName="MarkupMargin" autocomplete="off" required
                                                        [readonly]="IsMarginDisabled"
                                                        [ngClass]="this.MarkupCheck == true ? ((!form.controls.MarkupMargin.valid) && (validation)) ? 'validation_border': '' : ''"
                                                        (blur)="UpdatePrice()" (keypress)="keyPressNumbers($event)"
                                                        maxlength="9">
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-md-1">
                            <div class="col-lg-6 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel"></label>
                                    </div>

                                    <div class="col-lg-10 col-md-9">
                                        <div class="col-lg-12 col-md-9">

                                            <table class="table-bordered" style="margin-top: 0;">
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="trp required">
                                                            <label class="customLabel"> List Part Price</label>
                                                        </div>
                                                    </td>
                                                    <td colspan="2">
                                                        <input type="text" class="customInput Cost" [(ngModel)]="cost"
                                                            placeholder="$ Enter Part Price" formControlName='cost'
                                                            (change)="formatCurrency()" (click)="DisablePrice()"
                                                            autocomplete="off" required maxlength="12"
                                                            [ngClass]="((!form.controls.cost.valid) && (validation)) ? 'validation_border': ''"
                                                            [readonly]="IsPricePart" style="padding-left: 6px;">
                                                    </td>
                                                    <td colspan="4">&nbsp;</td>
                                                </tr>
                                                <tr class="text-start tbl-head">
                                                    <td colspan="7">
                                                        <label class="customLabel">Volume Pricing Discounts</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div (click)="addRow1()">
                                                            <i class="fas fa-plus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td><label class="customLabel">MinQty</label></td>
                                                    <td><label class="customLabel">MaxQty</label></td>
                                                    <td><label class="customLabel">Discount Type</label></td>
                                                    <td><label class="customLabel">Discount Value</label></td>
                                                    <td><label class="customLabel">Price($)</label></td>
                                                    <td><label class="customLabel">Description</label></td>
                                                </tr>

                                                <ng-container
                                                    *ngFor="let dynamic of dynamicCustomerDiscount; let i = index;">
                                                    <tr *ngIf="dynamic.isActive == true">
                                                        <td>
                                                            <div (click)="deleteRow1(i)">
                                                                <i class="fas fa-minus-circle green-text"></i>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.minQty}}" autocomplete="off"
                                                                value="{{dynamic.minQty}}"
                                                                (change)="CustomerCalc3(i, $event.target.value)"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.Qty}}" autocomplete="off"
                                                                value="{{dynamic.Qty}}"
                                                                (change)="CustomerCalc1(i, $event.target.value)"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <select name="{{dynamic.DiscountType}}"
                                                                value="{{dynamic.DiscountType}}" autocomplete="off"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput"
                                                                (change)='onDiscountTypeChange(i, $event.target.value)'>
                                                                <option value="">Select</option>
                                                                <option value="percentage">Percent Discount</option>
                                                                <option value="flat">Fixed Amount Discount</option>
                                                                <option value="fixed_price">Fixed Price for Item
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.DiscountPrice}}"
                                                                value="{{dynamic.DiscountPrice}}"
                                                                (change)="CustomerCalc(i, $event.target.value)"
                                                                autocomplete="off"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td class="d-flex">
                                                            <input name="{{dynamic.cost}}" class="customInput Costs"
                                                                autocomplete="off"
                                                                (change)="priceChangedCust(i, $event.target.value)"
                                                                type="text" [disabled]="dynamic.isReadOnly == false"
                                                                value="{{dynamic.cost}}" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.description}}" class="customInput"
                                                                autocomplete="off"
                                                                (change)="descriptionChangedCust(i, $event.target.value)"
                                                                type="text" value="{{dynamic.description}}" />
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel"></label>
                                    </div>

                                    <div class="col-lg-10 col-md-9">
                                        <div class="col-lg-12 col-md-9">

                                            <table class="table-bordered" style="margin-top: 0;">
                                                <tr>
                                                    <td colspan="8">&nbsp;</td>
                                                </tr>
                                                <tr class="text-start tbl-head">
                                                    <td colspan="7">
                                                        <label class="customLabel">Role Based Pricing</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div (click)="addRow2()">
                                                            <i class="fas fa-plus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td><label class="customLabel">Discount Type</label></td>
                                                    <td><label class="customLabel">Discount Value</label></td>
                                                    <td><label class="customLabel">Customer Type</label></td>
                                                    <td><label class="customLabel">Customer Value</label></td>
                                                    <td><label class="customLabel">Price($)</label></td>
                                                    <td><label class="customLabel">Description</label></td>
                                                </tr>

                                                <ng-container
                                                    *ngFor="let dynamic of dynamicCustomerRoleDiscount; let i = index;">
                                                    <tr *ngIf="dynamic.isActive == true">
                                                        <td>
                                                            <div (click)="deleteRow2(i)">
                                                                <i class="fas fa-minus-circle green-text"></i>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <select name="{{dynamic.DiscountType}}"
                                                                value="{{dynamic.DiscountType}}" autocomplete="off"
                                                                (change)="RoleDiscountType(i, $event.target.value)"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput">
                                                                <option value="">Select</option>
                                                                <option value="1">Percent Discount</option>
                                                                <option value="2">Fixed Amount Discount</option>
                                                                <option value="3">Fixed Price for Item</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.DiscountValue}}" autocomplete="off"
                                                                value="{{dynamic.DiscountValue}}"
                                                                (change)="RoleDiscountValue(i, $event.target.value)"
                                                                class="customInput Cost" type="text" />
                                                        </td>
                                                        <td>
                                                            <select name="{{dynamic.CustomerType}}"
                                                                value="{{dynamic.CustomerType}}" autocomplete="off"
                                                                [disabled]="dynamic.IsDReadonly == true"
                                                                class="customInput"
                                                                (change)="RoleCustomerType(i, $event.target.value)">
                                                                <option value="">Select</option>
                                                                <option value="1">User Role</option>
                                                                <!-- <option value="2">User</option> -->
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <div *ngIf="dynamic.CustomerType==1;">
                                                                <select name="{{dynamic.CustomerValue}}"
                                                                    value="{{dynamic.CustomerValue}}" autocomplete="off"
                                                                    [disabled]="dynamic.IsDReadonly == true"
                                                                    class="customInput"
                                                                    (change)="RoleCustomerValue(i, $event.target.value)">
                                                                    <option value=''> Select Customer</option>
                                                                    <option *ngFor="let item of CustomerTypeList"
                                                                        [value]="item.id">
                                                                        {{item.customerType}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="dynamic.CustomerType==2;">
                                                                <select name="{{dynamic.CustomerValue}}"
                                                                    value="{{dynamic.CustomerValue}}" autocomplete="off"
                                                                    [disabled]="dynamic.IsDReadonly == true"
                                                                    class="customInput"
                                                                    (change)="RoleCustomerValue(i, $event.target.value)">
                                                                    <option value=''> Select Customer</option>
                                                                    <option *ngFor="let item of CustomerTypeList1"
                                                                        [value]="item.id">
                                                                        {{item.customerName}} ({{item.customerHash}})
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="dynamic.CustomerType=='';">
                                                                <input name="{{dynamic.CustomerValue}}" value=""
                                                                    autocomplete="off"
                                                                    [disabled]="dynamic.IsDReadonly == false"
                                                                    class="customInput" type="text" />
                                                            </div>
                                                        </td>
                                                        <td class="d-flex">
                                                            <input name="{{dynamic.cost}}" class="customInput Costs"
                                                                autocomplete="off" type="text"
                                                                [disabled]="dynamic.isReadOnly == false"
                                                                value="{{dynamic.cost}}" />
                                                        </td>
                                                        <td>
                                                            <input name="{{dynamic.description}}" class="customInput"
                                                                (change)="RoleDescription(i, $event.target.value)"
                                                                autocomplete="off" type="text"
                                                                value="{{dynamic.description}}" />
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Production
                                </div>
                            </div>
                        </div>

                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Production Role</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" [(ngModel)]="userRole"
                                            (ngModelChange)="changeUserRole($event)" autocomplete="none|false|no"
                                            formControlName='userRole'>
                                            <option value=''>Select Role</option>
                                            <option *ngFor="let item of userRoleList" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Labor Hours</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <div style="display: flex">
                                            <input type="text" class="customInput Cost pl-5px" [(ngModel)]="hours"
                                                placeholder="Enter Labor Hours" formControlName='hours'
                                                autocomplete="off" required maxlength="10">
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Skill Level</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" formControlName="skillLevel"
                                            [(ngModel)]="skillLevel">
                                            <option value='0'>No Skill Required</option>
                                            <option *ngFor="let item of skillLevelList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Default Workflow Steps</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" formControlName="WorkflowSteps"
                                            [(ngModel)]="WorkflowSteps">
                                            <option value=''>Select Workflow Step</option>
                                            <option *ngFor="let item of WorkflowList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row hidden-xs">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Part Dimensions and Weight
                                </div>
                            </div>
                        </div>

                        <div class="mt-2 row">
                            <!--Adding table for dimension part detail-->

                            <div class="DimensionTable">
                                <!-- <h4 style="font-weight: 500;">Dimensions & Weight</h4> -->
                                <label class="customLabel">Dimensions & Weight</label>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 20%;">Name</th>
                                            <th scope="col" style="width: 22%;">Value</th>
                                            <th scope="col" style="width: 10%;">UOM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Length</td>
                                            <td> <input type="text" class="customInput border Cost pl-5px"
                                                    [(ngModel)]="length" formControlName='length' autocomplete="off"
                                                    required maxlength="5" style="height: 22px;     border-radius: 0">
                                            </td>
                                            <td><select class="customInput pl-1px" [(ngModel)]="DimentionTypeL"
                                                    formControlName='DimentionTypeL' style="border-radius: 0"
                                                    (change)="changeDimentions($event)">

                                                    <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <td>Width</td>
                                            <td> <input type="text" class="customInput border Cost pl-5px"
                                                    [(ngModel)]="width" formControlName='width' autocomplete="off"
                                                    required maxlength="5" style="height: 22px;     border-radius: 0">
                                            </td>
                                            <td> <select class="customInput pl-1px" [(ngModel)]="DimentionTypeW"
                                                    formControlName='DimentionTypeW' style="border-radius: 0"
                                                    (change)="changeDimentions($event)">

                                                    <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <td>Height</td>
                                            <td> <input type="text" class="customInput border Cost pl-5px"
                                                    [(ngModel)]="height" formControlName='height' autocomplete="off"
                                                    required maxlength="5" style="height: 22px;     border-radius: 0">
                                            </td>
                                            <td><select class="customInput pl-1px" [(ngModel)]="DimentionTypeH"
                                                    formControlName='DimentionTypeH' style="border-radius: 0"
                                                    (change)="changeDimentions($event)">

                                                    <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <td>Weight</td>
                                            <td><input type="text" class="customInput border Cost pl-5px"
                                                    [(ngModel)]="weight" formControlName='weight' autocomplete="off"
                                                    required maxlength="5" style="height: 22px;     border-radius: 0"
                                                    [ngClass]="((!form.controls.weight.valid) && (validation)) ? 'validation_border': ''">
                                            </td>
                                            <td> <select class="customInput pl-1px" [(ngModel)]="WeightType"
                                                    formControlName='WeightType' style="border-radius: 0">

                                                    <option *ngFor="let item of WeightTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="AddSpecific">
                                <!-- <h4 style="font-weight: 500;">Additional Specifications</h4> -->
                                <label class="customLabel">Additional Specifications</label>
                                <table class="table-bordered">
                                    <tr class="tbl-head">
                                        <td>
                                            <div (click)="addRow3()">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </td>
                                        <td><label class="setFont">Name</label></td>
                                        <td><label class="setFont">Value</label></td>
                                    </tr>

                                    <ng-container *ngFor="let dynamic of dynamicSpecificArray; let i = index;">
                                        <tr *ngIf="dynamic.isActive == true">
                                            <td>
                                                <div (click)="deleteRow3(i)">
                                                    <i class="fas fa-minus"></i>
                                                </div>
                                            </td>
                                            <td> <input name="{{dynamic.Name}}" value="{{dynamic.Name}}"
                                                    autocomplete="off" class="customInput"
                                                    (change)="Filedcalc1(i, $event.target.value)" type="text" />
                                            </td>
                                            <td> <input name="{{dynamic.Value}}" value="{{dynamic.Value}}"
                                                    autocomplete="off" class="customInput"
                                                    (change)="Filedcalc2(i, $event.target.value)" type="text" />
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>

                            </div>

                        </div>

                        <!-- Related Products start -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Related Products
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 row">

                            <div class="DimensionTable">
                                <form class="example-form" [formGroup]="myForm">
                                    <table class="table table-bordered">
                                        <tr class="tbl-head">
                                            <th>Part Number</th>
                                            <th>Part Name</th>
                                            <th>WP ID</th>
                                            <th>&nbsp;Action&nbsp;</th>
                                        </tr>
                                        <tr formArrayName="listOfItems"
                                            *ngFor="let item of myForm.get('listOfItems')['controls']; let i = index;">
                                            <ng-container [formGroupName]="i" *ngIf="myForm.value.listOfItems.length>0">
                                                <td>
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                        <input type="text" placeholder="Part Number"
                                                            aria-label="PartNumber" matInput
                                                            formControlName="PartNumber" [matAutocomplete]="auto"
                                                            (input)="onRPInput(choosedValue1.value,i,'PartNumber',false)"
                                                            #choosedValue1>
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredOptions"
                                                                [value]="option.PartNumber"
                                                                (onSelectionChange)="onRPChange($event,i,'PartNumber',true)">
                                                                {{option.PartNumber}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                        <input type="text" placeholder="Part Name" aria-label="PartName"
                                                            matInput formControlName="PartName"
                                                            [matAutocomplete]="auto1"
                                                            (input)="onRPInput(choosedValue2.value,i,'PartName',false)"
                                                            #choosedValue2>
                                                        <mat-autocomplete #auto1="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredOptions"
                                                                [value]="option.PartName"
                                                                (onSelectionChange)="onRPChange($event,i,'PartName',true)">
                                                                {{option.PartName}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field class="example-full-width" appearance="outline">
                                                        <input type="text" placeholder="WP ID" aria-label="WPID"
                                                            matInput formControlName="WPID" [matAutocomplete]="auto2"
                                                            (input)="onRPInput(choosedValue3.value,i,'WPID',false)"
                                                            #choosedValue3>
                                                        <mat-autocomplete #auto2="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredOptions"
                                                                [value]="option.WPID"
                                                                (onSelectionChange)="onRPChange($event,i,'WPID',teue)">
                                                                {{option.WPID}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                </td>
                                                <td class="mat-cell">
                                                    <button mat-icon-button color="warn" type="button"
                                                        title="delete row" (click)="removeItem(i)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>

                                                </td>

                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="text-right">
                                                <button type="button" class="btn blue-bg round-btn add-btn-res"
                                                    (click)="addNewItem();"><i class="fas fa-plus-circle"></i> Add
                                                    Row</button>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- Related Products end -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Image
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 d-flex align-items-center">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Image Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select name='ImageTypeId' class="customInput pl-1px"
                                            formControlName="ImageTypeId" [(ngModel)]="SelectedImageTypeId" id=""
                                            [ngClass]="((SelectedImageTypeId=='') && (validationImage)) ? 'validation_border': ''">
                                            <option value=''>Select Image Type</option>
                                            <option *ngFor="let item of ImageTypelist" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Upload</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="file" class=" customInput" #Image accept="
                                            application/pdf, image/*" (change)="ChngSettingImage($event.target.files)"
                                            id="file"
                                            [ngClass]="((PictureValue=='') && (validationImage)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-12">
                                <button type="button" class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0"
                                    (click)="AddMultiplePartImage();">+ Add image</button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-1" *ngFor="let image of MultipleImage;let i = index;">
                                <div class="particular_img">
                                    <img
                                        [src]="image.documenttype=='PDF'?DefaultLogoPDF:image.documenttype=='Word'?WordFile:image.imageUrl">
                                    <div class="view_img" (click)="viewImage(image?.imageUrl,image?.documenttype)">
                                        <i class="fa fa-eye" aria-hidden="true" data-toggle="modal"></i>
                                    </div>
                                    <button type="button" class="close" (click)="DeleteImageModalpopup(i,contentImage)"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="imgtype">
                                        Gallery {{i+1}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-1" *ngFor="let image of MultipleDownloadImg;let i = index;">
                                <div class="particular_img">
                                    <img
                                        [src]="image.documenttype=='PDF'?DefaultLogoPDF:image.documenttype=='Word'?WordFile:image.imageUrl">
                                    <div class="view_img" (click)="viewImage(image.imageUrl,image.documenttype)">
                                        <i class="fa fa-eye" aria-hidden="true" data-toggle="modal"></i>
                                    </div>
                                    <button type="button" class="close"
                                        (click)="DeleteMultipleDownloadImg(i,contentImage)" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="imgtype">
                                        Download {{i+1}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="margin_top">
                            <div class="customHeading mt-3">
                                Questions
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-4 pr-0">
                                    <div *ngFor="let questionForm of this.questionArray.controls; let i = index"
                                        class="question-detail defaulkt-qus p-0">
                                        <div [formGroup]="questionForm" class="pb-2">
                                            <select *ngIf="i==0" class="customInput pl-1px"
                                                (ngModelChange)="onchangeEvent($event)" formControlName='questionId'
                                                required>
                                                <option value="0">Select Question</option>
                                                <option *ngFor="let item of questionlist" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <select *ngIf="i > 0" class="form-control1 customInput "
                                                (ngModelChange)="onchangeEvent($event)" formControlName='questionId'
                                                disabled="true" required>
                                                <option value="0">Select Question</option>
                                                <option *ngFor="let item of questionlistNew" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="i==0" class="qus-action align-with-box add-newquestion"
                                            (click)="AddQuestionDropdownList(questionForm)">
                                            <i class="fas fa-plus-circle green-text"></i>
                                        </div>
                                        <div *ngIf="i>0" class="qus-action align-with-box add-newquestion"
                                            (click)="RemoveQuestionDropdownList(i,questionForm)">
                                            <i class="fas fa-minus-circle red-text"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- Sales Questins Start -->
                        <div class="AddSpecific" formArrayName="Questions">
                            <label class="customLabel">Sales Questions</label>
                            <table class="table-bordered">
                                <tr class="tbl-head">
                                    <td>
                                        <div (click)="addQuestion([])">
                                            <i class="fas fa-plus"></i>
                                        </div>
                                    </td>
                                    <td><label class="setFont">Sales Question</label></td>
                                    <td><label class="setFont">Answer Type</label></td>
                                    <td><label class="setFont">Required</label></td>
                                </tr>
                                <ng-container *ngFor="let q of Questions.controls; let i = index;" [formGroupName]="i">
                                    <tr>
                                        <td>
                                            <div (click)="delQuestion(i)">
                                                <i class="fas fa-minus"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <input matInput formControlName="questionTitle" maxlength="150"
                                                    placeholder="This is Question {{i+1}}">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <mat-select formControlName="answerTypeId">
                                                    <mat-option [value]=1>Text</mat-option>
                                                    <mat-option [value]=2>Boolean(Yes/No)</mat-option>
                                                    <mat-option [value]=3>Number</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="outline">
                                                <mat-select formControlName="isRequired">
                                                    <mat-option [value]=true>Yes</mat-option>
                                                    <mat-option [value]=false>No</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                        <!-- Sales Questins End -->

                        <button type="button" *ngIf="CheckCategory" class="btn blue-bg round-btn add-btn-res mt-2"
                            (click)="openModal()">eCommerce Details</button>
                        <button type="button" *ngIf="CheckCategoryDisable"
                            class="btn blue-bg round-btn add-btn-res mt-2" style="opacity:0.6">eCommerce
                            Details</button>

                        <div class="part-questions">

                            <div class="submit-from text-right">
                                <button type="button" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                    class="btn blue-bg round-btn" (click)="AddPart()"><i class="fas fa-save"
                                        aria-hidden="true"></i>{{buttonValue}}</button>
                                <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                    (click)="resetvendorData()"><i class="fas fa-redo" aria-hidden="true"></i>
                                    Clear </button>
                                <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                    (click)="PartBack()"><i class="fas fa" aria-hidden="true"></i>
                                    Exit </button>
                            </div>

                        </div>

                        <!-- old inventory logic -->
                        <!-- <div class="part-questions">
                            <div class="customHeading mt-3">
                                Inventory Definitions
                            </div>
                            <div class="row mt-md-1">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Default Location Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="customInput pl-1px" required name='LocationsId'
                                                formControlName="LocationsId" [(ngModel)]="SelectedLocation"
                                                (change)="changeLocation($event)"
                                                [ngClass]="((!form.controls.LocationsId.valid) && (InvValidation)) ? 'validation_border': ''">
                                                <option value=''>Select Default Part Location Name</option>
                                                <option *ngFor="let item of locationNameList" [value]="item.id">
                                                    {{item.locationName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Default Part Location</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="customInput pl-1px" required name='partLocation'
                                                formControlName="partLocation" [(ngModel)]="SelectedPartLocation"
                                                (change)="changeLocations($event)"
                                                [ngClass]="((!form.controls.partLocation.valid) && (InvValidation)) ? 'validation_border': ''">
                                                <option value=''>Select Default Part Location</option>
                                                <option *ngFor="let item of locationLevelList" [value]="item.id">
                                                    {{item.levelsName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Part Tracking Details</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="customInput pl-1px" formControlName="partTracking"
                                                [(ngModel)]="partTracking" (change)="changeTracking($event)"
                                                [ngClass]="((!form.controls.partTracking.valid) && (InvValidation)) ? 'validation_border': ''">
                                                <option *ngFor="let item of PartTrackingList" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-md-1">

                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Part Lead Time (Days)”</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="partLeadTime"
                                                placeholder="Enter Part Lead Time" formControlName='partLeadTime'
                                                autocomplete="off" maxlength="10">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Reorder Quantity</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput NumberOnly"
                                                [(ngModel)]="reorderAmount" placeholder="Enter Reorder Quantity"
                                                formControlName='reorderAmount' autocomplete="off" maxlength="10">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp required">
                                            <label class="customLabel">Minimum Inventory</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput Cost pl-5px"
                                                placeholder="Enter Minimum Inventory" formControlName="minInventory"
                                                [(ngModel)]="minInventory" autocomplete="off" required maxlength="6"
                                                [ngClass]="((!form.controls.minInventory.valid) && (InvValidation)) ? 'validation_border': ''">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="customHeading mt-3">
                                Inventory Overview
                            </div>
                            <div class="row mt-2 d-flex align-items-center">

                                <div class="col-lg-1 col-md-6 pr-lg-0">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Quantity</label>

                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvQty.valid) && (InvValidation)) ? 'form-control1 NumberOnly  validation_border': 'form-control1 NumberOnly'"
                                            placeholder="Qty" formControlName='InvQty' [(ngModel)]="InvQty"
                                            maxlength="5" min="1" max="99" style="margin-left:1px">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Date</label>
                                        <input type="date" class="customInput" min="{{MinDate}}"
                                            [ngClass]="((!form.controls.InvDate.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            placeholder="Date" formControlName='InvDate' [(ngModel)]="InvDate">
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-6 p-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">$ Cost</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvCost.valid) && (InvValidation)) ? 'form-control1 CostQty validation_border': 'form-control1 CostQty'"
                                            placeholder="$ Cost" formControlName='InvCost' [(ngModel)]="InvCost"
                                            maxlength="10">
                                    </div>
                                </div>

                                <ng-container *ngIf='InvType == "5"'>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1"></div>
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvServNo.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            placeholder="Service #" formControlName='InvServNo' [(ngModel)]="InvServNo">
                                    </div>

                                </ng-container>
                                <ng-container *ngIf='InvType == "6"'>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-2">Number</label>
                                            <select class="customInput" formControlName='InvPartOrder'
                                                [ngClass]="((!form.controls.InvPartOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                                [(ngModel)]="InvPartOrder">
                                                <option value=''>Select Part SO</option>
                                                <ng-container *ngFor="let item of InvPartOrderDDL">
                                                    <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </ng-container>
                                                <ng-container *ngFor="let item of InvPartOrderDDL">
                                                    <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                        [disabled]="true">
                                                        {{item.name}} - Fulfilled
                                                    </option>
                                                </ng-container>
                                            </select>

                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf='InvType == "7"'>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-2">Number</label>
                                            <select class="customInput" formControlName='InvProductOrder'
                                                [ngClass]="((!form.controls.InvProductOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                                [(ngModel)]="InvProductOrder">
                                                <option value=''>Select Product SO</option>
                                                <ng-container *ngFor="let item of InvProductOrderDDL">
                                                    <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </ng-container>
                                                <ng-container *ngFor="let item of InvProductOrderDDL">
                                                    <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                        [disabled]="true">
                                                        {{item.name}} - Fulfilled
                                                    </option>
                                                </ng-container>
                                            </select>

                                        </div>
                                    </div>
                                </ng-container>


                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Vendor<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvVendor'
                                            [ngClass]="((!form.controls.InvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvVendor">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of partVendorsList" [value]="item.Id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Location Name<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvLocName'
                                            [ngClass]="((!form.controls.InvLocName.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvLocName" (change)="changeLocationNew($event)">
                                            <option value=''>Location Name</option>
                                            <option *ngFor="let item of locationNameList" [value]="item.id">
                                                {{item.locationName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Location<span
                                                style="color:red">*</span></label>
                                        <select class="customInput cwidth" formControlName='InvLocName1'
                                            [ngClass]="((!form.controls.InvLocName1.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvLocName1">
                                            <option value=''>Select Location</option>
                                            <option *ngFor="let item of invlocationLevelList" [value]="item.levelsName">
                                                {{item.levelsName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-2">
                                    <div class="d-flex align-items-center mt-1">
                                        <label class="customLabel mr-2 ws-nobreak">Tracking<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvPartTracking'
                                            [ngClass]="((!form.controls.InvPartTracking.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvPartTracking" (change)="SetTracking($event)">
                                            <option *ngFor="let item of PartTrackingList1" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center mt-2">
                                        <button type="button" class="btn blue-bg round-btn add-btn-res"
                                            (click)="AddInv()"><i class="fas fa-plus-circle" aria-hidden="true"></i>
                                            Add
                                        </button>

                                    </div>
                                </div>

                            </div>



                            <div class="col-sm-12 p-0">
                                <table class="display table-bordered table clearfix order-detail mb-0 mt-2">
                                    <thead>
                                        <tr>
                                            <th style="font-weight:bold; width: 5%;"></th>
                                            <th style="font-weight:bold; width: 6%;">Avlb. Qty</th>
                                            <th style="font-weight:bold; width: 10%;">Date</th>
                                            <th style="font-weight:bold; width: 10%;">Cost</th>
                                            <th style="font-weight:bold; width: 12%;">Type</th>
                                            <th style="font-weight:bold; width: 12%;">Number</th>
                                            <th style="font-weight:bold; width: 12%;">Vendor</th>
                                            <th style="font-weight:bold; width: 10%;">Loc Name</th>
                                            <th style="font-weight:bold; width: 10%;">Location</th>
                                            <th style="font-weight:bold; width: 12%;">Tracking Details</th>
                                            <th style="font-weight:bold; width: 10%;">Manual/PO </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let InvItem of InvList; let i = index">
                                            <td>
                                                <a ngbTooltip="Move Inventory" *ngIf="InvItem.id!=null"><span
                                                        class="fa fa-exchange partEdit mr-1"
                                                        (click)="MoveInvRow(InvItem)"></span></a>
                                                <a ngbTooltip="Adjust Quantity"
                                                    *ngIf="InvItem.InvType!=5 && InvItem.InvType!=6 && InvItem.InvType!=7"><span
                                                        class="fa fa-indent partEdit ml-1"
                                                        (click)="AdjustInvRow(InvItem)"></span></a>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvQty}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvQty.valid) && (InvValidationUpdate)) ? 'form-control1 text-center NumberOnly validation_border': 'form-control1 text-center NumberOnly'"
                                                        formControlName='UpdateInvQty' maxlength="5" type="text"
                                                        [(ngModel)]="UpdateInvQty" min="1" max="99" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvDate |
                                                    date:
                                                    'MMM-dd-yyyy'}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input formControlName='UpdateInvDate' class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvDate.valid) && (InvValidationUpdate)) ? 'form-control1 text-center validation_border': 'form-control1 text-center'"
                                                        type="date" min="{{MinDate}}" [(ngModel)]="UpdateInvDate" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    ${{InvItem.InvCost}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvCost.valid) && (InvValidationUpdate)) ? 'form-control1 text-center CostQty validation_border': 'form-control1 text-center CostQty'"
                                                        formControlName='UpdateInvCost' type="text"
                                                        [(ngModel)]="UpdateInvCost" maxlength="8" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetInvTypeDDLText(InvItem.InvType)}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select formControlName='UpdateInvType' class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvType.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                        [(ngModel)]="UpdateInvType">
                                                        <option value=''>Select Type</option>
                                                        <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetInvType2DDLText(InvItem.InvType,
                                                    InvItem.InvType2)}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <ng-container *ngIf='UpdateInvType == "5"'>
                                                        <input class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvServNo.valid) && (InvValidationUpdate)) ? 'form-control1 text-center validation_border': 'form-control1 text-center'"
                                                            formControlName='UpdateInvServNo' type="text"
                                                            [(ngModel)]="UpdateInvServNo" />
                                                    </ng-container>
                                                    <ng-container *ngIf='UpdateInvType == "6"'>
                                                        <select formControlName='UpdateInvPartOrder' class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvPartOrder.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                            [(ngModel)]="UpdateInvPartOrder">
                                                            <option value=''>Select Part SO Order</option>
                                                            <option *ngFor="let item of InvPartOrderDDL"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                    <ng-container *ngIf='UpdateInvType == "7"'>
                                                        <select formControlName='UpdateInvProductOrder'
                                                            class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvProductOrder.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                            [(ngModel)]="UpdateInvProductOrder">
                                                            <option value=''>Select Product SO Order</option>
                                                            <option *ngFor="let item of InvProductOrderDDL"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetVendorNameById(InvItem.InvVendor)}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select class="customInput" formControlName='UpdateInvVendor'
                                                        [ngClass]="((!form.controls.UpdateInvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                                        [(ngModel)]="UpdateInvVendor">
                                                        <option value=''>Select Vendor</option>
                                                        <option *ngFor="let item of partVendorsList" [value]="item.Id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>

                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetLocName(InvItem.InvLoc)}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput text-center"
                                                        formControlName='UpdateInvPartLocation' type="text"
                                                        [(ngModel)]="UpdateInvPartLocation" max="200" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{InvItem.InvLoc1 == "null" ? "" : InvItem.InvLoc1 }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="tackingtooltip"
                                                    (click)="OpenSerialModel(InvItem.obj1, InvItem.InvType, InvItem.id == undefined ? InvItem.InvSno : InvItem.id, InvItem.InvTracking, InvItem.InvQty)">
                                                    <label class="customLabel"><span class="tooltipWrap tooltip-icon"
                                                            style="display:inline;"><i
                                                                class="fa fa-info-circle"></i></span>
                                                        <span class="tooltipsSerial">
                                                            <ng-container
                                                                *ngIf="InvItem.obj1.length > 0 else elseBlock">
                                                                <span
                                                                    *ngFor="let dynamic of filterActiveRecords(InvItem.obj1); let i = index; let isLast=last;"
                                                                    style="color:white">
                                                                    <ng-container *ngIf="dynamic.isActive == true">
                                                                        {{dynamic.serialNo == "" ? '-' :
                                                                        dynamic.serialNo}}{{dynamic.serialNo != "" ?
                                                                        isLast
                                                                        ? '' : ', ' : ''}}
                                                                    </ng-container>

                                                                </span>
                                                            </ng-container>
                                                            <ng-template #elseBlock>{{InvItem.InvTracking == 3 ? 'None'
                                                                : 'No Tracking'}}</ng-template>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div>{{InvItem.purchaseOrderId==null?"Manual":"PO"}}</div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="InvList.length==0">
                                            <td colspan="8" class="text-center">Inventory Item is not Available</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="mb-1 mt-2">
                            <h3 class="qty_hd" *ngIf="isTotalInvQty==true"><strong>Total Qty : {{TotalAllType}}</strong>
                            </h3>
                            <div class="submit-from text-right">
                                <button type="button" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                    class="btn blue-bg round-btn" (click)="AddPart()"><i class="fas fa-save"
                                        aria-hidden="true"></i>{{buttonValue}}</button>
                                <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                    (click)="resetvendorData()"><i class="fas fa-redo" aria-hidden="true"></i>
                                    Clear </button>
                                <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                    (click)="PartBack()"><i class="fas fa" aria-hidden="true"></i>
                                    Exit </button>
                            </div>

                        </div> -->


                    </div>

                </form>
            </div>

        </div>
    </div>

    <div class="row mb-4">
        <button type="button" *ngIf="PartHistory" class="btn blue-bg round-btn add-btn-res mt-2"
            (click)="openHistoryModal()" style="width: 145px;margin-left:21px;">Part History Log</button>
    </div>


    <div class="row" [style.display]="isInvFind==true ? 'block' : 'none'" *ngIf="isInvFind">
        <div class="col-md-12 p-lg-0">
            <button type="button" class="btn blue-bg round-btn part-category viewlogbtn" (click)="viewLogFun()"
                style="width: 145px;"><i class="fas fa-down-circle" aria-hidden="true"></i>Inventory Log<i
                    class="ml-1 fas fa-angle-down" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="inventory-table table-responsive" [style.display]="viewLog==true ? 'block' : 'none'">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th width="10%" scope="col">Action</th>
                    <th width="10%" scope="col">Date</th>
                    <th width="10%" scope="col">Type</th>
                    <th width="10%" scope="col">Number</th>
                    <th width="10%" scope="col">In Qty</th>
                    <th width="10%" scope="col">Out Qty</th>
                    <th width="10%" scope="col">Loc Name</th>
                    <th width="10%" scope="col">Location</th>
                    <th width="10%" scope="col">Tracking Details</th>
                    <th width="25%" scope="col">Remark</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of logList; let i = index">
                    <td [style.background-color]="item.bcolor">{{item.action}}</td>
                    <td [style.background-color]="item.bcolor">{{item.effectDate}}</td>
                    <td [style.background-color]="item.bcolor">{{item.type}}</td>
                    <td [style.background-color]="item.bcolor">{{item.typeValue}}</td>
                    <td [style.background-color]="item.bcolor">{{item.inQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.outQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.location}}</td>
                    <td [style.background-color]="item.bcolor">{{item.locationName}}</td>
                    <td [style.background-color]="item.bcolor">
                        <div class="tackingtooltip breakword">
                            <label class="customLabel">
                                <span class="tooltipWrap tooltip-icon" style="display:inline;"><i
                                        class="fa fa-info-circle"></i></span>
                                <span class="tooltipsSerials">
                                    <span style="color: white;">
                                        {{ item.tracking == "0" ? 'No Tracking' : item.tracking == "3" ? 'None' :
                                        item.serials == "" ? 'None' : item.serials }}
                                    </span>
                                </span>
                            </label>
                        </div>
                    </td>
                    <td [style.background-color]="item.bcolor">{{item.remark}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" align="text-center"><b><b>Total Available : {{TotalIn-TotalOut}}</b></b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

</section>

<ng-template #itemModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading">Move Inventory - Move part number {{partNo}} from
        {{displayOldLocation}} to New Location:</h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <form [formGroup]="formPopup">

            <div class="row mt-2">
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Current Qty</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='mInvQty'
                                [(ngModel)]="mInvQty" maxlength="5" min="1" max="99" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Date</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="date" class="customInput" min="{{MinDate}}" placeholder="Date"
                                formControlName='mInvDate' [(ngModel)]="mInvDate"
                                [ngClass]="((!formPopup.controls.mInvDate.valid) && (mInvValidation)) ? 'validation_border': ''"
                                disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Type</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" formControlName='mInvType' [(ngModel)]="mInvType"
                                [ngClass]="((!formPopup.controls.mInvType.valid) && (mInvValidation)) ? 'validation_border': ''">
                                <option value=''>Select Type</option>
                                <option *ngFor="let item of mInvTypeList" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf='mInvType == "5"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" placeholder="Service #"
                                    formControlName='mInvServNo' [(ngModel)]="mInvServNo"
                                    [ngClass]="((!formPopup.controls.mInvServNo.valid) && (mInvValidation)) ? 'validation_border': ''">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='mInvType == "6"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvPartOrder' [(ngModel)]="mInvPartOrder"
                                    [ngClass]="((!formPopup.controls.mInvPartOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Part SO Order</option>
                                    <ng-container *ngFor="let item of mInvPartOrderDDL">
                                        <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </ng-container>
                                    <ng-container *ngFor="let item of mInvPartOrderDDL">
                                        <option *ngIf="item.qtyToFulfill==0" [value]="item.id" [disabled]="true">
                                            {{item.name}} - Fulfilled
                                        </option>
                                    </ng-container>
                                    <!-- <option *ngFor="let item of mInvPartOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='mInvType == "7"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvProductOrder'
                                    [(ngModel)]="mInvProductOrder"
                                    [ngClass]="((!formPopup.controls.mInvProductOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Product SO Order</option>
                                    <ng-container *ngFor="let item of mInvProductOrderDDL">
                                        <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </ng-container>
                                    <ng-container *ngFor="let item of mInvProductOrderDDL">
                                        <option *ngIf="item.qtyToFulfill==0" [value]="item.id" [disabled]="true">
                                            {{item.name}} - Fulfilled
                                        </option>
                                    </ng-container>
                                    <!-- <option *ngFor="let item of mInvProductOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-2">
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Qty to Move</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='mInvQtyToMove'
                                [(ngModel)]="mInvQtyToMove" maxlength="5" min="1" max="99"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                [ngClass]="((!formPopup.controls.mInvQtyToMove.valid || mInvQtyValidation) && (mInvValidation)) ? 'validation_border': ''">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp">
                            <label class="customLabel">Location Name</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" formControlName='mInvLocation2' [(ngModel)]="mInvLocation2"
                                [ngClass]="((!formPopup.controls.mInvLocation2.valid) && (mInvValidation)) ? 'validation_border': ''"
                                (change)="changeLocationMove($event)">
                                <option value=''>Location Name</option>
                                <option *ngFor="let item of locationNameMoveList" [value]="item.id">
                                    {{item.locationName}}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp">
                            <label class="customLabel">Location</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" formControlName='mInvLocation1' [(ngModel)]="mInvLocation1"
                                [ngClass]="((!formPopup.controls.mInvLocation1.valid) && (mInvValidation)) ? 'validation_border': ''">
                                <option value=''>Location</option>
                                <option *ngFor="let item of locationLevelMoveList" [value]="item.levelsName">
                                    {{item.levelsName}}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-1 col-md-2 trp">
                            <label class="customLabel">Reason</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput" placeholder="Enter Reason"
                                formControlName='mInvReason' [(ngModel)]="mInvReason">
                        </div>

                    </div>
                </div>
            </div>
            <div class="alert alert-danger fade show mt-3" role="alert" style="padding: 0.35rem 1.25rem;"
                *ngIf="mQtyMoveMsg==true">
                Quantity can't be move more than {{mValidateQtyToFulfill}} for this sales order.
                <button type="button" class="close" (click)="closenoti()">
                    <strong aria-hidden="true" class="text-danger">&times;</strong>
                </button>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn blue-bg round-btn add-btn-res" (click)="MoveInv()"><i class="fa fa-arrow-right"
                aria-hidden="true"></i>
            Move
        </button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
    </div>
</ng-template>

<ng-template #itemModel2 let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading">Adjust Quantity - {{name}}</h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <form [formGroup]="formPopupAdjustQty">

            <div class="row mt-2">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Current Qty</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='adjInvQty'
                                [(ngModel)]="adjInvQty" maxlength="5" min="1" max="99" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Adjust Qty To</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='adjInvQtyToAdjust'
                                [(ngModel)]="adjInvQtyToAdjust" maxlength="5" min="0" max="99"
                                [ngClass]="((!formPopupAdjustQty.controls.adjInvQtyToAdjust.valid || (formPopupAdjustQty.controls.adjInvQtyToAdjust.value == formPopupAdjustQty.controls.adjInvQty.value)) && (adjInvValidation)) ? 'validation_border': ''">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp">
                            <label class="customLabel">Reason</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" [(ngModel)]="adjInvReason" formControlName="adjInvReason">
                                <option value="">Select</option>
                                <option *ngFor="let item of adjInvReasonList" [value]="item.name">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn blue-bg round-btn add-btn-res ml-0" (click)="AdjustInv()">
            Save
        </button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
    </div>
</ng-template>

<ng-template #QtyMoveMsgModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{mMsgClass}}" alt="">
            <p class="mb-0">{{mMsg}}</p>
        </div>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="deletePart(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<!-- The Modal -->
<ng-template #contentImage let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeleteImage(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<!-- The Modal -->
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header info-popup-header view-img-modal">
                <button type="button" class="close btn alert_modal" data-dismiss="modal"
                    (click)="HideImage();">&times;</button>
            </div>
            <div class="modal-body">
                <img [src]="viewimagesshow" alt="image" class="full-doc">
            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_Items" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="crossitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <p>{{ MainProp}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="crossitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="Imagevlaidation">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">You have already added 4 picture into list.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ImageOrderAlert">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ImageOrderAlertClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">Please select the image in correct order.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="ImageOrderAlertClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ImageAlert">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ImageAlertClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">Please select valid options.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="ImageAlertClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Updated Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="InvExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Part is Non-Inventory! You can not add the inventory on this Part </p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InvExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_mssg" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="mssgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="mssgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="noproduct" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="noprod()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{noproduct}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="noprod()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="CheckCategoryModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-scrollable pop">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">
                    eCommerce Details &nbsp; &nbsp;&nbsp;
                </h4>
                <button type="button" class="close btn alert_modal" (click)="closeModal()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <span>Show Part on eCommerce Site</span> &nbsp; <input type="checkbox" checked />
                <form [formGroup]="newform">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 form-group mt-2">
                                    <span class="customLabel"
                                        style="    font-size: 14px;text-decoration: underline;color: #333;">Field 1
                                        :</span>
                                    <input type="text" class="customInput" [(ngModel)]="InfoName" name="InfoName"
                                        formControlName='InfoName' autocomplete="off" style="padding-left: 7px;"
                                        required
                                        [ngClass]="((!newform.controls.InfoName.valid) && (popvalidation)) ? 'validation_border': ''">

                                    <!-- <ckeditor [(ngModel)]="templateBody" name="myckeditor"
                                        formControlName="templateBody" [config]="ckeConfig" debounce="500">
                                       </ckeditor> -->
                                    <textarea rows="3" cols="8" class="customInput customerTextarea textareashort"
                                        placeholder="Enter More Information" [(ngModel)]="shortDescription"
                                        name="shortDescription" formControlName='shortDescription' autocomplete="off"
                                        [ngClass]="((!newform.controls.shortDescription.valid) && (popvalidation)) ? 'validation_border': ''"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="customLabel"
                                        style="    font-size: 14px;text-decoration: underline;color: #333;">Part
                                        Relationship #1</span>
                                    <input type="text" class="customInput" [(ngModel)]="MatingName" name="MatingName"
                                        formControlName='MatingName' autocomplete="off" style="padding-left: 7px;"
                                        required
                                        [ngClass]="((!newform.controls.MatingName.valid) && (popvalidation)) ? 'validation_border': ''">

                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <label class="customLabel"> Part Category</label>
                                </div>
                                <div class="col-md-7" style="margin-left:18px">

                                    <label class="customLabel"> Company Part Number / Name</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12"></div>
                            </div>
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">

                                            <div *ngFor="let item of WordpressCategoryList; let i = index">
                                                <input formControlName='chkInv' type="checkbox"
                                                    (click)="chngIsInv($event.target.checked, i, item.id, item.partCategoryName)"
                                                    [checked]="WordpressCategoryList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{item.partCategoryName}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-7 scrollboxright">

                                            <div *ngFor="let item1 of CustomerPartNameList; let i = index">
                                                <input formControlName='chkInv1' type="checkbox"
                                                    (click)="chngIsInv1($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}}
                                                </label>

                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                    <br />
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="customLabel"
                                            style="    font-size: 14px;text-decoration: underline;color: #333;">Part
                                            Relationship #2</span>

                                        <input type="text" class="customInput" [(ngModel)]="CrimpName" name="CrimpName"
                                            formControlName='CrimpName' autocomplete="off" style="padding-left: 7px;"
                                            required
                                            [ngClass]="((!newform.controls.CrimpName.valid) && (popvalidation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel"> Part Category</label>
                                        </div>
                                        <div class="col-md-7" style="margin-left:18px">
                                            <label class="customLabel"> Company Part Number / Name</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">
                                            <div *ngFor="let crimp of WordpressCategoryCrimpToolsList; let i = index">
                                                <input formControlName='chkInvCrimp' type="checkbox"
                                                    (click)="chngIsInvCrimp($event.target.checked, i, crimp.id, crimp.partCategoryName)"
                                                    [checked]="WordpressCategoryCrimpToolsList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{crimp.partCategoryName}}</label>
                                            </div>
                                        </div>

                                        <div class="col-md-7 scrollboxright">
                                            <div *ngFor="let item1 of CustomerPartNameCrimpList; let i = index">
                                                <input formControlName='chkInv1Crimp' type="checkbox"
                                                    (click)="chngIsInv1Crimp($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}} </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 mt-2">
                                    <div class="form-group">
                                        <span class="customLabel"
                                            style="font-size: 14px;text-decoration: underline;color: #333;">Part
                                            Relationship #3</span>
                                        <input type="text" class="customInput" [(ngModel)]="RelatedName"
                                            name="RelatedName" formControlName='RelatedName' autocomplete="off"
                                            style="padding-left: 7px;" required
                                            [ngClass]="((!newform.controls.RelatedName.valid) && (popvalidation)) ? 'validation_border': ''">
                                    </div>

                                </div>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel"> Part Category</label>
                                        </div>
                                        <div class="col-md-7" style="margin-left:18px">
                                            <label class="customLabel"> Company Part Number / Name</label>
                                        </div>
                                    </div>

                                </div>


                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">
                                            <div
                                                *ngFor="let relatedItem of WordpressCategoryRelatedItemList; let i = index">
                                                <input formControlName='chkInvRelated' type="checkbox"
                                                    (click)="chngIsInvRelated($event.target.checked, i, relatedItem.id, relatedItem.partCategoryName)"
                                                    [checked]="WordpressCategoryRelatedItemList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{relatedItem.partCategoryName}}</label>

                                            </div>
                                        </div>


                                        <div class="col-md-7 scrollboxright">
                                            <div *ngFor="let item1 of CustomerPartNameRelatedList; let i = index">
                                                <input formControlName='chkInv1Related' type="checkbox"
                                                    (click)="chngIsInv1Related($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}} </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-9"></div>
                        <div class="col-md-3">
                            <button type="button" class="btn blue-bg round-btn" *ngIf="IsSave"
                                (click)="AddPartialPart()" style="width: 82px; float: right;">Save</button>
                            <button type="button" class="btn blue-bg round-btn" *ngIf="IsUpdate"
                                (click)="UpdatePartialPart()" style="width: 82px; float: right;">Update</button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>



<div class="modal" id="history">
    <div class="modal-dialog  modal-dialog-centered modal-lg bigmodal" role="document">
        <div class="modal-content">
            <h4 class="modal-title info-popup-title p-1 text-center">Part History Log - Part Name - {{this.PartName}}
            </h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="historyclose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!------------------------------------- part history table ------------------------------>
                <div class="card-desc1 mt-1  table-responsive">
                    <div style="height:282px; overflow:scroll">
                        <table class="table table-bordered" *ngIf="this.CheckHistoryList" style="margin: 0px 0 20px;"
                            id="fixedTable">
                            <thead>
                                <tr>

                                    <th>Field</th>
                                    <th>Date</th>
                                    <th>Price on Parts Details</th>
                                    <th>Updated Price</th>
                                    <th>SO/PO Old Price</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody class="customLabel">
                                <tr *ngFor="let data1 of PartHistoryList">

                                    <td>{{data1.field}}</td>
                                    <td>{{data1.date | date: 'MM/dd/yyyy'}}</td>
                                    <td>{{data1.price === 0 ? "N/A" : "$" + data1.price }}</td>
                                    <td>{{data1.updatePrice === 0 ? "N/A" : "$" + data1.updatePrice}}</td>
                                    <td>
                                        {{data1.oldPrice===0?"N/A": "$" + data1.oldPrice}}
                                    </td>
                                    <td>{{data1.remark}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="!this.CheckHistoryList">
                            <p style="font-size: 12px; text-align: center;">No Part History Found</p>
                        </div>

                    </div>



                    <div class="submit-from text-right">
                        <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                            aria-hidden="true"></i> Add Part
                    </button> -->
                        <button type="button" class="btn darkred-bg round-btn" (click)="historyclose();"><i
                                aria-hidden="true"></i>
                            Exit </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="vendorModel">
    <div class="modal-dialog  modal-dialog-centered modal-lg bigmodals" role="document">
        <div class="modal-content">
            <h4 class="modal-title info-popup-title p-1 text-center">Additional Vendors for Part:
                ({{this.PartName1}}/{{this.PartNumber1}})
            </h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="vendorclose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!------------------------------------- part history table ------------------------------>
                <div class="card-desc1">
                    <form [formGroup]="partForm">
                        <div class="row mt-2">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">

                                        <select class="customInput" [(ngModel)]="partmodelVendorValue" required
                                            formControlName="partmodelVendorValue"
                                            [ngClass]="((!partForm.controls.partmodelVendorValue.valid) && (partvalidation)) ? 'validation_border': ''">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of vendorlist2"
                                                [value]="item.vendorName + '-'+ item.id + '-' + item.discount">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp" style="display: flex;
                                    justify-content: space-between;">
                                        <label class="customLabel customLabelPartInfo">
                                            Auto Markup<span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltips">
                                                When selected, the Part Price will automatically be calculated
                                                based on the Vendor Cost and the Markup Margin
                                            </span>
                                        </label>

                                        <input type="checkbox" formControlName="PartMarkupCheck"
                                            [(ngModel)]="PartMarkupCheck" (change)="partChangeMargin($event)"
                                            style="top: 2px;">
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <div class="row">

                                            <div class="col-lg-10 col-md-9">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 trp">
                                                        <label class="customLabel customLabelPartInfo">
                                                            Markup Margin

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <input type="text" class="customInput"
                                                            [(ngModel)]="PartMarkupMargin" placeholder="Markup Margin"
                                                            name="PartMarkupMargin" formControlName="PartMarkupMargin"
                                                            required autocomplete="off"
                                                            [readonly]="IsPartMarginDisabled"
                                                            [ngClass]="this.PartMarkupCheck == true ? ((!partForm.controls.PartMarkupMargin.valid) && (partvalidation)) ? 'validation_border': '': ''"
                                                            (blur)="UpdatePricePart()"
                                                            (keypress)="keyPressPartNumbers($event)" maxlength="9"
                                                            style="width: 134px;">


                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="PVendorPartName"
                                            placeholder="$ Enter Vendor Part Name" formControlName='PVendorPartName'
                                            autocomplete="off" required style="padding-left: 6px;"
                                            [ngClass]="((!partForm.controls.PVendorPartName.valid) && (partvalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part Cost</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost" [(ngModel)]="PVendorCost"
                                            placeholder="$ Enter Vendor Part Cost" formControlName='PVendorCost'
                                            (blur)="VendorPartCurrency()" autocomplete="off" required maxlength="10"
                                            style="padding-left: 6px;"
                                            [ngClass]="((!partForm.controls.PVendorCost.valid) && (partvalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Company Part Price</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost" [(ngModel)]="PPartPrice"
                                            placeholder="$ Enter Part Price" formControlName='PPartPrice'
                                            (blur)="PartformatCurrency()" autocomplete="off" required maxlength="12"
                                            [ngClass]="((!partForm.controls.PPartPrice.valid) && (partvalidation)) ? 'validation_border': ''"
                                            [readonly]="IsPPricePart" style="padding-left: 6px;">


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part No.</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="PVendorNumber"
                                            placeholder="$ Enter Vendor Part Number" formControlName='PVendorNumber'
                                            autocomplete="off" required
                                            [ngClass]="((!partForm.controls.PVendorNumber.valid) && (partvalidation)) ? 'validation_border': ''"
                                            style="padding-left: 6px;">


                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">



                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsPartSave"
                                    (click)="AddVendorPart()" style="float: right">Save</button>
                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsPartUpdate"
                                    (click)="UpdateVendorPart()" style="float: right">Update</button>
                                <!-- <button class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                (click)="vendorclose()"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button> -->
                            </div>
                        </div>

                    </form>
                    <hr style="margin-bottom: 0;" />
                    <div class="card-desc1 mt-1  table-responsive">

                        <table class="display table clearfix">
                            <thead>
                                <tr>
                                    <th>Vendor Name</th>
                                    <th>Vendor Part Name</th>
                                    <th>Vendor Part Number</th>
                                    <th>Vendor Part Cost</th>
                                    <th>Vendor Addt'l Discount</th>
                                    <th>Auto-Markup</th>
                                    <th>MarkUp Margin</th>
                                    <th>Part Price</th>
                                    <th data-orderable="false">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let data of AddvendorList">
                                    <tr *ngIf="data.IsActive == true">
                                        <td title={{data.vendorName}}>
                                            {{data.vendorName.length <= 25 ? data.vendorName :
                                                SortString(data.vendorName)}} </td>
                                        <td title={{data.vendorPartName}}>
                                            {{data.vendorPartName == null ? '' : (data.vendorPartName.length <= 25 ?
                                                data.vendorPartName : SortString(data.vendorPartName))}} </td>
                                        <td title={{data.vendorPartNumber}}>
                                            {{data.vendorPartNumber == null ? '' : (data.vendorPartNumber.length <= 25 ?
                                                data.vendorPartNumber : SortString(data.vendorPartNumber))}} </td>
                                        <td>{{data.vendorPartCost |number: '1.2-2' | currency}}</td>
                                        <td>{{data.Discount}}</td>
                                        <td>{{data.markupCheck}}</td>
                                        <td>{{data.markupMargin}}</td>
                                        <td>{{data.cost |number: '1.2-2'| currency}}</td>
                                        <td>
                                            <a class="green-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                    class="fas fa-edit" (click)="VendorPartEdit(data)"></i></a>
                                            <a class="red-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                                    class="fas fa-trash-alt" (click)="deletevendorPart(data)"></i></a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="AddvendorList==null">
                                <tr class="c1">
                                    <td colspan="9" class="no-data-available">No data available in table</td>
                                </tr>
                            </tbody>
                        </table>





                        <div class="submit-from text-right">
                            <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button> -->
                            <button type="button" class="btn darkred-bg round-btn" (click)="vendorclose();"><i
                                    aria-hidden="true"></i>
                                Exit </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="PartNameModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="PartNameModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{this.partNameProperty}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="PartNameModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyVendor" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="AlreadyVendor()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{this.AlreadyVendorName}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="AlreadyVendor()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="deleteVendorlist" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="DeleteVendor()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>{{deleteDetailVendor}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-0 mr-1"
                    (click)="deletePartVendorList(PartdeletedItem);">Yes</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="DeleteVendor()">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="SaveModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="SavePopModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>{{this.SaveModel}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="SavePopModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ErrorModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="ErrorModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{ErrorModelMsg}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="ErrorModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="DisableModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="DisableModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Enter MarkUp Margin</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="DisableModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="msgModel404">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msg404()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Something went wrong</p>
                    <p>May be! WooCommerce API is not working</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msg404()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InventoryExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InventoryExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Part's Inventory Already Exist !</p>
                    <p>Do you want to make this part as Non-Inventory? Please Remove the Inventory!</p>

                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" (click)="RemoveInventory()">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="InventoryExist()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InventoryRemove">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InventoryRemove()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You</h1>
                    <p style="margin-bottom: 0">Part's Inventory Remove Successfully</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InventoryRemove()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyVendorExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="AlreadyVendorExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Primary Vendor '{{vendorText}}' already added to Additional Vendors</p>
                    <p>Please choose another primary vendor</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="AlreadyVendorExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InvExist1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist1()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">{{InvProp}}</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InvExist1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="notdelete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="notdelete_success()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{deleteUnsuccessMsg}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="notdelete_success()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="DiscountModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseDiscountModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{DiscountModel}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn" (click)="CloseDiscountModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SerialModel">
    <div class="modal-dialog modal-dialog-scrollable"
        [ngClass]="(this.qtyLabel.length > 15) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"><span *ngIf='exitProp == false' style="color:white">Add
                    </span><span style="color:white" *ngIf='exitProp == true'>Update </span>{{Tracking == "1" ? "Serial
                    #" : "Lot #"}} to {{inventoryQty}}
                    Part(s)</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseSerialModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">

                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of qtyLabel; let i=index;">
                        <ng-container *ngIf="data.isActive == true">
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i + 1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="IsSerialDisabled == true"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation(data.id)"
                                    [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                                text-align: left;">{{data.message}}</div>
                        </ng-container>

                    </div>

                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" *ngIf='exitProp == false' class="btn blue-bg round-btn add-btn-res ml-0 padding"
                    (click)="SaveDetail()"><i class="fas fa-save" aria-hidden="true"></i>Save</button>
                <button type="button" *ngIf='exitProp == true' class="btn blue-bg round-btn ml-0 padding"
                    (click)="EditDetail(this.openId)"><i class="fas fa-save" aria-hidden="true"></i>Update</button>
                <button type="button" class="btn red-bg round-btn m-0 padding" data-dismiss="modal"
                    (click)="CloseModel()">Exit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ExitModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseExitModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>Exit without completing all Parts?</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn blue-bg round-btn add-btn-res ml-0" (click)="SaveDetail1()" style="width: 100px;
                padding: 4px 12px;">Yes</button>
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal" (click)="CloseExitModel()"
                    style="width: 100px;
                    padding: 4px 12px;">No</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="DeleteSerials" style="z-index: 1080">
    <div class="modal-dialog modal-dialog-scrollable"
        [ngClass]="(this.AddSerials.length > 10 || this.DeleteSerialArr.length > 10 ) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">{{AddSerial == true ? "Add " + serialNumDiff : "Remove " +
                    serialNumDiff}} {{DSerialTracking == "1" ? "Serial#" : "Lot#"}} Number</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseDSerials()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of DeleteSerialArr; let i =index;">
                        <ng-container>
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i+1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == false"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation1(data.id)"
                                    [ngClass]="(data.isActive == false) ?  'addClass' : ''" />

                            </div>
                            <div class="col-md-1" *ngIf="AddSerial == false">
                                <span class="minusbutton" *ngIf="data.isActive == true">
                                    <i class="fas fa-minus-circle red-text"
                                        (click)="deleteSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                                <span class="minusbutton" *ngIf="data.isActive == false">
                                    <i class="fas fa-plus-circle green-text"
                                        (click)="addSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                            text-align: left;">{{data.message}}</div>


                        </ng-container>


                    </div>
                    <div class="row mt-1" *ngFor="let data of AddSerials; let j =index;">
                        <div class="col-md-4">
                            <span class="customLabel">Part {{data.ids}} :</span>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == true"
                                [(ngModel)]="data.serialNo" (blur)="changeValidation2(data.ids)"
                                [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-7" style="color:red; 
                        text-align: left;">{{data.message}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn blue-bg round-btn add-btn-res ml-0"
                    (click)="SaveDSerial(DSerialTracking, AddSerial)">Save</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseDSerials()"
                    data-dismiss="modal">Exit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="MoveSerials" style="z-index: 1080">
    <div class="modal-dialog modal-dialog-scrollable"
        [ngClass]="(this.MoveSerialArr.length > 10) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"> Move {{ moveDiff}} {{DSerialTracking == "1" ? "Serial#" :
                    "Lot#"}} Number</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseMoveSerials()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of MoveSerialArr; let i =index;">
                        <ng-container>
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i+1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == false"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation1(data.id)"
                                    [ngClass]="(data.isActive == false) ?  'movebutton' : ''" />

                            </div>
                            <div class="col-md-1">
                                <span class="minusbutton">
                                    <i class="fa fa-arrow-right" *ngIf="data.isActive == true" aria-hidden="true"
                                        (click)="MoveSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                            text-align: left;">{{data.message}}</div>


                        </ng-container>


                    </div>


                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn blue-bg round-btn add-btn-res ml-0"
                    (click)="MoveSerial(DSerialTracking)">Save</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseMoveSerials()"
                    data-dismiss="modal">Exit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="GeneralModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseGeneralModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{SerialComment}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal"
                    (click)="CloseGeneralModel()">Exit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SetTracking" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseTracking()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{SerialComment}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal"
                    (click)="CloseTracking()">Exit</button>
            </div>
        </div>
    </div>
</div>