<section class="content-section-1">
    <div class="loading lodingtext" *ngIf="loader"></div>
    <div class="row">
        <div class="col-12">
            <!-- --------------top bredcumps css here --------- -->
            <div class="top-bredcumps">
                <div class="cstmbreadcum paddingmng-right">
                    <div class="page_title_cstms">
                        <ul class="link_current">
                            <li>
                                <a routerLink="/adminDashboard">Home</a>
                            </li>
                            <li>
                                <span>Email Configuration List</span>
                            </li>
                        </ul>
                    </div>
                    <div class="top-button d-flex justify-content-between align-items-center gap-3">
                        <div class="new-order-btn">
                            <button mat-raised (click)="createNewOrder()"
                                class="save-button quick-bg d-flex align-items-center btn save-btn update-btn">
                                <mat-icon class="mr-1 text-light fs-20">add</mat-icon>
                                <span class="text-light quick-order-text">New Configuration</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- --------------top bredcumps css here --------- -->

            <div class="container-fluid">
                <div class="main-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 col-12 mb-4">
                            <div class="search_filter-alltype select-arrow-change-icon">
                                <mat-select [(value)]="selected" class="customInput">
                                    <mat-option value='All' selected>All</mat-option>
                                </mat-select>
                                <input type="text" [(ngModel)]="searchText" class="searchalltype" placeholder="Search"
                                    (input)="onSearch()">
                                <button class="btnsearch_alltype" type="button" (click)="onSearch()">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <mat-table [dataSource]="dataSource" class="table-material table-responsive">
                        <ng-container matColumnDef="menu">
                            <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                            <mat-cell *matCellDef="let row; let i = index;" data-label="Menu"> <mat-icon
                                    class="icon_fix">more_vert</mat-icon> </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="Name">
                                {{row.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="sender">
                            <mat-header-cell *matHeaderCellDef> Sender Email </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="Sender">
                                {{row.senderEmail}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="smtp">
                            <mat-header-cell *matHeaderCellDef> SMTP Server </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="SMTP">
                                {{row.smtp}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="port">
                            <mat-header-cell *matHeaderCellDef> Port </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="Sender">
                                {{row.port}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                            class="header-style table-responsive-header"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;let i= index;"
                            (click)="getById(row)"></mat-row>
                    </mat-table>

                </div>
            </div>
        </div>
    </div>
</section>