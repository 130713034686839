import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
// import { DownlineTreeviewItem, DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { runInThisContext } from 'vm';
import { ConstantsService } from '../constants.service';
import { SharedService } from '../services/shared.service';
import { PasswordStrengthValidator } from './PasswordStrengthValidator';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';


interface Program1 {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program1[];
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements AfterViewInit, OnDestroy, OnInit {
  // @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;

  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: true,
  //   maxHeight: 400
  // });
  // items: TreeviewItem[];
  values: number[];
  arrayRows = [];
  arrayPath = {};
  tableRows = [];
  selectedItem = '';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: number;

  deletedItem: string;
  deleteDetail: string;
  form: FormGroup;
  roles: string;
  userList: any;
  userRole = '';
  userRoleList: any;
  selectedRoleValue = '';
  selectedCompanyValue = '';
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: any;
  contactEmail: string;
  password: string;
  passworConfirm: string
  companyList: any;
  userGroupObjects = new Array();
  userId: string;
  companyId: any;
  keypress: boolean = false;
  value = 0;

  recordAlreadyExist = false;
  updateMode: boolean = true;
  buttonValue: string;
  rolePermissionsList: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  loader: boolean = false;
  permissionMessage = '';
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  TCreateUpdate: any = 1;
  userSearch: any;
  SearchValidation: any;
  SearchType: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  formSearch: FormGroup;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  skillLevelList: any;
  skillLevel = '0';
  PasswordNotUpdated: any;
  passwordLength: any;
  programs1: Program1[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
      this.settingLogoImage = localStorage.getItem("settingImageLogo");
      this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 3);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.buttonValue = this.constants.Save;
      this.userId = localStorage.getItem(this.constants.userId);
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,

      };

      //ankit commented
      // this.getSkillLevel(this.companyId, this.selectedRoleValue);
      // this.getAllUsersRole(this.companyId);
      // this.getAllCompany(this.companyId);
      // this.getAllCompany_search(this.companyId);

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
        if (this.value > 0) {
          this.getAllUsersRole(this.value);
          this.getSkillLevel(this.value, this.selectedRoleValue);
        }
        else {
          this.selectedRoleValue = '';
        }
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }
    this.form = new FormGroup({
      'userRole': new FormControl(),
      'firstName': new FormControl(),
      'middleName': new FormControl(),
      'lastName': new FormControl(),
      'phoneNumber': new FormControl(),
      'contactEmail': new FormControl(),
      'password': new FormControl(null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
      'passworConfirm': new FormControl(),
      'skillLevel': new FormControl()
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });

    this.form.controls['skillLevel'].disable();

  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      //this.value_search=0;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.clearDetails();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
      //this.value_search=0;
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  searchUser() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllUsers(this.value_search, this.userSearch);
  }

  AllUser() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    // this.SearchValidation = false;
    this.getAllUsers(this.value_search, '');
    
  }

  getAllUsers(companySearch, searchTerm) {
    if(companySearch>0){
      this.loader = true;
      this.sharedService.getAllUsersWithParameter(companySearch, searchTerm).subscribe(
        data => {
          this.ListShow = true;
          this.ListDiv = true;
          this.ListAngle = "up";
          this.data = data;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.userList = this.data.dataList;
          }
          this.loader = false;
          this.rerender();
        });
    }
    else{
      this.ErrorModal('Error','Please select company.')
    }
  }


  getAllUsersRole(companyId) {
    this.sharedService.getAllUserRolesByUserId(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.userRoleList = this.data.dataList;
        }
        else {
          this.selectedRoleValue = '';
        }
      });
  }

  getSkillLevel(id, roleId) {
    this.sharedService.getSkillLevelUser(id, roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.skillLevelList = this.data.dataList;
        }
        else {
          this.skillLevelList = '';
        }
      });
  }

  // getAllCompany(companyid) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyid).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items = this.data.dataList;
  //       // this.programs1 = this.data.dataList;
  //       this.companyList = this.data.dataList;
  //       this.companyList.forEach((x) => {
  //         this.userGroupObjects.push(new TreeviewItem(x, false));
  //       });
  //       this.value = Number(companyid);
  //     });
  // }

  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //       //this.getAllState();
  //     });
  // }

  changeUserRole(event) {
    if (event) {
      const getRole = this.userRoleList.filter(x => x.id == event);
      this.getSkillLevel(this.value, this.selectedRoleValue);
      if (getRole[0].isSkillRequired == true) {
        this.form.controls['skillLevel'].enable();
      }
      else {
        this.form.controls['skillLevel'].disable();
        this.skillLevel = '0';
      }
    }
    else {
      this.skillLevel = '0';
      this.form.controls['skillLevel'].disable();
    }
    this.selectedRoleValue = event;
  }

  addNewUser() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.userRole.status === this.constants.Invalid ||
      this.form.controls.phoneNumber.status === this.constants.Invalid ||
      this.form.controls.contactEmail.status === this.constants.Invalid ||
      this.form.controls.firstName.status === this.constants.Invalid ||
      this.form.controls.lastName.status === this.constants.Invalid ||
      this.value == 0
    ) {
      this.loader = false;
      return;
    }

    const obj = {};

    obj['userRoleID'] = this.selectedRoleValue;
    obj['firstname'] = this.firstName;
    obj['lastname'] = this.lastName;
    obj['phoneNo'] = this.phoneNumber;
    obj['email'] = this.contactEmail;
    obj['companyId'] = this.value;
    obj['password'] = this.password;
    obj['SkillLevel'] = Number(this.skillLevel);
    this.loader = true;
    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      this.sharedService.updateUser(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                $("#add_update").show();
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                // this.getAllUsers(this.companyId);  
                this.updateMode = true;
                this.id = null;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.clearDetails();
                this.rerender();
                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    } else {
      if (this.form.controls.password.value === undefined ||
        this.form.controls.password.value === null ||
        this.form.controls.password.value === '' ||
        this.form.controls.passworConfirm.value === undefined ||
        this.form.controls.passworConfirm.value === null ||
        this.form.controls.passworConfirm.value === '' ||
        this.password != this.passworConfirm
      ) {
        this.loader = false;
        return;
      }
      obj['CreatedBy'] = this.userId;
      if (this.password != this.passworConfirm) {
        return;
      }
      this.sharedService.addUser(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.validation = false;
                this.clearDetails();
                $("#add_success").show();
                // this.toastr.success(this.constants.RecordAddedSuccessfully);
                this.recordAlreadyExist = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;

                // this.getAllUsers(this.companyId); 
                this.rerender();

                break;
              case 400:
                if (data.errorMessage === 'PasswordNotUpdated') {
                  this.PasswordNotUpdated = true;
                } else {
                  //this.toastr.error(data.errorMessage);
                  $("#password_strength").show();
                  //this.clearDetails();
                  this.passwordLength = data.errorMessage;
                  this.validation = false;
                }
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle = "down";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.updateMode = false;
    this.id = item.userId
    this.value = item.companyId;

    this.selectedRoleValue = item.roleId;
    this.firstName = item.firstName;
    this.lastName = item.lastName;
    this.phoneNumber = item.phoneNumber;
    this.contactEmail = item.email;
    this.selectedItem = item.selectedItem;
    this.password = item.password;
    this.recordAlreadyExist = false;
    // this.config.hasCollapseExpand = true;
    this.clearbutton = false;


    this.sharedService.getAllUserRolesByUserId(this.value, '').subscribe(
      data => {
        this.data = data;
        if (this.value > 0) {
          this.userRoleList = this.data.dataList;
          if (item.skillLevel) {
            this.skillLevel = item.skillLevel;
          }
          else {
            this.skillLevel = '0';
          }
          this.changeUserRole(item.roleId);
        }
        else {
          this.selectedRoleValue = '';
        }
      });
    //this.getAllUsersRole(this.value);

  }
  viewUserDetails(item: any) {
    this.selectedRoleValue = item.roleName;
    this.firstName = item.firstName;
    this.lastName = item.lastName;
    this.phoneNumber = item.phoneNumber;
    this.contactEmail = item.email;
    this.value = item.companyName;

  }
  clearDetails() {
    this.id = null;
    this.updateMode = true;
    this.selectedRoleValue = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    this.contactEmail = '';
    this.selectedItem = '';
    this.password = '';
    this.passworConfirm = '';
    this.value = 0;
    this.buttonValue = this.constants.Save;
    this.validation = false;
    this.recordAlreadyExist = false;
    if (this.companyId > 0) {
      this.value = Number(this.companyId)
    }
    else {
      this.value = 0;
    }

    this.skillLevel = '';
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete ' + item.firstName + '?';
    this.deletedItem = item.userId;
  }


  deleteUser(item: any) {
    this.loader = true;
    this.sharedService.deleteUser(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            // this.getAllUsers(this.companyId);
            this.getAllUsers(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();

            break;
          case 400:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);
        }
        this.loader = false;
      });
  }
  public isValidFlg: boolean = true;
  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');

    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);

    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);

    field.value = formattedNumber;
  }
  onFilterChange(value: string): void {
  }


  onValueChange(value: any): void {
    this.value = value;
    if (this.value > 0) {
      this.getAllUsersRole(this.value);
      this.getSkillLevel(this.value, this.selectedRoleValue);
    }
    else {
      this.selectedRoleValue = '';
    }
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  closeModal() {
    this.selectedRoleValue = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    this.contactEmail = '';
    this.value = 0;
    this.password = '';
    this.router.navigate(['/users']);
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);

      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }


    if (logoImagePath != null && logoImagePath != "") {

      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  RemovedEmailMessage(event) {

    this.recordAlreadyExist = false;
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');

    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");

    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);

  }


  userBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.clearDetails();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  changePassword() {
    this.validation = true;
    let obj = {};
    if (this.form.controls.password.value === undefined ||
      this.form.controls.password.value === null ||
      this.form.controls.password.value === '' ||
      this.form.controls.passworConfirm.value === undefined ||
      this.form.controls.passworConfirm.value === null ||
      this.form.controls.passworConfirm.value === '' ||
      this.password != this.passworConfirm
    ) {
      this.loader = false;
      return;
    }
    obj['password'] = this.password;
    obj['UserId'] = this.id;
    if (this.password != this.passworConfirm) {
      return;
    }
    this.loader = true;
    this.sharedService.changePassword(obj)
      .subscribe(
        data => {
          this.loader = false;
          switch (data.statusCode) {
            case 200:
              $("#pwd_success").show();
              // this.toastr.success(this.constants.PasswordchangeSuccessfully);
              this.recordAlreadyExist = false;
              // this.getAllUsers(this.companyId);
              this.TCreateUpdate = 1;
              this.SearchControl = false;
              this.ListShow = false;
              this.ListDiv = false;
              this.clearDetails();
              this.rerender();
              break;
            case 400:
              if (data.errorMessage === 'PasswordNotUpdated') {
                this.PasswordNotUpdated = true;
              } else {
                //this.toastr.error(data.errorMessage);
                $("#password_strength").show();
                //this.clearDetails();
                this.passwordLength = data.errorMessage;
                this.validation = false;
              }
              break;
            default:
              $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
          }
          this.loader = false;
        }
      );

  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  updatepwd() {
    $("#pwd_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  addPasswordStrength() {
    $("#password_strength").hide();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
