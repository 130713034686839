import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {
  message: string = ""
  cancelButtonText = "Cancel"
  emailTemp: FormGroup;
  emailFrom: { id: number; value: string; }[];
  emailTo: { id: number; value: string; }[];
  userId: string;
  CustomerId: any;
  CompanyId: number = 0;
  VendorId: any;
  tempList: any;
  SendMailPoId: any;
  selectedOption: any;
  templateText: any;
  buyerName: string;
  text: any;
  showCc: boolean = false;
  showBcc: boolean = false;
  tempText: any;
  ImgUrl: any;
  emailObj: any = {};
  configObj: any;
  ErrorMsg: string = '';
  loader: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EmailTemplateComponent>,
    private constants: ConstantsService,
    private fb: FormBuilder,
    private sharedService: SharedService) {
    this.userId = localStorage.getItem(this.constants.userId);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
    if(this.data.type == 1) {
      this.emailObj = this.data;
      this.CompanyId = this.emailObj.comId;
      this.todo();
    }else if (this.data.type == 2) {
      this.emailObj = this.data;
      this.todo();
    }else {
      this.emailObj = this.data;
      this.CompanyId = this.emailObj.comId;
      this.todo();
    }
  }

  GetAPIKey() {
    return this.constants.EditorKeyTinyMCE;
  }

  todo() {
    this.emailTempFormCreate();
    this.isChange(this.emailObj.type)
    if(this.emailObj.type!=0){
      this.GetConfig();
    }
  }

  emailTempFormCreate() {
    this.emailTemp = new FormGroup({
      'docId': new FormControl(this.emailObj.docId),
      'docNo': new FormControl(this.emailObj.docNo),
      'from': new FormControl(this.emailObj.from, [Validators.required, Validators.email]),
      'sendTo': new FormControl(this.emailObj.to, [Validators.required, Validators.email]),
      'subject': new FormControl('', Validators.required),
      'message': new FormControl(''),
      'eTemp': new FormControl(''),
      'files': new FormArray([]),
      'priority': new FormControl('0', Validators.required),
      "txtEmailSendCC": new FormControl('', Validators.email),
      "txtEmailSendBCC": new FormControl('', Validators.email),
    })
    if (this.emailObj.files != undefined) {
      this.addfiles()
    }
  }

  isChange(data) {
    if(data!=0) {
      this.emailTemp.controls.eTemp.setValidators(this.setRequired());
    } else {
      this.emailTemp.controls.eTemp.clearValidators();
    }
  }

  setRequired() {
		return [Validators.required];
	}

  addfiles() {
    const control = this.filesGet;
    this.emailObj.files.forEach(element => {
      control.push(this.fb.control({
        'name': (element.name + '.' + element.type),
        'base64': (element.base64),
      }))
    });
  }

  get filesGet(): FormArray {
    return this.emailTemp.controls.files as FormArray
  }

  sendMail() {
    // if(this.configObj==undefined || this.configObj=='' || this.configObj==null){
    //   return;
    // }
    const obj = {
      "DocId": Number(this.emailObj.docId),
      "EmailTo": this.emailTemp.value.sendTo,
      "EmailCC": this.emailTemp.value.txtEmailSendCC,
      "EmailBcc": this.emailTemp.value.txtEmailSendBCC,
      "Priority": Number(this.emailTemp.value.priority),
      "Subject": this.emailTemp.value.subject,
      "Files": this.emailTemp.value.files,
      "EmailTemplate": this.emailTemp.value.message,
      "SenderEmail": this.emailTemp.value.from,
      "Smtp": this.emailObj.type == 0 ? this.emailObj.smtp :this.configObj.smtp,
      "Port": this.emailObj.type == 0 ? this.emailObj.port : this.configObj.port,
      "AppPassword": this.emailObj.type == 0 ? this.emailObj.appPassword : this.configObj.appPassword,
      "userId": this.userId
    }
    if (this.data.type == 1) {
      this.loader = true;
      this.sharedService.SendEmail(obj).subscribe(
        (data: any) => {
          this.loader = false;
          this.dialogRef.close({success: true, msg: this.data});
        },
        (error: any) => {
          this.loader = false;
          this.ErrorMsg = error;
          this.dialogRef.close({success: false, msg: this.ErrorMsg});
        }
      )
    } else if (this.data.type == 2) {
      this.loader = true;
      this.sharedService.SendSOEmail(obj).subscribe(
        (data: any) => {
          this.loader = false;
          this.dialogRef.close({ success: true, msg: this.data });
        },
        (error: any) => {
          this.loader = false;
          this.ErrorMsg = error;
          this.dialogRef.close({ success: false, msg: this.ErrorMsg });
        }
      )
    } else {
      this.loader = true;
      this.sharedService.SendEmail(obj).subscribe(
        (data: any) => {
          this.loader = false;
          this.dialogRef.close({ success: true, msg: this.data });
        },
        (error: any) => {
          this.loader = false;
          this.ErrorMsg = error;
          this.dialogRef.close({ success: false, msg: this.ErrorMsg });
        }
      )
    }
  }

  Canel() {
    this.dialogRef.close({ success: false, msg: '' });
  }

  GetConfig() {
    this.ErrorMsg = '';
    this.GetAllTemplate();
    if (this.CompanyId > 0) {
      this.sharedService.GetConfigForSendEmail(this.CompanyId, this.data.type).subscribe(
        (data: any) => {
          if (data.data != null && data.data != undefined) {
            this.configObj = data.data;
            if (this.configObj.senderEmail != this.emailTemp.value.from && (this.emailTemp.value.from != null && this.emailTemp.value.from != undefined && this.emailTemp.value.from != '')) {
              this.ErrorMsg = "Configurated email(" + this.configObj.senderEmail + ") for PO is mismatch with the Sender Email."
            }
          } else {
            this.ErrorMsg = "Please set email configuration before sending mail.";
          }
        });
    }
  }
  //Template List bind Into Drodpwon List.
  GetAllTemplate() {
    this.sharedService.GetAllEmailTemplate(this.CompanyId, '', this.data.type).subscribe(
      (data: any) => {
        if (data.dataList != '' || data.dataList != null) {
          this.tempList = data.dataList;
          if (this.tempList.length > 0) {
            if (this.tempList[0].tempName == "Sales ") {
              let result = this.tempList[0]
              this.templateText = result.tempText;
              if (this.templateText.includes("{Kathleen}")) {
                this.buyerName = localStorage.getItem('username')
                this.templateText = this.templateText.replaceAll("{Kathleen}", this.buyerName)
              }
              this.emailTemp.patchValue({
                eTemp: result.tempName,
                message: this.templateText
              })
            }
          }
        }
      }
    )
  }
  getInnerHTML(val) {
    return val.replace(/(<([^>]+)>)/ig, '');
  }

  ChangeTemplate($event) {
    var tempId = $event.value;
    if (tempId) {
      const maildetails = this.tempList.find(x => x.id == tempId);
      this.emailTemp.patchValue({
        message: this.ReplaceText(maildetails.tempText),
        subject: this.ReplaceText(maildetails.subject),
      })
    }
  }

  ReplaceText(tempText): string {
    if (tempText != '' && tempText != undefined && tempText != null) {
      if (this.data.type == 2) {
        if (tempText.includes("##CustomerName##"))
          tempText = tempText.replaceAll("##CustomerName##", this.data.BillTo.name);
        if (tempText.includes("##SalesOrder##"))
          tempText = tempText.replaceAll("##SalesOrder##", this.data.Order.orderNumber);
      } else if (this.data.type == 1) {
        if (tempText.includes("##VendorName##"))
          tempText = tempText.replaceAll("##VendorName##", this.emailObj.venName);
        if (tempText.includes("##PurchaseOrder##"))
          tempText = tempText.replaceAll("##PurchaseOrder##", this.emailObj.docNo)
      }
    }
    return tempText;
  }

  getCcInput() {
    this.showCc = true;
  }

  getBCcInput() {
    this.showBcc = true;
  }

  viewImage(value) {
    if (value != null && value != '') {
      var value1 = value.split(",");
      var blob = this.b64toBlob(value1[1], "application/pdf");
      let a = document.createElement("a");
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
    }
  }

  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  get emailfileGet(): FormArray {
    return this.emailTemp.controls.files as FormArray
  }

  deleteFile(index: number) {
    this.emailfileGet.removeAt(index);
  }
}
