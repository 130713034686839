import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, DebugElement } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { PartService } from 'src/app/services/part/part.service';
import { CompanyService } from 'src/app/services/company/company.service';

declare var $: JQueryStatic

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})

export class InventoryComponent implements AfterViewInit, OnDestroy, OnInit {
  recordAlreadyExist = false;
  NonInventory: Boolean = false;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  datalist: any;
  userId: string;
  id: any;
  companyId: any;
  searchPartId: any;
  searchVendorId: any;
  form: FormGroup;
  buttonValue: string;
  validation: boolean = false;
  loader: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  deleteDetail: string;
  deletedItem: string;
  displayPartName: any;
  displayPartNo: any;
  displayVendorPartName: any;
  displayVendorPartNo: any;
  displayVendorName: any;
  displayMinInventory: any;
  displayReorderQty: any;
  displayOldLocation: any;


  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;

  formSearch: FormGroup;
  companyList: any;
  userGroupObjects = new Array();

  invDiv: boolean = false;
  isInvFind: boolean = false;
  InvValidation: boolean = false;
  InvValidationUpdate: boolean = false;
  InvList = [];
  LocalInvList = [];
  InvDetails = [];
  normalShow = true;
  partId: number;
  InvSno = 1;
  InvSnoEdit = 100000;
  InvQty: any;
  InvDate: any;
  InvCost: any;
  InvType = '';
  InvPartOrder = '';
  InvProductOrder = '';
  InvType2: any;
  InvTypeList: any;
  InvCustNo: any;
  InvServNo: any;
  InvCustRMA: any;
  InvVendor = '';
  InvLocName: any = '';
  InvLocName1: any = '';
  InvPartTracking = '';
  InvPartLocation: any;
  UpdateInvQty: any;
  UpdateInvDate: any;
  UpdateInvCost: any;
  UpdateInvType = '';
  UpdateInvPartOrder = '';
  UpdateInvProductOrder = '';
  UpdateInvServNo: any;
  UpdateInvCustNo: any;
  UpdateInvCustRMA: any;
  UpdateInvVendor: any;
  UpdateInvPartLocation: any;
  DelRowNo: any;
  DelInvId: any;
  RowEdit: any;
  CurrentEditRow: any;
  EditRowTxtName: any;
  EditInvSno: any;
  btnShowvalidation: boolean = false;
  btnOkShow: boolean = false;
  now: Date;
  SelectedLocation = '';
  SelectedPartLocation = '';
  InvPartOrderDDL: any;
  InvProductOrderDDL: any;
  MinDate: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;

  SearchValidation: any;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  vendorSearch = '0';
  vendorListDDL: any;
  partVendorsList: any;
  searchType = '1';
  searchText: any;
  partSearch = '';
  partList: any;
  partListDDL: any;
  TotalIn: number = 0;
  TotalOut: number = 0;
  MoveAdjustDDLId: number;

  //Move Popup Properties
  formPopup: FormGroup;
  mInvValidation: boolean = false;
  mInvQtyValidation: boolean = false;
  mQtyMoveMsg: boolean = false;
  mMsg: any;
  mMsgClass: any;
  mInvQty: any;
  mInvQtyToMove: any;
  mInvDate: any;
  mInvCost: any;
  mInvType = '';
  mInvPartOrder = '';
  mInvProductOrder = '';
  mInvType2: any;
  mInvTypeList: any;
  mInvPartOrderDDL: any;
  mInvProductOrderDDL: any;
  mInvCustNo: any;
  mInvServNo: any;
  mInvCustRMA: any;
  mInvVendor: any;
  mInvLocation: any;
  mInvLocation2 :any = '';
  mInvLocation1 :any = '';
  mInvReason: any;
  mValidateQtyToFulfill: any;

  //Adjust Popup Properties
  formPopupAdjustQty: FormGroup;
  adjInvValidation: boolean = false;
  adjInvQty: any;
  adjInvQtyToAdjust: any;
  adjInvDate: any;
  adjInvCost: any;
  adjInvReason: any;
  adjInvReasonList = this.constants.InventoryAdjustReason;
  invlocationLevelList = [];
  locationLevelMoveList = [];
  MainInvRowNo: number;
  viewLog: boolean = false;
  totalQty: number = 0;
  totalQtyLog: number = 0;
  logList = [];
  logRowColor: any;
  confirmExit: boolean = false;
  isPartval: boolean = true;
  clickedFromOpenDrop: boolean;
  fieldToSearch = '3';
  locationNameList: any;
  locationNameMoveList : any;
  fieldToSearchPlaceHolder = 'Enter List or Vendor Part #';
  globalLocationNameList = [];
  //serial
  qtyLabel = [];
  qtyLabelNew = [];
  qtyPartName: any;
  inventoryQty: any;
  SerialList = [];
  exitProp: boolean = false;
  IsSerialDisabled: boolean = false;
  openId: any;
  Tracking: any;
  AddSerial: boolean = false;
  DeleteSerialArr = [];
  serialNumDiff: number;
  SerialComment: string;
  DSerialTracking: any;
  AddSerials = [];
  MoveSerialArr = [];
  moveDiff: number;
  @ViewChild('QtyMoveMsgModel', { static: false }) private QtyMoveMsgModel;
  @ViewChild('itemModel', { static: false }) private itemModel;
  @ViewChild('itemModel2', { static: false }) private itemModel2;
  InvProp: string;
  PartTrackingList = [];
  newlocationLevelList = [];
  openTracking: any;
  displayPartTracking: any;
  items_search: any;
 

  constructor(public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private partService: PartService,
    private companyService: CompanyService
  ) {
    this.getTrackingList();
  }


  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.clickedFromOpenDrop = false;

      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1018);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyId = localStorage.getItem(this.constants.companyId);

      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      //this.getAllCompany_search(this.companyId);
      // this.Getvendordropdownlist(this.companyId);
      this.InvTypeDropdownList();
      // this.getLocationLevel(this.companyId);

      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value_search = company;
        this.Getvendordropdownlist(this.value_search);
        this.getLocationLevel(this.value_search);
        // this.getAllCompany_search(company);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange_search(data);
      })
    }
    let today = new Date();
    this.MinDate = this.datepipe.transform(today, 'yyyy-MM-dd');
    this.form = new FormGroup({
      'InvQty': new FormControl('', Validators.required),
      'InvDate': new FormControl('', Validators.required),
      'InvCost': new FormControl('', Validators.required),
      'InvType': new FormControl('', Validators.required),
      'InvCustRMA': new FormControl(),
      'InvVendor': new FormControl('', Validators.required),
      'InvLocName': new FormControl('', Validators.required),
      'InvLocName1': new FormControl('', Validators.required),
      'InvPartTracking': new FormControl('', Validators.required),
      'InvPartLocation': new FormControl(),
      'InvServNo': new FormControl('', Validators.required),
      'InvPartOrder': new FormControl('', Validators.required),
      'InvProductOrder': new FormControl('', Validators.required),
      'UpdateInvQty': new FormControl('', Validators.required),
      'UpdateInvDate': new FormControl('', Validators.required),
      'UpdateInvCost': new FormControl('', Validators.required),
      'UpdateInvType': new FormControl('', Validators.required),
      'UpdateInvServNo': new FormControl('', Validators.required),
      'UpdateInvCustRMA': new FormControl(),
      'UpdateInvVendor': new FormControl(),
      'UpdateInvPartLocation': new FormControl(),
      'UpdateInvPartOrder': new FormControl('', Validators.required),
      'UpdateInvProductOrder': new FormControl('', Validators.required),

      //end
    });
    this.formSearch = new FormGroup({
      'vendorSearchDDL': new FormControl(),
      'searchTypeDDL': new FormControl(),
      'searchText': new FormControl(),
      'partSearch': new FormControl(),
      'fieldToSearch': new FormControl()
    });

    this.formPopup = new FormGroup({
      'mInvQty': new FormControl('', Validators.required),
      'mInvQtyToMove': new FormControl('', Validators.required),
      'mInvDate': new FormControl('', Validators.required),
      'mInvCost': new FormControl('', Validators.required),
      'mInvType': new FormControl('', Validators.required),
      'mInvCustRMA': new FormControl(),
      'mInvVendor': new FormControl(),
      'mInvServNo': new FormControl('', Validators.required),
      'mInvPartOrder': new FormControl('', Validators.required),
      'mInvProductOrder': new FormControl('', Validators.required),
      'mInvLocation': new FormControl(),
      'mInvLocation2': new FormControl('', Validators.required),
      'mInvLocation1': new FormControl('', Validators.required),
      'mInvReason': new FormControl(),
    });

    this.formPopupAdjustQty = new FormGroup({
      'adjInvQty': new FormControl('', Validators.required),
      'adjInvQtyToAdjust': new FormControl('', Validators.required),
      'adjInvDate': new FormControl('', Validators.required),
      'adjInvCost': new FormControl('', Validators.required),
      'adjInvType': new FormControl('', Validators.required),
      'adjInvCustRMA': new FormControl(),
      'adjInvServNo': new FormControl('', Validators.required),
      'adjInvPartOrder': new FormControl('', Validators.required),
      'adjInvProductOrder': new FormControl('', Validators.required),
      'adjInvLocation': new FormControl(),
      'adjInvReason': new FormControl(),
    });
  }

  getTrackingList(){
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if(data.length>0){
          this.PartTrackingList = data;
        }
      }
    )
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  closenoti() {
    this.mQtyMoveMsg = false;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
        if (this.value_search) {
          //   this.InvProductOrderDropdownList();
          //  this.InvPartOrderDropdownList();
        }
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.Getvendordropdownlist(this.value_search);
    this.getLocationLevel(this.value_search);
  }

  getLocationLevel(id) {

    this.sharedService.getLocationLevel(id, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationNameList = this.data.dataList.filter(x => x.locationTypeId == 1);
          this.globalLocationNameList = this.data.dataList;
          this.locationNameMoveList = this.data.dataList.filter(x => x.locationTypeId == 1);
        }
        else {
          this.locationNameList = '';
          this.locationNameMoveList = '';
        }
      });
  }

  changeLocationNew(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.SelectedLocation = value;
    this.getPartLocationLevelInv(1, value2);

  }

  changeLocationMove(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.getPartLocationLevelMove(1, value2);

  }
  getPartLocationLevelMove(id, locationName) {
    this.sharedService.getPartLocationLevel(id, locationName, this.value_search).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationLevelMoveList = this.data.dataList;
        }
        else {
          this.locationLevelMoveList = [];
        }
      });
  }

  getPartLocationLevelInv(id, locationName) {

    this.sharedService.getPartLocationLevel(id, locationName, this.value_search).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.invlocationLevelList = this.data.dataList;
        }
        else {
          this.invlocationLevelList = [];
        }
      });
  }

  Getvendordropdownlist(companyId) {
    this.loader = true;
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (companyId > 0) {
          this.vendorListDDL = this.data.dataList;
        }
        else {
          this.vendorSearch = '0';
        }
      }
    )
  }

  GetPartVendors() {
    this.loader = true;
    this.sharedService.GetPartVendors(this.value_search, this.searchPartId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (Number(this.value_search) > 0) {
          this.partVendorsList = this.data.dataList;
        }
        else {
          this.partVendorsList = null;
        }
      }
    )
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  searchInvPartList() {
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    else {
      this.invDiv = false;
      this.isInvFind = false;
      this.viewLog = false;
      this.loader = true;
      if (this.value_search == undefined) { this.value_search = 0; }
      this.sharedService.GetInventoryPartList(this.value_search, this.vendorSearch, this.fieldToSearch, this.partSearch).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.partList = this.data.dataList;
            this.rerender();
            this.ListShow = true;
            this.ListDiv = true;
            this.ListAngle = "up";
            this.InvValidation = false;
          }
        }
      )
    }
  }

   searchInvF(partData) {
    this.isPartval = true;
    this.displayVendorName = partData.vendorName;
    this.displayPartName = partData.name;
    this.displayPartNo = partData.partNo;
    this.displayVendorPartName = partData.vendorPartName;
    this.displayVendorPartNo = partData.vendorPartNumber;
    this.displayMinInventory = partData.minInventory;
    this.displayReorderQty = partData.reorderAmount;
    this.totalQty = !partData.totalAllType ? 0 : partData.totalAllType;
    this.totalQtyLog = !partData.totalInvLog ? 0 : partData.totalInvLog;
    this.searchPartId = partData.id;
    this.searchVendorId = partData.vendorId;
    this.NonInventory = partData.nonInventory;
    this.displayPartTracking = partData.partTracking;
    this.InvPartTracking = partData.partTracking == 0 ? '' : partData.partTracking;
    this.InvLocName  = partData.partLocationName;
    this.getPartLocationLevelInv(1, partData.locationName);
    this.InvLocName1 = partData.partLocation == undefined ? '' : partData.partLocation;
    this.GetPartVendors();
    this.searchInv();
  }

  

  searchInv() {
    this.viewLog = false;
    this.invDiv = true;
    this.loader = true;
    this.ListDiv = false;
    this.ListAngle = "down";
    this.sharedService.GetInventory(this.value_search, this.searchVendorId, this.searchPartId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (this.data.dataList.length > 0) {
            this.isInvFind = true;
            this.InvSno = 1;
            this.InvList = [];
            this.LocalInvList = [];
            for (var item = 0; item < this.data.dataList.length; item++) {
              let LocalInvType2 = '';
              if (this.data.dataList[item].typeId == "5") {
                LocalInvType2 = this.data.dataList[item].serviceNo;
              }
              else if (this.data.dataList[item].typeId == "6") {
                LocalInvType2 = this.data.dataList[item].partOrderId;
              }
              else if (this.data.dataList[item].typeId == "7") {
                LocalInvType2 = this.data.dataList[item].productOrderId;
              }

              let qty: number = 0;
              let orderqty: number = 0;
              if (this.data.dataList[item].purchaseOrderId == null || this.data.dataList[item].purchaseOrderId == "") {
                qty = this.data.dataList[item].qty;
              }
              else {
                let recQty = this.data.dataList[item].qty == null || this.data.dataList[item].qty == "" ? 0 : this.data.dataList[item].qty;
                let shipQty = this.data.dataList[item].shipQty == null || this.data.dataList[item].shipQty == "" ? 0 : this.data.dataList[item].shipQty;
                qty = parseInt(recQty) - parseInt(shipQty);
              }

              orderqty = this.data.dataList[item].orderedQty == null || this.data.dataList[item].orderedQty == "" ? 0 : this.data.dataList[item].orderedQty;

              let obj = {
                InvSno: this.InvSno,
                id: this.data.dataList[item].id,
                partId: this.data.dataList[item].partId,
                InvQty: qty,
                InvQtyOrdered: orderqty,
                InvDate: this.datepipe.transform(this.data.dataList[item].date, 'yyyy-MM-dd'),
                InvCost: this.data.dataList[item].cost,
                InvType: this.data.dataList[item].typeId,
                InvType2: LocalInvType2,
                InvCustRMA: this.data.dataList[item].customerRma,
                InvVendor: this.data.dataList[item].vendorId,
                InvPartLocation: this.data.dataList[item].partLocation,
                purchaseOrderId: this.data.dataList[item].purchaseOrderId,
                isActive: this.data.dataList[item].isActive,
                createdBy: this.data.dataList[item].createdBy,
                createdDate: this.data.dataList[item].createdDate,
                modifiedBy: this.data.dataList[item].modifiedBy,
                modifiedDate: this.data.dataList[item].modifiedDate,
                InvLoc: String(this.data.dataList[item].locationName),
                InvLoc1: String(this.data.dataList[item].location),
                InvTracking: this.data.dataList[item].tracking,
                obj1: this.data.dataList[item].partSerialNumber,
              }

              if (Number(qty) != 0) {
                this.InvList.push(obj);
                this.InvSno += 1;
              }
            }

            this.LocalInvList = this.InvList;
          }
          else {
            this.isInvFind = false;
            this.InvSno = 1;
            this.InvList = [];
            this.LocalInvList = [];

          }
          this.loader = false;
        }
        this.InvProductOrderDropdownList1(this.searchPartId);
        this.InvPartOrderDropdownList1(this.searchPartId);
      }

    )
  }
  InvExist1() {
    $('#InvExist1').hide();
  }

  viewLogFun() {
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    else {
      this.loader = true;
      this.sharedService.GetLog(this.value_search, this.searchPartId).subscribe(
        data => {
          this.data = data;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (this.data.dataList.length > 0) {
              this.viewLog = !this.viewLog;
              this.logList = this.data.dataList;
              this.logList.forEach(logLst => {

                let inQty = logLst.inQty == null || logLst.inQty == "" ? 0 : logLst.inQty;
                let outQty = logLst.outQty == null || logLst.outQty == "" ? 0 : logLst.outQty;

                logLst.id = logLst.id;
                logLst.action = logLst.action;
                logLst.type = logLst.type;
                logLst.typeValue = logLst.typeValue;
                logLst.salesOrderNo = logLst.salesOrderNo;
                logLst.inQty = inQty;
                logLst.outQty = outQty;
                logLst.effectDate = this.datepipe.transform(logLst.effectDate, 'MMM-dd-yyyy');
                logLst.remark = logLst.remark;
                logLst.location = logLst.location;
                logLst.isActive = logLst.isActive;
                if (logLst.isActive == false) {
                  logLst.bcolor = this.constants.FireBrick;
                }
                else {
                  logLst.bcolor = '';
                }
              });
            }
            else {
              this.viewLog = false;
              this.logList = [];
            }
            this.loader = false;
          }
        }
      )
    }
  }

  refillInventory() {
    this.InvList = this.LocalInvList.filter(item => item.isActive == true);
  }

  InvTypeDropdownList() {
    this.sharedService.GetPOTypeDetail().subscribe(
      data => {
        this.data = data;
        this.InvTypeList = this.data.dataList;
        this.mInvTypeList = this.data.dataList;
      }
    )
  }

  InvProductOrderDropdownList() {
    // Pass 1 For Product Order
    this.partService.PartInvOrderDropdownList(1, this.value_search).subscribe(
      data => {
        this.data = data;
        this.InvProductOrderDDL = this.data.dataList;
        this.mInvProductOrderDDL = this.data.dataList;
      }
    );
  }


  InvProductOrderDropdownList1(partId: any) {
    // Pass 1 For Product Order
    this.partService.PartInvOrderDropdownList1(1, this.value_search, partId).subscribe(
      data => {
        this.data = data;
        this.InvProductOrderDDL = this.data.dataList;
        this.mInvProductOrderDDL = this.data.dataList;
      }
    );
  }


  InvPartOrderDropdownList() {
    // Pass 2 For Part Order
    this.partService.PartInvOrderDropdownList(2, this.value_search).subscribe(
      data => {
        this.data = data;
        this.InvPartOrderDDL = this.data.dataList;
        this.mInvPartOrderDDL = this.data.dataList;
      }
    );
  }



  InvPartOrderDropdownList1(partId: any) {
    // Pass 2 For Part Order
    this.partService.PartInvOrderDropdownList1(2, this.value_search, partId).subscribe(
      data => {
        this.data = data;
        this.InvPartOrderDDL = this.data.dataList;
        this.mInvPartOrderDDL = this.data.dataList;
      }
    );
  }

  resetvendorData() {
    this.InvValidation = false;
    this.recordAlreadyExist = false;
    this.InvQty = '';
    this.InvDate = '';
    this.InvCost = '';
    this.InvType = '';
    this.InvCustNo = '';
    this.InvServNo = '';
    this.InvCustRMA = '';
    this.InvVendor = '';
    this.InvPartLocation = '';
    this.InvList = [];
    this.InvDetails = [];
    this.LocalInvList = [];
    this.invDiv = false;
    this.isInvFind = false;
    this.viewLog = false;
  }

  GetInvTypeDDLText(InvTypeId) {
    return this.InvTypeList.find(s => s.id == InvTypeId).name;
  }

  GetInvType2DDLText(InvTyepValue, InvTypeValue2) {
    if (InvTyepValue == "6") {
      if (this.InvPartOrderDDL != undefined) {
        const InvData = this.InvPartOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else if (InvTyepValue == "7") {
      if (this.InvProductOrderDDL != undefined) {
        const InvData = this.InvProductOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else {
      return InvTypeValue2;
    }

  }

  GetVendorNameById(vId) {
    const vData = this.partVendorsList.filter(x => x.id == vId);
    if (vData.length > 0) {
      return vData[0].name;
    }
    else {
      return '';
    }
  }

  ChangeOrderDropdown(value) {
    if (value) {
      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.loader = true;
      this.sharedService.GetOrderPartQtyToFulfill(value, this.searchPartId).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          this.mInvQtyToMove = this.data.data;
          this.mValidateQtyToFulfill = this.data.data;
        }
      );
    }
  }

  AddInv() {
    if (this.form.controls.InvQty.status === this.constants.Invalid ||
      this.form.controls.InvDate.status === this.constants.Invalid ||
      this.form.controls.InvCost.status === this.constants.Invalid ||
      this.form.controls.InvType.status === this.constants.Invalid ||
      this.form.controls.InvVendor.status === this.constants.Invalid
    ) {
      this.InvValidation = true;
      return;
    }
    else {
      if (this.InvType == "5") {
        if (this.form.controls.InvServNo.status === this.constants.Invalid) {
          this.InvValidation = true;
          return;
        }
      }
      if (this.InvType == "6") {
        if (this.form.controls.InvPartOrder.status === this.constants.Invalid) {
          this.InvValidation = true;
          return;
        }
      }
      if (this.InvType == "7") {
        if (this.form.controls.InvProductOrder.status === this.constants.Invalid) {
          this.InvValidation = true;
          return;
        }
      }

      this.InvValidation = false;
      if (this.InvType == "5") {
        this.InvType2 = this.InvServNo;
      }
      else if (this.InvType == "6") {
        this.InvType2 = Number(this.InvPartOrder);

      }
      else if (this.InvType == "7") {
        this.InvType2 = Number(this.InvProductOrder);
      }
      else {
        this.InvType2 = '';
      }
      this.isPartval = false;
      this.qtyLabel = [];
      if(this.InvPartTracking != "3" ){
        this.inventoryQty = this.InvQty;
        for (var i = 1; i <= this.InvQty; i++) {
          this.qtyPartName = this.displayPartName;
          var obj = {
            id: i, part: this.displayPartName + ' ' + i,
            serialNo: '', valid: false, type: this.InvPartTracking == "1" ? 'Serial' : 'Lot', message: '', isActive: true,
            ordering: i
          }
          this.exitProp = false;
          this.IsSerialDisabled = false;
          this.Tracking = this.InvPartTracking;
          this.qtyLabel.push(obj);
        }
        $('#SerialModel').show();
      }
      else{
       this.AddInventoryRecord();
      }
    }

  }

  AddInventoryRecord() {
    $('#SerialModel').hide();
    this.qtyLabelNew = this.qtyLabel;
    this.qtyLabel = [];
    let obj = {
      InvSno: this.InvSno,
      partId: this.searchPartId,
      InvQty: Number(this.InvQty),
      InvQtyOrdered: Number(this.InvQty),
      InvDate: this.InvDate,
      InvCost: Number(this.InvCost),
      InvType: Number(this.InvType),
      InvType2: this.InvType2,
      InvCustRMA: this.InvCustRMA,
      InvVendor: Number(this.InvVendor),
      InvPartLocation: this.InvPartLocation,
      isActive: true,
      createdBy: this.userId,
      createdDate: this.now = new Date(),
      InvLoc: this.InvLocName,
      InvLoc1: this.InvLocName1,
      InvTracking: this.InvPartTracking,
      obj1: this.qtyLabelNew,
      TrackingUpdate: 0
    }
    if (obj.InvType == 6 || obj.InvType == 7) {
      this.loader = true;
      this.sharedService.GetCheckInventory(obj.InvType2, obj.InvQty, obj.partId).subscribe(
        data => {
          this.data = data;
          this.loader = false;
          if (this.data.data == "0") {
            obj.obj1.forEach(element => {
              element.isSale = true;
            });
            this.LocalInvList.push(obj);
            this.InvSno += 1;
            this.isPartval = false;
            this.refillInventory();
            this.InvQty = '';
            this.InvDate = '';
            this.InvCost = '';
            this.InvType = '';
            this.InvType2 = '';
            this.InvServNo = '';
            this.InvPartOrder = '';
            this.InvProductOrder = '';
            this.InvCustRMA = '';
            this.InvVendor = '';
            this.InvPartLocation = '';
          }
          else {
            this.InvProp = "You can assign only " + this.data.data + " qty on this order ";
            $('#InvExist1').show();
          }
        });
    }
    else {
      this.LocalInvList.push(obj);
      this.InvSno += 1;
      this.isPartval = false;
      this.refillInventory();
      this.InvQty = '';
      this.InvDate = '';
      this.InvCost = '';
      this.InvType = '';
      this.InvType2 = '';
      this.InvServNo = '';
      this.InvPartOrder = '';
      this.InvProductOrder = '';
      this.InvCustRMA = '';
      this.InvVendor = '';
      this.InvPartLocation = '';
    }

  }

  SaveDetail() {
    let count = 0; let messageCount = 0; let sameCount = 0;
    const sameSerial = [];
    const filterElement = this.qtyLabel;
    if (filterElement[0].number == '') {
      filterElement[0].message = '';
    }
    else {
      filterElement[0].message = '';
    }
    this.qtyLabel.forEach(element => {
      if (element.serialNo == '') {
        element.valid = true;
        count++;
      }
      else {
        element.valid = false;
      }
    });
    if (count == 0) {
      let serial = '';
      this.qtyLabel.forEach(partLst => {
        sameSerial.push(partLst.serialNo);
        serial += partLst.serialNo + ':' + '' + ',';
      });

      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
      const duplicate = [...new Set(findDuplicates(sameSerial))];
      if (duplicate.length == 0) {
        if (serial) {
          serial = serial.slice(0, -1);
        }
        this.loader = true;
        this.partService.GetSerialExist(serial, this.searchPartId == undefined ? 0 : this.searchPartId, this.InvPartTracking).subscribe(
          data => {
            this.data = data;
            const list = this.data.dataList;
            this.loader = false;
            if (list.length > 0) {
              list.forEach(element => {
                this.loader = false;
                const getSerial = this.qtyLabel.filter(x => x.serialNo == element.serialNo);
                getSerial[0].message = this.InvPartTracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                messageCount = messageCount + 1;
              });
            }
            if (messageCount == 0) {
              this.AddInventoryRecord();
            }
            else {

            }
          });
      }
      else {
        if (duplicate.length > 0) {
          duplicate.forEach(element => {
            const getSerial = this.qtyLabel.filter(x => x.serialNo == element);
            getSerial.forEach(element => {
              element.message = this.InvPartTracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
            });
          });
        }
      }

    }
    else {
      if(this.qtyLabel.length > 15){
        $('#GeneralModel').show();
        var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
        this.SerialComment = "Please Add " + count + s;
      }
      return;
    }
  }
  CloseModel() {
    $('#SerialModel').hide();
  }

  InvExist() {
    $('#InvExist').hide();
  }

  MoveInvRow(InvItem) {
    this.MoveInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.mInvQty = InvItem.InvQty;
    this.mInvDate = InvItem.InvDate;
    this.mInvCost = InvItem.InvCost;
    this.mInvType = InvItem.InvType;
    this.mInvLocation2 = InvItem.InvLoc == undefined ? '' : InvItem.InvLoc;
    this.mInvLocation1 = InvItem.InvLoc1 == "undefined" ? '' : InvItem.InvLoc1;
    this.mInvLocation2 = '';
    this.mInvLocation1 = '';
    this.mInvCustRMA = InvItem.InvCustRMA;
    this.mInvVendor = Number(InvItem.InvVendor);
    this.mInvLocation = InvItem.InvPartLocation;
    this.displayOldLocation = InvItem.InvPartLocation;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });
  }

  MoveInv() {
    if (this.formPopup.controls.mInvQty.status === this.constants.Invalid ||
      this.formPopup.controls.mInvQtyToMove.status === this.constants.Invalid ||
      this.formPopup.controls.mInvDate.status === this.constants.Invalid ||
      this.formPopup.controls.mInvType.status === this.constants.Invalid ||
      this.formPopup.controls.mInvLocation1.status === this.constants.Invalid ||
      this.formPopup.controls.mInvLocation2.status === this.constants.Invalid
    ) {
      this.mInvValidation = true;
      return;
    }
    else {
      if (this.mInvType == "5" && this.formPopup.controls.mInvServNo.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "6" && this.formPopup.controls.mInvPartOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "7" && this.formPopup.controls.mInvProductOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }

      if ((this.mInvType == "6" || this.mInvType == "7") && Number(this.mInvQtyToMove) > Number(this.mValidateQtyToFulfill)) {
        this.mQtyMoveMsg = true;
        this.mInvQtyValidation = true;
        this.mInvValidation = true;
        return;
      }
      else {
        if (Number(this.mInvQtyToMove) > Number(this.mInvQty)) {
          this.mQtyMoveMsg = false;
          this.mInvQtyValidation = true;
          this.mInvValidation = true;
          return;
        }
      }

      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.mInvValidation = false;

      if (this.mInvType == "5") {
        this.mInvType2 = this.mInvServNo;
      }
      else if (this.mInvType == "6") {
        this.mInvType2 = Number(this.mInvPartOrder);

      }
      else if (this.mInvType == "7") {
        this.mInvType2 = Number(this.mInvProductOrder);
      }
      else {
        this.mInvType2 = '';
      }

      this.moveDiff = Number(this.mInvQtyToMove);
      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {
        if(InvData[0].InvTracking != "3" && InvData[0].InvTracking != "0"){
          this.MoveSerialArr = InvData[0].obj1.filter(x => x.isActive == true);
          this.DSerialTracking = InvData[0].InvTracking;
          $('#MoveSerials').show();
        }
        else{
       this.MoveInventoryMethod();
        }
      }

    }
  }
  CloseMoveSerials(){
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length > 0) {
      checkDeleteSerial.forEach(element => {
        element.isActive = true;
      });
    }
    $('#MoveSerials').hide();
  }

  MoveSerialNum(id: any, tracking: any) {
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length < this.moveDiff) {
      const value = this.MoveSerialArr.filter(x => x.id == id);
      value.forEach(element => {
        element.isActive = false;
      });
    }
    else {
      const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
      this.SerialComment = "You can only move " + this.moveDiff + trackingDetails;
      $('#GeneralModel').show();
    }

  }

  MoveSerial(tracking: any) {
    const checkDeleteSerial = this.MoveSerialArr.filter(x => x.isActive == false);
      if (checkDeleteSerial.length == this.moveDiff) {
        $('#MoveSerials').hide();
        this.isPartval = false;
        this.MoveInventoryMethod();
      }
      else {
        const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
        this.SerialComment = "Please Move " + this.moveDiff + trackingDetails;
        $('#GeneralModel').show();
      }
  }
  MoveInventoryMethod(){
    const newArr = [];
    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {

      if (Number(this.mInvQty) == Number(this.mInvQtyToMove)) {
        InvData[0].InvType = Number(this.mInvType);
        InvData[0].InvType2 = this.mInvType2;
        InvData[0].InvPartLocation = this.mInvLocation;
        InvData[0].InvLoc = this.mInvLocation2;
        InvData[0].InvLoc1 = this.mInvLocation1;
        InvData[0].InvReason = this.mInvReason;
        const A1 = this.MoveSerialArr.filter(x=>x.isActive == false);
        A1.forEach(flrArray => {
          if (InvData[0].InvType == "6" || InvData[0].InvType == "7") {
            flrArray.isSale = true;
          }
          flrArray.isActive = true;
        });
      }
      else {
        let obj = {
          InvSno: this.InvSno,
          partId: this.searchPartId,
          pInvSno: this.MainInvRowNo,
          InvQty: Number(this.mInvQtyToMove),
          InvQtyOrdered: Number(this.mInvQtyToMove),
          InvDate: this.mInvDate,
          InvCost: Number(this.mInvCost),
          InvType: Number(this.mInvType),
          InvType2: this.mInvType2,
          InvCustRMA: this.mInvCustRMA,
          InvVendor: Number(this.mInvVendor),
          isActive: true,
          InvPartLocation: this.mInvLocation,
          InvReason: this.mInvReason,
          InvLoc: this.mInvLocation2,
          InvLoc1: this.mInvLocation1,
          InvTracking: InvData[0].InvTracking,
          MovePid: InvData[0].id == undefined ? null : InvData[0].id.toString(),
          InvCustomPartId: Number(InvData[0].InvCustomPartId),
        }
        const filterArr = [];
        this.InvList.push(obj);
        this.InvList.forEach(element => {
          if (element.MovePid != undefined) {
            const list = this.InvList.filter(x => x.id == parseInt(element.MovePid));
            const a = this.MoveSerialArr.filter(x=>x.isActive == true);
            const b = this.MoveSerialArr.filter(x=>x.isActive == false);
            b.forEach(flrArray => {
              if (element.InvType == "6" || element.InvType == "7") {
                flrArray.isSale = true;
              }
              flrArray.isActive = true;
            });
            element.obj1 = b;
            
          list[0].obj1 = a;
          }
        });
        this.InvSno += 1;
        InvData[0].InvQty = Number(InvData[0].InvQty) - Number(this.mInvQtyToMove);
        this.isPartval = false;
        this.refillInventory();
        this.mInvQty = '';
        this.mInvQtyToMove = '';
        this.mInvDate = '';
        this.mInvCost = '';
        this.mInvType = '';
        this.mInvType2 = '';
        this.mInvServNo = '';
        this.mInvPartOrder = '';
        this.mInvProductOrder = '';
        this.mInvCustRMA = '';
        this.mInvLocation = '';
        this.mInvReason = '';
        this.mInvLocation2 = '';
        this.mInvLocation1 = '';
      }
      this.modalService.dismissAll();
      this.mMsg = this.constants.QtyMoved;
      this.mMsgClass = this.constants.SuccessPng;
      this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
  }

  MoveInvClr() {
    this.mQtyMoveMsg = false;
    this.mInvQtyValidation = false;
    this.mInvValidation = false;
    this.mInvQty = '';
    this.mInvQtyToMove = '';
    this.mInvDate = '';
    this.mInvCost = '';
    this.mInvType = '';
    this.mInvType2 = '';
    this.mInvServNo = '';
    this.mInvPartOrder = '';
    this.mInvProductOrder = '';
    this.mInvCustRMA = '';
    this.mInvVendor = '';
    this.mInvLocation = '';
    this.mInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }

  AdjustInvRow(InvItem) {
    this.AdjustInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.adjInvQty = InvItem.InvQty;
    this.adjInvDate = InvItem.InvDate;
    this.adjInvCost = InvItem.InvCost;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel2, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });
  }

  AdjustInv() {
    if (this.formPopupAdjustQty.controls.adjInvQty.status === this.constants.Invalid ||
      this.formPopupAdjustQty.controls.adjInvQtyToAdjust.status === this.constants.Invalid
    ) {
      this.adjInvValidation = true;
      return;
    }
    else {
      if (Number(this.adjInvQty) == Number(this.adjInvQtyToAdjust)) {
        this.adjInvValidation = true;
        return;
      }
      this.adjInvValidation = false;
      if (Number(this.adjInvQty) > Number(this.adjInvQtyToAdjust)) {
        this.serialNumDiff = Number(this.adjInvQty) - Number(this.adjInvQtyToAdjust);
        this.AddSerial = false;
      }
      else if (Number(this.adjInvQty) < Number(this.adjInvQtyToAdjust)) {
        this.serialNumDiff = Number(this.adjInvQtyToAdjust) - Number(this.adjInvQty);
        this.AddSerial = true;
      }
      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {
        this.DeleteSerialArr = InvData[0].obj1.filter(x => x.isActive == true);
        this.DeleteSerialArr.forEach(element => {
          element.IsSerialDisabled = true;
          element.message = "";
        });
      }
      this.isPartval = false;
      this.DSerialTracking = InvData[0].InvTracking;
      if(this.DSerialTracking != "3" && this.DSerialTracking != "0"){
        this.AddSerials = [];
        if (this.AddSerial == true) {
          let len = this.DeleteSerialArr.length;
          var s = len + 1;
          for (var i = 1; i <= this.serialNumDiff; i++) {
            var addSerial = {
              ids: len + 1, part: this.displayPartName + ' ' + s, id: 0,
              serialNo: '', isActive: true, isSale: false, ordering: s
            }
            len = len + 1;
            s = s + 1;
            this.AddSerials.push(addSerial);
          }
        }
        $('#DeleteSerials').show();
      }
      else{
        this.MoveAdjustFuns();
      }
    }
  }

  MoveAdjustFuns() {
  
    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {
      InvData[0].InvQty = Number(this.adjInvQtyToAdjust);
      InvData[0].InvReason = this.adjInvReason;
    }

    this.modalService.dismissAll();
    this.mMsg = this.constants.QtyAdjusted;
    this.mMsgClass = this.constants.SuccessPng;
    this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
     
    
  }

  MoveAdjustFun() {
    this.adjInvValidation = false;
    // const AdvQtyLabel = [];
    const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
    if (InvData.length > 0) {
      const newValue = InvData[0].obj1.filter(x => x.isActive == true);
      newValue.forEach(element => {
        element.isAdjust = null;
      });
      InvData[0].InvQty = Number(this.adjInvQtyToAdjust);
      InvData[0].InvReason = this.adjInvReason;
      this.isPartval = false;
      for (var i = 0; i < this.adjInvQtyToAdjust; i++) {
        newValue[i].isAdjust = true;
        // AdvQtyLabel.push(InvData[0].obj1[i]);
      }
      this.inventoryQty = this.adjInvQtyToAdjust;
      newValue.forEach(element => {
        if (element.isAdjust == true) {
          element.isActive = true;
        }
        else {
          element.isActive = false;
        }
      });
    }
    this.refillInventory();
    this.modalService.dismissAll();
    this.mMsg = this.constants.QtyAdjusted;
    this.mMsgClass = this.constants.SuccessPng;
    this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  AdjustInvClr() {
    this.adjInvValidation = false;
    this.adjInvQty = '';
    this.adjInvQtyToAdjust = '';
    this.adjInvDate = '';
    this.adjInvCost = '';
    this.adjInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }

  SaveConfirm(confirmSave) {
    this.modalService.open(confirmSave, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  SaveInv() {
    let obj = {};

    obj['VendorId'] = 0;
    obj['Name'] = 'Inventory';
    obj['Cost'] = 0;
    obj['Hours'] = '0';
    obj['PartCategoryId'] = 0;
    obj['CompanyId'] = 0;
    obj['PartNo'] = '0';

    let invArr = this.LocalInvList;

    for (var j = 0; j < invArr.length; j++) {
      let invObj = {};
      let LocalServNo = '';
      let LocalPartOrderId = null;
      let LocalProductOrderId = null;

      if (invArr[j].InvType == "5") {
        LocalServNo = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "6") {
        LocalPartOrderId = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "7") {
        LocalProductOrderId = invArr[j].InvType2;
      }

      invObj["Id"] = invArr[j].id;
      invObj["RcvdId"] = Number(this.value_search); //RcvdId Pass as Company Id
      invObj["PartId"] = invArr[j].partId;
      invObj["Qty"] = invArr[j].InvQty;
      invObj["OrderedQty"] = invArr[j].InvQtyOrdered;
      invObj["Date"] = invArr[j].InvDate;
      invObj["Cost"] = invArr[j].InvCost;
      invObj["TypeId"] = invArr[j].InvType;
      invObj["CustomerSo"] = invArr[j].MovePid; //CustomerSo use for move feature
      invObj["ServiceNo"] = LocalServNo;
      invObj["PartOrderId"] = LocalPartOrderId;
      invObj["ProductOrderId"] = LocalProductOrderId;
      invObj["CustomerRMA"] = invArr[j].InvCustRMA;
      invObj["VendorId"] = invArr[j].InvVendor;
      invObj["PartLocation"] = invArr[j].InvPartLocation;
      invObj["Reason"] = invArr[j].InvReason;
      invObj["IsActive"] = invArr[j].isActive;
      invObj["CreatedBy"] = invArr[j].createdBy;
      invObj["CreatedDate"] = invArr[j].createdDate;
      invObj["ModifiedBy"] = invArr[j].modifiedBy;
      invObj["ModifiedDate"] = invArr[j].modifiedDate;
      invObj["PurchaseOrderId"] = invArr[j].purchaseOrderId;
      invObj["LocationName"] = parseInt(invArr[j].InvLoc);
      invObj["Location"] = String(invArr[j].InvLoc1);
      invObj["Tracking"] = String(invArr[j].InvTracking);

      this.SerialList = [];
      const SerialNumberList = invArr[j].obj1;
      if (SerialNumberList != undefined) {
        for (var jk = 0; jk < SerialNumberList.length; jk++) {
          let serialObj = {};
          serialObj["Part"] = SerialNumberList[jk].part;
          serialObj["SerialNo"] = SerialNumberList[jk].serialNo;
          serialObj["IsSale"] = SerialNumberList[jk].isSale == null ? false : SerialNumberList[jk].isSale;
          serialObj["PartId"] = this.id;
          serialObj["PartInventoryId"] = invArr[j].id;
          serialObj["type"] = SerialNumberList[jk].type;
          serialObj["companyId"] = this.value_search;
          serialObj["isActive"] = SerialNumberList[jk].isActive;
          serialObj["Ordering"] = SerialNumberList[jk].ordering;
          if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
            serialObj["id"] = SerialNumberList[jk].id;
          }
          this.SerialList.push(serialObj);
        }
      }

      invObj['PartSerialNumber'] = this.SerialList;
      this.InvDetails.push(invObj);
    }

    obj["PartInventory"] = this.InvDetails;

    this.loader = true;
    this.sharedService.UpdateInventory(obj)
      .subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.buttonValue = this.constants.Save;
              $("#add_update").show();
              this.resetvendorData();
              this.isPartval = true;
              //this.searchInv();
              this.searchInvPartList();
              this.invDiv = false;
              this.ListAngle = this.constants.UpArrow;
              this.ListDiv = true;
              break;
            case 400:
              $("#wrong_msg").show();
              break;
            case 406:
              $("#InvExist1").show();
              break;
            default:
              $("#wrong_msg").show();
          }
          this.loader = false;
        }
      );
  }

  updateitem() {
    $("#add_update").hide();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);

  }

  Exit(discardItem) {
    if (this.isPartval == false || this.RowEdit == true) {
      this.deleteDetail = this.constants.ExitMessage;
      this.modalService.open(discardItem, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
    else {
      this.router.navigate(['/adminDashboard/']);
    }
  }

  gotoDashBoard() {
    this.router.navigate(['/adminDashboard/']);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ChangefieldToSearch(value) {
    this.fieldToSearchPlaceHolder = value == '1' ? 'Enter Search Keywords' : value == '2' ? 'Enter List or Vendor Part Name' : value == '3' ? 'Enter List or Vendor Part #' : '';
  }
  GetLocName(lid) {
    var num = parseInt(lid);
    if (this.locationNameList != null) {
      const vData = this.globalLocationNameList.filter(x => x.id == num);
      if (vData.length > 0) {
        return vData[0].locationName;
      }
      else {
        return '';
      }
    }

  }

  GetLocName1(lid) {
    var num = parseInt(lid);
    if (this.newlocationLevelList != null) {
      const vData = this.newlocationLevelList.filter(x => x.id == num);
      if (vData.length > 0) {
        return vData[0].levelsName;
      }
      else {
        return '';
      }
    }
  }
  filterActiveRecords(records: any) {
    if (records != undefined) {
      return records.filter(x => x.isActive == true);
    }
  }
  changeValidation(id) {
    const filterElement = this.qtyLabel.filter(x => x.id == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }
  OpenSerialModel(item: any, type: any, id: any, tracking: any, qty) {
    if(tracking != "3" && tracking != "0"){
      this.qtyLabel = [];
      item = item.sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
      for (var i = 0; i < item.length; i++) {
        var obj = {
          id: item[i].id, part: item[i].part, isActive: item[i].isActive,
          serialNo: item[i].serialNo, valid: false, type: tracking == "1" ? 'Serial' : 'Lot', SerialItemId: item[i].id,
          ordering: item[i].ordering
        }
        this.qtyLabel.push(obj);
        this.exitProp = true;
        // this.IsSerialDisabled = (type == 6 || type == 7) ? true : false;
        this.IsSerialDisabled = false;
        this.openId = id;
      }
      this.inventoryQty = qty;
      this.Tracking = tracking;
      $('#SerialModel').show();
    }
   
  }
  EditDetail(id: any) {
    if (this.IsSerialDisabled == false) {
      const valuel = this.qtyLabel.filter(x => x.isActive == true);
      let count = 0; let messageCount = 0; let sameCount = 0;
      const sameSerial = [];
      const filterElement = valuel;
      if (filterElement[0].number == '') {
        filterElement[0].message = '';
      }
      else {
        filterElement[0].message = '';
      }
      valuel.forEach(element => {
        if (element.serialNo == '') {
          element.valid = true;
          count++;
        }
        else {
          element.valid = false;
        }
      });
      if (count == 0) {
        let serial = ''; let ids = ''; let serialNext = [];
        valuel.forEach(partLst => {
          sameSerial.push(partLst.serialNo);
          var ss = partLst.SerialItemId == undefined ? '0' : partLst.SerialItemId;
          serial += partLst.serialNo + ':' + ss + ',';
        });

        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
        const duplicate = [...new Set(findDuplicates(sameSerial))];
        if (duplicate.length == 0) {
          if (serial) {
            serial = serial.slice(0, -1);
          }
          this.loader = true;
          this.partService.GetSerialExist(serial, this.searchPartId == undefined ? 0 : this.searchPartId, this.Tracking).subscribe(
            data => {
              this.data = data;
              const list = this.data.dataList;
              this.loader = false;
              if (list.length > 0) {
                list.forEach(element => {
                  const getSerial = valuel.filter(x => x.serialNo == element.serialNo);
                  getSerial[0].message = this.Tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                  messageCount = messageCount + 1;
                });
              }
              if (messageCount == 0) {
                this.EditDetail1(id, this.Tracking);
              }
              else {

              }
            });
        }
        else {
          if (duplicate.length > 0) {
            duplicate.forEach(element => {
              const getSerial = valuel.filter(x => x.serialNo == element);
              getSerial.forEach(element => {
                element.message = this.Tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
              });

            });
          }
        }

      }
      else {
        if(this.qtyLabel.length > 15){
          $('#GeneralModel').show();
          var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
          this.SerialComment = "Please Add " + count + s;
        }
        return;
      }

    }
    else {
      $('#SerialModel').hide();
    }

  }

  EditDetail1(id: any, tracking: any) {
    this.loader = true; let checkDetail;
    checkDetail = this.InvList.filter(x => x.id == id);
    if (checkDetail.length == 0) {
      checkDetail = this.InvList.filter(x => x.InvSno == id);
    }
    const oldSerial = []; const newSerial = [];
    checkDetail[0].obj1.forEach(element => {
      oldSerial.push(element.serialNo);
    });
    this.qtyLabel.forEach(element => {
      newSerial.push(element.serialNo);
    });
    const A1 = String(oldSerial);
    const A2 = String(newSerial);
    if (A1 == A2) {
      checkDetail[0].isChange = "false";
    }
    else {
      checkDetail[0].obj1 = this.qtyLabel;
      checkDetail[0].isChange = "true";
    }

   
    let obj = {};
    obj['Id'] = id;
    obj['companyId'] = this.value_search;
    obj['PartSerial'] = this.qtyLabel;
    this.sharedService.UpdateSerial(obj).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.statusCode == 200) {
          $('#SerialModel').hide();
          $('#Update_Success').show();
          this.openTracking = tracking;
          this.qtyLabel = [];
        }
        else {
          $('#SerialModel').hide();
          $('#wrong_msg').show();
        }
      }
      
    )

  }
  Update_SuccessClose(){
    $('#Update_Success').hide();
   }
   CloseDSerials() {
    const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length > 0) {
      checkDeleteSerial.forEach(element => {
        element.isActive = true;
      });
    }
    $('#DeleteSerials').hide();
  }

  deleteSerialNum(id: any, tracking: any) {
    const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
    if (checkDeleteSerial.length < this.serialNumDiff) {
      const value = this.DeleteSerialArr.filter(x => x.id == id);
      value.forEach(element => {
        element.isActive = false;
      });
    }
    else {
      const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
      this.SerialComment = "You can only remove " + this.serialNumDiff + trackingDetails;
      $('#GeneralModel').show();
    }

  }

  addSerialNum(id: any, tracking: any) {
    const value = this.DeleteSerialArr.filter(x => x.id == id);
    value.forEach(element => {
      element.isActive = true;
    });
  }

  CloseGeneralModel() {
    $('#GeneralModel').hide();
  }

  SaveDSerial(tracking: any, addSerial: boolean) {
    if (addSerial == false) {
      const checkDeleteSerial = this.DeleteSerialArr.filter(x => x.isActive == false);
      if (checkDeleteSerial.length == this.serialNumDiff) {
        $('#DeleteSerials').hide();
        this.modalService.dismissAll();
        this.MoveAdjustFun();
      }
      else {
        const trackingDetails = tracking == "1" ? " Serial#" : " Lot#";
        this.SerialComment = "Please Remove " + this.serialNumDiff + trackingDetails;
        $('#GeneralModel').show();
      }
    }
    else {
      let count = 0; let messageCount = 0;
        this.AddSerials.forEach(element => {
          if (element.serialNo == '') {
            element.valid = true;
            count++;
          }
          else {
            element.valid = false;
          }
        });
        if (count == 0) {
          let serial = ''; const sameSerial = [];
          let newArr = []; let newArr1 = [];
          newArr1 = this.AddSerials;
          newArr1.forEach(element => {
            element.id = 0;
          });
          newArr.push(...this.DeleteSerialArr, ...this.AddSerials);
          newArr.forEach(partLst => {
            sameSerial.push(partLst.serialNo);
            var sid = partLst.id == 0 ? '' : partLst.id;
            serial += partLst.serialNo + ':' + sid + ',';
          });
          let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
          const duplicate = [...new Set(findDuplicates(sameSerial))];
          if (duplicate.length == 0) {
            this.loader = true;
            this.partService.GetSerialExist(serial.trim(), this.id == undefined ? 0 : this.id, "1").subscribe(
              data => {
                this.data = data;
                const list = this.data.dataList;
                this.loader = false;
                if (list.length > 0) {
                  list.forEach(element => {
                    this.loader = false;
                    const getSerial = newArr.filter(x => x.serialNo == element.serialNo);
                    getSerial[0].message = tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                    messageCount = messageCount + 1;
                  });
                }
                if (messageCount == 0) {
                  const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
                  if (InvData.length > 0) {
                    this.AddSerials.forEach(element => {
                      element.partId = InvData[0].partId;
                      element.partInventoryId = InvData[0].id;
                      element.type = "Serial";
                      InvData[0].obj1.push(element);
                    });
                  }
                  $('#DeleteSerials').hide();
                  this.modalService.dismissAll();
                  this.MoveAdjustFun();
                }
                else {

                }
              });
          }
          else {
            if (duplicate.length > 0) {
              duplicate.forEach(element => {
                newArr.forEach(element => {
                  element.message = "";
                });
                const getSerial = newArr.filter(x => x.serialNo == element);
                getSerial.forEach(element => {
                
                  element.message = tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                });
                

              });
            }
          }
        }
        else{
          if(this.DeleteSerialArr.length > 10){
            $('#GeneralModel').show();
            var s = this.InvPartTracking == "1" ? " Serial #" : " Lot#";
            this.SerialComment = "Please Add " + count + s;
          }
        }
    }
  }

  SetTracking(e){
    if(this.displayPartTracking != ""){
      if(this.displayPartTracking == parseInt(e.target.value)){

      }
      else{
       $('#SetTracking').show();
       this.SerialComment = "Part Tracking Details already set to ' " + this.checkTracking(this.displayPartTracking) + "'"; 
      }
    }
    else{
      $('#SetTracking').show();
      this.SerialComment = "Please Select Part Tracking Details on Part"; 
    }
  }
  CloseTracking(){
    this.InvPartTracking = this.displayPartTracking == 0 ? "" : this.displayPartTracking;
    $('#SetTracking').hide();
  }
  checkTracking(id){
    const trackingData = this.PartTrackingList.filter(x=>x.id == id);
    return trackingData[0].name;
  }
  SortString(name){
    var newstr = name.substring(0,24);
    var newStr1 = newstr + "....";
    return newStr1;
 
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
