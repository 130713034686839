import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { CompanyService } from 'src/app/services/company/company.service';


declare var $: any
@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})

export class ShippingComponent implements AfterViewInit, OnDestroy, OnInit {

  [x: string]: any;
  companyId: any;
  companyList: any;
  userGroupObjects = new Array();
  CompanyValue: any;
  recordAlreadyExist = false;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  datalist: any;
  userId: string;
  id: any;
  isActive: any;
  createdDate: any;
  modifiedDate: any;
  createdBy: string;
  modifiedBy: string;
  form: FormGroup;
  formSearch: FormGroup;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  buttonValue: string;
  validation: boolean = false;
  SearchPOValidation: boolean = false;
  SearchDDLValidation: boolean = false;
  loader: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  deleteDetail: string;
  disabled = true;
  deletedItem: string;
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;
  partList = [];
  PartsDetails = [];
  cartonTypeList = this.constants.CartonType;
  LenghtUnit = this.constants.UnitLength;
  WidthUnit = this.constants.UnitLength;
  HeightUnit = this.constants.UnitLength;
  WeightUnit = this.constants.UnitLengthWeight;
  customerList: any;
  statuslist: any;
  paymentstatuslist: any;
  paymentDateValueList:any;
  ShippingStatus:any;
  divListPackages: boolean = false;
  now: Date;
  qty: any = 1;
  normalShow = true;
  EditRowPartId: any;
  EditRowTxtName: any;
  CurrentEditRow: any;
  RowEdit: any;
  customerId: any;
  customerName: any;
  customerAdd1: any;
  customerAdd2: any;
  customerCity: any;
  customerState: any;
  customerZip: any;
  orderStatusVal: any;
  paymentStatusVal:any;
  carrierServiceVal:any;
  transactionID:any;
  paymentDate:any;
  statusid:any;
  eOrderNumber:any;
  transactionStatus:any;
  customerCountry: any;
  cartonValidation: boolean = false;
  cartonRowNo: number = 1;
  cartonList = [];
  LocalCartonList = [];
  cartonDetails = [];
  cartonNo: number = 1;
  cartonType: any = '1';
  cartonError: any;
  Length1: any;
  Length2 = '1';
  Width1: any;
  Width2 = '1';
  Height1: any;
  Height2 = '1';
  Weight1: any;
  Weight2 = '2';
  cost : any;
  tracking : any;
  UpdateLength1: any;
  UpdateLength2: any;
  UpdateWidth1: any;
  UpdateWidth2: any;
  UpdateHeight1: any;
  UpdateHeight2: any;
  UpdateWeight1: any;
  tracking1 : any;
  cost1 : any;
  UpdateWeight2: any;
  btnOkShow: boolean = false;
  totalOrderQty: number;
  totalShipQty: number;
  SoSearchTxt: any = '';
  selectedCustomerSearch = '0';
  selectedStatusSearch = '0';
  RecOrderListShow = false;
  ListDiv: boolean = false;
  ListAngle: any;
  SalesOrderNo: any;
  SalesOrderId: any;
  OrderTypeId: Number;

  //Ship Popup Property
  ItemStatusList = [];
  LocalItemStatusList = [];
  formPopup: FormGroup;
  PopupValidation: boolean = false;
  TxtItemDateShipped: any = this.datepipe.transform((new Date), 'yyyy-MM-dd');
  TxtItemNoShip: any;
  DDLItemCarton = '';
  PopCustPartNo: any;
  PopPartName: any;
  PopVendorPartNo: any;
  PopVendPartName: any;
  PopDescr: any;
  PopOrderedSo: any;
  PopRcvdSO: any;
  PopOrdered: any;
  PopShipped: any;
  PopTotalInv: any;
  PopAvblInv: any;
  PopupErrorMsg: any;
  PopupMaxDate: any;
  PopupRowNo: number = 1;
  PopupItemId: any;
  PopupRecordId: any;
  HidTxtItemShipped: any = 0;
  isPartval: boolean = true;
  TotalShippedQty: any;
  partListWithoutCustomPart = [];
  partListCustomPart = [];
  SpecialPartList = [];
  isSaved: boolean = false;
  ShippingStatusModel : any = '';
  paymentstatusModel : any = '';
  paymentDateModel:any='';
  carrierModel:any='';
  constructor(
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private vendorService: VendorService,
    private customerService: CustomerService,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    public confirmation: ConfirmationDialogService,
    public dateFormatPipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  )
   { 
   
   }
  ngOnInit(): void {
   
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1017);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyId = localStorage.getItem(this.constants.companyId);
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      // this.getAllCompany(this.CompanyValue);
      // this.GetAllCustomer(this.CompanyValue);
      this.GetPaymentStatusdropdownlist();
      this.GetPaymentTransactionDateDropdownList();
      this.GetShippingStatus();
      this.getAllCarrier();
      
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        order: [[1, 'asc']]
      };
    
      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.CompanyValue = company;
        this.GetAllCustomer(this.CompanyValue);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.companyChangeValueMain(data);
      })
    }

    this.form = new FormGroup({
      'Length1': new FormControl(),
      'Length2': new FormControl(),
      'Width1': new FormControl(),
      'Width2': new FormControl(),
      'Height1': new FormControl(),
      'Height2': new FormControl(),
      'Weight1': new FormControl(),
      'Weight2': new FormControl(),
      'tracking' : new FormControl(),
      'cost' : new FormControl(),
      'cartonType': new FormControl(),
      'UpdateLength1': new FormControl(),
      'UpdateLength2': new FormControl(),
      'UpdateWidth1': new FormControl(),
      'UpdateWidth2': new FormControl(),
      'UpdateHeight1': new FormControl(),
      'UpdateHeight2': new FormControl(),
      'UpdateWeight1': new FormControl(),
      'UpdateWeight2': new FormControl(),
      'ShippingStatusModel' : new FormControl(),
      'paymentstatusModel':new FormControl(),
      'carrierModel':new FormControl(),
      'paymentDateModel':new FormControl(),
      'paymentDate':new FormControl(),
      'tracking1' : new FormControl(),
      'cost1' : new FormControl()
      
    });

    this.formSearch = new FormGroup({
      'SoSearchTxt': new FormControl(),
      'CustomerSearchDDL': new FormControl(),
      'StatusSearchDDL': new FormControl()
    });

    this.formPopup = new FormGroup({
      'ItemDateShipped': new FormControl('', Validators.required),
      'ItemNoShip': new FormControl('', Validators.required),
      'ItemCarton': new FormControl()
    });
    if (this.route.snapshot.params['soNumber']) {
      this.RecOrderListShow = true;
      if (sessionStorage.getItem('breadcrumd')) {
        this.breadCrumbName = sessionStorage.getItem('breadcrumd');
        this.breadCrumbUrl = '/dashboardlist/' + this.route.snapshot.params['type'] + '/';
      }
      this.toggleEditBySONumber(this.route.snapshot.params['soNumber'], this.companyId, 0);
    }
    else {
      this.breadCrumbName = null;
      this.breadCrumbUrl = null;
      this.companyId = localStorage.getItem(this.constants.companyId);
    }

  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {

    if (!this.form.pristine && !this.isSaved) {//!this.isPartval && !this.isSaved) {
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  ListDivShowHide() {

    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.UpArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.DownArrow;
      this.SearchByDropDown();
    }
  }

  getAllCompany(companyId) {
    this.loader = true;
    this.companyList = "";
    this.userGroupObjects = [];
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.items = this.data.dataList;
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));
        // });
        this.CompanyValue = Number(companyId);
        this.loader = false;
      });
  }

  companyChangeValueMain(value: any): void {
    this.CompanyValue = value;
    this.GetAllCustomer(this.CompanyValue);
  }

  GetAllCustomer(companyId) {
    this.loader = true;
    this.customerService.GetAllCustomer(companyId, '').subscribe(
      data => {
        this.data = data;
        this.customerList = this.data.dataList;
        this.loader = false;
      }
    );

    this.GetAllStatus();
  }

  GetAllStatus() {
    this.loader = true;
    this.sharedService.GetStatusdropdownlist().subscribe(
      data => {
        this.data = data;
        this.statuslist = this.data.dataList;
        this.loader = false;
      }
    )
  }

  
  GetPaymentStatusdropdownlist() {
    
    this.sharedService.GetPaymentStatusDropdownList().subscribe(
      data => {
        this.data = data;
        this.paymentstatuslist = this.data.dataList;
       
      }
    )
  }

  GetPaymentTransactionDateDropdownList() {
    this.sharedService.GetPaymentTransactionDateDropdownList().subscribe(
      data => {
        this.data = data;
        this.paymentDateValueList = this.data.dataList;
       
      }
    )
  }

  GetShippingStatus(){   
    this.sharedService.GetShippingStatusdropdownlist().subscribe(
      data => {
        this.data = data;
        this.ShippingStatus = this.data.dataList;
     
      }
    )
  }

  getAllCarrier() {
    this.vendorService.getAllCarrier().subscribe(
      data => {
        this.data = data;
       // this.carrierData = this.data.dataList;
       this.carrierData = this.constants.carrierList;
       
      });
  }

  GetStatusText(statusId) {
    if(this.statuslist!=undefined)
    {
      let orderStatus=this.statuslist.filter(s => s.id == statusId);
      var orderStatusVal1;
      for(let i=0; i<orderStatus.length; i++)
      {
        orderStatusVal1=orderStatus[i].name;
      }
    }
   
    return orderStatusVal1;
  }

  ChngCartonType(event) {
    if (event.target.value == "2") {
      this.divListPackages = true;
    }
    else {
      this.divListPackages = false;
    }
  }

  SearchBySONumber() {

    if (!this.SoSearchTxt || this.CompanyValue == 0) {
      this.SearchPOValidation = true;
      this.SearchDDLValidation = false;
      return;
    }
    else {
      this.selectedCustomerSearch = '0';
      this.selectedStatusSearch = '0';
      this.RecOrderListShow = false;
      this.toggleEditBySONumber(this.SoSearchTxt, null, 0);
    }

  }

  SearchBySOList(SoNumber,Id) {
    this.toggleEditBySONumber(SoNumber, null, Id);
    if(this.SpecialPartList.length>0)
    {
      this.isPartval = false;
    }
  }

  SearchByPONumber() {
    if (!this.SoSearchTxt || this.CompanyValue == 0) {
      this.SearchPOValidation = true;
      this.SearchDDLValidation = false;
      return;
    }
    else {
      this.selectedCustomerSearch = '0';
      this.selectedStatusSearch = '0';
      this.RecOrderListShow = false;
      this.SearchByDropDown();
    }
  }

  SearchByDropDown() {

    if (this.CompanyValue == 0 || (!this.selectedStatusSearch && !this.selectedCustomerSearch)) {
      this.SearchPOValidation = false;
      this.SearchDDLValidation = true;
      return;
    }
    else {
      this.SearchDDLValidation = false;
      let customerId = this.selectedCustomerSearch == '' ? '0' : this.selectedCustomerSearch;
      let statusId = this.selectedStatusSearch == '' ? '0' : this.selectedStatusSearch;
      let soNumber = this.SoSearchTxt == (undefined || '') ? '0' : this.SoSearchTxt;

      this.loader = true;
      if (soNumber) {
        customerId = "0";
        statusId = "0";
      }

      this.RecOrderListShow = true;
      this.clearDetails();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
   
      this.sharedService.getSalesOrderCustomerWise(this.CompanyValue, statusId, customerId, soNumber).subscribe(
        data => {         
          this.RecOrderListShow = true;
          this.ListDiv = true;
         
          this.ListAngle = this.constants.UpArrow;
          this.data = data;
          this.datalist = this.data.dataList;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.datalist = this.data.dataList;          
            this.loader = false;
            this.rerender();
          }
        }
      )
    }

  }

  toggleEditBySONumber(SoNumber: any, CompanyId: any, Id:any) {
    this.srNo1 = 1;
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    this.SalesOrderNo = SoNumber;
    // this.customArrayIndex = 0;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    var comId = CompanyId != null ? CompanyId : this.CompanyValue;
    this.sharedService.GetSODetailsBySONumber(Id, comId).subscribe(
      
      (data: any) => {
        if (data.data == null) {
          this.clearDetails()
          $("#no_record").show();
        }
        else {
          this.LocalCartonList = [];
          this.GetCartonList();
          this.LocalItemStatusList = [];
          this.OrderTypeId = data.data.orderTypeId;
          this.SalesOrderId = data.data.soId;
          this.customerId = data.data.id;
          this.customerName = data.data.customerName;
          this.customerAdd1 = data.data.addressLine1;
          this.customerAdd2 = data.data.addressLine2;
          this.customerCity = data.data.city;
          this.customerState = data.data.stateName;
          this.customerZip = data.data.zipCode;
         
          this.eOrderNumber=data.data.eOrderNo;
          this.paymentStatusVal=data.data.paymentStatus;
          this.carrierServiceVal=data.data.carrierService;
          this.transactionID=data.data.transactionID;
          this.transactionStatus=data.data.transactionStatus;
          this.customerCountry = data.data.countryName;
          this.paymentDate=data.data.paymentDate;
          this.statusid=data.data.statusId;
          this.carrierModel = data.data.shippingType == null ? '' : data.data.shippingType;
          this.GetStatusText(this.statusid) 
          if (data.data.orderPart.length > 0) {
            this.partList = data.data.orderPart;
            this.partList.forEach(partLst => {
              partLst.id = partLst.id;
              partLst.customerPartNumber = partLst.customerPartNumber;
              partLst.partName = partLst.partName;
              partLst.vendorPartNumber = partLst.vendorPartNumber;
              partLst.vendorPartName = partLst.vendorPartName;
              partLst.description = partLst.description;
              partLst.orderId = partLst.orderId;
              partLst.partId = partLst.partId;
              partLst.shipQty = partLst.shipQty == null || partLst.shipQty == "" ? 0 : partLst.shipQty;
              partLst.quantity = partLst.quantity;
              partLst.subTotal = partLst.subTotal == null ? 0 : partLst.subTotal;
              partLst.unitCost = partLst.unitCost == null ? 0 : partLst.unitCost;
              partLst.uomId = partLst.uomId;
              if(partLst.cartons != null){
                var cartonArray = partLst.cartons.split(',');
                var cartonResult = [...new Set(cartonArray)].join();
                partLst.cartons = cartonResult;
              }
             
              if (Number(partLst.shipQty) > 0) {
                if (Number(partLst.quantity) == Number(partLst.shipQty)) {
                  partLst.bcolor = this.constants.ForestGreen;
                }
                else {
                  partLst.bcolor = this.constants.Yellow;
                }
              }
              else {
                partLst.bcolor = this.constants.Silver;
              }

            });

            this.partListWithoutCustomPart = this.partList.filter(x => (x.virtual == false || x.virtual == null) && x.customPartId == null);
            this.partListWithoutCustomPart.forEach(partLst => {
              partLst.srNo = this.srNo1;
              this.srNo1 += 1;
            });
            this.partListCustomPart = this.partList.filter(x => x.virtual == true);
            this.partListCustomPart.forEach(partLst => {
              if (this.partList.filter(x => x.customPartId == partLst.partId).length > 0) {
                partLst.srNo = this.srNo1;
                partLst.PartList = this.partList.filter(x => x.customPartId == partLst.partId);
                this.srNo1 += 1;
              }
            });
            this.SpecialPartList = [];
            this.SpecialPartList.push(...this.partListWithoutCustomPart, ...this.partListCustomPart);
            this.SpecialPartList = this.SpecialPartList.sort((a, b) => (a.id > b.id ? 1 : -1));

          }
        }
      });
  }

  getCartonNo(cartonVal: number) {
    if (cartonVal == 0) {
      return this.constants.NA;
    }
    else {
      const chkFromList = this.LocalCartonList.filter(x => x.cartonNo === cartonVal);
      if (chkFromList.length == 0) {

        const chkFromPopup = this.LocalItemStatusList.filter(x => x.carton === cartonVal);
        if (chkFromPopup.length > 0) {
          chkFromPopup[0].carton = 0;
        }
        return this.constants.NA;
      }
      else {
        return "Package " + chkFromList[0].cartonNo;
      }
    }
  }

  GetCartonList() {
    this.cartonList = [];
    const ChkExist = this.LocalCartonList;
    if (ChkExist.length > 0) {
      ChkExist.forEach(ChkExist => {
        if (ChkExist.isActive == true) {
          this.cartonList.push(ChkExist);
        }
      });
    }
    else {
      this.loader = true;
      this.sharedService.GetCartonList(this.SalesOrderNo, this.CompanyValue).subscribe(
        data => {
          this.data = data;
          this.cartonRowNo = 1;
          this.cartonList = this.data.dataList;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.cartonList = this.data.dataList;
            if (this.cartonList.length > 0) {                             
              this.cartonList.forEach(cartonList => {
                cartonList.RowNo = this.cartonRowNo;
                this.LocalCartonList.push(cartonList);
                this.cartonNo = cartonList.cartonNo; //Set Max Carton No.
                this.cartonRowNo += 1;
              });
              this.cartonNo += 1; //Increment 1 in Max Carton No.
            }
            this.loader = false;
          }
        }
      );
    }
  }

  GetCartonType(typeId:number) {
    typeId = 2;
    return this.cartonTypeList.find(s => s.id == typeId).name != undefined ? this.cartonTypeList.find(s => s.id == typeId).name: '';
  }

  AddCarton() {

    if (!this.Length1 || !this.Width1 || !this.Height1 || !this.Weight1) {
      this.cartonValidation = true;
      return;
    }

    this.cartonValidation = false;

    if (this.LocalCartonList.length > 9) {
      this.cartonError = this.constants.MaxTenCarton;
      this.Length1 = '';
      this.Length2 = '1';
      this.Width1 = '';
      this.Width2 = '1';
      this.Height1 = '';
      this.Height2 = '1';
      this.Weight1 = '';
      this.Weight2 = '2';
      return;
    }

    if (this.LocalCartonList.length > 0) {
      const cartonExist = this.LocalCartonList.filter(x => x.length1 == this.Length1 && x.length2 == this.Length2 && x.width1 == this.Width1 && x.width2 == this.Width2 && x.height1 == this.Height1 && x.height2 == this.Height2 && x.weight1 == this.Weight1 && x.weight2 == this.Weight2);
      if (cartonExist.length > 0) {
        this.cartonError = this.constants.CartonExist;
        return;
      }
    }

    let obj = {
      RowNo: this.cartonRowNo,
      companyId: this.CompanyValue,
      soNo: this.SalesOrderNo,
      cartonNo: this.cartonNo,
      cartonType: this.cartonType,
      length1: this.Length1,
      length2: this.Length2,
      width1: this.Width1,
      width2: this.Width2,
      height1: this.Height1,
      height2: this.Height2,
      weight1: this.Weight1,
      weight2: this.Weight2,
      isActive: true,
      createdBy: this.userId,
      createdDate: this.now = new Date(),
      tracking : this.tracking,
      cost : this.cost
    }
    this.LocalCartonList.push(obj);
    this.cartonRowNo += 1;
    this.cartonNo += 1;

    if (this.SalesOrderId) {
      this.isPartval = false;
    }
    this.cartonError = "";
    this.Length1 = '';
    this.Length2 = '1';
    this.Width1 = '';
    this.Width2 = '1';
    this.Height1 = '';
    this.Height2 = '1';
    this.Weight1 = '';
    this.Weight2 = '2';
    this.tracking = '';
    this.cost = '';
    this.GetCartonList();
  }

  EditCarton(rowNo, rowText) {
    this.normalShow = true;
    this.RowEdit = true;
    if (rowText == "editRow") {
      this.CurrentEditRow = "Edit";
      this.RowEdit = false;
      this.EditRowPartId = rowNo;
      this.EditRowTxtName = "editRow";
      this.btnOkShow = true;
      const cartonData = this.LocalCartonList.filter(item => item.RowNo == rowNo);
      if (cartonData.length > 0) {
        this.UpdateLength1 = cartonData[0].length1;
        this.UpdateLength2 = cartonData[0].length2;
        this.UpdateWidth1 = cartonData[0].width1;
        this.UpdateWidth2 = cartonData[0].width2;
        this.UpdateHeight1 = cartonData[0].height1;
        this.UpdateHeight2 = cartonData[0].height2;
        this.UpdateWeight1 = cartonData[0].weight1;
        this.UpdateWeight2 = cartonData[0].weight2;
        this.tracking1 = cartonData[0].tracking;
        this.cost1 = cartonData[0].cost;
      }
    }
  }

  UpdateCarton(rowNo) {
    if (this.LocalCartonList.length > 0) {
      const cartonExist = this.LocalCartonList.filter(x => x.length1 == this.UpdateLength1 && x.length2 == this.UpdateLength2 && x.width1 == this.UpdateWidth1 && x.width2 == this.UpdateWidth2 && x.height1 == this.UpdateHeight1 && x.height2 == this.UpdateHeight2 && x.weight1 == this.UpdateWeight1 && x.weight2 == this.UpdateWeight2 && x.tracking == this.tracking1 && x.cost == this.cost1 );
      if (cartonExist.length > 0) {
        this.cartonError = this.constants.CartonExist;
        return;
      }
    }
    const cartonData = this.LocalCartonList.filter(item => item.RowNo == rowNo);
    if (cartonData.length > 0 && cartonData != null) {
      cartonData[0].length1 = this.UpdateLength1;
      cartonData[0].length2 = this.UpdateLength2;
      cartonData[0].width1 = this.UpdateWidth1;
      cartonData[0].width2 = this.UpdateWidth2;
      cartonData[0].height1 = this.UpdateHeight1;
      cartonData[0].height2 = this.UpdateHeight2;
      cartonData[0].weight1 = this.UpdateWeight1;
      cartonData[0].weight2 = this.UpdateWeight2;
      cartonData[0].tracking = this.tracking1;
      cartonData[0].cost = this.cost1;
      if (this.SalesOrderId) {
       this.isPartval = false;
        //this.isPartval = true;
      }
    }

    this.cartonError = "";
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnOkShow = false;
    this.GetCartonList();
    
  }

  CancleUpdateCarton() {
    this.cartonError = "";
    this.btnOkShow = false;
    this.EditRowPartId = 0;
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
  }

  ShowItemModel(itemModel, id, qty, pqty) {
    this.ClearPopup();
    const ChkExist = this.LocalCartonList.filter(x => x.isActive === true);
    if (ChkExist.length > 0) {
      this.formPopup.get('ItemCarton').enable();
    }
    else {
      this.formPopup.get('ItemCarton').disable();
    }

    this.PopupItemId = '';
    this.PopCustPartNo = '';
    this.PopPartName = '';
    this.PopVendorPartNo = '';
    this.PopVendPartName = '';
    this.PopDescr = '';
    this.PopOrdered = '0';
    this.PopShipped = '0';
    this.PopOrderedSo = '0';
    this.PopRcvdSO = '0';
    this.PopTotalInv = '0';
    this.PopAvblInv = '0';

    const GridItemData = this.partList.filter(item => item.id == id);
    if (GridItemData.length > 0) {
      let today = new Date();
    // this.PopupMaxDate = this.datepipe.transform((new Date), 'yyyy-MM-dd');
      //this.ItemDateShipped = this.datepipe.transform((new Date), 'yyyy-MM-dd');
      this.TxtItemDateShipped = this.datepipe.transform((new Date), 'yyyy-MM-dd');
      this.TxtItemNoShip = qty - pqty;
      this.PopupItemId = id;
      this.PopCustPartNo = GridItemData[0].customerPartNumber;
      this.PopPartName = GridItemData[0].partName;
      this.PopVendorPartNo = GridItemData[0].vendorPartNumber;
      this.PopVendPartName = GridItemData[0].vendorPartName;
      this.PopDescr = GridItemData[0].description;
      this.PopOrdered = GridItemData[0].quantity;
      this.PopShipped = GridItemData[0].shipQty;
      this.PopOrderedSo = qty;
      // this.sharedService.GetShippedInvDetails(this.CompanyValue, this.SalesOrderId, this.customerId, GridItemData[0].partId, this.OrderTypeId).subscribe(
      //   (data: any) => {
      //     this.PopOrderedSo = data.data.qty;
      //     this.PopRcvdSO = data.data.recQty;
      //     this.PopTotalInv = data.data.totalInv;
      //     this.PopAvblInv = Number(data.data.totalInv) - Number(data.data.totalAssigned);
         
      //   });
      this.GetPopList();
      this.modalService.open(itemModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
  }

  // SavePopup() {
  //   if (this.formPopup.controls.ItemDateShipped.status === this.constants.Invalid ||
  //     this.formPopup.controls.ItemNoShip.status === this.constants.Invalid) {
  //     this.PopupValidation = true;
  //     return;
  //   }
  //   else {
  //     if ((!this.PopRcvdSO ? 0 : parseInt(this.PopRcvdSO)) < (((!this.TotalShippedQty ? 0 : parseInt(this.TotalShippedQty)) - (!this.HidTxtItemShipped ? 0 : parseInt(this.HidTxtItemShipped))) + (!this.TxtItemNoShip ? 0 : parseInt(this.TxtItemNoShip)))) {
  //       this.PopupErrorMsg = this.constants.QtyExceed;
  //       return;
  //     }

  //     this.PopupErrorMsg = "";
  //     if (this.PopupRecordId == null) {
  //       let obj = {
  //         rowNo: this.PopupRowNo,
  //         soNo: this.SalesOrderNo,
  //         companyId: parseInt(this.CompanyValue),
  //         itemId: parseInt(this.PopupItemId),
  //         dateShipped: this.TxtItemDateShipped,
  //         qtyShipped: parseInt(this.TxtItemNoShip),
  //         carton: this.DDLItemCarton == '' ? 0 : parseInt(this.DDLItemCarton),
  //         isActive: true,
  //         createdBy: this.userId,
  //         createdDate: this.now = new Date(),
  //       }
  //       this.LocalItemStatusList.push(obj);
  //       this.PopupRowNo += 1;
  //     }
  //     else {
  //       const ShipData = this.LocalItemStatusList.filter(x => x.rowNo === this.PopupRecordId && x.soNo === this.SalesOrderNo && x.companyId === Number(this.CompanyValue) && x.itemId === this.PopupItemId);
  //       if (ShipData.length > 0 && ShipData != null) {
  //         ShipData[0].dateShipped = this.TxtItemDateShipped;
  //         ShipData[0].qtyShipped = parseInt(this.TxtItemNoShip);
  //         ShipData[0].carton = this.DDLItemCarton == '' ? 0 : parseInt(this.DDLItemCarton);
  //         ShipData[0].modifiedBy = this.userId;
  //         ShipData[0].modifiedDate = this.now = new Date();
  //       }
  //     }
  //     this.isPartval = false;
  //     this.GetPopList();
  //     this.ClearPopup();

  //   }
  // }
  SavePopup() {
    if (this.formPopup.controls.ItemDateShipped.status === this.constants.Invalid ||
      this.formPopup.controls.ItemNoShip.status === this.constants.Invalid) {
      this.PopupValidation = true;
      return;
    }
    else {
    //  
      if(this.PopupRecordId == 0){
        const aQty = parseInt(this.TxtItemNoShip) + parseInt(this.TotalShippedQty);
         if (parseInt(this.TxtItemNoShip) > this.PopOrderedSo || this.PopOrderedSo == this.PopShipped || aQty >  this.PopOrderedSo) {
        this.PopupErrorMsg = this.constants.QtyExceed;
        return;
      }
      
      }
      else{
        if (parseInt(this.TxtItemNoShip) > this.PopOrderedSo || this.PopOrderedSo == this.PopShipped || parseInt(this.TotalShippedQty) >  this.PopOrderedSo) {
          this.PopupErrorMsg = this.constants.QtyExceed;
          return;
        }
      }
       
      //if ((((!this.TotalShippedQty ? 0 : parseInt(this.TotalShippedQty)) - (!this.HidTxtItemShipped ? 0 : parseInt(this.HidTxtItemShipped))) + (!this.TxtItemNoShip ? 0 : parseInt(this.TxtItemNoShip)))) {
     
      this.PopupErrorMsg = "";
      if (this.PopupRecordId == null) {
        let obj = {
          rowNo: this.PopupRowNo,
          soNo: this.SalesOrderNo,
          companyId: parseInt(this.CompanyValue),
          itemId: parseInt(this.PopupItemId),
          dateShipped: this.TxtItemDateShipped,
          qtyShipped: parseInt(this.TxtItemNoShip),
          carton: this.DDLItemCarton == '' ? 0 : parseInt(this.DDLItemCarton),
          isActive: true,
          createdBy: this.userId,
          createdDate: this.now = new Date(),
        }
        this.LocalItemStatusList.push(obj);
        this.PopupRowNo += 1;
      }
      else {
        const ShipData = this.LocalItemStatusList.filter(x => x.rowNo === this.PopupRecordId && x.soNo === this.SalesOrderNo && x.companyId === Number(this.CompanyValue) && x.itemId === this.PopupItemId);
        if (ShipData.length > 0 && ShipData != null) {
          ShipData[0].dateShipped = this.TxtItemDateShipped;
          ShipData[0].qtyShipped = parseInt(this.TxtItemNoShip);
          ShipData[0].carton = this.DDLItemCarton == '' ? 0 : parseInt(this.DDLItemCarton);
          ShipData[0].modifiedBy = this.userId;
          ShipData[0].modifiedDate = this.now = new Date();
        }
      }
      this.isPartval = false;
      this.GetPopList();
      this.ClearPopup();

    }
  }
  editPopItem(rowno: any) {
    const ItemData = this.LocalItemStatusList.filter(x => x.rowNo === rowno && x.soNo === this.SalesOrderNo && x.companyId === Number(this.CompanyValue) && x.itemId === this.PopupItemId);
    if (ItemData.length > 0) {
      this.PopupRecordId = rowno;
      this.TxtItemDateShipped = this.datepipe.transform(ItemData[0].dateShipped, 'yyyy-MM-dd');
      this.TxtItemNoShip = ItemData[0].qtyShipped;
      this.DDLItemCarton = ItemData[0].carton;
      this.HidTxtItemShipped = ItemData[0].qtyShipped;
    }
  }

  deletePopItemConfirm(item, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  deleteCartonConfirm(item, contentCarton) {
    this.modalService.open(contentCarton, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  deletePopItem(item: any) {

    const ItemData = this.LocalItemStatusList.filter(x => x.rowNo === item && x.soNo === this.SalesOrderNo && x.companyId === Number(this.CompanyValue) && x.itemId === this.PopupItemId);
    if (ItemData.length > 0) {
      if (ItemData[0].id == undefined) {
        for (let index = 0; index < this.LocalItemStatusList.length; index++) {
          if (item == this.LocalItemStatusList[index].rowNo) {
            this.LocalItemStatusList.splice(index, 1);
          }
        }
      }
      else {
        ItemData[0].isActive = false;
      }
      this.isPartval = false;
      this.GetPopList();
      this.ClearPopup();
    }
  }

  deleteCarton(item: any) {
    const ItemData = this.LocalCartonList.filter(x => x.RowNo === item);
    if (ItemData.length > 0) {
      if (ItemData[0].id == undefined) {
        for (let index = 0; index < this.LocalCartonList.length; index++) {
          if (item == this.LocalCartonList[index].RowNo) {
            this.LocalCartonList.splice(index, 1);
            this.cartonNo -= 1; //Increment 1 in Max Carton No.
          }
        }
      }
      else {
        ItemData[0].isActive = false;
      }

      if (this.SalesOrderId) {
        this.isPartval = false;
      }

      this.GetCartonList();
    }
  }

  ClearPopup() {
    this.HidTxtItemShipped = 0;
    this.TxtItemDateShipped = '';
    this.TxtItemNoShip = '';
    this.DDLItemCarton = '';
    this.PopupRecordId = null;
    this.PopupErrorMsg = '';
    this.PopupValidation = false;

  }

  GetPopList() {

    this.ItemStatusList = [];
    this.TotalShippedQty = 0;
    let cartons = '';
    const ChkExist = this.LocalItemStatusList.filter(x => x.soNo === this.SalesOrderNo && x.companyId === Number(this.CompanyValue) && x.itemId === this.PopupItemId);
    if (ChkExist.length > 0) {

      ChkExist.forEach(ChkExist => {
        if (ChkExist.isActive == true) {
          this.ItemStatusList.push(ChkExist);
          this.TotalShippedQty += ChkExist.qtyShipped;
          if (Number(ChkExist.carton) != 0) {
            cartons += ChkExist.carton + ',';
          }
        }
      });
      this.colorChange(cartons);
    }
    else {
      this.loader = true;
      this.sharedService.GetShippedItemList(this.SalesOrderNo, this.CompanyValue, this.PopupItemId).subscribe(
        data => {
          
          this.data = data;
          this.PopupRowNo = 1;
          this.ItemStatusList = this.data.dataList;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.ItemStatusList = this.data.dataList;
            if (this.ItemStatusList.length > 0) {
              this.ItemStatusList.forEach(ItemStatusList => {
                ItemStatusList.rowNo = this.PopupRowNo;
                this.LocalItemStatusList.push(ItemStatusList);
                this.TotalShippedQty += ItemStatusList.qtyShipped;
                if (Number(ItemStatusList.carton) != 0) {
                  cartons += ItemStatusList.carton + ',';
                }
                this.PopupRowNo += 1;
              });
            }
            this.colorChange(cartons);
            this.loader = false;
          }
        }
      )
    }
  }

  colorChange(cartons) {
    const ChkGridItem = this.partList.filter(x => x.id === this.PopupItemId);
    if (ChkGridItem.length > 0) {
      ChkGridItem[0].shipQty = this.TotalShippedQty;

      var cartonArray = cartons.slice(0, -1).split(',');
      var cartonResult = [...new Set(cartonArray)].join();
      ChkGridItem[0].cartons = cartonResult;

      if (Number(ChkGridItem[0].shipQty) > 0) {
        if (Number(ChkGridItem[0].shipQty) == Number(ChkGridItem[0].quantity)) {
          ChkGridItem[0].bcolor = this.constants.ForestGreen;
        }
        else {
          ChkGridItem[0].bcolor = this.constants.Yellow;
        }
      }
      else {
        ChkGridItem[0].bcolor = this.constants.Silver;
      }
    }
  }

  finalSave() {

    if (this.SalesOrderId == null) {
      $("#add_search").show();
      return;
    }

    this.totalOrderQty = 0;
    this.totalShipQty = 0;

    this.partList.forEach(partLst => {
      this.totalOrderQty += partLst.quantity;
      this.totalShipQty += partLst.shipQty;
    });
    let obj = {};
    obj['OrderTypeId'] = this.OrderTypeId;
    obj['SOId'] = this.SalesOrderId;
    obj['SONumber'] = this.SalesOrderNo;
    obj['CompanyId'] = parseInt(this.CompanyValue);

  
 //Ambrish
    obj['eOrderNo']= this.eOrderNumber;
    obj['PaymentStatus']=this.paymentstatusModel;
    obj['CarrierService']=this.carrierModel;
    obj['paymentDate']=this.paymentDate;
    obj['TransactionStatus']=this.paymentDateModel;
    obj['StatusId']=Number(this.ShippingStatusModel);
   
    let dataPart = this.LocalItemStatusList;
    for (var j = 0; j < dataPart.length; j++) {
      let partObj = {};
      partObj["Id"] = dataPart[j].id;
      partObj["SoNo"] = dataPart[j].soNo;
      partObj["CompanyId"] = dataPart[j].companyId;
      partObj["ItemId"] = dataPart[j].itemId;
      partObj["DateShipped"] = this.datepipe.transform(dataPart[j].dateShipped, 'yyyy-MM-dd');
      partObj["QtyShipped"] = dataPart[j].qtyShipped;
      partObj["Carton"] = dataPart[j].carton;
      partObj["IsActive"] = dataPart[j].isActive;
      partObj["CreatedDate"] = dataPart[j].createdDate;
      partObj["ModifiedDate"] = dataPart[j].modifiedDate;
      partObj["CreatedBy"] = dataPart[j].createdBy;
      partObj["ModifiedBy"] = dataPart[j].modifiedBy;
      this.PartsDetails.push(partObj);
    }
    obj["ShippedItem"] = this.PartsDetails;

    let cartonPart = this.LocalCartonList;
    for (var j = 0; j < cartonPart.length; j++) {
      let partObj = {};
      partObj["Id"] = cartonPart[j].id;
      partObj["CompanyId"] = cartonPart[j].companyId;
      partObj["SoNo"] = cartonPart[j].soNo;
      partObj["CartonNo"] = cartonPart[j].cartonNo;
      partObj["CartonType"] = parseInt(cartonPart[j].cartonType);
      partObj["Length1"] = parseInt(cartonPart[j].length1);
      partObj["Length2"] = parseInt(cartonPart[j].length2);
      partObj["Width1"] = parseInt(cartonPart[j].width1);
      partObj["Width2"] = parseInt(cartonPart[j].width2);
      partObj["Height1"] = parseInt(cartonPart[j].height1);
      partObj["Height2"] = parseInt(cartonPart[j].height2);
      partObj["Weight1"] = parseInt(cartonPart[j].weight1);
      partObj["Weight2"] = parseInt(cartonPart[j].weight2);
      partObj["IsActive"] = cartonPart[j].isActive;
      partObj["CreatedDate"] = cartonPart[j].createdDate;
      partObj["ModifiedDate"] = cartonPart[j].modifiedDate;
      partObj["CreatedBy"] = cartonPart[j].createdBy;
      partObj["ModifiedBy"] = cartonPart[j].modifiedBy;
      partObj["tracking"] = String(cartonPart[j].tracking);
      partObj["cost"] = parseFloat(cartonPart[j].cost);
      this.cartonDetails.push(partObj);
    }
    obj["ShippedCarton"] = this.cartonDetails;
    this.loader = true;
    this.sharedService.UpdateShippedItem(obj)
      .subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.isSaved=true;
              this.modalService.dismissAll();
              $("#add_update").show();
              this.clearDetails();
              this.rerender();
              this.SearchBySOList(data.data,0);
              break;
            case 400:
              $("#wrong_msg").show();
              break;
            default:
              $("#wrong_msg").show();
          }
          this.loader = false;
        }
      );
  }

  clearDetails() {
    this.partList = [];
    this.PartsDetails = [];
    this.cartonList = [];
    this.LocalCartonList = [];
    this.cartonDetails = [];

    this.customerId = '';
    this.customerName = '';
    this.customerAdd1 = '';
    this.customerAdd2 = '';
    this.customerCity = '';
    this.customerState = '';
    this.customerZip = '';
    this.customerCountry = '';
    this.cartonValidation = false;
    this.cartonRowNo = 1;

    this.cartonNo = 1;
    this.cartonType = '1';
    this.cartonError = '';
    this.Length1 = '';
    this.Length2 = '1';
    this.Width1 = '';
    this.Width2 = '1';
    this.Height1 = '';
    this.Height2 = '1';
    this.Weight1 = '';
    this.Weight2 = '2';

    this.SoSearchTxt = '';

    this.SalesOrderNo = '';
    this.SalesOrderId = '';

    this.ItemStatusList = [];
    this.LocalItemStatusList = [];

    this.PopupRowNo = 1;

    this.HidTxtItemShipped = 0;

    this.TotalShippedQty = '';
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  norecorditem() {
    $("#no_record").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  searchitem() {
    $("#add_search").hide();
  }

  Exit(discardItem) {
    if (this.isPartval == false || this.RowEdit == true) {
      this.modalService.open(discardItem, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      this.deleteDetail = this.constants.ExitMessage;
    }
    else {
      this.router.navigate(['/adminDashboard/']);
    }
  }

  gotoDashBoard() {
    this.router.navigate(['/adminDashboard/']);
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
function fromJsonDate(paymentDate: any) {
  throw new Error('Function not implemented.');
}

