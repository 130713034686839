<section class="content-section-2">
    <div class="loading lodingtext" *ngIf="loader"></div>
    <div class="row">
        <div class="col-12">
            <!-- --------------top bredcumps css here --------- -->
            <div class="top-bredcumps">
                <div class="cstmbreadcum paddingmng-right">
                    <div class="page_title_cstms mt-2">
                        <ul class="link_current">
                            <li>
                                <a type="button">Home</a>
                            </li>
                            <li>
                                <span>Receiving List</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- --------------top bredcumps css here --------- -->
             <div class="content-section-1">
            <div class="container-fluid">
                <div class="main-section">
                    <!-- --------------------top-section start here--------- -->
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 col-12 mb-4">
                            <div class="search_filter-alltype select-arrow-change-icon">
                                <mat-select [(value)]="selected" class="customInput">
                                    <mat-option value='All' selected>All</mat-option>
                                </mat-select>
                                <input type="text" [(ngModel)]="searchText" class="searchalltype"
                                    placeholder="Search by Purchase Order Number or Vendor Name" (input)="onSearch()">
                                <button class="btnsearch_alltype" type="button" (click)="onSearch()">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- --------------------top-section end here--------- -->
                    <!-- ------------------table section--------------- -->
                   
                            <mat-table [dataSource]="dataSource" (scroll)="onScroll($event)"
                                class="table-material table-responsive">
                                <ng-container matColumnDef="menu">
                                    <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                                    <mat-cell *matCellDef="let row; let i = index;" data-label="Menu"> <mat-icon
                                            class="icon_fix">more_vert</mat-icon> </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="issuedDate">
                                    <mat-header-cell *matHeaderCellDef> Issued Date </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="Issued Date"> {{row.issueDate == null ||
                                        row.issueDate == '' ? 'N/A' : row.issueDate | date:
                                        "MMM-dd-yyyy"}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="poNumber">
                                    <mat-header-cell *matHeaderCellDef> PO Number </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="PO Number">
                                        {{row.poNumber}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="vendorName">
                                    <mat-header-cell *matHeaderCellDef> Vendor Name </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="Vendor Name">
                                        {{row.vendorName}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="contactName">
                                    <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="Contact Name">
                                        {{row.contactName}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="EstArrDate">
                                    <mat-header-cell *matHeaderCellDef> Est.<br>Arrival Date </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="Est. Arrival Date"> {{row.estArrDate |
                                        date:"MMM-dd-yyyy" }}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                    <mat-cell *matCellDef="let row" data-label="Status">
                                        {{getStatusKey(row.status)}}</mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                                    class="header-style table-responsive-header"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;let i= index;"
                                    (click)="getPOById(row)"></mat-row>
                            </mat-table>
                    <!-- ------------------table section--------------- -->

                </div>
            </div>
        </div>
        </div>
    </div>
</section>