<section class="content-section-1">
    <div class="loading lodingtext" *ngIf="loader"></div>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="masterForm" autocomplete="off">
                <!-- --------------top bredcumps css here --------- -->
                <div class="top-bredcumps">
                    <div class="cstmbreadcum paddingmng-right">
                        <div class="page_title_cstms">
                            <ul class="link_current">
                                <li>
                                    <a class="backbtns" (click)="backToList()">
                                        <mat-icon>keyboard_arrow_left</mat-icon>
                                        <span>Back to List</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/adminDashboard">Home</a>
                                </li>
                                <li>
                                    <span>Email Configuration Details</span>
                                </li>
                            </ul>
                        </div>
                        <div class="top-button d-flex justify-content-between align-items-center gap-3">
                            <button class="btn cancel-btn update-btn" (click)="Cancel()">
                                Cancel
                            </button>
                            <button class="btn cancel-btn update-btn" (click)="SendTestEmail()"
                                [disabled]="!(masterForm.value.id>0)">
                                <i aria-hidden="true" class="fa fa-envelope"></i>&nbsp;Send Test Email
                            </button>
                            <button class="btn save-btn update-btn" type="submit" (click)="AddUpdate()"
                                [disabled]="!masterForm.valid">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <!-- --------------top bredcumps css here --------- -->

                <div class="container-fluid">
                    <div class="main-section">
                        <mat-card>
                            <mat-card-header class="mb-3">
                                <h2>Email Configuration</h2>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="col-md-12 row mb-2">
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Name</mat-label>
                                            <input matInput formControlName="name" type="text" placeholder="Name"
                                                required>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Sender Email</mat-label>
                                            <input matInput formControlName="senderEmail" type="text"
                                                placeholder="Sender Email" autocomplete="off" required>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Sender Email App Password</mat-label>
                                            <input matInput formControlName="appPassword"
                                                [type]="hide1 ? 'password' : 'text'"
                                                placeholder="Sender Email App Password" autocomplete="off" required>
                                            <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' :
                                                'visibility'}}</mat-icon>

                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-12 row mb-2">
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Sender Email Password</mat-label>
                                            <input matInput formControlName="password"
                                                [type]="hide2 ? 'password' : 'text'" placeholder="Sender Email Password"
                                                autocomplete="off" required>
                                            <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' :
                                                'visibility'}}</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>SMTP server</mat-label>
                                            <input matInput formControlName="smtp" type="text" placeholder="SMTP server"
                                                required>
                                            <mat-icon matSuffix>
                                                <span mat-raised-button
                                                    matTooltip="Simple Mail Transfer Protocol. It's an application used by mail servers to send, receive, and/or relay outgoing mail between email senders and receivers. ... For example, the SMTP server Gmail uses is smtp.gmail.com"
                                                    aria-label="SMTP tooltip">
                                                    <span class="material-symbols-outlined">info</span>
                                                </span>
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Port</mat-label>
                                            <input matInput formControlName="port" type="text" placeholder="Port"
                                                required>
                                            <mat-icon matSuffix>
                                                <span mat-raised-button
                                                    matTooltip="Port 587: The standard secure SMTP port Modern email servers use port 587 for the secure submission of email for delivery."
                                                    aria-label="Port tooltip">
                                                    <span class="material-symbols-outlined">info</span>
                                                </span>
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </form>

        </div>
    </div>
</section>