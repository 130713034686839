<section class="content-section-2" id="new-layout">
    <div class="loading lodingtext" *ngIf="loader">
        <!-- <p>{{lodingtext}}</p> -->
    </div>
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms">
                <a routerLink="/companys" class="backbtns" *ngIf="IsAllCompany" (click)="BackToList()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span>Back to List</span>
                </a>
                <ul class="link_current">
                    <li>
                        <span>Company List</span>
                    </li>
                </ul>
            </div>
            <div class="top-button" *ngIf="IsAllCompany">
                <button class="cancel-button" (click)="Cancel()">
                    <span>Cancel</span>
                </button>
                <button class="save-button" (click)="SaveUpdCompany(companyId)">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </div>
    <div class="content-section-1">
    <div class="container-fluid" *ngIf="!IsAllCompany">
            <div class="main-section">
                <div class="white-box1 cust-label">
                    <div class="card-desc1">
                        <div class="row detailSection">
                            <div class="col-md-12 filter-column">
                                <div class="row  ipad-search align-items-center">
                                    <div class="col-xl-4 col-lg-4 col-sm-8 col-8 order-change-filter">
                                        <div class="cstm-radiosearch">
                                            <div class="filter_cstm_radio" (change)="companyByStatus($event)">
                                                <mat-form-field appearance="outline" class="w-100 select-arrow-change-icon">
                                                    <mat-label>Filter</mat-label>
                                                    <mat-select [(ngModel)]="filter"
                                                        [ngModelOptions]="{standalone:true}"
                                                        (selectionChange)="companyByStatus($event)">
                                                        <mat-option value='All'>All</mat-option>
                                                        <mat-option value='Active' selected="true">Active</mat-option>
                                                        <mat-option value='In-Active'>Inactive</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-5 col-sm-12 col-12 search-filter-order">
                                        <div class="search_filter-alltype">
                                            <mat-select [(ngModel)]="selectedCompanySearch"
                                                (selectionChange)="SearchCompany($event)"
                                                [ngModelOptions]="{standalone: true}"
                                                class="customInput icon-change-input">
                                                <mat-option value='all' selected>All</mat-option>
                                                <mat-option value='companyName'>Company Name</mat-option>
                                                <mat-option value='addressLine1'>Company Address</mat-option>
                                            </mat-select>
                                            <input type="text" class="searchalltype"
                                                (input)="CompanyFilterByName($event)"
                                                placeholder="{{selectedCompanyText}}">
                                            <button class="btnsearch_alltype" type="button">
                                                <mat-icon (click)="SearchByDropDown()">search</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-sm-4 col-4 new-company-button">
                                        <button class="cpyButton" (click)="AddNewCompany()">+ New Company</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="white-box1 mt-12">
                    <div class="card-desc example-container cstm_scrollbar_theme company-table">
                        <ul class="heading-section">
                            <li class="menu">Menu</li>
                            <li class="item">Item</li>
                            <li class="part-n">Company Name</li>
                            <li class="desc">Address</li>
                            <li class="uom">Parent</li>
                            <li class="ready">Status</li>
                            <li class="shiped">Last Modified</li>
                            <li class="package">Modified By</li>
                        </ul>
                        <mat-tree [dataSource]="companyDataSource" [treeControl]="treeControl">
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node *matTreeNodeDef="let node;">
                                <!-- use a disabled button to provide padding for tree leaf -->

                                <ul class="value-section" (click)="GetCompanyById(node.id)">
                                    <li class="menu"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></li>
                                    <li class="item" data-label="item">{{node.itemNo}}</li>
                                    <li class="part-n" data-label="Company Name">
                                        <span>
                                            {{node.companyName}}
                                        </span>
                                    </li>
                                    <li class="desc" data-label="Address"> {{node.addressLine1}}
                                    </li>
                                    <li class="uom" data-label="Parent">{{node.parentCompanyName}}</li>
                                    <li class="ready" data-label="Status">{{node.isActive}} </li>
                                    <li class="shiped" data-label="Last Modified">
                                        {{node.modifiedDate}}
                                    </li>
                                    <li class="package" data-label="Modified by">
                                        {{node.modifiedBy}}
                                    </li>

                                </ul>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-tree-node *matTreeNodeDef="let node;when: hasChild">
                                <ul class="value-section" (click)="GetCompanyById(node.id)">
                                    <li class="menu"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></li>
                                    <li class="item" data-label="item">{{node.itemNo}}
                                        <div class="expend-arrow-div">
                                            <a class="add-icon-tab-2">
                                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle '">
                                                    <mat-icon class="mat-icon-rtl-mirror">
                                                        {{treeControl.isExpanded(node) ? 'expand_more' :
                                                        'chevron_right'}}
                                                    </mat-icon>
                                                </button>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="part-n" data-label="Company Name">
                                        <span>
                                            {{node.companyName}}
                                        </span>
                                    </li>
                                    <li class="desc" data-label="Address">{{node.addressLine1}} <br>
                                    </li>
                                    <li class="uom" data-label="Parent">{{node.parentCompanyName}}</li>
                                    <li class="ready" data-label="Status">
                                        {{node.isActive}}
                                    </li>
                                    <li class="shiped" data-label="Last Modified">
                                        {{node.modifiedDate}}
                                    </li>
                                    <li class="package" data-label="Modified by">
                                        {{node.modifiedBy}}
                                    </li>
                                </ul>
                            </mat-tree-node>
                        </mat-tree>
                        <!-- ----------------------------------------------------------table- section----------------------------- -->
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="content-section-1">
    <div class="container-fluid first-section-top" *ngIf="IsAllCompany">
        <div class="main-section">
                <form [formGroup]="CompanyForm">
                    <div class="row">
                        <!-- Company Form -->
                        <div class="col-12">
                            <div class="row">
                                <div class="col-xl-4  col-12 first-card select-arrow-change-icon mb-3">
                                    <div class="card company-card">
                                        <mat-card class="example-card p-0 h-100 card_cst_one border-0">
                                            <mat-card-content class="card_cstm-mngsd">
                                                <div class="row align-items-center">
                                                    <div class="col-8 mb-3 first-card">
                                                        <mat-form-field class="forShipping"
                                                        appearance="outline">
                                                        <mat-label>Company Name</mat-label>
                                                        <input matInput type="text" placeholder="Enter Company Name"
                                                            formControlName="companyName">
                                                    </mat-form-field>
                                                    <span class="text-danger valid-name"
                                                        *ngIf="(CompanyformError.companyName.touched || submitted) && CompanyformError.companyName.errors?.required">
                                                        Company Name is required
                                                    </span>
                                                    </div>
                                                    <div class="col-4 mb-3 fourth-card">
                                                        <mat-form-field class="forShipping" appearance="outline">
                                                        <mat-label>Status</mat-label>
                                                        <mat-select formControlName='isActive'>
                                                            <mat-option *ngFor="let statusList of StatusList"
                                                                [value]="statusList.value">
                                                                {{statusList.type}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <span class="text-danger valid-phone"
                                                        *ngIf="(CompanyformError.isActive.touched || submitted) && CompanyformError.isActive.errors?.required">
                                                        Status is required
                                                    </span>
                                                    </div>
                                                </div>
                                               <div class="row">
                                                <div class="col-12 mb-3">
                                                    <mat-form-field class="forShipping w-100" appearance="outline">
                                                        <mat-label>Street Address</mat-label>
                                                        <input matInput type="text" maxlength="100" minlength="3"
                                                            formControlName="addressLine1"
                                                            placeholder="Enter Street Address">
                                                    </mat-form-field>
                                                </div>
                                               </div>
                                                <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <mat-form-field class="forShipping w-100" appearance="outline">
                                                            <mat-label>Unit, Suit, Building, etc</mat-label>
                                                            <input matInput type="text" maxlength="100"
                                                                formControlName="addressLine2"
                                                                placeholder="Enter Unit, Suit, Building, etc">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <mat-form-field class="forShipping w-100" appearance="outline">
                                                            <mat-label>City</mat-label>
                                                            <input matInput type="text" placeholder="Enter City"
                                                                formControlName="city">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center">
                                                    <div class="col-4 mb-3 first-card">
                                                        <mat-form-field class="forShipping"
                                                        appearance="outline">
                                                        <mat-label>State</mat-label>
                                                        <mat-select formControlName='stateId'>
                                                            <mat-option disabled selected>Select State</mat-option>
                                                            <mat-option *ngFor="let item of stateData"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <span class="text-danger valid-phone"
                                                        *ngIf="(CompanyformError.stateId.touched || submitted) && CompanyformError.stateId.errors?.required">
                                                        State is required
                                                    </span>
                                                    </div>
                                                    <div class="col-8 mb-3 fourth-card">
                                                        <mat-form-field class="forShipping w-100"
                                                            appearance="outline">
                                                            <mat-label>Zip Code</mat-label>
                                                            <input matInput type="text" placeholder="xxxxx-xxxx"
                                                                formControlName='zipCode' minlength="5" maxlength="10">
                                                                <!-- (input)="RemoveMaskValidation('C')"> -->
                                                        </mat-form-field>
                                                        <span class="text-danger valid-name"
                                                            *ngIf="(CompanyformError.zipCode.touched || submitted) && CompanyformError.zipCode.errors?.required">
                                                            Zip Code is required
                                                        </span>
                                                        <span class="text-danger valid-name"
                                                            *ngIf="CompanyformError.zipCode.errors?.minlength">
                                                            Enter a valid Zip Code
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="forShipping w-100"
                                                        *ngIf="countrList.length > 0" appearance="outline">
                                                        <mat-label>Country</mat-label>
                                                        <mat-select formControlName='countryId'
                                                            (selectionChange)="changeCompanyCountry($event)">
                                                            <mat-option *ngFor="let item of countrList" [value]="item.id">
                                                                {{item.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    </div>
                                                </div>                                
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-12 second-card mb-3">
                                    <div class="card company-card">
                                        <mat-card class="example-card p-0 h-100 card_cst_one border-0">
                                            <mat-card-content class="card_cstm-mngsd">
                                               <div class="row">
                                                <div class="col-12">
                                                    <mat-form-field class="forShipping mb-3 p-0 w-100 icon-change-input "
                                                    appearance="outline">
                                                    <mat-label>Parent</mat-label>
                                                    <mat-select [(value)]="SelectedCompanyValue"
                                                        (valueChange)="companyChangeValue($event)">
                                                        <ng-container *ngTemplateOutlet="recursiveOptions;
                                                        context: { $implicit: companyDetailsList, depth: 1 }">
                                                        </ng-container>
                                                        <ng-template #recursiveOptions let-list let-depth="depth">
                                                            <ng-container *ngFor="let program of list">
                                                                <mat-option [value]="program.id" selected
                                                                    *ngIf="program.parentId == null && program.id == CompanyForm.value.id">
                                                                    None
                                                                </mat-option>
                                                                <mat-option [value]="program.id"
                                                                    *ngIf="program.id != CompanyForm.value.id && program.parentId != CompanyForm.value.id"
                                                                    [ngStyle]="{ 'padding-left.px': depth <= 1 ? null : 16 * depth}">
                                                                    {{program.companyName}}
                                                                </mat-option>
                                                                <ng-container
                                                                    *ngIf="!!program.children && program.children.length > 0">
                                                                    <ng-container *ngTemplateOutlet="recursiveOptions;
                                                        context: { $implicit: program.children, depth: depth + 1 }">
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-template>
                                                    </mat-select>
                                                </mat-form-field>
                                                </div>
                                               </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="cmpny-no position-relative">
                                                            <mat-form-field class="forShipping mb-3 p-0 w-100"
                                                                appearance="outline">
                                                                <mat-label>Company Phone Number</mat-label>
                                                                <input matInput type="text" placeholder="x (xxx) xxx-xxxx"
                                                                    formControlName="phoneNumber" mask="0 (000) 000 0000">
                                                            </mat-form-field>
                                                            <span class="text-danger valid-phone-company"
                                                                *ngIf="(CompanyformError.phoneNumber.touched || submitted) && CompanyformError.phoneNumber.errors?.required">
                                                                Company Phone Number is required
                                                            </span>
                                                            <span class="text-danger valid-phone-company"
                                                                *ngIf="CompanyformError.phoneNumber.errors?.minlength">
                                                                Enter a valid Phone Number
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                               <div class="row">
                                                <div class="col-12">
                                                    <mat-form-field class="forShipping mb-3 p-0 w-100" appearance="outline">
                                                        <mat-label>Company Email</mat-label>
                                                        <input matInput type="text" placeholder="Enter Company Email"
                                                            formControlName="contactEmail">
                                                    </mat-form-field>
                                                    <span class="text-danger valid-phone"
                                                        *ngIf="(CompanyformError.contactEmail.touched || submitted) && CompanyformError.contactEmail.errors?.required">
                                                        Company Email is required
                                                    </span>
                                                    <span class="text-danger valid-phone"
                                                    *ngIf="CompanyformError.contactEmail.errors?.email || CompanyformError.contactEmail.errors?.pattern">
                                                    Enter a valid email address
                                                </span>
                                                </div>
                                               </div>                                                
                                               <div class="row">
                                                <div class="col-12">
                                                    <mat-form-field class="forShipping mb-3 p-0 w-100" appearance="outline">
                                                        <mat-label>Company Domain</mat-label>
                                                        <input matInput type="text" placeholder="Enter Company Domain"
                                                            formControlName="companyDomain">
                                                    </mat-form-field>
                                                </div>
                                               </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="forShipping mb-3 p-0 w-100" appearance="outline">
                                                            <mat-label>Contact Name</mat-label>
                                                            <input matInput type="text" placeholder="Enter Contact Name"
                                                                formControlName="compContactName">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="forShipping  p-0 w-100" appearance="outline">
                                                            <mat-label>Contact Phone Number</mat-label>
                                                            <input matInput type="text" placeholder="x (xxx) xxx-xxxx"
                                                                formControlName="compContactNumber" mask="0 (000) 000 0000">
                                                        </mat-form-field>
                                                        <span class="text-danger valid-phone"
                                                            *ngIf="CompanyformError.compContactNumber.errors?.minlength">
                                                            Enter a valid Phone Number
                                                        </span>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                                <!-- Compane WareHouse -->
                                <div class="col-xl-4 col-lg-6 col-md-6 col-12 fourth-card select-arrow-change-icon mb-3">
                                    <form [formGroup]="CompanyWareHouseForm">
                                        <div class="card company-card">
                                            <mat-card class="example-card p-0 h-100 card_cst_one border-0">
                                                <mat-card-content class="card_cstm-mngsd">
                                                    <div class="row align-items-center">
                                                        <div class="col-8 mb-3 first-card">
                                                            <mat-form-field class="forShipping"
                                                            appearance="outline">
                                                            <mat-label>Warehouse Name</mat-label>
                                                            <input matInput type="text"
                                                                placeholder="Enter WareHouse Name"
                                                                formControlName="name">
                                                        </mat-form-field>
                                                        <span class="text-danger valid-name"
                                                            *ngIf="(CompanyWareHouseFormError.name.touched || submitted) && CompanyWareHouseFormError.name.errors?.required">
                                                            WareHouse Name is required
                                                        </span>
                                                        </div>
                                                        <div class="col-4 mb-3 fourth-card">
                                                            <mat-form-field class="forShipping"
                                                            appearance="outline">
                                                            <mat-label>Status</mat-label>
                                                            <mat-select formControlName='isActive'
                                                                (selectionChange)="ChanegeCompanyStatus($event)">
                                                                <mat-option *ngFor="let statusList of StatusList"
                                                                    [value]="statusList.value">
                                                                    {{statusList.type}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <span class="text-danger valid-phone"
                                                            *ngIf="(CompanyWareHouseFormError.isActive.touched || submitted) && CompanyWareHouseFormError.isActive.errors?.required">
                                                            Status is required
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <mat-form-field class="forShipping w-100"
                                                        appearance="outline">
                                                        <mat-label>Street Address</mat-label>
                                                        <input matInput type="text" maxlength="100" minlength="3"
                                                            formControlName="address1"
                                                            placeholder="Enter Street Address">
                                                    </mat-form-field>
                                                    <span class="text-danger valid-phone"
                                                        *ngIf="(CompanyWareHouseFormError.address1.touched || submitted) && CompanyWareHouseFormError.address1.errors?.required">
                                                        Address is required
                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <mat-form-field class="forShipping w-100"
                                                        appearance="outline">
                                                        <mat-label>Unit, Suit, Building, etc</mat-label>
                                                        <input matInput type="text" maxlength="100"
                                                            formControlName="address2"
                                                            placeholder="Enter Unit, Suit, Building, etc">
                                                    </mat-form-field>
                                                        </div>
                                                    </div>
                                                   <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <mat-form-field class="forShipping w-100"
                                                        appearance="outline">
                                                        <mat-label>City</mat-label>
                                                        <input matInput type="text" placeholder="Enter City"
                                                            formControlName="city">
                                                        <span class="text-danger valid-phone"
                                                            *ngIf="(CompanyWareHouseFormError.city.touched || submitted) && CompanyWareHouseFormError.city.errors?.required">
                                                            City is required
                                                        </span>
                                                    </mat-form-field>
                                                    </div>
                                                   </div>
                                                    <div class="row align-items-center">
                                                        <div class="col-4 mb-3 first-card">
                                                            <mat-form-field class="forShipping"
                                                            appearance="outline">
                                                            <mat-label>State</mat-label>
                                                            <mat-select formControlName='stateId'>
                                                                <mat-option disabled selected>Select State</mat-option>
                                                                <mat-option *ngFor="let item of WareHouseStateData"
                                                                    [value]="item.id">
                                                                    {{item.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <span class="text-danger valid-phone"
                                                            *ngIf="(CompanyWareHouseFormError.stateId.touched || submitted) && CompanyWareHouseFormError.stateId.errors?.required">
                                                            State is required
                                                        </span>
                                                        </div>
                                                        <div class="col-8 mb-3 fourth-card">
                                                            <div class="position-relative">
                                                                <mat-form-field class="forShipping w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Zip Code</mat-label>
                                                                    <input matInput type="text" placeholder="xxxxx-xxxx" minlength="5" maxlength="10"
                                                                         formControlName='zipCode'>
                                                                        <!-- (input)="RemoveMaskValidation('W')"> -->
                                                                </mat-form-field>
                                                                <span class="text-danger valid-name"
                                                                    *ngIf="(CompanyWareHouseFormError.zipCode.touched || submitted) && CompanyWareHouseFormError.zipCode.errors?.required">
                                                                    Zip Code is required
                                                                </span>
                                                                <span class="text-danger valid-name"
                                                                    *ngIf="CompanyWareHouseFormError.zipCode.errors?.minlength">
                                                                    Enter a valid Zip Code
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <mat-form-field class="forShipping w-100"
                                                        appearance="outline">
                                                        <mat-label>Country</mat-label>
                                                        <mat-select formControlName='countryId'
                                                            (selectionChange)="changeWareHouseCountry($event)">
                                                            <mat-option *ngFor="let item of countrList"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                        </div>
                                                    </div>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Additional Information Start -->
                    <div class="container-fluid">
                        <div class="row additional-section">
                            <div class="col-12 p-0">
                                <div class="card">
                                    <mat-card class="example-card h-100 card_cst_one">
                                        <mat-card-header class="addition-info-card-header">
                                            <mat-card-title class="mt-card-title addition-info-heading">Additional
                                                Information</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content class="card_cstm-mngsd additional-card-content">
                                            <div class="row">
                                                <!-- Business Channels -->
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                                    <form [formGroup]="BusinessChaneelsForm">
                                                        <div class="card" formArrayName="Channels">
                                                            <div class="card-heading">
                                                                <h4>Business Channels</h4>
                                                            </div>
                                                            <div class="row row-padding top-heading">
                                                                <div class="col-8">
                                                                    <span class="table-heading padding-mobile">Business
                                                                        Channels</span>
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="table-heading">Status</span>
                                                                </div>
                                                                <div class="col-1"></div>
                                                            </div>
                                                            <div *ngFor="let chanel of Channels.controls; let i = index;"
                                                                [formGroupName]="i">
                                                                <div class="row additional-border-row">
                                                                    <div class="col-6 p-0 column-padding">
                                                                        <span *ngIf="i==0">
                                                                            <mat-form-field>
                                                                                <input matInput type="text" readonly
                                                                                    formControlName="channelName">
                                                                            </mat-form-field>
                                                                        </span>
                                                                        <span *ngIf="i >0">
                                                                            <mat-form-field>
                                                                                <input matInput type="text"
                                                                                    formControlName="channelName">
                                                                            </mat-form-field>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-5 p-0">
                                                                        <mat-form-field class="w-100 icon-change-input">
                                                                            <mat-select placeholder="Status"
                                                                                formControlName="status">
                                                                                <mat-option
                                                                                    *ngFor="let statusList of StatusList"
                                                                                    [value]="statusList.value">
                                                                                    {{statusList.type}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                    <div class="col-1 m-auto" *ngIf="i >0">
                                                                        <mat-icon (click)="RemoveBusinessChannels(i)">
                                                                            <mat-icon>cancel</mat-icon>
                                                                        </mat-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="text-start">
                                                                <button type="button" class="add-row-button"
                                                                    (click)="AddBusinessChannels()">+ Add Row</button>
                                                            </div>


                                                        </div>
                                                    </form>
                                                </div>
                                                <!-- Customer Roles -->
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-right desktop-padding-left-right ipad-card-2">
                                                    <form [formGroup]="CustomerRolesForm">
                                                        <div class="card" formArrayName="Roles">
                                                            <div class="card-heading">
                                                                <h4>Customer Roles</h4>
                                                            </div>
                                                            <div class="row row-padding top-heading">
                                                                <div class="col-4 column-padding"><span
                                                                        class="table-heading">Role Name</span></div>
                                                                <div class="col-4 padding-mobile"><span
                                                                        class="table-heading">Business Channel</span>
                                                                </div>
                                                                <div class="col-3"><span
                                                                        class="table-heading">Status</span>
                                                                </div>
                                                                <div class="col-1"></div>
                                                            </div>
                                                            <div *ngFor="let role of Roles.controls; let i = index;"
                                                                [formGroupName]="i">
                                                                <div class="row additional-border-row">
                                                                    <div class="col-4 p-0">
                                                                        <span *ngIf="i <2">
                                                                            <mat-form-field class="w-100">
                                                                                <input matInput type="text" readonly
                                                                                    formControlName="rolesName">
                                                                            </mat-form-field>
                                                                        </span>
                                                                        <span *ngIf="i >1">
                                                                            <mat-form-field class="w-100 ">
                                                                                <input matInput type="text"
                                                                                    formControlName="rolesName">
                                                                            </mat-form-field>
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-4 p-0">
                                                                        <mat-form-field class="w-100 icon-change-input">
                                                                            <mat-select placeholder="Channel"
                                                                                formControlName="chanels">
                                                                                <ng-container
                                                                                    *ngFor="let channels of BusinessChaneelsForm.value.Channels">
                                                                                    <mat-option
                                                                                        *ngIf="channels.status == 1"
                                                                                        [value]="channels.channelName">
                                                                                        {{ channels.channelName }}
                                                                                    </mat-option>
                                                                                </ng-container>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                    <div class="col-4 p-0 position-relative">
                                                                        <mat-form-field class="w-90 icon-change-input">
                                                                            <mat-select placeholder="Status"
                                                                                formControlName="status">
                                                                                <mat-option
                                                                                    *ngFor="let statusList of StatusList"
                                                                                    [value]="statusList.value">
                                                                                    {{statusList.type}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                        <div class=" m-auto p-0 cancel-column"
                                                                            *ngIf="i >1">
                                                                            <mat-icon (click)="RemoveCustomerRoles(i)">
                                                                                <mat-icon>cancel</mat-icon>
                                                                            </mat-icon>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="text-start">
                                                                <button type="button" class="add-row-button"
                                                                    (click)="AddCustomerRoles()">+ Add Row</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-left ipad-card-3">
                                                    <div class="card">
                                                        <div class="card-heading">
                                                            <h4>Company Logo</h4>
                                                        </div>
                                                        <div class="row row-padding top-heading">
                                                            <div class="col-3 column-padding"><span
                                                                    class="table-heading">Image</span></div>
                                                            <div class="col-3 padding-mobile">
                                                                <span class="table-heading padding-mobile">File
                                                                    Name</span>
                                                            </div>
                                                            <div class="col-6 company-logo-info">
                                                                <span class="table-heading padding-mobile">Company Logo
                                                                    should be .PNG file 100h x 250w</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-12 company-logo-column">
                                                                    <img *ngIf="imageUrl && !imageError"
                                                                        [src]="imageUrl" class="company-logo-img">
                                                                </div>
                                                            </div>
                                                            <div class="row additional-border-row image-row">
                                                                <div class="col-12" *ngIf="!imageError">
                                                                    <div *ngFor="let img of files;let i = index;">
                                                                        <div class="col-6">
                                                                            {{img.name}}
                                                                        </div>
                                                                        <div class="col-6" (click)="DeleteFile(i)"
                                                                            *ngIf="files!= undefined && files.length > 0">
                                                                            <mat-icon
                                                                                style="float: right;">cancel</mat-icon>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6"
                                                                    *ngIf="imageUrl != undefined && files.length == 0"
                                                                    (click)="DefaultDeleteFile()">
                                                                    <mat-icon style="float: right;">cancel</mat-icon>
                                                                </div>
                                                                <div *ngIf="imageError" style="color: red;">
                                                                    {{ imgError }}
                                                                </div>
                                                            </div>
                                                            <div class="row align-items-center" appFileUpload
                                                                (filesChangeEmiter)="onFileChange($event.target.files)">
                                                                <div
                                                                    class="col-xl-6 col-lg-5 col-md-5 col-12 drop-file-col">
                                                                    <div class="dropzone">
                                                                        <div class="text-wrapper">
                                                                            <div class="centered">
                                                                                <input type="file" accept=".png"
                                                                                    class="choose-file-hidden upload_icon_custom"
                                                                                    (change)="onFileChange($event.target.files)"
                                                                                    (click)="$event.target.value=null">
                                                                                <label for="file"
                                                                                    class="drop-file-text">Drop file
                                                                                    here
                                                                                    !</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-xl-6 col-lg-5 col-md-5 col-12 text-start select-file-column">
                                                                    <button class="save-button">
                                                                        <input type="file" accept=".png"
                                                                            class="choose-file-hidden upload_icon_custom"
                                                                            (change)="onFileChange($event.target.files)"
                                                                            (click)="$event.target.value=null">
                                                                        <span>Select File</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Additional Information End -->
                    <!-- Default Settings Start -->
                    <div class="container-fluid">
                        <div class="row additional-section">
                            <div class="col-12 p-0">
                                <div class="card">
                                    <mat-card class="example-card h-100 card_cst_one">
                                        <mat-card-header class="addition-info-card-header">
                                            <mat-card-title class="mt-card-title addition-info-heading">Default
                                                Settings</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content class="card_cstm-mngsd additional-card-content mt-2">
                                            <div class="row">
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                                    <mat-form-field class="forShipping mb-3 p-0 w-100"
                                                        appearance="outline">
                                                        <mat-label>Quote Expiration Date (days)</mat-label>
                                                        <input matInput type="number" mask="00"
                                                            formControlName="defQuoteDays">
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                                    <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                        <mat-label>FOB Point</mat-label>
                                                        <mat-select placeholder="FOB Point" formControlName="fobPoint">
                                                            <mat-option [value]=0 disabled>
                                                                Select FOB Point
                                                            </mat-option>
                                                            <mat-option *ngFor="let fop of FobPoint" [value]="fop.id">
                                                                {{fop.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    class="col-xl-4 col-lg-12 col-12 col-pd-left col-pd-right desktop-padding-right ipad-card-1">
                                                    <mat-form-field class="w-100 icon-change-input" appearance="outline">
                                                        <mat-label>Shipping Terms</mat-label>
                                                        <mat-select placeholder="Shipping Terms"
                                                            formControlName="shippingTerm">
                                                            <mat-option [value]=0 disabled>
                                                                Select Shipping Terms
                                                            </mat-option>
                                                            <mat-option *ngFor="let st of shippingTerms"
                                                                [value]="st.id">
                                                                {{st.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Default Settings End -->
                </form>
        </div>
    </div>
</div>
</section>