<div class="menu-sidebar-top">
  <li class="nav-item mb-view sidebar-ank">
    <div class="d-flex p-1 comapny-dropdown-height select-arrow-change-icon">
      <div>
        <a class="menu-toggle border-bottom-0 d-flex align-items-center" (click)="tog()"><i class="fas fa-bars mr-2 sidebar-cross-icon"></i><span
            class="menu-title">Menu</span></a>
      </div>
      <div class="company-dropdowm-height select-arrow-change-icon">
        <mat-form-field appearance="outline" class="w-80">
          <!-- <mat-label>Company</mat-label> -->
          <mat-select [(value)]="SelectedCompanyValue" (valueChange)="companyChangeValue($event)">
            <mat-option [value]="0">Select Company</mat-option>
            <ng-container *ngTemplateOutlet="recursiveOptions;
                                            context: { $implicit: CompanyObj, depth: 1 }">
            </ng-container>
            <ng-template #recursiveOptions let-list let-depth="depth">
              <ng-container *ngFor="let program of list">
                <mat-option [value]="program.value" [ngStyle]="{ 'padding-left.px': depth <= 1 ? null : 16 * depth}">
                  {{ program.text }}
                </mat-option>
                <ng-container *ngIf="!!program.children && program.children.length > 0">
                  <ng-container *ngTemplateOutlet="recursiveOptions;
                                            context: { $implicit: program.children, depth: depth + 1 }">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </li>
</div>
<section class="sidebar" (click)="toggleMenu()">
  <ul class="navigation navigation-main SideBar" id="main-menu-navigation">
   

    <li class="nav-item mb-view" (click)="mbview()">
      <a title="Dashboard" routerLink="./adminDashboard" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <span class="material-icons">home</span>
        <span class="menu-title">Dashboard</span></a>
    </li>
    <li class="nav-item">
      <a title="Company" class="has-sub" (click)="togglesideBarCompany()">
        <span class="material-icons">
          apartment
        </span>
        <span class="menu-title">Company</span>
        <span class="menuarrow">
          <i class="fas fa-chevron-right" [className]="
              arrowCompany ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveCompany }" class="sub-menu">
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./companyList" title="CompanyList"> <span>Company List
              (Old)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./companys" title="CompanyList"> <span>Company List</span></a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a title="Users" class="has-sub" (click)="togglesideBar()">
        <span class="material-icons">
          groups
        </span>
        <span class="menu-title">Users</span>
        <span class="menuarrow">
          <i class="fas fa-chevron-right"
            [className]="arrow ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i></span></a>
      <ul [ngClass]="{ active: isSideBarActive }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./users" title="UserManagement"> <span>User Management</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./roles" title="RoleManagement"> <span>Role Management</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./permissions" title="Permissions"> <span>Permissions</span></a>
        </li>
      </ul>
    </li>
    <li class="nav-item mb-view" (click)="mbview()">
      <a routerLinkActive="active" title="Customers" routerLink="./customers">
        <img src="/assets/image/customer-1.svg" /><span class="menu-title">Customers</span></a>
    </li>
    <li class="nav-item mb-view" (click)="mbview()">
      <a routerLinkActive="active" title="Vendors" routerLink="./vendors">
        <img src="/assets/image/suppliers.svg" /><span class="menu-title">Vendors</span></a>
    </li>
    <li class="nav-item mb-view" (click)="mbview()">
      <a routerLinkActive="active" title="Products" routerLink="./products">
        <img src="/assets/image/Product.svg" />
        <span class="menu-title">Products</span></a>
    </li>
    <li class="nav-item">
      <a title="Part" class="has-sub" (click)="togglesideBarCustompart()">
        <img src="/assets/image/parts.svg" /><span class="menu-title">Parts & Services</span>
        <span class="menuarrow">
          <i class="fas fa-chevron-right" [className]="
              arrowCustomPart ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveCustomPart }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./parts" title="Individual Part">
            <span>Individual Parts</span></a>
        </li>
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./partDetail" title="Parts"><span>Parts (New)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./customPart" title="Custom Part"><span>Custom Parts </span></a>
        </li>
      </ul>
    </li>
    <li class="nav-item mb-view" (click)="mbview()">
      <a routerLinkActive="active" title="Sales Order" routerLink="./SO">
        <img src="/assets/image/sales-order.svg" /><span class="menu-title">Sales Order</span></a>
    </li>
    <!-- <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Sales Orders" class="has-sub" (click)="togglesideBarOrder()">
        <img src="/assets/image/sales-order.svg" />
        <span class="menu-title">Sales Order</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowPO ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveOrder }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./orders" title="Sales Orders">
            <span>Create or Modify SO</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./SO" title="Sales Order">
            <span>Sales Order(New)</span></a>
        </li>
      </ul>
    </li> -->
    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Purchase Orders" class="has-sub" (click)="togglesideBarPurchseOrder()">
        <img src="/assets/image/purchase-order.svg" /><span class="menu-title">Purchase Order</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowPO ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActivePO }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./purchasorder" title="Purchase Orders">
            <span>Purchase Order (Old)</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./poList" title="Purchase Orders">
            <span>Purchase Order (New)</span></a>
        </li>
      </ul>
    </li>

    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Operations Management" class="has-sub" (click)="togglesideBarOperationsManagement()">
        <img src="/assets/image/operations-manag.svg" /><span class="menu-title">Operations Management</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowOM ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveOM }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./productionjobs" title="Production Jobs">
            <span>Production Jobs</span></a>
        </li>

        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./myproductiontask" title="My Production Task">
            <span>My Production Task</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./teammanagement" title="Team Management">
            <span>Team Management</span></a>
        </li>
      </ul>
    </li>
    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Purchase Orders" class="has-sub" (click)="toggleSippingReceiving()">
        <img src="/assets/image/purchase-order.svg" /><span class="menu-title">Shipping & Receiving</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
           arrowSR ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveSR }" class="sub-menu">

        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./assigninventorysos" title="Assign Inventory SOs">
            <span>Fulfill Parts for SOs(old)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./fulfillment" title="Assign Inventory SOs">
            <span>Fulfillment</span></a>
        </li>
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./receiveorder" title="Receive">
            <span>Receiving (Old)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./receiving" title="Receive">
            <span>Receiving</span></a>
        </li>    
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./putaway" title="Put Away">
            <span>Put Away</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./shipment" title="shipment">
            <span>Shipping</span></a>
        </li>
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./shipping" title="Shipping">
            <span>Shipping (Old)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./picklists" title=" Pick List">
            <span>Picking</span></a>
        </li>
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./picklist" title="Create Pick List">
            <span>Create Pick List</span></a>
        </li> -->
      </ul>
    </li>
    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Inventory" class="has-sub" (click)="togglesideBarInventory()">
        <img src="/assets/image/inventory.svg" /><span class="menu-title">Inventory</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowInventory ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveInventory }" class="sub-menu">
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./inventory" title="Inventory">
            <span>Inventory List (old)</span></a>
        </li> -->
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./inventory-list" title="Inventory List">
            <span>Inventory Management</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./locationlevel" title="Location Details">
            <span>Warehouse Setup</span></a>
        </li>
        <!-- work on production -->
        <!-- <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./inventorybelowminimum" title="Inventory Below Minimum">
            <span>Inventory Below Minimum</span></a>
        </li> -->
      </ul>
    </li>

    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="RMA" class="has-sub" (click)="togglesideBarRMA()">
        <!-- <i class="fas fa-cogs"></i> -->
        <span class="material-icons">
          refresh
        </span>
        <span class="menu-title">RMA</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowRMA ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveRMA }" class="sub-menu">
        <li class="receive mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="#" title="Company">
            <!-- <img src="/assets/image/steering-wheel.svg" class="customicon"> -->
            <span>Company </span>
          </a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="rmavendor" title="Vendor">
            <span>Vendor </span></a>
        </li>
      </ul>
    </li>

    <li class="nav-item">
      <a title="Settings" class="has-sub" (click)="togglesideBarUtility()">
        <!-- <i class="fas fa-gear"></i> -->
        <span class="material-icons">
          settings
        </span>
        <span class="menu-title">Settings</span>
        <span class="menuarrow">
          <i class="fas fa-cogs" [className]="
              arrowUtility ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>
      <ul [ngClass]="{ active: isSideBarActiveUtility }" class="sub-menu">
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./settings" title="General Settings">
            <span>General Settings</span></a>
        </li>
        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./utility" title="Utility"><span>Utility </span></a>
        </li>
        <li class="nav-item">
          <a title="Categories" class="has-sub" (click)="togglesideCategories()">
            <span class="menu-title"> Custom Fields </span>
            <span class="menuarrow">
              <i class="fas fa-cogs" [className]="
                  arrowCategories
                    ? 'fas fa-chevron-down'
                    : 'fas fa-chevron-right'
                "></i></span></a>
          <ul [ngClass]="{ active: isSideBarCategories }" class="sub-menu">
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Category" routerLink="./vehiclelist">
                <span class="menu-title">Vehicles List</span></a>
            </li>

            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Category" routerLink="./category">
                <span class="menu-title">Parts Category</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Alert Type" routerLink="./alerttype">
                <span class="menu-title">Alert Type</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Alert Categories" routerLink="./alertcategory">
                <span class="menu-title">Alert Category</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Status Reason Codes" routerLink="./statusreasoncode">
                <span class="menu-title">Status Reason Codes</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Task Category" routerLink="./taskcategory">
                <span class="menu-title">Task Category</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Skill Level Category" routerLink="./skilllevelcategory">
                <span class="menu-title">Skill Level Category</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Customer Contact Type" routerLink="./customercontacttype">
                <span class="menu-title">Customer Contact Type</span></a>
            </li>
          </ul>
        </li>

        <li class="nav-item mb-view" (click)="mbview()">
          <a routerLinkActive="active" title="Task" routerLink="./searchablefield">
            <span class="menu-title">Searchable Fields</span></a>
        </li>

        <li class="nav-item">
          <a title="Production Set-up" class="has-sub" (click)="togglesideProductSetUp()">
            <span class="menu-title"> Production Set-up </span>
            <span class="menuarrow">
              <i class="fas fa-cogs" [className]="
                  arrowProduction
                    ? 'fas fa-chevron-down'
                    : 'fas fa-chevron-right'
                "></i> </span></a>
          <ul [ngClass]="{ active: isSideBarProduction }" class="sub-menu">
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Task" routerLink="./task">
                <span class="menu-title">Task</span></a>
            </li>

            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Workflows" routerLink="./workflows">
                <span class="menu-title">Workflows</span></a>
            </li>
          </ul>
        </li>
        <li class="nav-item mb-view" (click)="mbview()">
          <a routerLinkActive="active" title="Part Questions" routerLink="./partquestions">
            <span class="menu-title">Part Questions</span></a>
        </li>

        <li class="nav-item">
          <a title="Email Settings" class="has-sub" (click)="togglesideEmail()">
            <span class="menu-title"> Email Settings </span>
            <span class="menuarrow">
              <i class="fas fa-cogs" [className]="
                  arrowEmail ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
                "></i></span></a>
          <ul [ngClass]="{ active: isSideBarEmail }" class="sub-menu">
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Email Template" routerLink="./emailtemplate">
                <span class="menu-title">Email Template</span></a>
            </li>

            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Email Configuration" routerLink="./emailconfig">
                <span class="menu-title">Email Configuration</span></a>
            </li>
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Email Config" routerLink="./email-config-list">
                <span class="menu-title">Email Configuration(New)</span></a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a title="Dashboards" class="has-sub" (click)="togglesidedashBoard()">
            <span class="menu-title"> Dashboards </span>
            <span class="menuarrow">
              <i class="fas fa-cogs" [className]="
                  arrowdashboard
                    ? 'fas fa-chevron-down'
                    : 'fas fa-chevron-right'
                "></i></span></a>
          <ul [ngClass]="{ active: isSideBarDashboard }" class="sub-menu">
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Widgets" routerLink="./widget">
                <span class="menu-title">Widgets</span></a>
            </li>

            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Templates" routerLink="./template">
                <span class="menu-title">Templates</span></a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a title="Api Management" class="has-sub" (click)="togglesideApiManagement()">
            <span class="menu-title"> API Management </span>
            <span class="menuarrow">
              <i class="fas fa-cogs" [className]="
                  arrowApiManagement
                    ? 'fas fa-chevron-down'
                    : 'fas fa-chevron-right'
                "></i></span></a>
          <ul [ngClass]="{ active: isSideBarApiManagement }" class="sub-menu">
            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" title="Ecommerce Api Details" routerLink="./ecommerceapidetails">
                <span class="menu-title">eCommerce API Details</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </li>

    <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
      <a title="Report" class="has-sub" (click)="togglesideBarReport()">
        <!-- <i class="far fa-file"></i> -->
        <span class="material-icons">
          assessment
        </span>
        <span class="menu-title">Report</span>
        <span class="menuarrow">
          <i class="far fa-file" [className]="
              arrowReport ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            "></i></span></a>

      <ul [ngClass]="{ active: isSideBarActiveReport }" class="sub-menu">
        <li class="receive mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./report" title="SalesOrder">
            <!-- <img src="/assets/image/steering-wheel.svg" class="customicon"> -->
            <span>Sales Order </span>
          </a>
        </li>
        <li class="receive mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./soreport" title="SalesOrder">
            <!-- <img src="/assets/image/steering-wheel.svg" class="customicon"> -->
            <span>Sales Report (New) </span>
          </a>
        </li>

        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./purchaseorderreport" title="Purchase Order">
            <span>Purchase Order </span></a>
        </li>

        <li class="mb-view" (click)="mbview()">
          <a routerLinkActive="active" routerLink="./poreport" title="Purchase Order">
            <span>Purchase Report (New) </span></a>
        </li>

        <li class="nav-item" [style.display]="ChKPO1 == true ? 'block' : 'none'">
          <a title="InventoryReport" class="has-sub" (click)="togglesideBarInventoryReport()"><span
              class="menu-title">Inventory Report</span>
            <span class="menuarrow">
              <i class="far fa-file" [className]="
                  arrowInventoryReport
                    ? 'fas fa-chevron-down'
                    : 'fas fa-chevron-right'
                "></i></span></a>

          <ul [ngClass]="{ active: isSideBarActiveInventoryReport }" class="sub-menu">
            <li class="receive mb-view" (click)="mbview()">
              <a routerLinkActive="active" routerLink="./inventoryreportbypartname" title="InventoryReportByName">
                <!-- <img src="/assets/image/steering-wheel.svg" class="customicon"> -->
                <span>Inventory Report With Part Name </span>
              </a>
            </li>

            <li class="mb-view" (click)="mbview()">
              <a routerLinkActive="active" routerLink="./inventoryreportbydateandlocation"
                title="InventoryReportByDateandLocation">
                <span>Inventory Report Date & Location </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </ul>
</section>